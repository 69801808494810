import React from 'react'

// Define Tolerances Type
export const TWO_SIDE_TOL = 'TwoSideTol'
export const UPPER_ONE_SIDE_TOL = 'UpperOneSideTol'
export const LOWER_ONE_SIDE_TOL = 'LowerOneSideTol'
export const UNKNOWN_TOL = 'UnknownTol'

// Define Value State
export const GOOD_STATE = 'good'
export const WARN_UPPER_STATE = 'warn_upper'
export const WARN_LOWER_STATE = 'warn_lower'
export const NG_UPPER_STATE = 'ng_upper'
export const NG_LOWER_STATE = 'ng_lower'
export const NONE_STATE = 'none'

function checkToleranceType(props) {
  const { usl, lsl, uwl, lwl } = props

  if (usl !== null && lsl !== null) {
    if (lwl === null) {
      return UPPER_ONE_SIDE_TOL
    } else if (uwl === null) {
      return LOWER_ONE_SIDE_TOL
    } else {
      return TWO_SIDE_TOL
    }
  } else if (usl !== null && lsl === null) {
    return UPPER_ONE_SIDE_TOL
  } else if (usl === null && lsl !== null) {
    return LOWER_ONE_SIDE_TOL
  } else {
    return UNKNOWN_TOL
  }
}

function getStateBasedOnToleranceType(value, usl, lsl, uwl, lwl, toleranceType, defaultState) {
  switch (toleranceType) {
    case TWO_SIDE_TOL:
      if (value > usl) {
        return NG_UPPER_STATE
      } else if (value < lsl) {
        return NG_LOWER_STATE
      } else if (value > uwl) {
        return WARN_UPPER_STATE
      } else if (value < lwl) {
        return WARN_LOWER_STATE
      } else if (value <= usl && value >= lsl) {
        return GOOD_STATE
      } else {
        return defaultState
      }
    case UPPER_ONE_SIDE_TOL:
      if (value > usl) {
        return NG_UPPER_STATE
      } else if (uwl && value > uwl) {
        // when Nat Boundary
        return WARN_UPPER_STATE
      } else {
        return GOOD_STATE
      }
    case LOWER_ONE_SIDE_TOL:
      if (value < lsl) {
        return NG_LOWER_STATE
      } else if (lwl && value < lwl) {
        // when Nat Boundary
        return WARN_LOWER_STATE
      } else {
        return GOOD_STATE
      }
    default:
      return defaultState
  }
}

export function getValueState(props) {
  const toleranceType = checkToleranceType(props)
  const { val, usl, lsl, uwl, lwl, natMode } = props

  return getStateBasedOnToleranceType(val, usl, lsl, uwl, lwl, toleranceType, GOOD_STATE)
}

export function getOverviewValueState(props) {
  const toleranceType = checkToleranceType(props)
  const { val, usl, lsl, uwl, lwl, natMode } = props

  return getStateBasedOnToleranceType(val, usl, lsl, uwl, lwl, toleranceType, NONE_STATE)
}

function getStateBasedOnToleranceTypeForScrollBoard(value, usl, lsl, uwl, lwl, toleranceType, defaultState) {
  switch (toleranceType) {
    case TWO_SIDE_TOL:
      if (value > usl) {
        return NG_UPPER_STATE
      } else if (value < lsl) {
        return NG_LOWER_STATE
      } else if (value > uwl) {
        return WARN_UPPER_STATE
      } else if (value < lwl) {
        return WARN_LOWER_STATE
      } else if (value <= usl && value >= lsl) {
        return GOOD_STATE
      } else {
        return defaultState
      }
    case UPPER_ONE_SIDE_TOL:
      if (value > usl) {
        return NG_UPPER_STATE
      } else {
        return GOOD_STATE
      }
    case LOWER_ONE_SIDE_TOL:
      if (value < lsl) {
        return NG_LOWER_STATE
      } else {
        return GOOD_STATE
      }
    default:
      return defaultState
  }
}

export function getScrollBoardValueState(props) {
  const toleranceType = checkToleranceType(props)
  const { val, usl, lsl, uwl, lwl } = props

  return getStateBasedOnToleranceTypeForScrollBoard(val, usl, lsl, uwl, lwl, toleranceType, GOOD_STATE)
}
