import TabPanel, { Item } from 'devextreme-react/tab-panel'
import { connect } from 'react-redux'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import './AdminForm.scss'
import AdminAccount from './account/AdminAccount'
import AdminDBInfo from './db-info/AdminDBInfo'
import AdminLanguage from './language/AdminLanguage'
import AdminLicense from './license/AdminLicense'
import AdminRibbon from './ribbon/AdminRibbon'
import AdminEndUsers from './end-users/AdminEndUsers'
import AdminPushSettings from './push-settings/AdminPushSettings'

function AdminForm(pageKey) {
  return (
    <div className="admin-form-container">
      <TabPanel className="admin-form-container" id="adminPanel" animationEnabled={true} swipeEnabled={false} focusStateEnabled={false}>
        <Item className="grid-tab-container" title={dqTranslateMsg('Page_AdminSettings_' + 'Company') + ' / ' + dqTranslateMsg('Page_AdminSettings_' + 'License')}>
          <AdminEndUsers pageKey={pageKey} />
        </Item>
        <Item className="grid-tab-container" title={dqTranslateMsg('Page_AdminSettings_' + 'Account')}>
          <AdminAccount pageKey={pageKey} />
        </Item>
        <Item className="grid-tab-container" title={dqTranslateMsg('Page_AdminSettings_' + 'Language')}>
          <AdminLanguage pageKey={pageKey} />
        </Item>
        <Item className="grid-tab-container" title={dqTranslateMsg('Page_AdminSettings_' + 'DBInfo')}>
          <AdminDBInfo pageKey={pageKey} />
        </Item>
        <Item className="grid-tab-container" title={dqTranslateMsg('Page_AdminSettings_' + 'Ribbon')}>
          <AdminRibbon pageKey={pageKey} />
        </Item>
        {/* <Item className="grid-tab-container" title={dqTranslateMsg('Page_AdminSettings_' + 'PushSettings')}>
          <AdminPushSettings pageKey={pageKey} />
        </Item> */}
      </TabPanel>
    </div>
  )
}

const mapStateToProps = (state) => ({
  common: state.common,
  chartRawData: state.chartRawData,
})

export default connect(mapStateToProps)(AdminForm)
