import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import CatalogUtils from './CatalogUtils'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'
import CPUtils from 'src/functions/CommonPageUtils'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'
import * as VCArima from 'src/functions/ValueChartArima'

export function toolTipBalloonCallback(args, settings, configSettings, values, char) {
  const checkArimaData = (args) => {
    // const arimaName = [dqTranslateMsg('Page_ValueChart_' + 'LowPred'), dqTranslateMsg('Page_ValueChart_' + 'UpperPred'), dqTranslateMsg('Page_ValueChart_' + 'Pred')]
    const arimaName = ['low_pred', 'pred', 'upper_pred']

    let isMatch = false
    args.forEach((element) => {
      if (arimaName.includes(element.seriesId)) {
        isMatch = true
      } else {
        isMatch = false
      }
    })

    return isMatch
  }

  let displayStr = ''

  if (checkArimaData(args)) {
    const preStr = dqTranslateMsg('Page_ValueChart_' + 'Prediction')
    displayStr += `<b>${preStr}</b><br>`
    args.forEach((element) => {
      if (element.seriesName) {
        displayStr += `${element.seriesName}<br>`
      }
      if (element.data) {
        const numberItemName = dqTranslateMsg('Page_ValueChart_' + 'Index')
        displayStr += `${numberItemName} : ${element.data[0]} <br>`
      }
      const itemName = dqTranslateMsg('Db_field_Value_' + 'V0001')
      displayStr += `${itemName} : ${DNUtils.calcValueDisplay(element.data[1], configSettings)} <br>`
    })
  } else {
    args.forEach((element) => {
      if (element.seriesName) {
        displayStr += `<b>${element.seriesName}</b><br>`
      }

      if (element.data) {
        const numberItemName = dqTranslateMsg('Page_ValueChart_' + 'Index')
        displayStr += `${numberItemName} : ${element.data[0]} <br>`
      }
    })
    settings.page.toolTipInfoItems.forEach((item) => {
      const dataField = item
      const valueAtIndex = values[args[0].data[0] - 1][dataField]
      const itemName = dqTranslateMsg('Db_field_Value_' + dataField.charAt(0).toUpperCase() + dataField.slice(1))

      if (valueAtIndex) {
        if (dataField === 'v0004') {
          const dateTimeFormat = CPUtils.getDisplayDateTimeSec(settings.page.dateTimeTooltipFormat, valueAtIndex)
          displayStr += `${itemName} : ${dateTimeFormat} <br>`
        } else if (dataField === 'v0001') {
          displayStr += `${itemName} : ${DNUtils.valueDisplay(valueAtIndex, char, configSettings)} <br>`
        } else {
          displayStr += `${itemName} : ${valueAtIndex} <br>`
        }
      } else {
        displayStr += `${itemName} : Unknown <br>`
      }
    })
  }

  if (settings.page.specificationLimitEnable === true || settings.page.warningLimitEnable === true || settings.page.averageLimitEnable === true || settings.page.nominalLimitEnable === true) {
    const limitsItemName = dqTranslateMsg('Page_ValueChart_' + 'Limits')
    displayStr += `<br>${limitsItemName}<br>`

    if (settings.page.specificationLimitEnable === true) {
      displayStr += typeof settings.usl === 'number' ? `USL : ${DNUtils.axisValueDisplay(settings.usl, char, configSettings)} <br>` : ''
      displayStr += typeof settings.lsl === 'number' ? `LSL : ${DNUtils.axisValueDisplay(settings.lsl, char, configSettings)} <br>` : ''
    }

    if (settings.page.warningLimitEnable === true) {
      displayStr += typeof settings.uwl === 'number' ? `UWL : ${DNUtils.calcValueDisplay(settings.uwl, configSettings)} <br>` : ''
      displayStr += typeof settings.lwl === 'number' ? `LWL : ${DNUtils.calcValueDisplay(settings.lwl, configSettings)} <br>` : ''
    }

    if (settings.page.averageLimitEnable === true) {
      const trans = dqTranslateMsg('Page_ValueChart_' + 'Average')
      displayStr += typeof settings.average === 'number' ? `${trans} : ${DNUtils.calcValueDisplay(settings.average, configSettings)} <br>` : ''
    }

    if (settings.page.nominalLimitEnable === true) {
      const trans = dqTranslateMsg('Page_ValueChart_' + 'Nominal')
      displayStr += typeof settings.nominal === 'number' ? `${trans} : ${settings.nominal} <br>` : ''
    }

    if (settings.page.plausibilityLimitEnable === true) {
      displayStr += typeof settings.upl === 'number' ? `UPL: ${settings.upl} <br>` : ''
      displayStr += typeof settings.lpl === 'number' ? `LPL: ${settings.lpl} <br>` : ''
    }

    if (settings.page.scrapLimitEnable === true) {
      displayStr += typeof settings.url === 'number' ? `URL : ${settings.url} <br>` : ''
      displayStr += typeof settings.lrl === 'number' ? `LRL : ${settings.lrl} <br>` : ''
    }

    if (settings.page.acceptanceLimitEnable === true) {
      displayStr += typeof settings.uacl === 'number' ? `UAcL : ${settings.uacl} <br>` : ''
      displayStr += typeof settings.lacl === 'number' ? `LAcL : ${settings.lacl} <br>` : ''
    }
  }

  return displayStr
}

export function makeXAxisLabelFormat(value, index, settings, refValues) {
  const xAxisLabelItems = settings.page.xAxisLabelItems
  const valueArray = refValues

  if (xAxisLabelItems.length === 0) {
    return value
  }

  if (value < 1 || value > valueArray.length) {
    return ''
  }

  let xLabel = ''
  xAxisLabelItems.forEach((item) => {
    if (!ETCUtils.isPositiveInteger(value)) {
      return
    }

    const itemValue = valueArray[value - 1][item]

    if (xLabel === '') {
      if (itemValue !== null && itemValue !== undefined) {
        if (item === 'v0004') {
          xLabel = CPUtils.getDisplayDateTimeSec(settings.page.dateTimeLabelDisplayFormat, itemValue)
        } else {
          xLabel = CatalogUtils.convertByCatalog(item, itemValue, settings.catalogs)
        }
      } else {
        xLabel = 'null'
      }
    } else {
      xLabel =
        item === 'v0004'
          ? `${xLabel}/${CPUtils.getDisplayDateTimeSec(settings.page.dateTimeLabelDisplayFormat, itemValue)}`
          : `${xLabel}/${CatalogUtils.convertByCatalog(item, itemValue, settings.catalogs)}`
    }
  })

  return xLabel
}
