import { Item } from 'devextreme-react/toolbar'
import IL from 'src/functions/IconLoader'
import './HeaderIcon.scss'
import { convertUserRoleToString, userRoleLevel } from './constants/authLevelConstants'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import { MONO_BLUE, MONO_RED, COLOR } from './constants/iconPathDefine'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'

class HeaderIcon {
  static fontFamily = (lang) => {
    const filtered = [
      {
        Value: 'dq-font-1',
        country: 'common',
        text: dqTranslateMsg('Header_' + 'Font_1'),
      },
      {
        Value: 'dq-font-2',
        country: 'en',
        text: dqTranslateMsg('Header_' + 'Font_2'),
      },
      {
        Value: 'dq-font-3',
        country: 'en',
        text: dqTranslateMsg('Header_' + 'Font_3'),
      },
      {
        Value: 'dq-font-4',
        country: 'en',
        text: dqTranslateMsg('Header_' + 'Font_4'),
      },
      {
        Value: 'dq-font-5',
        country: 'en',
        text: dqTranslateMsg('Header_' + 'Font_5'),
      },
      {
        Value: 'dq-font-6',
        country: 'ja',
        text: dqTranslateMsg('Header_' + 'Font_6'),
      },
      {
        Value: 'dq-font-7',
        country: 'ja',
        text: dqTranslateMsg('Header_' + 'Font_7'),
      },
      {
        Value: 'dq-font-8',
        country: 'ja',
        text: dqTranslateMsg('Header_' + 'Font_8'),
      },
      {
        Value: 'dq-font-9',
        country: 'ja',
        text: dqTranslateMsg('Header_' + 'Font_9'),
      },
      {
        Value: 'dq-font-10',
        country: 'ja',
        text: dqTranslateMsg('Header_' + 'Font_10'),
      },
      {
        Value: 'dq-font-11',
        country: 'ja',
        text: dqTranslateMsg('Header_' + 'Font_11'),
      },
      {
        Value: 'dq-font-12',
        country: 'kr', // Assuming Korean fonts for Spoqa Han Sans Neo
        text: dqTranslateMsg('Header_' + 'Font_12'),
      },
      {
        Value: 'dq-font-13',
        country: 'kr', // Assuming Korean fonts for D2 Coding
        text: dqTranslateMsg('Header_' + 'Font_13'),
      },
      {
        Value: 'dq-font-14',
        country: 'kr', // Assuming Korean fonts for Nanum Gothic
        text: dqTranslateMsg('Header_' + 'Font_14'),
      },
      {
        Value: 'dq-font-15',
        country: 'kr', // Assuming Korean fonts for Nanum Myeongjo
        text: dqTranslateMsg('Header_' + 'Font_15'),
      },
    ].filter((item) => item.country === lang || item.country === 'common')

    if (filtered) {
      return filtered
    }

    return [
      {
        Value: 'dq-font-1',
        country: 'common',
        text: dqTranslateMsg('Header_' + 'Font_1'),
      },
    ]
  }

  static menuIconType = () => [
    {
      Value: MONO_BLUE,
      Path: MONO_BLUE,
      text: dqTranslateMsg('Header_MonoBlue'),
    },
    {
      Value: MONO_RED,
      Path: MONO_RED,
      text: dqTranslateMsg('Header_MonoRed'),
    },
    {
      Value: COLOR,
      Path: COLOR,
      text: dqTranslateMsg('Header_Color'),
    },
  ]

  static getIconMenuPath(iconType) {
    const iconMenu = HeaderIcon.menuIconType().find((item) => item.Value === iconType)

    if (iconMenu === undefined) {
      return HeaderIcon.menuIconType()[0]
    }

    return iconMenu.Path
  }

  static languageData = () => [
    {
      Value: 'en',
      text: dqTranslateMsg('Header_' + 'English'),
    },
    {
      Value: 'ja',
      text: dqTranslateMsg('Header_' + 'Japanese'),
    },
    {
      Value: 'kr',
      text: dqTranslateMsg('Header_' + 'Korean'),
    },
  ]

  static headerMenuConfig = [
    { level: convertUserRoleToString(userRoleLevel.superuser), use: true },
    { level: convertUserRoleToString(userRoleLevel.admin), use: true },
    { level: convertUserRoleToString(userRoleLevel.engineer), use: true },
    { level: convertUserRoleToString(userRoleLevel.operator), use: false },
    { level: convertUserRoleToString(userRoleLevel.cmm), use: false },
    { level: convertUserRoleToString(userRoleLevel.qsense), use: false },
  ]

  static headerMenuAdminConfig = [
    { level: convertUserRoleToString(userRoleLevel.superuser), use: true },
    { level: convertUserRoleToString(userRoleLevel.admin), use: true },
    { level: convertUserRoleToString(userRoleLevel.engineer), use: false },
    { level: convertUserRoleToString(userRoleLevel.operator), use: false },
    { level: convertUserRoleToString(userRoleLevel.cmm), use: false },
    { level: convertUserRoleToString(userRoleLevel.qsense), use: false },
  ]

  static loadConfiguration(authLevel, iconType, theme, evt) {
    const configAuth = this.headerMenuConfig.find((item) => item.level === authLevel)

    if (configAuth) {
      if (configAuth.use) {
        return (
          <Item visible={true} location={'after'} widget={'dxButton'}>
            <div className="config-page-button-container">
              {/* <img src={IL.getNavImgPath(mode, theme, IL.NAV.CONFIGURATION)} width="30" height="30" onClick={evt} /> */}
              <img src={ps['configuration'].getPageIcon(iconType, theme)} width="30" height="30" onClick={evt} />
            </div>
          </Item>
        )
      }
    } else {
      return null
    }
  }

  static loadAdminConfiguration(authLevel, iconType, theme, evt) {
    const adminConfigAuth = this.headerMenuAdminConfig.find((item) => item.level === authLevel)

    if (adminConfigAuth) {
      if (adminConfigAuth.use) {
        return (
          <Item visible={true} location={'after'} widget={'dxButton'}>
            <div className="load-button-container">
              {/* <img src={IL.getNavImgPath(mode, theme, IL.NAV.ADMIN_CONFIGURATION)} width="30" height="30" onClick={evt} /> */}
              <img src={ps['adminSettings'].getPageIcon(iconType, theme)} width="30" height="30" onClick={evt} />
            </div>
          </Item>
        )
      }
    } else {
      return null
    }
  }
}

export default HeaderIcon
