import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import { backendPath } from './defines/DBInfo'
import JwtUtils from 'src/functions/JwtUtils'
const path = backendPath

export const setCharInfo = async (updatedData) => {
  const requestBody = {
    requestMsg: updatedData,
  }

  try {
    const accessToken = JwtUtils.loadToken()
    const response = await fetch(path + 'data/setCharInfo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${accessToken}`,
      },
      body: JSON.stringify(requestBody),
    })

    const result = await response.json()
    return result
  } catch (error) {
    throw error
  }
}

export const deleteChar = async (part_id, char_id) => {
  const makeReqBody = (part_id, char_id) => {
    let requestBody = { part_id: part_id, char_id: char_id }

    return requestBody
  }

  try {
    const accessToken = JwtUtils.loadToken()
    const requestBody = JSON.stringify(makeReqBody(part_id, char_id))

    const response = await fetch(path + 'data/deleteChar', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${accessToken}`,
      },
      body: requestBody,
    })

    const result = await response.json()
    return result
  } catch (error) {
    throw error
  }
}
