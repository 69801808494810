import { Column, DataGrid, Editing, Item, Lookup, Scrolling, Toolbar, ValidationRule } from 'devextreme-react/data-grid'
import { Label, SimpleItem } from 'devextreme-react/form'
import TextTitle from 'src/functions/TextTitle'
import { dqTranslateMsg } from '../dq-convert/DQLanguage'
import { useSelector } from 'react-redux'
import { SelectBox } from 'devextreme-react'

const DataGridSimpleItem = ({ ref, colSpan, type, label, dataSource, onSave, configState }) => {
  const mapFilterField = (type, useField) => {
    if (!['part', 'char', 'value'].includes(type)) {
      throw new Error('Invalid type')
    }

    const translationPrefix = {
      part: 'Db_field_Part_',
      char: 'Db_field_Characteristics_',
      value: 'Db_field_Value_',
    }

    return useField[type].map((item) => {
      let captionCondition = ''
      if (configState.commonSettings.displayFieldNameWithNumber) {
        captionCondition += `[ ${item.text} ] `
      }
      captionCondition += dqTranslateMsg(translationPrefix[type] + item.text)
      return { value: item.value, text: captionCondition }
    })
  }

  const useField = configState.useFieldList
  const useFilterField = mapFilterField(type, useField)

  return (
    <SimpleItem colSpan={colSpan}>
      <DataGrid
        ref={ref}
        style={{ width: '100%', height: '150px', padding: '3px' }}
        showRowLines={true}
        showBorders={true}
        dataSource={dataSource}
        onSaved={onSave}
        noDataText={dqTranslateMsg('Page_Database_' + 'NoData')}
        // onEditorPreparing={(e) => {
        //   if (e.dataField == 'field_id') {
        //     e.editorOptions.width = '200px'
        //     e.editorOptions.showDropDownButton = true
        //   }
        // }}
        scrolling={{
          mode: 'standard',
          showScrollbar: 'onHover',
          useNative: false,
        }}
      >
        {/* <Toolbar>
          <Item location="before">
            <div width="100%" style={{ fontSize: '16px' }} f>
              {label}
            </div>
          </Item>
          <Item name="addRowButton" />
        </Toolbar> */}
        <Editing mode="batch" useIcons={true} allowAdding={true} allowDeleting={true} allowUpdating={true} />
        <Scrolling mode="standard" />

        <Column dataField="field_id" width="70%" caption={dqTranslateMsg('Page_Database_' + 'FieldID')} alignment="center">
          <Lookup dataSource={useFilterField} width={'auto'} valueExpr="value" displayExpr="text" />
          <ValidationRule type="required" />
        </Column>
        <Column dataField="value" width="30%" caption={dqTranslateMsg('Page_Database_' + 'ConditionValue')} alignment="center">
          <ValidationRule type="required" />
        </Column>
      </DataGrid>
    </SimpleItem>
  )
}

export default DataGridSimpleItem
