import { GroupItem } from 'devextreme-react/form'
import produce from 'immer'
import { useDispatch, useSelector } from 'react-redux'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import ColorBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ColorBoxSimpleItem'

export default function ThemeMarkArea(pageKey, pageState, configState) {
  const dispatch = useDispatch()
  // const pageKey = 'qualityControlChart'

  // const pageState = useSelector((state) => state.common.pages[pageKey])

  const onColorBoxMarkAreaValueChanged = (e, type, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(pageState, (draft) => {
      if (!draft.lineChart.markAreas[type]) {
        draft.lineChart.markAreas[type] = {} // Initialize the key if it doesn't exist
      }
      draft.lineChart.markAreas[type][dataField] = value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`theme-mark-area-group-item`} colCount={4} caption={dqTranslateMsg('SidePanel_' + 'MarkArea')}>
      {ColorBoxSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'NG'),
        labelLocation: 'top',
        name: 'color',
        value: pageState.lineChart.markAreas.error.color,
        disabled: false,
        editAlphaChannel: true,
        onValueChanged: (e) => onColorBoxMarkAreaValueChanged(e, 'error', 'color'),
      })}
      {ColorBoxSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'Warn'),
        labelLocation: 'top',
        name: 'color',
        value: pageState.lineChart.markAreas.warn.color,
        disabled: false,
        editAlphaChannel: true,
        onValueChanged: (e) => onColorBoxMarkAreaValueChanged(e, 'warn', 'color'),
      })}
      {ColorBoxSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'Good'),
        labelLocation: 'top',
        name: 'color',
        value: pageState.lineChart.markAreas.good.color,
        disabled: false,
        editAlphaChannel: true,
        onValueChanged: (e) => onColorBoxMarkAreaValueChanged(e, 'good', 'color'),
      })}
      {ColorBoxSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'OutOfControl'),
        labelLocation: 'top',
        name: 'color',
        value: pageState.lineChart.markAreas.outOfControl?.color,
        disabled: false,
        editAlphaChannel: true,
        onValueChanged: (e) => onColorBoxMarkAreaValueChanged(e, 'outOfControl', 'color'),
      })}
    </GroupItem>
  )
}
