import { useEffect, useRef, useState } from 'react'
import ReactECharts from 'echarts-for-react'
import produce from 'immer'
import { connect } from 'react-redux'
import ApiStatus from 'src/api/defines/ApiStatus'
import { getHistogram } from 'src/api/histogram'
import { useLanguage } from 'src/contexts/languages'
import CCUtils from 'src/functions/CommonCalcUtils'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'
import useEChartsResize from 'src/hook/apache-echarts/useEChartResize'
import * as histImpl from 'src/pages/chart-histogram/components/main-view/chart-option/HistogramChartOptionImpl'
import PCUtils from 'src/functions/ProcessCapabilityUtils'

const getViewPortTheme = (pageKey, theme) => {
  if (pageKey !== 'reportPrint') {
    return theme.includes('dark') || theme.includes('contrast') ? 'dark' : 'light'
  }

  return 'light'
}

function distributionName(dist) {
  const defineUseDistribution = [
    { value: 0, type: 'Auto-calculation' },
    { value: 1, type: 'Normal-distribution' },
    { value: 2, type: 'Log-normal-distribution' },
    { value: 21, type: 'Folded-normal-distribution' },
    { value: 30, type: 'Weibull-distribution' },
  ]

  const targetDistribution = defineUseDistribution.find((item) => item.value === dist)

  return targetDistribution.type
}

function makeSettings(info, curPage, configPage, selected) {
  const tgRawData = selected.find((item) => item.part_id === info.part_id && item.char_id === info.char_id)
  const targetValue = tgRawData.valueRaw
  const targetChar = tgRawData.charRaw

  const srcData = targetValue.map((value) => value.v0001)
  const lsl = PCUtils.getLsl(targetChar)
  const usl = PCUtils.getUsl(targetChar)

  const { sum, length } = PCUtils.getSum(srcData)
  const average = PCUtils.getAverage(sum, length)
  const stdDev = PCUtils.getStandardDeviation(srcData, average)
  const minValue = CCUtils.getMin(srcData)
  const maxValue = CCUtils.getMax(srcData)

  const nominal = PCUtils.getNominal(targetChar)
  const gapUslLsl = PCUtils.getUslLslGap(usl, lsl)
  const warnOffset = PCUtils.getWarningOffset(configPage.commonSettings.warningLimitPercentage)
  const lwl = PCUtils.getLwl(lsl, gapUslLsl, warnOffset)
  const uwl = PCUtils.getUwl(usl, gapUslLsl, warnOffset)
  const x99_865 = PCUtils.getUcl(average, stdDev)
  const x0_135 = PCUtils.getLcl(average, stdDev)

  const minValues = [minValue, lsl, x0_135].filter((value) => value !== null)
  const maxValues = [maxValue, usl, x99_865].filter((value) => value !== null)
  const min = Math.min(...minValues)
  const max = Math.max(...maxValues)
  const xAxisLeft = min - (max - min) * 0.1
  const xAxisRight = max + (max - min) * 0.1

  const decimalPlaces = configPage.commonSettings.calcDisplayDecimalPlaces

  const settings = {
    ...curPage,
    lsl: lsl,
    usl: usl,
    lwl: lwl,
    uwl: uwl,
    x0_135: x0_135,
    x99_865: x99_865,
    nominal: nominal,
    warnOffset: warnOffset,
    xAxisLeft: xAxisLeft,
    xAxisRight: xAxisRight,
    decimalPlaces: decimalPlaces,
    srcData: srcData,
  }

  const configSettings = configPage

  return { targetChar, targetValue, settings, configSettings }
}

function makeUpdateOption(data, settings) {
  const bin_count_list = data.bin_count_list
  const bin_density_list = data.bin_density_list
  const bin_width_start_list = data.bin_width_start_list
  const bin_width_center_list = data.bin_width_center_list
  const bin_width_end_list = data.bin_width_end_list
  settings.xAxisLeft = Math.min(settings.xAxisLeft, data.x0_135)
  settings.xAxisRight = Math.max(settings.xAxisRight, data.x99_865)

  const addStartMinArray = []
  const addStartCenterArray = []
  const addStartMaxArray = []
  const addZeroCountMinArray = []
  let dec = bin_width_end_list[0] - bin_width_start_list[0]
  // for (let i = bin_width_start_list[0]; i >= settings.xAxisLeft; i = i - dec) {
  //   addStartMinArray.push(i)
  //   addStartCenterArray.push(i + dec / 2)
  //   addStartMaxArray.push(i + dec)
  //   addZeroCountMinArray.push(0)
  // }
  let ii = bin_width_start_list[0]
  do {
    ii = ii - dec
    addStartMinArray.push(ii)
    addStartCenterArray.push(ii + dec / 2)
    addStartMaxArray.push(ii + dec)
    addZeroCountMinArray.push(0)
  } while (ii >= settings.xAxisLeft - dec)

  addStartMinArray.sort((a, b) => a - b)
  addStartCenterArray.sort((a, b) => a - b)
  addStartMaxArray.sort((a, b) => a - b)

  const addEndMaxArray = []
  const addEndCenterArray = []
  const addEndMinArray = []
  const addZeroCountMaxArray = []
  dec = bin_width_end_list[0] - bin_width_start_list[0]
  // for (let i = bin_width_end_list[bin_width_end_list.length - 1]; i <= settings.xAxisRight; i = i + dec) {
  //   addEndMinArray.push(i - dec)
  //   addEndCenterArray.push(i - dec / 2)
  //   addEndMaxArray.push(i)
  //   addZeroCountMaxArray.push(0)
  // }
  let i = bin_width_end_list[bin_width_end_list.length - 1]
  do {
    i = i + dec
    addEndMinArray.push(i - dec)
    addEndCenterArray.push(i - dec / 2)
    addEndMaxArray.push(i)
    addZeroCountMaxArray.push(0)
  } while (i <= settings.xAxisRight + dec)

  addEndMaxArray.sort((a, b) => a - b)
  addEndCenterArray.sort((a, b) => a - b)
  addEndMinArray.sort((a, b) => a - b)

  bin_count_list.unshift(...addZeroCountMinArray)
  bin_count_list.push(...addZeroCountMaxArray)

  bin_density_list.unshift(...addZeroCountMinArray)
  bin_density_list.push(...addZeroCountMaxArray)

  bin_width_start_list.unshift(...addStartMinArray)
  bin_width_start_list.push(...addEndMinArray)

  bin_width_center_list.unshift(...addStartCenterArray)
  bin_width_center_list.push(...addEndCenterArray)

  bin_width_end_list.unshift(...addStartMaxArray)
  bin_width_end_list.push(...addEndMaxArray)

  return {
    bin_count_list: bin_count_list,
    bin_density_list: bin_density_list,
    bin_width_start_list: bin_width_start_list,
    bin_width_center_list: bin_width_center_list,
    bin_width_end_list: bin_width_end_list,
    curve_x_values: data.curve_x_values,
    curve_y_values: data.curve_y_values,
    xBar: data.xBar,
    x99_865: data.x99_865,
    x50: data.x50,
    x0_135: data.x0_135,
  }
}

const ApacheEChartHistogramComponentReport = ({ common, rawData = [], info = { part_id: 1, char_id: 1 }, option = [], theme, pageKey, setHistoImage, index }) => {
  const { key, parts, chars, values, charArray, curPage, configPage, valuesArranged } = rawData

  const { language } = useLanguage()
  const chartRef = useRef(null)
  const [chartOption, setChartOption] = useState(option)
  // const [isLoadingPanel, setIsLoadingPanel] = useState(false)

  const apacheEChartOpt = {
    renderer: 'svg',
    locale: ETCUtils.checkLangForECharts(language),
  }

  useEffect(() => {
    setChartOption(option)
  }, [option])

  const fetchDataForChart = async (chartRef, pageKey, targetChar, targetValue, settings, configSettings) => {
    if (targetValue.length < 5) {
      console.error('value is not enough')
      return
    }

    try {
      const result = await getHistogram(targetChar, targetValue, settings, configSettings)
      if (!ApiStatus.isSuccess(result.status)) {
        alert(getErrCommMsg(result.status))
      }

      const data = result.data
      if (data.bin_count_list) {
        const htOption = chartRef.current.getEchartsInstance().getOption()

        const optionBaseData = makeUpdateOption(data, settings)
        const bellCurveData = optionBaseData.curve_x_values.map((x, index) => [x, optionBaseData.curve_y_values[index]])
        const next = produce(htOption, (draft) => {
          draft.series = []
          draft.series.push({
            type: 'bar',
            xAxisIndex: 0,
            // data: optionBaseData.bin_count_list,
            data: optionBaseData.bin_density_list,
            itemStyle: {
              color: settings.barChart.histogramBar.color,
            },
            barWidth: '99%', // Adjust the bar width to fit y[0] within the x-axis range
          })
          draft.series.push({
            type: 'line',
            name: 'curve',
            xAxisIndex: 1,
            data: bellCurveData,
            // data: optionBaseData.curve_x_values.map((x, index) => [x, optionBaseData.converted_curve_y_values[index]]),
            symbol: 'none',
            itemStyle: {
              color: settings.barChart.bellCurve.color,
            },
            lineStyle: {
              type: settings.lineChart.chartLines.type,
              width: settings.lineChart.chartLines.width,
            },
          })
          draft.series.push({
            type: 'line',
            name: 'Lines',
            xAxisIndex: 1,
            markLine: {
              animation: false,
              symbol: 'none',
              precision: 10,
              // precision: settings.lineChart.markLines.precision,
              data: histImpl.convertToMarkLine(data.dist, settings, configSettings, optionBaseData),
              emphasis: {
                disabled: true,
              },
            },
          })

          draft.xAxis[0].data = optionBaseData.bin_width_center_list
          draft.xAxis[1].min = optionBaseData.bin_width_center_list[0]
          draft.xAxis[1].max = optionBaseData.bin_width_center_list[optionBaseData.bin_width_center_list.length - 1]
          draft.xAxis[0].axisLabel.formatter = (value, index) => histImpl.makeXAxisLabelFormat(value, configSettings)

          draft.tooltip[0].formatter = function (args) {
            return histImpl.toolTipBalloonCallback(args, targetChar, settings, configSettings, data.dist, optionBaseData)
          }

          if (pageKey !== 'reportPrint') {
            draft.title[0].text = `${targetChar.c2002 ?? 'empty'} P${targetChar.part_id ?? '?'}/C${targetChar.char_id ?? '?'}` + ' : ' + distributionName(data.dist)
            draft.title[0].textStyle.fontSize = (settings.page.fontSize / (settings.page.layout.row + settings.page.layout.column)) * 4
          }
        })
        chartRef.current.getEchartsInstance().setOption(next)
        // setIsLoadingPanel(false)
      }
    } catch (error) {
      // Handle exceptions from the API call or during option construction
      console.error('Failed to fetch or update chart data:', error)
    }
  }

  useEffect(() => {
    if (chartRef && chartRef.current) {
      // setIsLoadingPanel(true)
      console.log(`Histogram Chart Component Info <Part_ID : ${info.part_id}, Char_ID : ${info.char_id}>`)
      const { targetChar, targetValue, settings, configSettings } = makeSettings(info, curPage, configPage, valuesArranged)
      fetchDataForChart(chartRef, pageKey, targetChar, targetValue, settings, configSettings)
    }
  }, [curPage, configPage, chartOption])

  return <ReactECharts key={key} ref={chartRef} style={{ width: '300px', height: '200px' }} notMerge={true} option={chartOption} theme={getViewPortTheme(pageKey, theme)} opts={apacheEChartOpt} />
}
const mapStateToProps = (state) => ({
  common: state.common,
  chartRawData: state.chartRawData,
})

export default connect(mapStateToProps)(ApacheEChartHistogramComponentReport)
