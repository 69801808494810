import { CheckBox } from 'devextreme-react'
import { GroupItem } from 'devextreme-react/form'
import produce from 'immer'
import { useDispatch } from 'react-redux'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import ListSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ListSimpleItem'

const DBFieldAdvancedSavedFilter = (pageKey, pageState, configState, setIsVisibleFilterPopup, setPopupDisplayFilter) => {
  const dispatch = useDispatch()
  const savedFilters = pageState.filters.advanced.savedFilters
  const selectedFilterNo = [pageState.filters.advanced.selectedFilterNo]

  const makeDataSourceSavedFilters = () => {
    const baseItem = { id: 0, filterName: 'Not Selected' }

    const filterListDataSource = savedFilters.map((item, index) => {
      return {
        id: index + 1,
        ...item,
      }
    })

    filterListDataSource.unshift(baseItem)

    return filterListDataSource
  }

  const handleSavedFiltersDelete = async (e) => {
    const deleteFilter = e.itemData
    if (deleteFilter.id === 0) {
      e.cancel = true
      return
    }

    const savedFilters = pageState.filters.advanced.savedFilters
    const selectedFilterNo = pageState.filters.advanced.selectedFilterNo
    // const sameNameFilter = savedFilters.find(filter => filter.filterName === deleteFilter.filterName)
    const next = produce(pageState, (draft) => {
      const filteredFilter = savedFilters.filter((filter) => filter.filterName !== deleteFilter.filterName)
      if (selectedFilterNo > filteredFilter.length) {
        draft.filters.advanced.selectedFilterNo = 0
      }
      draft.filters.advanced.savedFilters = filteredFilter
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const handleFilterClicked = (e) => {
    console.log('Filter Clicked')
    const clickedFilter = e.itemData
    if (clickedFilter.id === 0) {
      return
    }
    setIsVisibleFilterPopup(true)
    setPopupDisplayFilter(clickedFilter)
  }

  const onSelectionChanged = (e) => {
    if (e.addedItems.length > 0) {
      const selectedItem = e.addedItems[0]
      // setSelectedItemKeys(value)
      const next = produce(pageState, (draft) => {
        draft.filters.advanced.selectedFilterNo = selectedItem.id
      })
      dispatch(ps[pageKey].setPageSettingsToRedux(next))
    }
  }

  const onSelectedItemKeysChange = ({ name, value }) => {
    if (name === 'selectedItemKeys' || selectedItemKeys.length !== 0) {
      // setSelectedItemKeys(value)
      const next = produce(pageState, (draft) => {
        draft.filters.advanced.selectedFilterNo = value[0].id
      })
      dispatch(ps[pageKey].setPageSettingsToRedux(next))
    }
  }

  const renderSavedFilters = (itemData) => {
    const filterName = itemData.filterName
    const checkBoxValue = selectedFilterNo === itemData.id

    const handleCheckBoxClicked = (e) => {
      console.log(e)

      const next = produce(pageState, (draft) => {
        draft.filters.advanced.selectedFilterNo = itemData.id
      })
      dispatch(ps[pageKey].setPageSettingsToRedux(next))
    }

    return (
      <div style={{ display: 'flex', width: '100%', alignItems: 'center', gap: '5px' }}>
        <div style={{ width: '10%', height: '100%', textAlign: 'center' }}></div>
        <CheckBox value={checkBoxValue} onValueChanged={handleCheckBoxClicked} />
        {/* <div style={{ width: '10%', height: '100%', textAlign: 'center' }}>{itemData.id + 1}</div> */}
        <div style={{ width: '10%', height: '100%', textAlign: 'center' }}>:</div>
        <div style={{ width: '10%', height: '100%', textAlign: 'center' }}>{filterName}</div>
      </div>
    )
  }

  return (
    <GroupItem key={`db-field-advanced-saved-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'SavedFilterList')}>
      {ListSimpleItem({
        colSpan: 2,
        label: '',
        labelLocation: 'top',
        dataSource: makeDataSourceSavedFilters(),
        itemRender: null,
        allowItemDeleting: true,
        onItemClick: handleFilterClicked,
        itemDeleteMode: 'static',
        noDataText: dqTranslateMsg('SidePanel_' + 'NoData'),
        // onItemDeleted: handleSavedFiltersDelete,
        onItemDeleting: handleSavedFiltersDelete,
        onReorder: null,
        showSelectionControls: true,
        selectionMode: 'single',
        selectedItemKeys: selectedFilterNo,
        // selectedItems: selectedFilterNo,
        selectByClick: false,
        keyExpr: 'id',
        displayExpr: 'filterName',
        // onOptionChanged: onSelectedItemKeysChange,
        onSelectionChanged: onSelectionChanged,
        backgroundCondition: true,
      })}
    </GroupItem>
  )
}

export default DBFieldAdvancedSavedFilter
