import { Grid } from '@mui/material'
import { Form } from 'devextreme-react'
import { Button } from 'devextreme-react/button'
import produce from 'immer'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DQLoadPanel from 'src/components/dq-load-form/DQLoadPanel'
import { useAuth } from 'src/contexts/auth'
import DQToast from 'src/functions/DQToast'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'
import { configurationSettingsPrm } from '../../settings/ConfigSettings'
import './CommonSettings.scss'
import SettingsDisplayValueOption from './components/SettingsDisplayValueOption'
import SettingsExtendedLimitOption from './components/SettingsExtendedLimitOption'
import SettingsOutlierOption from './components/SettingsOutlierOption'
import SettingsProcessCapability from './components/SettingsProcessCapability'
import DQSaveReset from 'src/functions/DQSaveReset'

export default function CommonSettings({ pageKey }) {
  const dispatch = useDispatch()
  const { user } = useAuth()
  const [loading, setLoading] = useState(false)
  const parentRef = useRef(null)
  const toastRef = useRef(null)

  const common = useSelector((state) => state.common)
  const configPage = common.pages.configuration
  const configAll = common.base
  const settings = configPage

  // const catalogs = testData
  const handleSaveButtonClicked = () => {
    dqsImpl.setCurBaseSettings(dispatch, user, configAll, 'configuration', configPage)
    toastRef.current.handleToastDisplay('success', dqTranslateMsg('SidePanel_' + 'SavedSettings'))
  }

  const handleResetButtonClicked = () => {
    const next = produce(configPage, (draft) => {
      draft.commonSettings = configurationSettingsPrm.commonSettings
    })

    dqsImpl.setCurBaseSettings(dispatch, user, configAll, 'configuration', next)
    toastRef.current.handleToastDisplay('warning', dqTranslateMsg('SidePanel_' + 'ResetSettings'))
  }

  const items = {
    length: 5,
    // Display Value Option
    list_1: [SettingsDisplayValueOption(), null, null, null, null],
    list_2: [SettingsProcessCapability(), null, null, null, null],
    list_3: [SettingsOutlierOption(), null, null, null, null],
    list_4: [null, null, null, null, null],
    list_5: [null, null, null, null, null],
  }

  return (
    <div className="common-settings-container">
      <DQLoadPanel open={loading} />
      <DQToast ref={toastRef} />
      <div className="common-settings-control-container">
        {/* <DQSaveReset
          saveBtnText={dqTranslateMsg('SidePanel_' + 'Save')}
          resetBtnText={dqTranslateMsg('SidePanel_' + 'Reset')}
          saveCallback={handleSaveButtonClicked}
          resetCallback={handleResetButtonClicked}
        /> */}
        <div className="button-container">
          <Button id="partLoadButton" icon="save" text={dqTranslateMsg('Page_Configuration_' + 'Save')} width={'100%'} height={'100%'} onClick={handleSaveButtonClicked} />
        </div>
        <div className="button-container">
          <Button id="partLoadButton" icon="undo" text={dqTranslateMsg('Page_Configuration_' + 'Reset')} width={'100%'} height={'100%'} onClick={handleResetButtonClicked} />
        </div>
      </div>
      {/* <div className="common-settings-content-container"> */}
      <Grid container spacing={0}>
        <Grid item key={'common_settings_1'} style={{ flex: `1 0 calc(100% / ${items.length})`, padding: '3px' }}>
          <Form id="configuration-form" style={{ paddingTop: '5px' }} labelMode={'outside'} showColonAfterLabel={false} labelLocation={'top'}>
            {items.list_1}
          </Form>
        </Grid>
        <Grid item key={'common_settings_2'} style={{ flex: `1 0 calc(100% / ${items.length})`, padding: '3px' }}>
          <Form id="configuration-form" style={{ paddingTop: '5px' }} labelMode={'outside'} showColonAfterLabel={false} labelLocation={'top'}>
            {items.list_2}
          </Form>
        </Grid>
        <Grid item key={'common_settings_3'} style={{ flex: `1 0 calc(100% / ${items.length})`, padding: '3px' }}>
          <Form id="configuration-form" style={{ paddingTop: '5px' }} labelMode={'outside'} showColonAfterLabel={false} labelLocation={'top'}>
            {items.list_3}
          </Form>
        </Grid>
        <Grid item key={'common_settings_4'} style={{ flex: `1 0 calc(100% / ${items.length})`, padding: '3px' }}>
          <Form id="configuration-form" style={{ paddingTop: '5px' }} labelMode={'outside'} showColonAfterLabel={false} labelLocation={'top'}>
            {items.list_4}
          </Form>
        </Grid>
        <Grid item key={'common_settings_5'} style={{ flex: `1 0 calc(100% / ${items.length})`, padding: '3px' }}>
          <Form id="configuration-form" style={{ paddingTop: '5px' }} labelMode={'outside'} showColonAfterLabel={false} labelLocation={'top'}>
            {items.list_5}
          </Form>
        </Grid>
      </Grid>
      {/* </div> */}
    </div>
  )
}
