import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as xImpl from './XAxisChartOptionImpl'
import CCUtils from 'src/functions/CommonCalcUtils'
import * as ptImpl from './PositionalTolerancesChartOptionImpl'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'
import { outlierFilterValues } from 'src/functions/ValueProcessUtils'

export const getXAxisChartOption = (settings, configSettings, targetChar, valueArray) => {
  const char = targetChar
  const value = valueArray
  const titleText = `${char.c2002 ?? 'empty'} P:${char.part_id ?? '?'} / C:${char.char_id ?? '?'}`

  const { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset } = PCUtils.getAllLimits(char, configSettings)
  const limits = { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset }
  const filteredValue = outlierFilterValues(limits, char, value, configSettings)

  const { lcl, ucl, average, minValue, maxValue, v0001s } = PCUtils.getCalculatedPrms(filteredValue, configSettings)

  const seriesData = xImpl.makeXYArray(filteredValue, 'v0001')
  const decimalPlaces = char.c2022 ?? configSettings.commonSettings.displayDecimalPlaces
  const xAxisRight = v0001s.length + 1
  const xAxisLeft = 0

  const topBottomCalcArray = {
    settings,
    usl,
    lsl,
    uwl,
    lwl,
    upl,
    lpl,
    url,
    lrl,
    uacl,
    lacl,
    maxValue,
    minValue,
  }

  const yAxisTop = xImpl.makeYAxisTop(topBottomCalcArray)
  const yAxisBottom = xImpl.makeYAxisBottom(topBottomCalcArray)

  // const yAxisTop = xImpl.makeYAxisTop(settings, usl, lsl, uwl, lwl, maxValue, minValue)
  // const yAxisBottom = xImpl.makeYAxisBottom(settings, usl, lsl, uwl, lwl, maxValue, minValue)

  // chart setting reorganization
  const addedSettings = {
    ...settings,
    ...configSettings,
    titleText,
    lsl,
    usl,
    lwl,
    uwl,
    upl,
    lpl,
    url,
    lrl,
    lacl,
    uacl,
    ucl,
    lcl,
    average,
    nominal,
    // warnOffset,
    xAxisLeft,
    xAxisRight,
    yAxisTop,
    yAxisBottom,
    decimalPlaces,
  }
  return {
    title: {
      text: addedSettings.titleText,
      left: 'center',
      textStyle: {
        fontFamily: 'Arial',
        fontSize: (18 / (settings.page.layout.row + settings.page.layout.column)) * 2,
      },
    },
    animationDuration: 300,
    backgroundColor: 'rgba(255, 255, 255, 0)',
    grid: {
      top: '13%',
      bottom: 40,
      left: '0%',
      right: '5%',
      containLabel: true,
    },
    xAxis: {
      type: 'value',
      min: addedSettings.xAxisLeft,
      max: addedSettings.xAxisRight,
      minInterval: 1,
      maxInterval: addedSettings.page.xAxisDenseLabel ? 2 : addedSettings.xAxisRight < 10 ? 2 : Math.abs(Math.floor(addedSettings.xAxisRight / 5)),
      // maxInterval: 1,
      // nameTextStyle: {
      //   fontFamily: 'Arial',
      // },
      axisLabel: {
        rotate: 90,
        fontFamily: 'Arial',
        fontSize: ((addedSettings.page.fontSize - 2) / (addedSettings.page.layout.row + addedSettings.page.layout.column)) * 1.7,
        hideOverlap: true,
        formatter: (values, index) => xImpl.makeXAxisLabelFormat(values, index, addedSettings, valueArray),
      },
      splitLine: {
        show: false,
      },
      axisLine: {
        onZero: false,
        hideOverlap: true,
      },
    },
    yAxis: {
      type: 'value',
      scale: true,
      min: addedSettings.yAxisBottom,
      max: addedSettings.yAxisTop,
      axisLabel: {
        fontFamily: 'Arial',
        fontSize: ((addedSettings.page.fontSize - 2) / (addedSettings.page.layout.row + addedSettings.page.layout.column)) * 1.7,
        showMinLabel: false,
        showMaxLabel: false,
        formatter: function (value, index) {
          let axisMax = addedSettings.yAxisTop
          let axisMin = addedSettings.yAxisBottom

          if (value === axisMin || value === axisMax) {
            return ''
          }
          return value
        },
      },
      axisLine: {
        onZero: false,
      },
      splitLine: {
        show: true,
      },
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    tooltip: {
      trigger: 'axis',
      appendToBody: true,
      position: function (pos, params, dom, rect, size) {
        let obj = { top: 60 }
        obj['left'] = pos[0] < size.viewSize[0] / 2 ? pos[0] + 50 : pos[0] - size.contentSize[0] - 50
        return obj
      },
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      extraCssText: 'width: auto',
      formatter: function (args) {
        return xImpl.toolTipBalloonCallback(args, char, addedSettings, configSettings, valueArray)
      },
      textStyle: {
        fontFamily: 'Arial',
      },
    },
    legend: {
      top: '5%',
      textStyle: {
        fontFamily: 'Arial',
      },
    },
    dataZoom: [
      {
        show: true,
        type: 'slider',
        orient: 'horizontal',
        height: `3%`,
        bottom: '3%',
        brushSelect: true,
        showDataShadow: true,
        showDetail: false,
        filterMode: 'none',
      },
      {
        show: true,
        type: 'slider',
        orient: 'vertical',
        width: `3%`,
        brushSelect: true,
        showDataShadow: true,
        showDetail: false,
        filterMode: 'none',
      },
      {
        show: true,
        type: 'inside',
        yAxisIndex: 0,
        filterMode: 'none',
        zoomLock: false, // Allow zooming
        zoomOnMouseWheel: 'shift', // Zoom on Shift + MouseWheel
      },
      {
        show: true,
        type: 'inside',
        xAxisIndex: 0,
        filterMode: 'none',
      },
    ],
    series: [
      {
        name: dqTranslateMsg('Page_PositionalTolerancesChart_' + 'ValueInfo'),
        type: 'line',
        symbol: (value, params) => xImpl.makeSymbol(value, params, addedSettings),
        symbolSize: (value, params) => xImpl.makeSymbolSize(value, params, addedSettings),
        itemStyle: {
          color: (params) => xImpl.makeSymbolColor(params, addedSettings),
        },
        lineStyle: xImpl.convertToChartLine(settings),
        data: seriesData,
        markLine: {
          animation: false,
          symbol: 'none',
          precision: 10,
          emphasis: {
            disabled: true,
          },
          data: xImpl.convertToMarkLine(addedSettings),
        },
        markArea: {
          type: 'line',
          name: 'Areas',
          data: xImpl.convertToMarkArea(addedSettings),
        },
      },
    ],
  }
}
