import TreeView from 'devextreme-react/tree-view'
import * as events from 'devextreme/events'
import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useSelector } from 'react-redux'
import { navigation_dq } from 'src/app-navigation-dq'
import { useAuth } from 'src/contexts/auth'
import { useTheme } from 'src/contexts/theme'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import { useNavigation } from '../../contexts/navigation'
import { useScreenSize } from '../../utils/media-query'
import './SideNavigationMenuDark.scss'
import HeaderIcon from 'src/functions/HeaderIcon'
import { useLanguage } from 'src/contexts/languages'

export default function SideNavigationMenuPreview(props) {
  const { dataLoaded, targetUserRole, ribbon } = props

  const { isLarge } = useScreenSize()
  // add brian to check data loaded or not
  const { user } = useAuth()
  const { language } = useLanguage()
  const chartRawData = useSelector((state) => state.chartRawData)
  // const charInfo = chartRawData.charInfo
  const valueInfo = chartRawData.valueInfo
  const common = useSelector((state) => state.common)
  // const userRole = targetUserRole
  const swatchTheme = common.pages.configuration.swatchTheme
  const navList = ribbon
  const { setTheme, getTheme } = useTheme()

  function normalizePathPreview() {
    let targetNavigation
    const tempIconType = HeaderIcon.menuIconType().find((item) => item.Value === common.pages.configuration.menuIconType)

    let menuIconType
    if (tempIconType === undefined) {
      menuIconType = HeaderIcon.menuIconType()[0]
    } else {
      menuIconType = tempIconType
    }

    const isDataLoaded = valueInfo.length > 0 ? true : false

    targetNavigation = navigation_dq(navList, menuIconType.Path, getTheme(), swatchTheme, isDataLoaded)

    return targetNavigation.map((item) => {
      if (Array.isArray(item.items)) {
        const updatedItems = item.items.map((subItem) => ({
          ...subItem,
          text: dqTranslateMsg(subItem.text),
        }))

        return {
          ...item,
          items: updatedItems,
          text: dqTranslateMsg(item.text),
          expanded: isLarge,
          path: item.path && !/^\//.test(item.path) ? `/${item.path}` : item.path,
        }
      } else {
        return {
          ...item,
          text: dqTranslateMsg(item.text),
          expanded: isLarge,
          path: item.path && !/^\//.test(item.path) ? `/${item.path}` : item.path,
        }
      }
    })
  }

  const items = useMemo(normalizePathPreview, [valueInfo, navList, language, common.pages.configuration.menuIconType])

  const {
    navigationData: { currentPath },
  } = useNavigation()

  const treeViewRef = useRef(null)
  const wrapperRef = useRef()

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance
    if (!treeView) {
      return
    }

    if (currentPath !== undefined) {
      treeView.selectItem(currentPath)
      treeView.expandItem(currentPath)
    }
  }, [currentPath])

  return (
    <div className="content" style={{ width: '100%' }}>
      <div className={'dx-swatch-additional side-navigation-menu-dark'} style={{ width: '100%' }}>
        {/* {children} */}
        <div className={'menu-container'} style={{ width: '100%' }}>
          <TreeView
            ref={treeViewRef}
            items={items}
            keyExpr={'path'}
            selectionMode={'single'}
            focusStateEnabled={false}
            expandEvent={'click'}
            // onItemClick={selectedItemChanged}
            // onItemExpanded={makeTreeViewExpandOnlyOneParent}
            // onContentReady={onMenuReady}
            width={'100%'}
          />
        </div>
      </div>
    </div>
  )
}
