import JwtUtils from 'src/functions/JwtUtils'
import { backendPath } from './defines/DBInfo'

const path = backendPath

// ** UPLOAD DATA APIS
export const uploadFileData = async (file) => {
  return new Promise((resolve, reject) => {
    const formData = new FormData()
    formData.append('file', file.File)
    formData.append('codepage', file.Encoding)

    const accessToken = JwtUtils.loadToken()

    fetch(path + 'data/uploadFileData', {
      method: 'POST',
      headers: {
        Authorization: `${accessToken}`,
      },
      body: formData,
    })
      .then((response) => {
        if (response.ok) {
          return response.json()
        } else {
          throw new Error('Network response was not ok.')
        }
      })
      .then((result) => resolve(result))
      .catch((error) => reject(error))
  })
}
