import { Button } from 'devextreme-react/button'
import { Column, CustomRule, DataGrid, Editing, Lookup, Scrolling, ValidationRule, SearchPanel } from 'devextreme-react/data-grid'
import { NumberBox, Button as NumberBoxButton } from 'devextreme-react/number-box'
import { Toast } from 'devextreme-react/toast'
import produce from 'immer'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getUserList } from 'src/api/auth'
import ApiStatus from 'src/api/defines/ApiStatus'
import * as userApi from 'src/api/user'
import { useAuth } from 'src/contexts/auth'
import * as dtoUtils from 'src/dto/DTOImpl'
import { convertUserRoleToNumber, convertUserRoleToString, userRoleLevel } from 'src/functions/constants/authLevelConstants'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as permi from 'src/pages/components/dq-permission/DQPermission'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'
import { configurationSettingsPrm } from 'src/pages/config/components/settings/ConfigSettings'
import { setConfiguration } from 'src/store/common'
import './AdminAccount.scss'
import { Tooltip } from 'devextreme-react'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'
import TextTitle from 'src/functions/TextTitle'
import { adminSettingsPrm } from '../../settings/AdminSettings'
import { getAdminCompany } from 'src/api/admin/AdminCompanyApi'

const userRoleState = () => [
  {
    ID: 1,
    value: userRoleLevel.superuser,
    text: dqTranslateMsg('Page_AdminSettings_' + 'SuperUser'),
  },
  {
    ID: 2,
    value: userRoleLevel.admin,
    text: dqTranslateMsg('Page_AdminSettings_' + 'Admin'),
  },
  {
    ID: 3,
    value: userRoleLevel.engineer,
    text: dqTranslateMsg('Page_AdminSettings_' + 'Engineer'),
  },
  {
    ID: 4,
    value: userRoleLevel.operator,
    text: dqTranslateMsg('Page_AdminSettings_' + 'Operator'),
  },
  {
    ID: 5,
    value: userRoleLevel.cmm,
    text: dqTranslateMsg('Page_AdminSettings_' + 'CMM'),
  },
  {
    ID: 6,
    value: userRoleLevel.qsense,
    text: dqTranslateMsg('Page_AdminSettings_' + 'QSense'),
  },
  {
    ID: 7,
    value: userRoleLevel.demo,
    text: dqTranslateMsg('Page_AdminSettings_' + 'Demo'),
  },
]

const initialState = []

function checkPasswordFinalValidation(e, configSettings) {
  const password = e.data.UserPassword
  const limitMinTotalLen = configSettings.accountSettings.password.totalLen
  const limitMinSCharCount = configSettings.accountSettings.password.minSpecialChar
  const limitMinUpperCaseLetterCount = configSettings.accountSettings.password.minUpperCaseLetter
  const limitMinLetterCount = configSettings.accountSettings.password.minLetter
  // Check if the length is at least 10 characters
  if (password.length < limitMinTotalLen) {
    dqTranslateMsg('Page_AdminSettings_' + 'ErrorMsgPasswordCondition')
    return false
  }

  // Check if there are at least 2 special characters
  const specialChars = password.match(/[!@#$%^&*(),.?":{}|<>]/g) || []
  if (specialChars.length < limitMinSCharCount) {
    dqTranslateMsg('Page_AdminSettings_' + 'ErrorMsgPasswordCondition')
    return false
  }

  // Check if there are at least 2 uppercase letters
  const uppercaseLetters = password.match(/[A-Z]/g) || []
  if (uppercaseLetters.length < limitMinUpperCaseLetterCount) {
    dqTranslateMsg('Page_AdminSettings_' + 'ErrorMsgPasswordCondition')
    return false
  }

  // Check if there are at least 6 lowercase letters
  const lowercaseLetters = password.match(/[a-z]/g) || []
  if (lowercaseLetters.length < limitMinLetterCount) {
    dqTranslateMsg('Page_AdminSettings_' + 'ErrorMsgPasswordCondition')
    return false
  }

  return true
}

function checkPasswordValidation(e, configSettings) {
  const password = e.value
  const limitMinTotalLen = configSettings.accountSettings.password.totalLen
  const limitMinSCharCount = configSettings.accountSettings.password.minSpecialChar
  const limitMinUpperCaseLetterCount = configSettings.accountSettings.password.minUpperCaseLetter
  const limitMinLetterCount = configSettings.accountSettings.password.minLetter
  // Check if the length is at least 10 characters
  if (password.length < limitMinTotalLen) {
    dqTranslateMsg('Page_AdminSettings_' + 'ErrorMsgPasswordCondition')
    return false
  }

  // Check if there are at least 2 special characters
  const specialChars = password.match(/[!@#$%^&*(),.?":{}|<>]/g) || []
  if (specialChars.length < limitMinSCharCount) {
    dqTranslateMsg('Page_AdminSettings_' + 'ErrorMsgPasswordCondition')
    return false
  }

  // Check if there are at least 2 uppercase letters
  const uppercaseLetters = password.match(/[A-Z]/g) || []
  if (uppercaseLetters.length < limitMinUpperCaseLetterCount) {
    dqTranslateMsg('Page_AdminSettings_' + 'ErrorMsgPasswordCondition')
    return false
  }

  // Check if there are at least 6 lowercase letters
  const lowercaseLetters = password.match(/[a-z]/g) || []
  if (lowercaseLetters.length < limitMinLetterCount) {
    dqTranslateMsg('Page_AdminSettings_' + 'ErrorMsgPasswordCondition')
    return false
  }

  return true
}

export default function AdminAccount({ pageKey }) {
  const dataGridRef = useRef(null)
  const [userList, setUserList] = useState(null)
  const dispatch = useDispatch()
  const { user } = useAuth()
  const common = useSelector((state) => state.common)
  const configAll = common.base
  const configPage = common.pages.adminSettings
  const settings = configPage
  const limitMinSCharCount = settings.accountSettings.password.minSpecialChar
  const limitMinUpperCaseLetterCount = settings.accountSettings.password.minUpperCaseLetter
  const limitMinLetterCount = settings.accountSettings.password.minLetter

  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: 'info',
    message: '',
  })

  // test
  const handleToastDisplay = (type, message) => {
    setToastConfig({
      ...toastConfig,
      isVisible: true,
      type,
      message,
    })
  }

  function onHiding() {
    setToastConfig({
      ...toastConfig,
      isVisible: false,
    })
  }

  const handleSaveButton = () => {
    const next = produce(configPage, (draft) => {
      draft.accountSettings = settings.accountSettings
    })

    dqsImpl.setCurBaseSettings(dispatch, user, configAll, 'adminSettings', next)
    handleToastDisplay('success', dqTranslateMsg('Page_AdminSettings_' + 'SavedSettings'))
  }

  const handleResetButton = () => {
    const next = produce(configPage, (draft) => {
      draft.accountSettings = adminSettingsPrm.accountSettings
    })

    dqsImpl.setCurBaseSettings(dispatch, user, configAll, 'adminSettings', next)
    handleToastDisplay('warning', dqTranslateMsg('Page_AdminSettings_' + 'ResetSettings'))
  }

  useEffect(() => {
    getUserList()
      .then((result) => {
        if (!ApiStatus.isSuccess(result.status)) {
          alert(getErrCommMsg(result.status))
        }

        const convertedUserDTO = result.data.user_infos.map((info) => {
          return dtoUtils.makeUserInfoToDTOFormat(info)
        })

        setUserList(
          convertedUserDTO.map((item, index) => ({
            ID: index + 1,
            UserID: item.user_id,
            UserPassword: item.user_password,
            UserCompany: item.user_password,
            UserPlant: item.user_plant,
            UserGroup: item.user_group,
            // UserRole: convertUserRoleToString(item.user_role),
            UserRole: item.user_role,
            UserName: item.user_info !== null && item.user_info !== '' ? JSON.parse(item.user_info).name : '',
            UserJob: item.user_info !== null && item.user_info !== '' ? JSON.parse(item.user_info).name : '',
          }))
        )
      })
      .catch((error) => {
        alert(error)
      })

    // const getAdminInformation = async () => {
    //   const companyList = await getAdminCompany()
    //   const plantList = await getAdminPlant()
    //   const groupList = await getAdminGroup()
    // }

    // getAdminInformation()
  }, [])

  function handlePasswordDisplay(e) {
    const replacedString = '**********'
    return replacedString
  }

  function onRowInserting(e) {
    const rowData = e.data

    let is_cancel = true

    const isIDExist = userList.some((user) => user.UserID === rowData.UserID)
    if (isIDExist === false) {
      userApi.createUserInfo(rowData.UserID, rowData.UserPassword, rowData.UserRole, rowData.UserName, rowData.UserJob)
      handleToastDisplay('success', 'Added New Account')

      // add
      const addedUser = produce(userList, (draft) => {
        draft.push({
          ID: userList.length + 1,
          UserID: rowData.UserID,
          UserPassword: rowData.UserPassword,
          UserCompany: rowData.UserCompany,
          UserGroup: rowData.UserGroup,
          UserPlant: rowData.UserPlant,
          UserRole: rowData.UserRole,
          UserName: rowData.UserName,
          UserJob: rowData.UserJob,
        })
      })
      // Update Datagrid
      setUserList(addedUser)
    } else {
      alert('warning', '[Fail] Same User ID Exist')
      e.cancel = is_cancel
    }
  }
  function onRowUpdated(e) {
    if (checkPasswordFinalValidation(e, configPage)) {
      userApi.updateUserInfoForAccount(e.data.UserID, e.data.UserPassword, e.data.UserRole, e.data.UserName, e.data.UserJob)
    } else {
      alert('Password format invalid')
    }
  }

  function onRowRemoving(e) {
    const rowData = e.data
    userApi.deleteUserInfo(rowData.UserID)
  }

  const handleMinTotalLength = (target, configSettings) => {
    if (target.value >= limitMinSCharCount + limitMinUpperCaseLetterCount + limitMinLetterCount) {
      const next = produce(settings, (draft) => {
        draft.accountSettings.password.totalLen = target.value
      })

      dispatch(ps.adminSettings.setPageSettingsToRedux(next))
    } else {
      dqTranslateMsg('Page_AdminSettings_' + 'MinLengthConditionError')
    }
  }

  const handleMinSpecialCharacters = (target) => {
    const next = produce(settings, (draft) => {
      draft.accountSettings.password.minSpecialChar = target.value
    })

    dispatch(ps.adminSettings.setPageSettingsToRedux(next))
  }

  const handleMinUpperCaseLetters = (target) => {
    const next = produce(settings, (draft) => {
      draft.accountSettings.password.minUpperCaseLetter = target.value
    })

    dispatch(ps.adminSettings.setPageSettingsToRedux(next))
  }

  const handleMinLetters = (target) => {
    const next = produce(settings, (draft) => {
      draft.accountSettings.password.minLetter = target.value
    })

    dispatch(ps.adminSettings.setPageSettingsToRedux(next))
  }

  /**
   * Render
   */
  function onEditingStart(e) {
    e.data.UserPassword = ''
  }

  function renderResetPasswordButton(rowData) {
    const userID = rowData.data.UserID
    return (
      <>
        <Button
          text={dqTranslateMsg('Page_AdminSettings_' + 'Apply')}
          onClick={async (e) => {
            let result = confirm(dqTranslateMsg('Page_AdminSettings_' + 'ConfirmMessage'))
            if (result) {
              try {
                await userApi.defaultUserPassword(userID)
                alert(dqTranslateMsg('Page_AdminSettings_' + 'PasswordResetSuccess'))
              } catch (error) {
                alert(dqTranslateMsg('Page_AdminSettings_' + 'PasswordResetError'))
              }
            }
          }}
        />
        {/* <Tooltip text="123"></Tooltip> */}
      </>
    )
  }

  return (
    <div className="dx-theme-background-color">
      <Toast width={400} visible={toastConfig.isVisible} message={toastConfig.message} type={toastConfig.type} onHiding={onHiding} displayTime={600} />
      <div style={{ width: '80%', height: '100%' }}>
        <DataGrid
          ref={dataGridRef}
          className="admin-account-grid-container"
          dataSource={userList}
          allowColumnReordering={true}
          columnAutoWidth={true}
          columnResizingMode="widget"
          allowColumnResizing={true}
          filterRow={{ visible: false }}
          noDataText={dqTranslateMsg('Page_AdminSettings_' + 'NoData')}
          paging={{ enabled: true }}
          hoverStateEnabled={true}
          showColumnLines={true}
          showRowLines={true}
          showBorders={true}
          rowAlternationEnabled={true}
          twoWayBindingEnabled={false}
          onRowInserting={onRowInserting}
          onRowUpdated={onRowUpdated}
          onRowRemoving={onRowRemoving}
          onEditingStart={onEditingStart}
        >
          {permi.checkWritePermission(user.user_role) ? <Editing mode="row" useIcons={true} allowAdding={true} allowUpdating={true} allowDeleting={true} /> : null}
          <Scrolling mode="standard" rowRenderingMode="standard" />
          <SearchPanel visible={true} width={150} placeholder={dqTranslateMsg('Page_AdminSettings_' + 'SearchPlaceHoler')} />
          <Column dataField="ID" caption={dqTranslateMsg('Page_AdminSettings_' + 'ID')} alignment="center" allowEditing={false} />
          <Column dataField="UserID" caption={dqTranslateMsg('Page_AdminSettings_' + 'UserID')} alignment="center">
            <ValidationRule type="required" message="not allow @ character" />
          </Column>
          <Column
            dataField="UserPassword"
            caption={dqTranslateMsg('Page_AdminSettings_' + 'UserPassword')}
            alignment="center"
            customizeText={handlePasswordDisplay}
            editCellRender={(cell) => {
              const handleInputChange = (e) => {
                const value = e.target.value
                cell.setValue(value)
                // You might need to call some validation function here to update the validation state
                // checkPasswordValidation(e, configPage) // Replace with your validation logic
              }

              return <input type="password" placeholder="Password" defaultValue={cell.value} onInput={handleInputChange} />
            }}
          >
            <ValidationRule type="required" />
            <CustomRule validationCallback={(e) => checkPasswordValidation(e, configPage)} />
          </Column>
          <Column dataField="UserCompany" caption={dqTranslateMsg('Page_AdminSettings_' + 'UserRole')} alignment="center">
            <Lookup dataSource={userRoleState()} displayExpr="text" valueExpr="value" />
          </Column>
          <Column dataField="UserPlant" caption={dqTranslateMsg('Page_AdminSettings_' + 'UserRole')} alignment="center">
            <Lookup dataSource={userRoleState()} displayExpr="text" valueExpr="value" />
          </Column>
          <Column dataField="UserGroup" caption={dqTranslateMsg('Page_AdminSettings_' + 'UserRole')} alignment="center">
            <Lookup dataSource={userRoleState()} displayExpr="text" valueExpr="value" />
          </Column>
          <Column dataField="UserRole" caption={dqTranslateMsg('Page_AdminSettings_' + 'UserRole')} alignment="center">
            <Lookup dataSource={userRoleState()} displayExpr="text" valueExpr="value" />
          </Column>
          <Column dataField="UserName" caption={dqTranslateMsg('Page_AdminSettings_' + 'UserName')} alignment="center">
            <ValidationRule type="required" />
          </Column>
          <Column dataField="UserJob" caption={dqTranslateMsg('Page_AdminSettings_' + 'UserJob')} alignment="center">
            <ValidationRule type="required" />
          </Column>
          <Column width={'auto'} caption={dqTranslateMsg('Page_AdminSettings_' + 'ResetPassword')} cellRender={(e) => renderResetPasswordButton(e)} alignment="center"></Column>
        </DataGrid>
      </div>
      <div style={{ width: '20%', height: '100%', padding: '5px' }}>
        {/* Function Buttons */}
        <div className="side-panel-function-buttons">
          <Button className="save" icon="save" text={dqTranslateMsg('Page_AdminSettings_' + 'Save')} width={100} onClick={handleSaveButton} />
          <Button className="undo" icon="undo" text={dqTranslateMsg('Page_AdminSettings_' + 'Reset')} width={100} onClick={handleResetButton} />
        </div>

        <TextTitle text={dqTranslateMsg('Page_AdminSettings_' + 'PasswordCondition')} width="100%" fontSize={'16px'} textAlign={'left'} />
        <TextTitle text={dqTranslateMsg('Page_AdminSettings_' + 'MinTotalLength')} width="40%" childrenWidth={'60%'} fontSize={'12px'}>
          <NumberBox showSpinButtons={true} value={configPage.accountSettings.password.totalLen} min={1} max={20} step={1} mode="number" onValueChanged={(e) => handleMinTotalLength(e, configPage)}>
            <NumberBoxButton name="spins" />
          </NumberBox>
        </TextTitle>
        <TextTitle text={dqTranslateMsg('Page_AdminSettings_' + 'MinSpecialCharacters')} width="40%" childrenWidth={'60%'} fontSize={'12px'}>
          <NumberBox showSpinButtons={true} value={configPage.accountSettings.password.minSpecialChar} min={0} max={10} step={1} mode="number" onValueChanged={handleMinSpecialCharacters}>
            <NumberBoxButton name="spins" />
          </NumberBox>
        </TextTitle>
        <TextTitle text={dqTranslateMsg('Page_AdminSettings_' + 'MinUpperCase')} width="40%" childrenWidth={'60%'} fontSize={'12px'}>
          <NumberBox showSpinButtons={true} value={configPage.accountSettings.password.minUpperCaseLetter} min={0} max={10} step={1} mode="number" onValueChanged={handleMinUpperCaseLetters}>
            <NumberBoxButton name="spins" />
          </NumberBox>
        </TextTitle>
        <TextTitle text={dqTranslateMsg('Page_AdminSettings_' + 'MinLetter')} width="40%" childrenWidth={'60%'} fontSize={'12px'}>
          <NumberBox showSpinButtons={true} value={configPage.accountSettings.password.minLetter} min={0} max={10} step={1} mode="number" onValueChanged={handleMinLetters}>
            <NumberBoxButton name="spins" />
          </NumberBox>
        </TextTitle>
      </div>
    </div>
  )
}
