import DatabaseCharForm from './database-char-form/DatabaseCharForm'
import DatabaseFilterForm from './database-filter-form/DatabaseFilterForm'
import DatabasePartForm from './database-part-form/DatabasePartForm'
import './DatabaseForm.scss'

function DatabaseForm({ pageKey }) {
  try {
    return (
      <div className="dx-theme-background-color">
        <div className={'database-part-container'}>
          <DatabasePartForm></DatabasePartForm>
        </div>
        <div className={'database-char-container'}>
          <DatabaseCharForm></DatabaseCharForm>
        </div>
      </div>
    )
  } catch (e) {
    console.log(e)
  }
}

export default DatabaseForm
