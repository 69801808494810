import { EmptyItem, GroupItem } from 'devextreme-react/form'
import { useDispatch, useSelector } from 'react-redux'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'
import RadioGroupSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/RadioGroupSimpleItem'
import * as sd from 'src/pages/components/side-panel-form/component/define/SettingsDefine'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'
import TextAreaSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/TextAreaSimpleItem'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'

export default function SettingsOutlierOption() {
  const dispatch = useDispatch()
  const pageKey = 'configuration'
  const pageState = useSelector((state) => state.common.pages[pageKey])

  const onNumberBoxValueChange = (e, dataField) => {
    if (e.event === undefined) return

    const value = e.value
    const minOption = e.component.option('min')
    const next = produce(pageState, (draft) => {
      draft.commonSettings[dataField] = value === null ? minOption : value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onCheckBoxValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(pageState, (draft) => {
      draft.commonSettings[dataField] = value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`common-display-value-option-group-item`} colCount={2} caption={dqTranslateMsg('Page_Configuration_' + 'OutlierOption')}>
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'UsePlausibilityFunction'),
        labelLocation: 'right',
        name: 'usePlausibilityFunc',
        value: pageState.commonSettings.usePlausibilityFunc,
        disabled: false,
        onValueChanged: (e) => onCheckBoxValueChanged(e, 'usePlausibilityFunc'),
      })}
      <EmptyItem />

      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'UseScrapFunction'),
        labelLocation: 'right',
        name: 'useScrapFunc',
        value: pageState.commonSettings.useScrapFunc,
        disabled: false,
        onValueChanged: (e) => onCheckBoxValueChanged(e, 'useScrapFunc'),
      })}
      <EmptyItem />
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'UseNaturalBoundaryFunction'),
        labelLocation: 'right',
        name: 'useNaturalBoundary',
        value: pageState.commonSettings.useNaturalBoundary,
        disabled: false,
        onValueChanged: (e) => onCheckBoxValueChanged(e, 'useNaturalBoundary'),
      })}
      <EmptyItem />
      {CheckBoxSimpleItem({
        ref: null,
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'UseAdjustmentTolerancesPercentage'),
        labelLocation: 'right',
        name: 'useAdjustNormalTolerances',
        value: pageState.commonSettings.useAdjustNormalTolerances,
        disabled: false,
        onValueChanged: (e) => onCheckBoxValueChanged(e, 'useAdjustNormalTolerances'),
      })}
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: '',
        labelLocation: 'top',
        name: 'tolerancesPercentage',
        min: 0,
        max: 500,
        step: 1,
        value: pageState.commonSettings.tolerancesPercentage,
        unit: 'PercentageShort',
        disabled: false,
        onValueChanged: (e) => onNumberBoxValueChange(e, 'tolerancesPercentage'),
      })}
    </GroupItem>
  )
}
