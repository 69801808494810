import { pageStatusList } from 'src/functions/constants/pageStatus'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import { pageName } from 'src/functions/constants/pageName'
class CommonPageUtils {
  /**
   * get Parameter
   */
  static loadRefChartData(chartState) {
    const parts = chartState.partInfo
    const chars = chartState.charInfo
    const values = chartState.valueInfo

    return { parts, chars, values }
  }

  static getSelectedCharacteristics(common, pageKey) {
    const selectedChars = common.loaded_all[pageKey]

    return selectedChars
  }

  static sliceCharsByCharCountPerPage(curPageSettings, chars, pageKey) {
    const charCnt = this.getSelectedCharCount(curPageSettings, chars, pageKey)
    const sortedArray = this.getSortArrayAscendingByPartCharId(chars)

    if (pageKey === pageName.positionalTolerancesChart) {
      const ptArray = sortedArray.filter((array) => array.group_index === 1 || array.group_index === 2)

      const sliced = ptArray.slice(0, charCnt)
      return sliced ? sliced : []
    }

    const sliced = sortedArray.slice(0, charCnt)
    return sliced ? sliced : []
  }

  static getSortArrayAscendingByPartCharId(chars) {
    const sortedArray = [...chars].sort((a, b) => {
      if (a.part_id !== b.part_id) {
        return a.part_id - b.part_id
      } else {
        return a.char_id - b.char_id
      }
    })

    return sortedArray
  }

  static getSelectedCharCount(curPageSettings, chars, pageKey) {
    if (curPageSettings.page.hasOwnProperty('layout')) {
      const layout = curPageSettings.page.layout
      const count = layout.column * layout.row
      if (pageKey === pageName.positionalTolerancesChart) {
        return count * 3
      }

      return count
    }

    // Only One-Characteristics Select Page
    const oneCharSelectPage = [pageName.reportPrint, pageName.reportPrintMultiFunction, pageName.decisionTreeChart]
    if (oneCharSelectPage.includes(pageKey)) {
      return 1
    }

    const twoCharSelectPage = [pageName.linearRegressionChart]
    if (twoCharSelectPage.includes(pageKey)) {
      return 2
    }

    const fiveCharSelectPage = [pageName.valueList]
    if (fiveCharSelectPage.includes(pageKey)) {
      return 5
    }

    const tenCharSelectPage = [pageName.correlationChart]
    if (tenCharSelectPage.includes(pageKey)) {
      return 10
    }

    return chars.length
  }

  static loadRefOtherData(common, chars, pageKey) {
    const curPage = common.pages[pageKey]

    let charArray

    const selectedChars = this.getSelectedCharacteristics(common, pageKey)
    if (selectedChars) {
      charArray = selectedChars
    } else {
      charArray = this.sliceCharsByCharCountPerPage(curPage, chars, pageKey)
    }

    const configPage = common.pages.configuration
    const currentSel = common.current_sel
    const pageAll = common.pages

    return {
      curPage,
      charArray,
      configPage,
      currentSel,
      pageAll,
    }
  }

  static checkMatchCharsAndValues(selectedChars, values) {
    // 1. Filtering process explained with clear variable names for better debugging
    const filteredValues = values.filter((value) => {
      // Check if the current value matches any of the selected characters
      const isMatch = selectedChars.some((selectedChar) => {
        // Check for matching part_id and char_id
        return selectedChar.part_id === value.part_id && selectedChar.char_id === value.char_id
      })

      // Return the result of the match check
      return isMatch
    })

    // 2. Return the filtered list after evaluating all values
    return filteredValues
  }

  static removeDummyInValues(valuesWithDummy) {
    const removedValues = valuesWithDummy.map((item) => ({
      ...item,
      valueRaw: item.valueRaw.filter((value) => value.v0002 === 0),
    }))

    return removedValues
  }
  static removeInvalidInValues(valuesWithDummy, isShowDeletedValue = true) {
    let returnValues

    const removedValues = valuesWithDummy.map((item) => ({
      ...item,
      valueRaw: item.valueRaw.filter((value) => value.v0002 !== 255),
    }))

    returnValues = removedValues

    if (!isShowDeletedValue) {
      const removedDeletedValues = removedValues.map((item) => ({
        ...item,
        valueRaw: item.valueRaw.filter((value) => value.v0002 !== 256),
      }))

      returnValues = removedDeletedValues
    }

    return returnValues
  }

  static checkUseTargetFunction(key) {
    const entry = pageStatusList[key]

    // Check if the entry exists and has the required nested structure
    if (entry && entry.func && entry.func.sidePanel) {
      const isTargetTrue = entry.func.sidePanel.target
      return isTargetTrue
    } else {
      return null // Return null or an appropriate value indicating the configuration does not exist
    }
  }

  static getConfigItemListFromUseField(type, useNoneFlag, useItems, useFieldList, configState) {
    let result = []

    if (useNoneFlag === true) {
      const noneItem = {
        value: 'None',
        text: dqTranslateMsg('SidePanel_' + 'None'),
      }

      result.push(noneItem)
    }

    const itemForSettings = useFieldList.filter((item) => item.configuration === true)

    if (useItems === null) {
      itemForSettings.forEach((item) => {
        // const upperFirstLetter = item.value.charAt(0).toUpperCase() + item.value.slice(1)
        let captionCondition = ''
        if (configState.commonSettings.displayFieldNameWithNumber) {
          captionCondition += `[ ${item.text} ] `
        }
        captionCondition += dqTranslateMsg('Db_field_' + type + '_' + item.text)

        result.push({
          number: item.number,
          value: item.value,
          numValue: item.value.slice(1),
          text: captionCondition,
        })
      })
    }

    return result
  }

  static makeItemListFromSetting(type, settingItemArray, useFieldList, configState) {
    let result = []

    // 설정가능항목만 필터
    const itemList = settingItemArray.map((settingValue) => useFieldList.find((item) => item.value === settingValue)).filter((item) => item !== undefined)
    // const itemList = useFieldList.filter((item) =>
    //   settingItemArray.includes(item.value)
    // )

    itemList.forEach((item) => {
      // const upperFirstLetter = item.value.charAt(0).toUpperCase() + item.value.slice(1)

      let captionCondition = ''
      if (configState.commonSettings.displayFieldNameWithNumber) {
        captionCondition += `[ ${item.text} ] `
      }
      captionCondition += dqTranslateMsg('Db_field_' + type + '_' + item.text)

      result.push({
        value: item.value,
        // text: dqTranslateMsg('Db_field_' + type + '_' + upperFirstLetter),
        text: captionCondition,
      })
    })

    return result
  }

  static getDisplayDateTimeSec(type, dateTimeString) {
    // dateTimeString = 'yyyyMMddhhmmss'
    let converted = ''

    // Check if the dateTimeString is in the correct format
    const dateTimeRegex = /^\d{14}$/
    if (!dateTimeRegex.test(dateTimeString)) {
      return converted // Return an empty string if the format is incorrect
    }

    const year = dateTimeString.slice(0, 4)
    const month = dateTimeString.slice(4, 6)
    const day = dateTimeString.slice(6, 8)
    const hour = dateTimeString.slice(8, 10)
    const minute = dateTimeString.slice(10, 12)
    const second = dateTimeString.slice(12, 14)

    if (type === 'date/time/sec') {
      converted = `${year}-${month}-${day} ${hour}:${minute}:${second}`
    } else if (type === 'date/time') {
      converted = `${year}-${month}-${day} ${hour}:${minute}`
    } else if (type === 'date') {
      converted = `${year}-${month}-${day}`
    } else if (type === 'time/sec') {
      converted = `${hour}:${minute}:${second}`
    } else {
      converted = `${hour}:${minute}`
    }

    return converted
  }
}

export default CommonPageUtils
