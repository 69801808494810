// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 50px);
}
.page-container .chart-container {
  width: 85%;
  height: 100%;
}
.page-container .chart-container .chart-form-content::-webkit-scrollbar {
  width: 5px;
}
.page-container .chart-container .chart-form-content::-webkit-scrollbar-thumb {
  background-color: #3e4451;
}
.page-container .chart-container .chart-form-content::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0);
}
.page-container .side-panel-container {
  width: 15%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/functions/PageContainer.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,mBAAA;EACA,WAAA;EACA,0BAAA;AACF;AACE;EACE,UAAA;EACA,YAAA;AACJ;AAEI;EACE,UAAA;AAAN;AAEI;EACE,yBAAA;AAAN;AAEI;EACE,wCAAA;AAAN;AAIE;EACE,UAAA;EACA,YAAA;AAFJ","sourcesContent":[".page-container {\r\n  display: flex;\r\n  flex-direction: row;\r\n  width: 100%;\r\n  height: calc(100vh - 50px);\r\n\r\n  .chart-container {\r\n    width: 85%;\r\n    height: 100%;\r\n    // z-index: 1; // 뒤로 보내기 위해 낮은 z-index 설정\r\n\r\n    .chart-form-content::-webkit-scrollbar {\r\n      width: 5px;\r\n    }\r\n    .chart-form-content::-webkit-scrollbar-thumb {\r\n      background-color: #3e4451;\r\n    }\r\n    .chart-form-content::-webkit-scrollbar-track {\r\n      background-color: rgba(255, 255, 255, 0);\r\n    }\r\n  }\r\n\r\n  .side-panel-container {\r\n    width: 15%;\r\n    height: 100%;\r\n    // z-index: 2; // 뒤로 보내기 위해 낮은 z-index 설정\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
