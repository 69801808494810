import { Button } from 'devextreme-react/button'
import { Toast } from 'devextreme-react/toast'
import produce from 'immer'
import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from 'src/contexts/auth'
import { linearRegressionChartSettingsPrm } from 'src/pages/chart-linear-regression/components/settings/LinearRegressionChartSettings'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'

export default function SidePanelLinearRegressionChartTheme(props) {
  const { pageKey } = props
  const dispatch = useDispatch()

  const [scrollPosition, setScrollPosition] = useState(0)
  const { user } = useAuth()
  const common = useSelector((state) => state.common)
  const curPage = common.pages.linearRegressionChart

  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: 'info',
    message: '',
  })

  const handleToastDisplay = (type, message) => {
    setToastConfig({
      ...toastConfig,
      isVisible: true,
      type,
      message,
    })
  }

  function onHiding() {
    setToastConfig({
      ...toastConfig,
      isVisible: false,
    })
  }

  const handleCurPageSettingsSave = (updatePageData) => {
    dqsImpl.setCurPageSettings(dispatch, user, pageKey, updatePageData)
  }
  const handleAllSettingsSave = () => {
    const next = produce(curPage, (draft) => {
      Object.keys(curPage).forEach((key) => {
        draft[key] = curPage[key]
      })
    })

    handleCurPageSettingsSave(next)
    handleToastDisplay('success', dqTranslateMsg('SidePanel_' + 'SavedSettings'))
  }
  const handleAllSettingsReset = () => {
    const next = produce(curPage, (draft) => {
      Object.entries(linearRegressionChartSettingsPrm.lineChart).forEach(([key, value]) => {
        draft.lineChart[key] = value
      })
      Object.entries(linearRegressionChartSettingsPrm.scatterChart).forEach(([key, value]) => {
        draft.scatterChart[key] = value
      })
    })

    handleCurPageSettingsSave(next)
    handleToastDisplay('warning', dqTranslateMsg('SidePanel_' + 'ResetSettings'))
  }

  return (
    <div className="side-panel-value-chart-container">
      <Toast width={400} visible={toastConfig.isVisible} message={toastConfig.message} type={toastConfig.type} onHiding={onHiding} displayTime={600} />
      {/* Function Buttons */}
      <div className="side-panel-function-buttons">
        <Button className="save" icon="save" text={dqTranslateMsg('SidePanel_' + 'Save')} width={'50%'} onClick={handleAllSettingsSave} />
        <Button className="undo" icon="undo" text={dqTranslateMsg('SidePanel_' + 'Reset')} width={'50%'} onClick={handleAllSettingsReset} />
      </div>
    </div>
  )
}
