import * as dqConvert from 'src/pages/components/dq-convert/DQConvert'
import CPUtils from 'src/functions/CommonPageUtils'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'
import CatalogUtils from 'src/functions/CatalogUtils'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'

export function isNullOrUndefined(data) {
  return data === null || data === undefined
}

export const makeXAxisLabelFormat = (value, index, settings, refValues) => {
  const xAxisLabelItems = settings.page.xAxisLabelItems
  const valueArray = refValues

  if (xAxisLabelItems.length === 0) {
    return value
  }

  if (value < 1 || value > valueArray.length) {
    return ''
  }

  let xLabel = ''
  xAxisLabelItems.forEach((item) => {
    if (!ETCUtils.isPositiveInteger(value)) {
      return
    }

    const itemValue = valueArray[value - 1][item]

    if (xLabel === '') {
      if (itemValue !== null && itemValue !== undefined) {
        if (item === 'v0004') {
          xLabel = CPUtils.getDisplayDateTimeSec(settings.page.dateTimeLabelDisplayFormat, itemValue)
        } else {
          xLabel = CatalogUtils.convertByCatalog(item, itemValue, settings.catalogs)
        }
      } else {
        xLabel = 'null'
      }
    } else {
      xLabel =
        item === 'v0004'
          ? `${xLabel}/${CPUtils.getDisplayDateTimeSec(settings.page.dateTimeLabelDisplayFormat, itemValue)}`
          : `${xLabel}/${CatalogUtils.convertByCatalog(item, itemValue, settings.catalogs)}`
    }
  })

  return xLabel
}

export const toolTipBalloonCallback = (args, char, settings, configSettings, valueArray) => {
  let displayStr = ''

  if (args[0].seriesName) {
    displayStr += `${args[0].seriesName}<br>`
  }
  if (args[0].data) {
    displayStr += `${dqTranslateMsg('Page_PositionalTolerancesChart_' + 'Number')} : ${args[0].data[0]}<br>`
  }

  const formatItem = (dataField, valueAtIndex) => {
    const itemName = dqTranslateMsg('Db_field_Value_' + dataField.charAt(0).toUpperCase() + dataField.slice(1))
    if (valueAtIndex) {
      if (dataField === 'v0004') {
        const dateTimeFormat = CPUtils.getDisplayDateTimeSec(settings.page.dateTimeTooltipFormat, valueAtIndex)
        return `${itemName} : ${dateTimeFormat}<br>`
      } else if (dataField === 'v0001') {
        return `${itemName} : ${DNUtils.valueDisplay(valueAtIndex, char, configSettings)}<br>`
      } else {
        return `${itemName} : ${valueAtIndex}<br>`
      }
    } else {
      return `${itemName} : null<br>`
    }
  }

  settings.page.toolTipInfoItems.forEach((item) => {
    const dataField = item
    const valueAtIndex = valueArray[args[0].dataIndex][dataField]
    displayStr += formatItem(dataField, valueAtIndex)
  })

  const addLimitInfo = (label, value, char, configSettings) => {
    return value !== undefined && typeof value === 'number' ? `${label} : ${DNUtils.axisValueDisplay(value, char, configSettings)}<br>` : ''
  }

  if (settings.page.toolTipUseSpecLimits || settings.page.toolTipUseWarnLimits) {
    const limitsItemName = dqTranslateMsg('Page_PositionalTolerancesChart_' + 'Limits')
    displayStr += `<br>${limitsItemName}<br>`

    if (settings.page.toolTipUseSpecLimits) {
      displayStr += addLimitInfo('USL', settings.usl, char, configSettings)
      displayStr += addLimitInfo('LSL', settings.lsl, char, configSettings)
    }

    if (settings.page.toolTipUseWarnLimits) {
      displayStr += addLimitInfo('UWL', settings.uwl, char, configSettings)
      displayStr += addLimitInfo('LWL', settings.lwl, char, configSettings)
    }
  }

  return displayStr
}

export function makeXYArray(valueArray, axisKey) {
  if (isNullOrUndefined(valueArray) || valueArray.length === 0) {
    // If there is no data, Empty array will return into series field.
    console.error('There is no value data')

    return []
  }

  const xyArray = []
  valueArray
    // .filter((data) => data.v0002 === 0)
    .map((value, index) => {
      xyArray.push([index + 1, value[axisKey]])
    })

  return xyArray
}

export const makeSymbol = (value, params, settings) => {
  // const val = params.data.v0001
  const val = params.data[1]
  if (settings.usl === null && settings.lsl === null) {
    return 'none'
  } else if (settings.usl !== null && settings.lsl !== null) {
    if (val > settings.usl) {
      return settings.lineChart.symbols.error.upper.shape
    } else if (val > settings.uwl) {
      return settings.lineChart.symbols.warn.upper.shape
    } else if (val >= settings.lwl) {
      return settings.lineChart.symbols.good.shape
    } else if (val >= settings.lsl) {
      return settings.lineChart.symbols.warn.lower.shape
    } else {
      return settings.lineChart.symbols.error.lower.shape
    }
  } else if (settings.usl == null) {
    if (val >= settings.lwl) {
      return settings.lineChart.symbols.good.shape
    } else if (val > settings.lsl) {
      return settings.lineChart.symbols.warn.lower.shape
    } else {
      return settings.lineChart.symbols.error.lower.shape
    }
  } else {
    // settings.lsl === null case
    if (val > settings.usl) {
      return settings.lineChart.symbols.error.upper.shape
    } else if (val > settings.uwl) {
      return settings.lineChart.symbols.warn.upper.shape
    } else {
      return settings.lineChart.symbols.good.shape
    }
  }
}

export const makeSymbolSize = (value, params, settings) => {
  // const val = params.data.v0001
  const val = params.data[1]

  if (settings.usl === null && settings.lsl === null) {
    return 0
  } else if (settings.usl !== null && settings.lsl !== null) {
    if (val > settings.usl) {
      return settings.lineChart.symbols.error.upper.size
    } else if (val > settings.uwl) {
      return settings.lineChart.symbols.warn.upper.size
    } else if (val >= settings.lwl) {
      return settings.lineChart.symbols.good.size
    } else if (val >= settings.lsl) {
      return settings.lineChart.symbols.warn.lower.size
    } else {
      return settings.lineChart.symbols.error.lower.size
    }
  } else if (settings.usl == null) {
    if (val >= settings.lwl) {
      return settings.lineChart.symbols.good.size
    } else if (val > settings.lsl) {
      return settings.lineChart.symbols.warn.lower.size
    } else {
      return settings.lineChart.symbols.error.lower.size
    }
  } else {
    // settings.lsl === null case
    if (val > settings.usl) {
      return settings.lineChart.symbols.error.upper.size
    } else if (val > settings.uwl) {
      return settings.lineChart.symbols.warn.upper.size
    } else {
      return settings.lineChart.symbols.good.size
    }
  }
}

export const makeSymbolColor = (params, settings) => {
  // const val = params.data.v0001
  const val = params.data[1]

  if (settings.usl === null && settings.lsl === null) {
    return 'rgba(255,255,255,0)'
  } else if (settings.usl !== null && settings.lsl !== null) {
    if (val > settings.usl) {
      return settings.lineChart.symbols.error.upper.color
    } else if (val > settings.uwl) {
      return settings.lineChart.symbols.warn.upper.color
    } else if (val >= settings.lwl) {
      return settings.lineChart.symbols.good.color
    } else if (val >= settings.lsl) {
      return settings.lineChart.symbols.warn.lower.color
    } else {
      return settings.lineChart.symbols.error.lower.color
    }
  } else if (settings.usl == null) {
    if (val >= settings.lwl) {
      return settings.lineChart.symbols.good.color
    } else if (val > settings.lsl) {
      return settings.lineChart.symbols.warn.lower.color
    } else {
      return settings.lineChart.symbols.error.lower.color
    }
  } else {
    // settings.lsl === null case
    if (val > settings.usl) {
      return settings.lineChart.symbols.error.upper.color
    } else if (val > settings.uwl) {
      return settings.lineChart.symbols.warn.upper.color
    } else {
      return settings.lineChart.symbols.good.color
    }
  }
}

export const convertToMarkLine = (settings, pageKey) => {
  const createMarkLine = (enabled, labelEnabled, labelName, yAxisValue, lineSettings) => {
    const position = 'insideEndTop'
    const fontFamily = 'Arial'
    const fontSize = pageKey === 'reportPrint' ? 7 : ((settings.page.fontSize / (settings.page.layout.row + settings.page.layout.column)) * 3) / 2

    return {
      show: yAxisValue !== null && enabled,
      name: labelEnabled ? labelName : '',
      yAxis: yAxisValue,
      label: {
        position: position,
        formatter: (args) => args.name,
        fontFamily: fontFamily,
        fontSize: fontSize,
      },
      lineStyle: {
        type: lineSettings?.type,
        color: lineSettings?.color,
        width: lineSettings?.width,
      },
      labelLayout: { moveOverlap: 'shiftY' },
    }
  }

  const { specificationLimitEnable, warningLimitEnable, nominalLimitEnable, averageLimitEnable, plausibilityLimitEnable, scrapLimitEnable, acceptanceLimitEnable } = settings.page
  const { specificationLimitLabelEnable, warningLimitLabelEnable, nominalLimitLabelEnable, averageLimitLabelEnable, plausibilityLimitLabelEnable, scrapLimitLabelEnable, acceptanceLimitLabelEnable } =
    settings.page

  const targetML = [
    { limitEnable: specificationLimitEnable, labelEnable: specificationLimitLabelEnable, name: 'LSL', value: settings.lsl, config: settings.lineChart.markLines.specLimit },
    { limitEnable: specificationLimitEnable, labelEnable: specificationLimitLabelEnable, name: 'USL', value: settings.usl, config: settings.lineChart.markLines.specLimit },
    { limitEnable: warningLimitEnable, labelEnable: warningLimitLabelEnable, name: 'LWL', value: settings.lwl, config: settings.lineChart.markLines.warnLimit },
    { limitEnable: warningLimitEnable, labelEnable: warningLimitLabelEnable, name: 'UWL', value: settings.uwl, config: settings.lineChart.markLines.warnLimit },
    { limitEnable: nominalLimitEnable, labelEnable: nominalLimitLabelEnable, name: 'Nominal', value: settings.nominal, config: settings.lineChart.markLines.nominal },
    { limitEnable: averageLimitEnable, labelEnable: averageLimitLabelEnable, name: 'Average', value: settings.average, config: settings.lineChart.markLines.average },
    { limitEnable: plausibilityLimitEnable, labelEnable: plausibilityLimitLabelEnable, name: 'UPL', value: settings.upl, config: settings.lineChart.markLines.plausibilityLimit },
    { limitEnable: plausibilityLimitEnable, labelEnable: plausibilityLimitLabelEnable, name: 'LPL', value: settings.lpl, config: settings.lineChart.markLines.plausibilityLimit },
    { limitEnable: scrapLimitEnable, labelEnable: scrapLimitLabelEnable, name: 'URL', value: settings.url, config: settings.lineChart.markLines.scrapLimit },
    { limitEnable: scrapLimitEnable, labelEnable: scrapLimitLabelEnable, name: 'LRL', value: settings.lrl, config: settings.lineChart.markLines.scrapLimit },
    { limitEnable: acceptanceLimitEnable, labelEnable: acceptanceLimitLabelEnable, name: 'UAcl', value: settings.uacl, config: settings.lineChart.markLines.acceptanceLimit },
    { limitEnable: acceptanceLimitEnable, labelEnable: acceptanceLimitLabelEnable, name: 'LAcl', value: settings.lacl, config: settings.lineChart.markLines.acceptanceLimit },
  ]

  // Create markLines using map
  const markLines = targetML.map((item) => createMarkLine(item.limitEnable, item.labelEnable, item.name, item.value, item.config))

  // Filter out mark lines where show is false, and remove 'show' property
  return markLines.filter((line) => line.show).map(({ show, ...rest }) => rest)
}

export const convertToMarkArea = (settings) => {
  const createMarkArea = (showCondition, yAxisStart, yAxisEnd, color) => [{ show: showCondition }, { yAxis: yAxisStart }, { yAxis: yAxisEnd, itemStyle: { color: color } }]

  const { usl, lsl, uwl, lwl, yAxisTop, yAxisBottom } = settings
  const { error, warn, good } = settings.lineChart.markAreas

  // Define targetMA with all mark area objects
  const targetMA = [
    { show: usl !== null && yAxisTop !== null, fromValue: usl, toValue: yAxisTop, color: error.color },
    { show: lsl !== null && yAxisBottom !== null, fromValue: lsl, toValue: yAxisBottom, color: error.color },
    { show: uwl !== null && usl !== null, fromValue: uwl, toValue: usl, color: warn.color },
    { show: lwl !== null && lsl !== null, fromValue: lwl, toValue: lsl, color: warn.color },
    { show: lwl !== null && uwl !== null, fromValue: lwl, toValue: uwl, color: good.color },
  ]

  // Generate markAreas using map based on targetMA
  const markAreas = targetMA
    .filter((item) => item.show) // Only create areas where the show condition is true
    .map((item) => createMarkArea(item.show, item.fromValue, item.toValue, item.color))

  // Filter out areas where 'show' is false and remove the 'show' property
  const removeShowMarkAreas = markAreas.map((innerArray) => innerArray.filter((item) => !item.show))

  return removeShowMarkAreas
}

export const convertToChartLine = (settings) => {
  const rtnChartLine = {
    type: settings.lineChart.chartLines.type,
    width: settings.lineChart.chartLines.width,
    color: settings.lineChart.chartLines.color,
  }

  return rtnChartLine
}

const calculateYAxisSpace = (props) => {
  // const {
  //   page: { specificationLimitEnable, warningLimitEnable },
  // } = settings
  const { settings, usl, lsl, uwl, lwl, upl, lpl, url, lrl, uacl, lacl, maxValue, minValue } = props
  const {
    page: { specificationLimitEnable, warningLimitEnable, plausibilityLimitEnable, scrapLimitEnable, acceptanceLimitEnable },
  } = settings

  // const t_usl = specificationLimitEnable ? usl ?? maxValue : maxValue
  // const t_lsl = specificationLimitEnable ? lsl ?? minValue : minValue

  // const t_uwl = warningLimitEnable ? uwl ?? maxValue : maxValue
  // const t_lwl = warningLimitEnable ? lwl ?? minValue : minValue

  // const max = Math.max(t_usl, t_uwl, maxValue)
  // const min = Math.min(t_lsl, t_lwl, minValue)
  const t_usl = specificationLimitEnable ? usl ?? maxValue : maxValue
  const t_lsl = specificationLimitEnable ? lsl ?? minValue : minValue

  const t_uwl = warningLimitEnable ? uwl ?? maxValue : maxValue
  const t_lwl = warningLimitEnable ? lwl ?? minValue : minValue

  const t_upl = plausibilityLimitEnable ? upl ?? maxValue : maxValue
  const t_lpl = plausibilityLimitEnable ? lpl ?? minValue : minValue

  const t_url = scrapLimitEnable ? url ?? maxValue : maxValue
  const t_lrl = scrapLimitEnable ? lrl ?? minValue : minValue

  const t_uacl = acceptanceLimitEnable ? uacl ?? maxValue : maxValue
  const t_lacl = acceptanceLimitEnable ? lacl ?? minValue : minValue

  const max = Math.max(t_usl, t_uwl, t_upl, t_url, t_uacl, maxValue)
  const min = Math.min(t_lsl, t_lwl, t_lpl, t_lrl, t_lacl, minValue)

  let space = Math.abs(max - min)
  if (space === 0) space = 1

  const additionalSpace = space * 0.1

  return { max, min, additionalSpace }
}

export const makeYAxisTop = (props) => {
  const { max, additionalSpace } = calculateYAxisSpace(props)
  return max + additionalSpace
}

export const makeYAxisBottom = (props) => {
  const { min, additionalSpace } = calculateYAxisSpace(props)
  return min - additionalSpace
}
