import { languageData } from 'src/pages/components/dq-convert/DQLanguage'

export const defaultRibbon = {
  superuser: [
    { name: 'overview', isDataNeed: true, parent: '', type: 'page' },
    { name: 'monitoring', isDataNeed: false, parent: '', type: 'page' },
    { name: 'database', isDataNeed: false, parent: '', type: 'page' },
    { name: 'charts', isDataNeed: true, parent: '', type: 'directory' },
    { name: 'valueChart', isDataNeed: true, parent: 'charts', type: 'page' },
    {
      name: 'histogramChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    {
      name: 'positionalTolerancesChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    { name: 'boxPlotChart', isDataNeed: true, parent: 'charts', type: 'page' },
    {
      name: 'linearRegressionChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    {
      name: 'correlationChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    {
      name: 'qualityControlChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    {
      name: 'capabilitySummaryChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    { name: 'paretoChart', isDataNeed: true, parent: 'charts', type: 'page' },
    {
      name: 'probabilityPlotChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    {
      name: 'decisionTreeChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    { name: 'lists', isDataNeed: true, parent: '', type: 'directory' },
    { name: 'valueList', isDataNeed: true, parent: 'lists', type: 'page' },
    { name: 'summaryList', isDataNeed: true, parent: 'lists', type: 'page' },
    {
      name: 'valueSummaryList',
      isDataNeed: true,
      parent: 'lists',
      type: 'page',
    },
    { name: 'scrollBoard', isDataNeed: false, parent: '', type: 'page' },
    { name: 'reportPrint', isDataNeed: false, parent: '', type: 'page' },
    { name: 'reportPrintMultiFunction', isDataNeed: false, parent: '', type: 'page' },
    { name: 'uploadFile', isDataNeed: false, parent: '', type: 'page' },
    { name: 'configuration', isDataNeed: false, parent: '', type: 'page' },
    { name: 'adminSettings', isDataNeed: false, parent: '', type: 'page' },
    { name: 'companyInfo', isDataNeed: false, parent: '', type: 'page' },
  ],
  admin: [
    { name: 'overview', isDataNeed: true, parent: '', type: 'page' },
    { name: 'monitoring', isDataNeed: false, parent: '', type: 'page' },
    { name: 'database', isDataNeed: false, parent: '', type: 'page' },
    { name: 'charts', isDataNeed: true, parent: '', type: 'directory' },
    { name: 'valueChart', isDataNeed: true, parent: 'charts', type: 'page' },
    {
      name: 'histogramChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    {
      name: 'positionalTolerancesChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    { name: 'boxPlotChart', isDataNeed: true, parent: 'charts', type: 'page' },
    {
      name: 'linearRegressionChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    {
      name: 'correlationChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    {
      name: 'qualityControlChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    {
      name: 'capabilitySummaryChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    { name: 'paretoChart', isDataNeed: true, parent: 'charts' },
    {
      name: 'probabilityPlotChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    {
      name: 'decisionTreeChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    { name: 'lists', isDataNeed: true, parent: '', type: 'directory' },
    { name: 'valueList', isDataNeed: true, parent: 'lists', type: 'page' },
    { name: 'summaryList', isDataNeed: true, parent: 'lists', type: 'page' },
    {
      name: 'valueSummaryList',
      isDataNeed: true,
      parent: 'lists',
      type: 'page',
    },
    // { name: 'scrollBoard', isDataNeed: false, parent: '', type: 'page' },
    { name: 'reportPrint', isDataNeed: true, parent: '', type: 'page' },
    { name: 'uploadFile', isDataNeed: false, parent: '', type: 'page' },
    { name: 'configuration', isDataNeed: false, parent: '', type: 'page' },
    { name: 'adminSettings', isDataNeed: false, parent: '', type: 'page' },
    { name: 'companyInfo', isDataNeed: false, parent: '', type: 'page' },
  ],
  engineer: [
    { name: 'overview', isDataNeed: true, parent: '', type: 'page' },
    { name: 'monitoring', isDataNeed: false, parent: '', type: 'page' },
    { name: 'database', isDataNeed: false, parent: '', type: 'page' },
    { name: 'charts', isDataNeed: true, parent: '', type: 'directory' },
    { name: 'valueChart', isDataNeed: true, parent: 'charts', type: 'page' },
    {
      name: 'histogramChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    {
      name: 'positionalTolerancesChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    { name: 'boxPlotChart', isDataNeed: true, parent: 'charts', type: 'page' },
    {
      name: 'linearRegressionChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    {
      name: 'correlationChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    {
      name: 'qualityControlChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    {
      name: 'capabilitySummaryChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    { name: 'paretoChart', isDataNeed: true, parent: 'charts', type: 'page' },
    {
      name: 'probabilityPlotChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    {
      name: 'decisionTreeChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    { name: 'lists', isDataNeed: true, parent: '', type: 'directory' },
    { name: 'valueList', isDataNeed: true, parent: 'lists', type: 'page' },
    { name: 'summaryList', isDataNeed: true, parent: 'lists', type: 'page' },
    {
      name: 'valueSummaryList',
      isDataNeed: true,
      parent: 'lists',
      type: 'page',
    },
    { name: 'reportPrint', isDataNeed: true, parent: '', type: 'page' },
    { name: 'uploadFile', isDataNeed: false, parent: '', type: 'page' },
    // { name: 'configuration', isDataNeed: false, parent: '', type: 'page' },
    // { name: 'adminSettings', isDataNeed: false, parent: '' },
    { name: 'companyInfo', isDataNeed: false, parent: '', type: 'page' },
  ],
  operator: [
    { name: 'overview', isDataNeed: true, parent: '', type: 'page' },
    { name: 'monitoring', isDataNeed: false, parent: '', type: 'page' },
    { name: 'database', isDataNeed: false, parent: '', type: 'page' },
    { name: 'charts', isDataNeed: true, parent: '', type: 'directory' },
    { name: 'valueChart', isDataNeed: true, parent: 'charts', type: 'page' },
    {
      name: 'histogramChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    {
      name: 'positionalTolerancesChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    { name: 'boxPlotChart', isDataNeed: true, parent: 'charts', type: 'page' },
    {
      name: 'linearRegressionChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    {
      name: 'correlationChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    {
      name: 'qualityControlChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    {
      name: 'capabilitySummaryChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    { name: 'paretoChart', isDataNeed: true, parent: 'charts', type: 'page' },
    {
      name: 'probabilityPlotChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    {
      name: 'decisionTreeChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    { name: 'lists', isDataNeed: true, parent: '', type: 'directory' },
    { name: 'valueList', isDataNeed: true, parent: 'lists', type: 'page' },
    { name: 'summaryList', isDataNeed: true, parent: 'lists', type: 'page' },
    {
      name: 'valueSummaryList',
      isDataNeed: true,
      parent: 'lists',
      type: 'page',
    },
    { name: 'reportPrint', isDataNeed: true, parent: '', type: 'page' },
    { name: 'uploadFile', isDataNeed: false, parent: '', type: 'page' },
    // { name: 'configuration', isDataNeed: false, parent: '' },
    // { name: 'adminSettings',isDataNeed: false, parent: '' },
    { name: 'companyInfo', isDataNeed: false, parent: '', type: 'page' },
  ],
  cmm: [
    { name: 'overview', isDataNeed: true, parent: '', type: 'page' },
    { name: 'monitoring', isDataNeed: false, parent: '', type: 'page' },
    { name: 'database', isDataNeed: false, parent: '', type: 'page' },
    { name: 'charts', isDataNeed: true, parent: '', type: 'directory' },
    { name: 'valueChart', isDataNeed: true, parent: 'charts', type: 'page' },
    {
      name: 'histogramChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    // {
    //   name: 'positionalTolerancesChart',
    //   isDataNeed: true,
    //   parent: 'charts',

    // },
    // { name: 'boxPlotChart', isDataNeed: true, parent: 'charts' },
    // {
    //   name: 'linearRegressionChart',
    //   isDataNeed: true,
    //   parent: 'charts',

    // },
    // {
    //   name: 'correlationChart',
    //   isDataNeed: true,
    //   parent: 'charts',

    // },
    // {
    //   name: 'qualityControlChart',
    //   isDataNeed: true,
    //   parent: 'charts',

    // },
    // {
    //   name: 'capabilitySummaryChart',
    //   isDataNeed: true,
    //   parent: 'charts',

    // },
    // { name: 'paretoChart', isDataNeed: true, parent: 'charts' },
    // {
    //   name: 'probabilityPlotChart',
    //   isDataNeed: true,
    //   parent: 'charts',

    // },
    // {
    //   name: 'decisionTreeChart',
    //   isDataNeed: true,
    //   parent: 'charts',

    // },
    { name: 'lists', isDataNeed: true, parent: '', type: 'directory' },
    { name: 'valueList', isDataNeed: true, parent: 'lists', type: 'page' },
    // { name: 'summaryList', isDataNeed: true, parent: 'lists' },
    // {
    //   name: 'valueSummaryList',
    //   isDataNeed: true,
    //   parent: 'lists',

    // },
    { name: 'reportPrint', isDataNeed: true, parent: '', type: 'page' },
    { name: 'uploadFile', isDataNeed: false, parent: '', type: 'page' },
    // { name: 'configuration', isDataNeed: false, parent: '' },
    // { name: 'adminSettings', isDataNeed: false, parent: '' },
    { name: 'companyInfo', isDataNeed: false, parent: '', type: 'page' },
  ],
  qsense: [
    { name: 'overview', isDataNeed: true, parent: '', type: 'page' },
    { name: 'monitoring', isDataNeed: false, parent: '', type: 'page' },
    { name: 'database', isDataNeed: false, parent: '', type: 'page' },
    { name: 'charts', isDataNeed: true, parent: '', type: 'directory' },
    { name: 'valueChart', isDataNeed: true, parent: 'charts', type: 'page' },
    {
      name: 'histogramChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    {
      name: 'positionalTolerancesChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    { name: 'boxPlotChart', isDataNeed: true, parent: 'charts', type: 'page' },
    // {
    //   name: 'linearRegressionChart',
    //   isDataNeed: true,
    //   parent: 'charts',

    // },
    {
      name: 'correlationChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    {
      name: 'qualityControlChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    // {
    //   name: 'capabilitySummaryChart',
    //   isDataNeed: true,
    //   parent: 'charts',

    // },
    // { name: 'paretoChart', isDataNeed: true, parent: 'charts' },
    // {
    //   name: 'probabilityPlotChart',
    //   isDataNeed: true,
    //   parent: 'charts',

    // },
    // {
    //   name: 'decisionTreeChart',
    //   isDataNeed: true,
    //   parent: 'charts',

    // },
    { name: 'lists', isDataNeed: true, parent: '', type: 'directory' },
    { name: 'valueList', isDataNeed: true, parent: 'lists', type: 'page' },
    { name: 'summaryList', isDataNeed: true, parent: 'lists', type: 'page' },
    {
      name: 'valueSummaryList',
      isDataNeed: true,
      parent: 'lists',
      type: 'page',
    },
    { name: 'reportPrint', isDataNeed: true, parent: '', type: 'page' },
    { name: 'uploadFile', isDataNeed: false, parent: '', type: 'page' },
    // { name: 'configuration', isDataNeed: false, parent: '' },
    // { name: 'adminSettings', isDataNeed: false, parent: '' },
    { name: 'companyInfo', isDataNeed: false, parent: '', type: 'page' },
  ],
  // demo spec is same to engineer
  demo: [
    { name: 'overview', isDataNeed: true, parent: '', type: 'page' },
    { name: 'monitoring', isDataNeed: false, parent: '', type: 'page' },
    { name: 'database', isDataNeed: false, parent: '', type: 'page' },
    { name: 'charts', isDataNeed: true, parent: '', type: 'directory' },
    { name: 'valueChart', isDataNeed: true, parent: 'charts', type: 'page' },
    {
      name: 'histogramChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    {
      name: 'positionalTolerancesChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    { name: 'boxPlotChart', isDataNeed: true, parent: 'charts', type: 'page' },
    {
      name: 'linearRegressionChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    {
      name: 'correlationChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    {
      name: 'qualityControlChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    {
      name: 'capabilitySummaryChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    { name: 'paretoChart', isDataNeed: true, parent: 'charts', type: 'page' },
    {
      name: 'probabilityPlotChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    {
      name: 'decisionTreeChart',
      isDataNeed: true,
      parent: 'charts',
      type: 'page',
    },
    { name: 'lists', isDataNeed: true, parent: '', type: 'directory' },
    { name: 'valueList', isDataNeed: true, parent: 'lists', type: 'page' },
    { name: 'summaryList', isDataNeed: true, parent: 'lists', type: 'page' },
    {
      name: 'valueSummaryList',
      isDataNeed: true,
      parent: 'lists',
      type: 'page',
    },
    { name: 'scrollBoard', isDataNeed: false, parent: '', type: 'page' },
    { name: 'reportPrint', isDataNeed: true, parent: '', type: 'page' },
    { name: 'uploadFile', isDataNeed: false, parent: '', type: 'page' },
    { name: 'configuration', isDataNeed: false, parent: '', type: 'page' },
    // { name: 'adminSettings', isDataNeed: false, parent: '' },
    { name: 'companyInfo', isDataNeed: false, parent: '', type: 'page' },
  ],
}

export const adminSettingsPrm = {
  company: ['dynamicQS', 'dynamicGS'],
  license: ['License1', 'License1', 'License3', 'License4', 'License5'],
  accountSettings: {
    password: {
      totalLen: 4,
      minSpecialChar: 1,
      minUpperCaseLetter: 1,
      minLetter: 0,
    },
  },
  language: languageData,
  dbConfig: [
    {
      nickName: 'default',
      address: 'localhost',
      port: 3306,
      username: 'dqs',
      password: 'dqs',
      db_name: 'qs_sense',
    },
  ],
  ribbon: defaultRibbon,
}
