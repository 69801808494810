// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.catalog-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px;
}
.catalog-container .catalog-control-container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 4%;
}
.catalog-container .catalog-control-container .button-container {
  width: 8%;
  height: 100%;
}
.catalog-container .catalog-content-container {
  width: 100%;
  height: 96%;
  display: flex;
  flex-direction: row;
  padding: 5px;
}
.catalog-container .catalog-datagrid-container {
  width: 16.6666666667%;
  height: 100%;
  padding: 5px;
}
.catalog-container .catalog-datagrid-container .catalog-title-container {
  width: 100%;
  height: 3%;
  font-family: "Arial";
  font-size: 15px;
}
.catalog-container .catalog-datagrid-container .catalog-datagrid-content {
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/config/components/main-view/catalog/Catalog.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;AACF;AACE;EACE,aAAA;EACA,8BAAA;EACA,WAAA;EACA,UAAA;AACJ;AACI;EACE,SAAA;EACA,YAAA;AACN;AAGE;EACE,WAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;AADJ;AAIE;EACE,qBAAA;EACA,YAAA;EACA,YAAA;AAFJ;AAII;EACE,WAAA;EACA,UAAA;EACA,oBAAA;EACA,eAAA;AAFN;AAKI;EACE,WAAA;EACA,YAAA;AAHN","sourcesContent":[".catalog-container {\r\n  width: 100%;\r\n  height: 100%;\r\n  display: flex;\r\n  flex-direction: column;\r\n  padding: 5px;\r\n\r\n  .catalog-control-container {\r\n    display: flex;\r\n    justify-content: space-between;\r\n    width: 100%;\r\n    height: 4%;\r\n\r\n    .button-container {\r\n      width: 8%;\r\n      height: 100%;\r\n    }\r\n  }\r\n\r\n  .catalog-content-container {\r\n    width: 100%;\r\n    height: 96%;\r\n    display: flex;\r\n    flex-direction: row;\r\n    padding: 5px;\r\n  }\r\n\r\n  .catalog-datagrid-container {\r\n    width: calc(100% / 6);\r\n    height: 100%;\r\n    padding: 5px;\r\n\r\n    .catalog-title-container {\r\n      width: 100%;\r\n      height: 3%;\r\n      font-family: 'Arial';\r\n      font-size: 15px;\r\n    }\r\n\r\n    .catalog-datagrid-content {\r\n      width: 100%;\r\n      height: 100%;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
