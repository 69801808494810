import React, { createContext, useContext, useEffect, useState } from 'react'
import { loadMessages, locale } from 'devextreme/localization'
import enMessage from 'src/functions/lang/enMessage.json'
import jpMessage from 'src/functions/lang/jaMessage.json'
import krMessage from 'src/functions/lang/krMessage.json'
import { useDispatch, useSelector } from 'react-redux'
import { convertLanguageStructureForDevLocale } from 'src/pages/components/dq-convert/DQLanguage'
import produce from 'immer'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'
import { useAuth } from 'src/contexts/auth'
import { LoadPanel } from 'devextreme-react'

const LanguageContext = createContext()

export function useLanguage() {
  return useContext(LanguageContext)
}

export function LanguageProvider({ children }) {
  const { user, loading } = useAuth()

  const dispatch = useDispatch()
  const rdxLanguageState = useSelector((state) => state.common.pages.adminSettings.language)
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'en')
  const [langLoading, setLangLoading] = useState(true)

  const saveRecentLanguage = (lang) => {
    localStorage.setItem('language', lang)
  }

  const switchLanguage = (lang) => {
    setLangLoading(true)
    setLanguage(lang)
    saveRecentLanguage(lang)
    // updateLanguage(lang)
    locale(lang)
    setLangLoading(false)
  }

  const updateLanguage = (languageSetting) => {
    setLangLoading(true)
    const savedLanguage = convertLanguageStructureForDevLocale(languageSetting)

    const loadLang = {
      en: { ...savedLanguage.en, ...enMessage },
      ja: { ...savedLanguage.ja, ...jpMessage },
      kr: { ...savedLanguage.kr, ...krMessage },
    }

    loadMessages(loadLang)

    // const localeAbbr = languageSetting
    // locale(localeAbbr)
    setLangLoading(false)
  }

  useEffect(() => {
    // Globalize.load(supplemental, deCldrData)
    const initLang = localStorage.getItem('language') || 'en'
    const savedLanguage = convertLanguageStructureForDevLocale(rdxLanguageState)

    const loadLang = {
      en: { ...savedLanguage.en, ...enMessage },
      ja: { ...savedLanguage.ja, ...jpMessage },
      kr: { ...savedLanguage.kr, ...krMessage },
    }

    loadMessages(loadLang)

    const localeAbbr = initLang
    locale(localeAbbr)
    setLangLoading(false)
  }, [])

  if (!(loading === false && langLoading === false)) {
    return <LoadPanel />
  }

  return <LanguageContext.Provider value={{ language, switchLanguage, updateLanguage }}>{children}</LanguageContext.Provider>
}
