import CCUtils from './CommonCalcUtils'
import CPUtils from 'src/functions/CommonPageUtils'
import * as DBUtils from './DatabaseUtils'
import PCUtils from './ProcessCapabilityUtils'
import CatalogUtils from './CatalogUtils'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import DNUtils from 'src/functions/DisplayNumberUtils'
import * as CDSUtils from 'src/functions/CheckDataStateUtils'
import { outlierFilterValues } from './ValueProcessUtils'
import { makeSbSymbol, makeSbSymbolColor, makeSbSymbolSize } from './apache-echarts/ApacheEChartsSymbolCallback'
import { makeSbMarkLine } from './apache-echarts/ApacheEChartsMarkLineCallback'

// class ScrollBoardValueChartUtils {
export function toolTipBalloonCallback(args, settings, configSettings, values, char) {
  let displayStr = ''

  args.forEach((element) => {
    if (element.seriesName) {
      displayStr += `<b>${element.seriesName}</b><br>`
    }

    if (element.data) {
      const numberItemName = dqTranslateMsg('Page_ScrollBoard_' + 'Index')
      displayStr += `${numberItemName} : ${element.data[0]} <br>`
    }
  })

  settings.page.valueChart.toolTipInfoItems?.forEach((item) => {
    const dataField = item
    const valueAtIndex = values[args[0].data[0] - 1][dataField]
    const itemName = dqTranslateMsg('Db_field_Value_' + dataField.charAt(0).toUpperCase() + dataField.slice(1))

    if (valueAtIndex) {
      if (dataField === 'v0004') {
        const dateTimeFormat = CPUtils.getDisplayDateTimeSec(settings.page.valueChart.dateTimeTooltipFormat, valueAtIndex)
        displayStr += `${itemName} : ${dateTimeFormat} <br>`
      } else if (dataField === 'v0001') {
        displayStr += `${itemName} : ${DNUtils.valueDisplay(valueAtIndex, char, configSettings)} <br>`
      } else {
        displayStr += `${itemName} : ${valueAtIndex} <br>`
      }
    } else {
      displayStr += `${itemName} : Unknown <br>`
    }
  })

  if (
    settings.page.valueChart.specificationLimitEnable === true ||
    settings.page.valueChart.warningLimitEnable === true ||
    settings.page.valueChart.averageLimitEnable === true ||
    settings.page.valueChart.nominalLimitEnable === true
  ) {
    const limitsItemName = dqTranslateMsg('Page_ScrollBoard_' + 'Limits')
    displayStr += `<br>${limitsItemName}<br>`

    if (settings.page.valueChart.specificationLimitEnable === true) {
      displayStr += typeof settings.usl === 'number' ? `USL : ${DNUtils.axisValueDisplay(settings.usl, char, configSettings)} <br>` : ''
      displayStr += typeof settings.lsl === 'number' ? `LSL : ${DNUtils.axisValueDisplay(settings.lsl, char, configSettings)} <br>` : ''
    }

    if (settings.page.valueChart.warningLimitEnable === true) {
      displayStr += typeof settings.uwl === 'number' ? `UWL : ${DNUtils.calcValueDisplay(settings.uwl, configSettings)} <br>` : ''
      displayStr += typeof settings.lwl === 'number' ? `LWL : ${DNUtils.calcValueDisplay(settings.lwl, configSettings)} <br>` : ''
    }

    if (settings.page.valueChart.averageLimitEnable === true) {
      const trans = dqTranslateMsg('Page_ScrollBoard_' + 'Average')
      displayStr += typeof settings.average === 'number' ? `${trans} : ${DNUtils.calcValueDisplay(settings.average, configSettings)} <br>` : ''
    }

    if (settings.page.valueChart.nominalLimitEnable === true) {
      const trans = dqTranslateMsg('Page_ScrollBoard_' + 'Nominal')
      displayStr += typeof settings.nominal === 'number' ? `${trans} : ${settings.nominal} <br>` : ''
    }
  }

  return displayStr
}

export function makeXAxisLabelFormat(value, index, settings, refValues) {
  const xAxisLabelItems = settings.page.valueChart?.xAxisLabelItems
  const valueArray = refValues

  if (!xAxisLabelItems || xAxisLabelItems.length === 0) {
    return value
  }

  if (value < 1 || value > valueArray.length) {
    return ''
  }

  let xLabel = ''
  xAxisLabelItems.forEach((item) => {
    if (!ETCUtils.isPositiveInteger(value)) {
      return
    }

    const itemValue = valueArray[value - 1][item]

    if (xLabel === '') {
      if (itemValue !== null && itemValue !== undefined) {
        if (item === 'v0004') {
          xLabel = CPUtils.getDisplayDateTimeSec(settings.page.valueChart.dateTimeLabelDisplayFormat, itemValue)
        } else {
          xLabel = CatalogUtils.convertByCatalog(item, itemValue, settings.configSettings.catalogs)
        }
      } else {
        xLabel = 'null'
      }
    } else {
      xLabel =
        item === 'v0004'
          ? `${xLabel}/${CPUtils.getDisplayDateTimeSec(settings.page.valueChart.dateTimeLabelDisplayFormat, itemValue)}`
          : `${xLabel}/${CatalogUtils.convertByCatalog(item, `${itemValue}`, settings.configSettings.catalogs)}`
    }
  })

  return xLabel
}

export function adjustedForecastSampleSize(settingForecastSize, settingSampleSize, valueSize) {
  // forecast size is less than settingSampleSize - 3 (api spec)
  const sampleSize = settingSampleSize < valueSize ? settingSampleSize : valueSize
  const forecastSize = settingForecastSize < sampleSize - 4 ? settingForecastSize : sampleSize - 4

  return { sampleSize, forecastSize }
}

/**
 *
 * ValueChart Parameter
 */
export function checkMatchCharsAndValues(selectedChars, values) {
  const arrangedSrc = values.filter((value) => {
    return selectedChars.some((char) => char.part_id === value.part_id && char.char_id === value.char_id)
  })
  return arrangedSrc
}

export function removeDummyInValues(valuesWithDummy) {
  const removedValues = valuesWithDummy.map((item) => ({
    ...item,
    valueRaw: item.valueRaw.filter((value) => value.v0002 === 0),
  }))

  return removedValues
}

export const calculateYAxisSpace = (props) => {
  const { settings, usl, lsl, uwl, lwl, upl, lpl, url, lrl, uacl, lacl, maxValue, minValue } = props
  const {
    valueChart: { specificationLimitEnable, warningLimitEnable, plausibilityLimitEnable, scrapLimitEnable, acceptanceLimitEnable },
  } = settings.page

  const t_usl = specificationLimitEnable ? usl ?? maxValue : maxValue
  const t_lsl = specificationLimitEnable ? lsl ?? minValue : minValue

  const t_uwl = warningLimitEnable ? uwl ?? maxValue : maxValue
  const t_lwl = warningLimitEnable ? lwl ?? minValue : minValue

  const t_upl = plausibilityLimitEnable ? upl ?? maxValue : maxValue
  const t_lpl = plausibilityLimitEnable ? lpl ?? minValue : minValue

  const t_url = scrapLimitEnable ? url ?? maxValue : maxValue
  const t_lrl = scrapLimitEnable ? lrl ?? minValue : minValue

  const t_uacl = acceptanceLimitEnable ? uacl ?? maxValue : maxValue
  const t_lacl = acceptanceLimitEnable ? lacl ?? minValue : minValue

  const max = Math.max(t_usl, t_uwl, t_upl, t_url, t_uacl, maxValue)
  const min = Math.min(t_lsl, t_lwl, t_lpl, t_lrl, t_lacl, minValue)

  let space = Math.abs(max - min)
  if (space === 0) space = 1

  const additionalSpace = space * 0.1

  return { max, min, additionalSpace }
}

export const makeYAxisTop = (props) => {
  const { max, additionalSpace } = calculateYAxisSpace(props)
  return max + additionalSpace
}

export const makeYAxisBottom = (props) => {
  const { min, additionalSpace } = calculateYAxisSpace(props)
  return min - additionalSpace
}

export const convertToChartLine = (settings) => {
  const { type, width, color } = settings.theme.valueChart.lineChart.chartLines

  return {
    type: type,
    width: width,
    color: color,
  }
}

export const convertToMarkArea = (settings) => {
  const markAreas = [
    [
      {
        show: settings.usl === null || settings.yAxisTop === null ? false : true,
      },
      {
        yAxis: settings.usl,
      },
      {
        yAxis: settings.yAxisTop,
        itemStyle: {
          color: settings.theme.valueChart.lineChart.markAreas.error.color,
        },
      },
    ],
    [
      {
        show: settings.lsl === null || settings.yAxisBottom === null ? false : true,
      },
      {
        yAxis: settings.lsl,
      },
      {
        yAxis: settings.yAxisBottom,
        itemStyle: {
          color: settings.theme.valueChart.lineChart.markAreas.error.color,
        },
      },
    ],
    [
      {
        show: settings.uwl === null || settings.usl === null ? false : true,
      },
      {
        yAxis: settings.uwl,
      },
      {
        yAxis: settings.usl,
        itemStyle: {
          color: settings.theme.valueChart.lineChart.markAreas.warn.color,
        },
      },
    ],
    [
      {
        show: settings.lwl === null || settings.lsl === null ? false : true,
      },
      {
        yAxis: settings.lwl,
      },
      {
        yAxis: settings.lsl,
        itemStyle: {
          color: settings.theme.valueChart.lineChart.markAreas.warn.color,
        },
      },
    ],
    [
      {
        show: settings.lwl === null || settings.uwl === null ? false : true,
      },
      {
        yAxis: settings.lwl,
      },
      {
        yAxis: settings.uwl,
        itemStyle: {
          color: settings.theme.valueChart.lineChart.markAreas.good.color,
        },
      },
    ],
  ]

  const validAreas = markAreas.filter((array) => array.some((item) => item.show === true))

  const removeShowMarkAreas = validAreas.map((innerArray) => innerArray.filter((item) => !item.show))

  return removeShowMarkAreas
}

export function makeSeriesDataSplit(settings) {
  const flatValues = settings.filteredData.flat()
  const listItem = flatValues.flat().map((value) => value[settings.page.splitItem[0]])
  const uniqueList = [...new Set(listItem)]

  const valueData = uniqueList.map((item) => {
    return {
      name: item ?? '',
      value: flatValues
        .map((obj, index) => {
          if (obj[settings.page.splitItem[0]] === item) {
            return [index + 1, obj.v0001]
          }
        })
        .filter((item) => item !== undefined),
    }
  })

  console.log(valueData)

  return valueData
}

export const makeSeries = (mode, settings, sbPageSettings) => {
  let dispValue = []
  let isSplitChart = false
  if (settings.page.splitItem.length > 0) {
    isSplitChart = true
    dispValue = makeSeriesDataSplit(settings)
  } else {
    const flatValues = settings.filteredData.flat()
    dispValue = [
      {
        name: dqTranslateMsg('Page_ValueChart_' + 'ValueInfo'),
        value: flatValues.map((value, index) => {
          return [index + 1, value.v0001]
        }),
      },
    ]
  }

  let seriesData = []

  seriesData.push({
    type: 'line',
    markLine: {
      animation: false,
      symbol: 'none',
      precision: 10,
      // data: convertToMarkLine(mode, settings),
      data: makeSbMarkLine(mode, settings),
      emphasis: {
        disabled: true,
      },
    },
  })
  seriesData.push({
    type: 'line',
    emphasis: {
      disabled: true,
    },
    markArea: {
      data: convertToMarkArea(settings),
    },
  })

  // splited
  dispValue.map((data, index) =>
    seriesData.push({
      type: 'line',
      name: isSplitChart === true ? CatalogUtils.convertByCatalog(settings.page.splitItem[0], data.name, settings.configSettings.catalogs) : `${data.name}`,
      data: data.value,
      symbol: (value, params) => makeSbSymbol(value, params, settings, settings),
      symbolSize: (value, params) => makeSbSymbolSize(value, params, settings),
      animation: true,
      itemStyle: {
        color: (params) => makeSbSymbolColor(params, settings),
      },
      // emphasis: {
      //   focus: 'series',
      // },
      ...(isSplitChart === false
        ? {
            lineStyle: convertToChartLine(settings),
          }
        : null),
    })
  )

  return seriesData
}

export const makeManualSeries = (mode, settings, configSettings) => {
  let dispValue = []
  let isSplitChart = false
  if (settings.page.splitItem.length > 0) {
    isSplitChart = true
    dispValue = makeSeriesDataSplit(settings)
  } else {
    const flatValues = settings.filteredData.flat()
    dispValue = [
      {
        name: dqTranslateMsg('Page_ScrollBoard_' + 'ValueInfo'),
        value: flatValues.map((value, index) => {
          return [index + 1, value.v0001]
        }),
      },
    ]
  }

  let seriesData = []

  seriesData.push({
    type: 'line',
    markLine: {
      silent: true,
      animation: false,
      symbol: 'none',
      precision: 10,
      // data: convertToMarkLine(mode, settings),
      data: makeSbMarkLine(mode, settings),
      emphasis: {
        disabled: true,
      },
    },
  })
  seriesData.push({
    type: 'line',
    emphasis: {
      disabled: true,
    },
    markArea: {
      silent: true,
      data: convertToMarkArea(settings),
    },
  })

  // splited
  dispValue.map((data, index) => {
    const markPoints = settings.filteredData
      .map((value, index) => {
        if (value.v0005 !== null && value.v0005 !== '') {
          const v0005 = value.v0005.split(',')
          const v0005Array = v0005.map((event) => {
            return CatalogUtils.convertByCatalog('v0005', event, configSettings.catalogs)
          })
          const formattedV0005 = Array.isArray(v0005Array) ? v0005Array.join(', ') : v0005Array.toString()

          return {
            coord: [index + 1, value.v0001],
            value: value.v0005,
            symbolSize: 0,
            label: {
              formatter: `{value|${formattedV0005}}`,
              backgroundColor: '#eee',
              borderColor: '#777',
              borderWidth: 1,
              borderRadius: 4,
              position: 'top',
              // width: 'auto', // Set width to auto for flexible sizing
              rich: {
                hr: {
                  borderColor: '#777',
                  width: '100%',
                  borderWidth: 0.5,
                  height: 0,
                },
                value: {
                  color: '#000',
                  // width: 100,
                  width: 'auto',
                  maxWidth: 300, // Set a max width for longer content
                  overflow: 'hidden', // Hide overflow if the text is too long
                  textOverflow: 'ellipsis', // Add ellipsis (...) for overflowed text
                  height: 30,
                  padding: [0, 10, 0, 10],
                  align: 'center',
                },
                valueHead: {
                  color: '#777',
                  height: 25,
                  align: 'center',
                },
              },
            },
          }
        } else {
          return null
        }
      })
      .filter((item) => item !== null)

    let seriesConfig = {
      type: 'line',
      name: isSplitChart === true ? CatalogUtils.convertByCatalog(settings.page.splitItem[0], data.name, settings.configSettings.catalogs) : `${data.name}`,
      data: data.value,
      symbol: (value, params) => makeSbSymbol(value, params, settings),
      symbolSize: (value, params) => makeSbSymbolSize(value, params, settings),
      animation: true,
      itemStyle: {
        color: (params) => makeSbSymbolColor(params, settings),
      },
      markPoint: {
        data: markPoints, // 마크포인트 추가
        // symbol: 'pin', // 마크포인트 심볼
        // position: 'outside',
        // symbol: `image://${URL.createObjectURL(new Blob([balloonSVG], { type: 'image/svg+xml' }))}`, // 말풍선 SVG 사용
        // symbolSize: 50, // 심볼 크기
        // symbolSize: (value, params) => {
        //   // content의 길이에 따라 symbolSize를 동적으로 조절
        //   const content = `${params.data.value}` // 표시할 내용
        //   const baseSize = 50 // 기본 크기
        //   const additionalSize = content.length * 8 // 콘텐츠 길이에 따라 크기를 조절
        //   return [baseSize + additionalSize, baseSize + additionalSize] // 가로, 세로 크기 동적 조절
        // },
        // symbolOffset: [0, '100%'],
      },
      // markPoint: {
      //   data: markPoints, // 마크포인트 추가
      //   // symbolOffset: [0, '100%'],
      // },
      ...(isSplitChart === false
        ? {
            lineStyle: convertToChartLine(settings),
          }
        : null),
    }

    seriesData.push(seriesConfig)
  })

  return seriesData
}

export function makePCData(configSettings, char, value) {
  const { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset } = PCUtils.getAllLimits(char, configSettings)
  const limits = { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset }
  // const filteredValue = outlierFilterValues(limits, char, value.valueRaw, configSettings)
  const filteredValue = value.valueRaw

  if (filteredValue.length !== 0) {
    const { lcl, ucl, average, minValue, maxValue, v0001s } = PCUtils.getCalculatedPrms(filteredValue, configSettings)

    return {
      lsl,
      usl,
      nominal,
      lwl,
      uwl,
      upl,
      lpl,
      url,
      lrl,
      lacl,
      uacl,
      ucl,
      lcl,
      valuesReference: filteredValue,
      v0001s,
      average,
      minValue,
      maxValue,
    }
  } else {
    return null
  }
}

export function makeChartData(pageSet, configSettings, pcSet, char, value) {
  // const titleText = `${DBUtils.getFieldData(char, 'c2002') ?? 'empty'} P:${DBUtils.getFieldData(char, 'part_id') ?? '?'} / C:${DBUtils.getFieldData(char, 'char_id') ?? '?'}`

  // const maxValue = CCUtils.getMax(pcSet.v0001s)
  // const minValue = CCUtils.getMin(pcSet.v0001s)
  // const xAxisRight = pcSet.v0001s.length + 1
  // const xAxisLeft = 0
  // // const yAxisTop = makeYAxisTop(pageSet, pcSet.usl, pcSet.lsl, maxValue)
  // // const yAxisBottom = makeYAxisBottom(pageSet, pcSet.usl, pcSet.lsl, minValue)
  // // const yAxisTop = makeYAxisTop(pageSet, pcSet.usl, pcSet.lsl, maxValue, minValue)
  // // const yAxisBottom = makeYAxisBottom(pageSet, pcSet.usl, pcSet.lsl, maxValue, minValue)
  // const yAxisTop = makeYAxisTop(pageSet, pcSet.usl, pcSet.lsl, pcSet.uwl, pcSet.lwl, maxValue, minValue)
  // const yAxisBottom = makeYAxisBottom(pageSet, pcSet.usl, pcSet.lsl, pcSet.uwl, pcSet.lwl, maxValue, minValue)
  // const decimalPlaces = DBUtils.getFieldData(char, 'c2022') ?? configSettings.commonSettings.displayDecimalPlaces

  // const filteredData = value.valueRaw

  // return {
  //   titleText,
  //   maxValue,
  //   minValue,
  //   xAxisRight,
  //   xAxisLeft,
  //   yAxisBottom,
  //   yAxisTop,
  //   decimalPlaces,
  //   filteredData,
  // }
  const titleText = `${DBUtils.getFieldData(char, 'c2002') ?? 'empty'} P:${DBUtils.getFieldData(char, 'part_id') ?? '?'} / C:${DBUtils.getFieldData(char, 'char_id') ?? '?'}`
  const xAxisRight = pcSet.v0001s.length + 1
  const xAxisLeft = 0

  const topBottomCalcArray = {
    settings: pageSet,
    usl: pcSet.usl,
    lsl: pcSet.lsl,
    uwl: pcSet.uwl,
    lwl: pcSet.lwl,
    upl: pcSet.upl,
    lpl: pcSet.lpl,
    url: pcSet.url,
    lrl: pcSet.lrl,
    uacl: pcSet.uacl,
    lacl: pcSet.lacl,
    maxValue: pcSet.maxValue,
    minValue: pcSet.minValue,
  }

  const yAxisTop = makeYAxisTop(topBottomCalcArray)
  const yAxisBottom = makeYAxisBottom(topBottomCalcArray)
  const decimalPlaces = DNUtils.getValueDecimalPlaces(char, configSettings)

  const filteredData = pcSet.valuesReference
  const maxValue = pcSet.maxValue
  const minValue = pcSet.minValue

  return {
    titleText,
    maxValue,
    minValue,
    xAxisRight,
    xAxisLeft,
    yAxisBottom,
    yAxisTop,
    decimalPlaces,
    filteredData,
  }
}

export function makeChartOption(pageKey, pageSet, configSettings, char, valueArray, mode, pcSet, chartSet) {
  try {
    // const pcSet = makePCData(configSettings, char, valueArray)
    // const chartSet = makeChartData(pageSet, configSettings, pcSet, char, valueArray)

    const settings = {
      ...pageSet,
      configSettings,
      ...pcSet,
      ...chartSet,
      char,
    }

    return {
      title:
        pageKey !== 'reportPrint'
          ? {
              text: settings.titleText,
              left: 'center',
              textStyle: {
                fontFamily: 'Arial',
                fontSize:
                  mode === 'manual'
                    ? (settings.theme.valueChartTitleFontSize / (1 + 1)) * 4
                    : (settings.theme.valueChartTitleFontSize / (settings.page.autoPageValueChartLayoutY + settings.page.autoPageValueChartLayoutX)) * 4,
              },
            }
          : null,
      animationDuration: 300,
      backgroundColor: 'rgba(255, 255, 255, 0)',
      grid: {
        top: '15%',
        bottom: '7%',
        left: '0%',
        right: '5%',
        containLabel: true,
      },
      legend:
        settings.page.autoPageValueChartLayoutY > 3
          ? null
          : {
              top: '8%',
              orient: 'horizontal',
            },
      tooltip: {
        trigger: 'axis',
        appendToBody: true,
        position: function (pos, params, dom, rect, size) {
          let obj = { top: 60 }
          obj['left'] = pos[0] < size.viewSize[0] / 2 ? pos[0] + 50 : pos[0] - size.contentSize[0] - 50
          return obj
        },
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        extraCssText: 'width: auto; text-align: left;',
        formatter: (args) => toolTipBalloonCallback(args, settings, configSettings, valueArray.valueRaw, char),
        textStyle: {
          fontFamily: 'Arial',
        },
        axisPointer: { type: 'cross', z: 9999 },
      },
      toolbox:
        pageKey !== 'reportPrint'
          ? {
              feature: {
                saveAsImage: {},
              },
            }
          : null,
      xAxis: {
        type: 'value',
        min: settings.xAxisLeft,
        max: settings.xAxisRight,
        minInterval: 1,
        maxInterval: settings.page.valueChart.xAxisDenseLabel ? 2 : settings.xAxisRight < 10 ? 1 : Math.abs(Math.floor(settings.xAxisRight / 5)),
        data: valueArray.valueRaw.map((value, index) => [index + 1]),
        axisLabel: {
          rotate: 90,
          fontFamily: 'Arial',
          fontSize:
            mode === 'manual'
              ? (settings.theme.valueChartXAxisFontSize / (1 + 1)) * 3
              : (settings.theme.valueChartAutoYAxisFontSize / (settings.page.autoPageValueChartLayoutY + settings.page.autoPageValueChartLayoutX)) * 3,
          hideOverlap: true,
          showMinLabel: false,
          showMaxLabel: false,
          formatter: (value, index) => makeXAxisLabelFormat(value, index, settings, valueArray.valueRaw),
        },
        axisLine: {
          onZero: false,
          hideOverlap: true,
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          interval: 1,
        },
      },
      yAxis: {
        min: settings.yAxisBottom,
        max: settings.yAxisTop,
        splitLine: {
          show: false,
        },
        nameTextStyle: {
          fontFamily: 'Arial',
        },
        axisLabel: {
          fontFamily: 'Arial',
          fontSize:
            mode === 'manual'
              ? (settings.theme.valueChartYAxisFontSize / (1 + 1)) * 3
              : (settings.theme.valueChartAutoYAxisFontSize / (settings.page.autoPageValueChartLayoutY + settings.page.autoPageValueChartLayoutX)) * 3,
          showMinLabel: false,
          showMaxLabel: false,
          formatter: function (value, index) {
            let axisMax = settings.yAxisTop
            let axisMin = settings.yAxisBottom

            if (value === axisMin || value === axisMax) {
              return ''
            }
            return value
          },
        },
        axisLine: {
          onZero: false,
        },
      },
      dataZoom: [
        {
          show: true,
          type: 'slider',
          orient: 'horizontal',
          xAxisIndex: 0,
          height: `3%`,
          bottom: '3%',
          brushSelect: true,
          showDataShadow: true,
          showDetail: false,
          filterMode: 'none',
        },
        {
          show: true,
          type: 'slider',
          orient: 'vertical',
          width: `3%`,
          brushSelect: true,
          showDataShadow: true,
          showDetail: false,
          filterMode: 'none',
        },
        {
          show: true,
          type: 'inside',
          yAxisIndex: 0,
          filterMode: 'none',
          zoomLock: false, // Allow zooming
          zoomOnMouseWheel: 'shift', // Zoom on Shift + MouseWheel
        },
        {
          show: true,
          type: 'inside',
          xAxisIndex: 0,
          filterMode: 'none',
        },
      ],
      series: makeSeries(mode, settings),
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export function makeManualChartOption(pageKey, pageSet, configSettings, char, valueArray, mode, pcSet, chartSet) {
  try {
    // const pcSet = makePCData(configSettings, char, valueArray)
    // const chartSet = makeChartData(pageSet, configSettings, pcSet, char, valueArray)

    const settings = {
      ...pageSet,
      configSettings,
      ...pcSet,
      ...chartSet,
      char,
    }

    return {
      title:
        pageKey !== 'reportPrint'
          ? {
              text: settings.titleText,
              left: 'center',
              textStyle: {
                fontFamily: 'Arial',
                fontSize:
                  mode === 'manual'
                    ? (settings.theme.valueChartTitleFontSize / (1 + 1)) * 4
                    : (settings.theme.valueChartTitleFontSize / (settings.page.autoPageValueChartLayoutY + settings.page.autoPageValueChartLayoutX)) * 4,
              },
            }
          : null,
      animationDuration: 300,
      backgroundColor: 'rgba(255, 255, 255, 0)',
      grid: {
        top: '15%',
        bottom: '7%',
        left: '0%',
        right: '5%',
        containLabel: true,
      },
      legend:
        settings.page.autoPageValueChartLayoutY > 3
          ? null
          : {
              top: '6%',
              orient: 'horizontal',
            },
      tooltip: {
        trigger: 'axis',
        appendToBody: true,
        position: function (pos, params, dom, rect, size) {
          let obj = { top: 60 }
          obj['left'] = pos[0] < size.viewSize[0] / 2 ? pos[0] + 50 : pos[0] - size.contentSize[0] - 50
          return obj
        },
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        extraCssText: 'width: auto; text-align: left;',
        formatter: (args) => toolTipBalloonCallback(args, settings, configSettings, valueArray.valueRaw, char),
        textStyle: {
          fontFamily: 'Arial',
        },
        axisPointer: { type: 'cross', z: 9999 },
      },
      toolbox:
        pageKey !== 'reportPrint'
          ? {
              feature: {
                saveAsImage: {},
              },
            }
          : null,
      xAxis: {
        type: 'value',
        min: settings.xAxisLeft,
        max: settings.xAxisRight,
        minInterval: 1,
        maxInterval: settings.page.valueChart.xAxisDenseLabel ? 2 : settings.xAxisRight < 10 ? 1 : Math.abs(Math.floor(settings.xAxisRight / 5)),
        data: valueArray.valueRaw.map((value, index) => [index + 1]),
        axisLabel: {
          rotate: 90,
          fontFamily: 'Arial',
          fontSize:
            mode === 'manual'
              ? (settings.theme.valueChartXAxisFontSize / (1 + 1)) * 3
              : (settings.theme.valueChartAutoYAxisFontSize / (settings.page.autoPageValueChartLayoutY + settings.page.autoPageValueChartLayoutX)) * 3,
          hideOverlap: true,
          showMinLabel: false,
          showMaxLabel: false,
          formatter: (value, index) => makeXAxisLabelFormat(value, index, settings, valueArray.valueRaw),
        },
        axisLine: {
          onZero: false,
          hideOverlap: true,
        },
        splitLine: {
          show: false,
        },
        axisTick: {
          interval: 1,
        },
      },
      yAxis: {
        min: settings.yAxisBottom,
        max: settings.yAxisTop,
        splitLine: {
          show: false,
        },
        nameTextStyle: {
          fontFamily: 'Arial',
        },
        axisLabel: {
          fontFamily: 'Arial',
          fontSize:
            mode === 'manual'
              ? (settings.theme.valueChartYAxisFontSize / (1 + 1)) * 3
              : (settings.theme.valueChartAutoYAxisFontSize / (settings.page.autoPageValueChartLayoutY + settings.page.autoPageValueChartLayoutX)) * 3,
          showMinLabel: false,
          showMaxLabel: false,
          formatter: function (value, index) {
            let axisMax = settings.yAxisTop
            let axisMin = settings.yAxisBottom

            if (value === axisMin || value === axisMax) {
              return ''
            }
            return value
          },
        },
        axisLine: {
          onZero: false,
        },
      },
      dataZoom: [
        {
          show: true,
          type: 'slider',
          orient: 'horizontal',
          xAxisIndex: 0,
          height: `3%`,
          bottom: '3%',
          brushSelect: true,
          showDataShadow: true,
          showDetail: false,
          filterMode: 'none',
        },
        {
          show: true,
          type: 'slider',
          orient: 'vertical',
          width: `3%`,
          brushSelect: true,
          showDataShadow: true,
          showDetail: false,
          filterMode: 'none',
        },
        {
          show: true,
          type: 'inside',
          yAxisIndex: 0,
          filterMode: 'none',
          zoomLock: false, // Allow zooming
          zoomOnMouseWheel: 'shift', // Zoom on Shift + MouseWheel
        },
        {
          show: true,
          type: 'inside',
          xAxisIndex: 0,
          filterMode: 'none',
        },
      ],
      series: makeManualSeries(mode, settings, configSettings),
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export function makeChartOptionArray(type, pageKey, chars, values, pageSet, configSettings, mode) {
  return values
    .map((value, index) => {
      const char = chars.find((c) => c.part_id === value.part_id && c.char_id === value.char_id)
      const info = {
        part_id: char.part_id,
        char_id: char.char_id,
      }

      if (value.valueRaw.length > 0) {
        if (type === 'manual') {
          const pcSet = makePCData(configSettings, char, value)

          let option = null
          if (pcSet) {
            const chartSet = makeChartData(pageSet, configSettings, pcSet, char, value)
            option = makeManualChartOption(pageKey, pageSet, configSettings, char, value, mode, pcSet, chartSet)
          }

          return { option, info, refValues: pcSet.valuesReference }
        } else {
          const pcSet = makePCData(configSettings, char, value)

          let option = null
          if (pcSet) {
            const chartSet = makeChartData(pageSet, configSettings, pcSet, char, value)
            option = makeChartOption(pageKey, pageSet, configSettings, char, value, mode, pcSet, chartSet)
          }
          return { option, info, refValues: pcSet.valuesReference }
        }
      } else {
        return null
      }
    })
    .filter((item) => item !== null)
}
