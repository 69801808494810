import IconLoader from 'src/functions/IconLoader'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'

export const renderResult = (cellData, settings, configSettings, theme) => {
  const iconType = settings.theme.iconType.result

  if (cellData.data.result === 1) {
    return (
      <img
        src={`${IconLoader.getImgPath(
          configSettings.menuIconType, // mode
          IconLoader.TYPES_DEF.find((type) => type.path === iconType).id,
          theme.includes('dark') ? 1 : 0,
          IconLoader.STATE_DEF.find((type) => type.path === 'good').id
        )}`}
        // src={ps['summaryList'].getPageIcon(iconType, theme)}
        width="20"
        height="20"
      />
    )
  } else if (cellData.data.result === 0) {
    return (
      <img
        src={`${IconLoader.getImgPath(
          configSettings.menuIconType, // mode
          IconLoader.TYPES_DEF.find((type) => type.path === iconType).id,
          theme.includes('dark') ? 1 : 0,
          IconLoader.STATE_DEF.find((type) => type.path === 'bad').id
        )}`}
        width="20"
        height="20"
      />
    )
  } else {
    return <img src={'/images/color/warning-exclamation.png'} width="20" height="20" />
  }
}

export function renderCalcDisplay(value, configSettings) {
  return <div>{DNUtils.calcValueDisplay(value, configSettings)}</div>
}
export function renderDBValueDisplay(value, configSettings) {
  return <div>{DNUtils.calcValueDisplay(value, configSettings)}</div>
}

export function renderCp(cellData, settings, configSettings) {
  if (cellData.data.cp !== null && cellData.data.cp !== undefined) {
    if (cellData.data.cp <= settings.page.cpGood) {
      return (
        <div className="summary-list-cp-cpk-container">
          <div style={{ color: 'red' }}>{DNUtils.calcValueDisplay(cellData.data.cp, configSettings)}</div>
          <img src={'/images/color/warning-exclamation.svg'} width="15" height="15" />
        </div>
      )
    }

    return <div>{DNUtils.calcValueDisplay(cellData.data.cp, configSettings)}</div>
  } else {
    return <img src={'/images/color/warning-exclamation.svg'} width="20" height="20" />
  }
}

export function renderCpk(cellData, settings, configSettings) {
  if (cellData.data.cpk !== null && cellData.data.cpk !== undefined) {
    if (cellData.data.cpk <= settings.page.cpkGood) {
      return (
        <div className="summary-list-cp-cpk-container">
          <div style={{ color: 'red' }}>{DNUtils.calcValueDisplay(cellData.data.cpk, configSettings)}</div>
          <img src={'/images/color/warning-exclamation.svg'} width="15" height="15" />
        </div>
      )
    }

    return (
      <div className="summary-list-cp-cpk-container">
        <div style={{ fontSize: '12px' }}>{DNUtils.calcValueDisplay(cellData.data.cpk, configSettings)}</div>
      </div>
    )
  } else {
    return (
      <>
        <img src={'/images/color/warning-exclamation.svg'} width="20" height="20" />
      </>
    )
  }
}

export function renderOutOfTol(cellData, settings, configSettings, theme) {
  const iconType = settings.theme.iconType.outOfTolerances

  if (cellData.data.out_of_tol === 1) {
    return (
      <img
        src={`${IconLoader.getImgPath(
          configSettings.menuIconType, // mode
          IconLoader.TYPES_DEF.find((type) => type.path === iconType).id,
          theme.includes('dark') ? 1 : 0,
          IconLoader.STATE_DEF.find((type) => type.path === 'good').id
        )}`}
        width="20"
        height="20"
      />
    ) // OK
  } else if (cellData.data.out_of_tol === 0) {
    return (
      <img
        src={`${IconLoader.getImgPath(
          configSettings.menuIconType, // mode
          IconLoader.TYPES_DEF.find((type) => type.path === iconType).id,
          theme.includes('dark') ? 1 : 0,
          IconLoader.STATE_DEF.find((type) => type.path === 'bad').id
        )}`}
        width="20"
        height="20"
      />
    )
  } else {
    return (
      <>
        <img src={'/images/color/warning-exclamation.png'} width="20" height="20" />
        {/* <div style={{ fontSize: '7px' }}>Data cannot be evaluated</div> */}
      </>
    )
  }
}
