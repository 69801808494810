import { DateBox, Switch, Tooltip, Validator } from 'devextreme-react'
import { Button } from 'devextreme-react/button'
import { Column, DataGrid, Editing, Item, Lookup, Scrolling, Toolbar, ValidationRule } from 'devextreme-react/data-grid'
import { NumberBox, Button as NumberBoxButton } from 'devextreme-react/number-box'
import SelectBox from 'devextreme-react/select-box'
import { Toast } from 'devextreme-react/toast'
import { CustomRule } from 'devextreme-react/validator'
import produce from 'immer'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from 'src/contexts/auth'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'
import * as sd from 'src/pages/components/side-panel-form/component/define/SettingsDefine'
import { databaseSettingsPrm } from 'src/pages/database/components/settings/DatabaseSettings'
import { setDatabasePage } from 'src/store/common'
import './DatabaseFilterForm.scss'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'
import TextTitle from 'src/functions/TextTitle'
import { useLanguage } from 'src/contexts/languages'

const arrangementDefine = () => [
  { value: 'None', text: dqTranslateMsg('Page_Database_' + 'None') },
  {
    value: 'DateTime',
    text: dqTranslateMsg('Page_Database_' + 'DateTime'),
  },
  {
    value: 'PartIdent',
    text: dqTranslateMsg('Page_Database_' + 'PartIdent'),
  },
  { value: 'Order', text: dqTranslateMsg('Page_Database_' + 'Order') },
]

const sortDefine = () => [
  { value: 'None', text: dqTranslateMsg('Page_Database_' + 'None') },
  {
    value: 'Ascending',
    text: dqTranslateMsg('Page_Database_' + 'Ascending'),
  },
  {
    value: 'Descending',
    text: dqTranslateMsg('Page_Database_' + 'Descending'),
  },
]

// const sortDefine = ['NONE', 'ASCENDING', 'DESCENDING']
const useOnOffDefine = [true, false]

function convertToDevExpressDate(dateString) {
  const year = parseInt(dateString.slice(0, 4), 10)
  const month = parseInt(dateString.slice(4, 6), 10) - 1 // Months are zero-based (0-11)
  const day = parseInt(dateString.slice(6, 8), 10)
  const hours = parseInt(dateString.slice(8, 10), 10)
  const minutes = parseInt(dateString.slice(10, 12), 10)
  const seconds = parseInt(dateString.slice(12, 14), 10)

  return new Date(year, month, day, hours, minutes, seconds)
}

function convertToCustomFormat(devExpressDate) {
  const year = devExpressDate.getFullYear()
  const month = (devExpressDate.getMonth() + 1).toString().padStart(2, '0')
  const day = devExpressDate.getDate().toString().padStart(2, '0')
  const hours = devExpressDate.getHours().toString().padStart(2, '0')
  const minutes = devExpressDate.getMinutes().toString().padStart(2, '0')
  const seconds = devExpressDate.getSeconds().toString().padStart(2, '0')

  return `${year}${month}${day}${hours}${minutes}${seconds}`
}

function makeConvertGridDataFromDTO(data) {
  console.log(data)
  // Initialize an empty result array
  const resultArray = []

  for (const key in data) {
    if (data.hasOwnProperty(key)) {
      resultArray.push({ field_id: key, value: data[key] })
    }
  }

  return resultArray
}

export default function DatabaseFilterForm(props) {
  const { pageKey } = props.value
  const dispatch = useDispatch()
  const { user } = useAuth()
  const { language } = useLanguage()

  const common = useSelector((state) => state.common)
  const curPage = common.pages[pageKey]
  const configPage = common.pages.configuration

  const useField = configPage.useFieldList
  const usePartFilterField = useField.part.map((item) => {
    return { ...item, text: dqTranslateMsg('Db_field_Part_' + item.text) }
  })
  const useCharFilterField = useField.char.map((item) => {
    return {
      ...item,
      text: dqTranslateMsg('Db_field_Characteristics_' + item.text),
    }
  })
  const useValueFilterField = useField.value.map((item) => {
    return { ...item, text: dqTranslateMsg('Db_field_Value_' + item.text) }
  })

  const refFilterPartGrid = useRef(null)
  const refFilterCharGrid = useRef(null)
  const refFilterValueGrid = useRef(null)

  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: 'info',
    message: '',
  })

  const handleToastDisplay = (type, message) => {
    setToastConfig({
      ...toastConfig,
      isVisible: true,
      type,
      message,
    })
  }

  function onHiding() {
    setToastConfig({
      ...toastConfig,
      isVisible: false,
    })
  }

  const handleCurPageSettingsSave = (updatePageData) => {
    dqsImpl.setCurPageSettings(dispatch, user, pageKey, updatePageData)
  }

  function handleSaveButtonClicked() {
    const next = produce(curPage, (draft) => {
      Object.keys(curPage).forEach((key) => {
        draft[key] = curPage[key]
      })
    })

    handleCurPageSettingsSave(next)
    handleToastDisplay('success', dqTranslateMsg('Page_Database_' + 'SavedSettings'))
  }

  function handleResetButtonClicked() {
    const next = produce(curPage, (draft) => {
      Object.entries(databaseSettingsPrm).forEach(([key, value]) => {
        draft[key] = value
      })
    })

    handleCurPageSettingsSave(next)
    handleToastDisplay('warning', dqTranslateMsg('Page_Database_' + 'ResetSettings'))
  }

  function handleArrangementChange(e) {
    const next = produce(settings, (draft) => {
      draft.filters.others.arrangement = e.value
    })

    dispatch(ps.database.setPageSettingsToRedux(next))
  }
  function handleSortChange(e) {
    const next = produce(settings, (draft) => {
      draft.filters.common.dateTimeSort = e.value
    })

    dispatch(ps.database.setPageSettingsToRedux(next))
  }

  /** Load Count */
  function handleUseEasyLoad(e) {
    const next = produce(settings, (draft) => {
      draft.filters.others.useEasyLoad = e.value
    })

    dispatch(ps.database.setPageSettingsToRedux(next))
  }

  function handleEasyLoadValue(e) {
    const next = produce(settings, (draft) => {
      draft.filters.common.easyLoadValue = e.value
    })

    dispatch(ps.database.setPageSettingsToRedux(next))
  }

  function handleEasyLoadValueType(e) {
    const next = produce(settings, (draft) => {
      draft.filters.others.easyLoadValueType = e.value
    })

    dispatch(ps.database.setPageSettingsToRedux(next))
  }

  const handleUseIgnoreDuplicatedValue = (e) => {
    const next = produce(settings, (draft) => {
      draft.filters.others.ignoreDuplicatedValue = e.value
    })

    dispatch(ps.database.setPageSettingsToRedux(next))
  }

  /** Data Time */
  const handleUseDateTimeUpdated = (e) => {
    const next = produce(settings, (draft) => {
      draft.filters.others.useDateTime = e.value
    })

    dispatch(ps.database.setPageSettingsToRedux(next))
  }

  const handleStartDateTimeChanged = (e) => {
    const startTime = parseInt(convertToCustomFormat(e.value))
    const endTime = parseInt(settings.filters.common.endDateTime)

    if (startTime > endTime) {
      alert(dqTranslateMsg('Page_Database_' + 'ErrorMsgInvalidDateTime'))
      return
    }

    const next = produce(settings, (draft) => {
      draft.filters.common.startDateTime = convertToCustomFormat(e.value)
    })
    dispatch(ps.database.setPageSettingsToRedux(next))
  }

  const handleEndDateTimeChanged = (e) => {
    const startTime = parseInt(settings.filters.common.startDateTime)
    const endTime = parseInt(convertToCustomFormat(e.value))

    if (startTime > endTime) {
      alert(dqTranslateMsg('Page_Database_' + 'ErrorMsgInvalidDateTime'))
      return
    }
    const next = produce(settings, (draft) => {
      draft.filters.common.endDateTime = convertToCustomFormat(e.value)
    })

    dispatch(ps.database.setPageSettingsToRedux(next))
  }

  /** Data Time */
  const handlePageCalculationInAdvance = (e) => {
    const next = produce(settings, (draft) => {
      draft.filters.others.pageCalculationInAdvance = e.value
    })

    dispatch(ps.database.setPageSettingsToRedux(next))
  }

  const handlePartFilterSave = () => {
    const partRows = refFilterPartGrid.current.instance.getVisibleRows()
    const partFilter = {}
    partRows.forEach((item) => {
      partFilter[item.data.field_id] = item.data.value
    })

    const next = produce(settings, (draft) => {
      draft.filters.quick.part = partFilter
    })
    dispatch(ps.database.setPageSettingsToRedux(next))
  }

  const handleCharFilterSave = () => {
    const charRows = refFilterCharGrid.current.instance.getVisibleRows()
    const charFilter = {}
    charRows.forEach((item) => {
      charFilter[item.data.field_id] = item.data.value
    })

    const next = produce(settings, (draft) => {
      draft.filters.quick.char = charFilter
    })
    dispatch(ps.database.setPageSettingsToRedux(next))
  }

  const handleValueFilterSave = (e) => {
    const valueRows = refFilterValueGrid.current.instance.getVisibleRows()
    const valueFilter = {}
    valueRows.forEach((item) => {
      valueFilter[item.data.field_id] = item.data.value
    })

    const next = produce(settings, (draft) => {
      draft.filters.quick.value = valueFilter
    })
    dispatch(ps.database.setPageSettingsToRedux(next))
  }

  const validateRange = (value, type) => {
    let fromDate

    const now = new Date()
    if (type === 'hour') {
      fromDate = new Date(now.getTime() - value * 60 * 60 * 1000)
    } else if (type === 'day') {
      fromDate = new Date(now.getTime() - value * 24 * 60 * 60 * 1000)
    } else if (type === 'week') {
      fromDate = new Date(now.getTime() - value * 7 * 24 * 60 * 60 * 1000)
    } else {
      // count
      return true
    }

    // Check if fromDate is an Invalid Date
    if (isNaN(fromDate.getTime())) {
      throw new Error('Invalid Date')
    }

    // Create a date representing January 1, 1970
    var epoch = new Date('1970-01-01T00:00:00Z')

    // Check if fromDate is after the epoch
    return fromDate > epoch
  }

  const partDataSource = makeConvertGridDataFromDTO(settings.filters.quick.part)
  const charDataSource = makeConvertGridDataFromDTO(settings.filters.quick.char)
  const valueDataSource = makeConvertGridDataFromDTO(settings.filters.quick.value)

  return (
    <div className="dx-theme-background-color">
      <Toast width={400} visible={toastConfig.isVisible} message={toastConfig.message} type={toastConfig.type} onHiding={onHiding} displayTime={600} />
      <div className="filter-container">
        <div className="filter-button-container" style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
          <Button
            id="filterApplyButton"
            // icon="check"
            className="save"
            icon="save"
            text={dqTranslateMsg('SidePanel_' + 'Save')}
            width={'50%'}
            onClick={handleSaveButtonClicked}
          />
          <Button id="filterReleaseButton" className="undo" icon="undo" text={dqTranslateMsg('SidePanel_' + 'Reset')} width={'50%'} onClick={handleResetButtonClicked} />
        </div>
        <div className="settings-container">
          <TextTitle text={dqTranslateMsg('Page_Database_' + 'FilterList')} width="50%" childrenWidth="50%" fontSize={'16px'} textAlign={'left'}></TextTitle>
        </div>

        <div className="database-filter-form-container">
          <div className="dx-field">
            <div className="dx-field-label">{dqTranslateMsg('Page_Database_' + 'Arrangement')}</div>
            <div className="dx-field-value">
              <SelectBox items={arrangementDefine()} value={settings.filters.others.arrangement} onValueChanged={handleArrangementChange} valueExpr="value" displayExpr={'text'} />
            </div>
          </div>
          <div className="dx-field">
            <div className="dx-field-label">{dqTranslateMsg('Page_Database_' + 'Sort')}</div>
            <div className="dx-field-value">
              <SelectBox items={sortDefine()} value={settings.filters.common.dateTimeSort} onValueChanged={handleSortChange} valueExpr="value" displayExpr={'text'} />
            </div>
          </div>
          <div className="dx-field">
            <div className="dx-field-label">{dqTranslateMsg('Page_Database_' + 'UseEasyLoad')}</div>
            <div className="dx-field-value">
              <Switch
                disabled={settings.filters.others.useDateTime === true ? true : false}
                items={sd.onOffTypeDefine}
                value={settings.filters.others.useEasyLoad}
                onValueChanged={handleUseEasyLoad}
              />
            </div>
          </div>
          {settings.filters.others.useEasyLoad && (
            <div className="dx-field">
              <div className="dx-field-label"></div>
              <div className="dx-field-value" style={{ display: 'flex' }}>
                <NumberBox style={{ width: '50%', height: '100%' }} showSpinButtons={true} value={settings.filters.common.easyLoadValue} min={1} mode="number" onValueChanged={handleEasyLoadValue}>
                  <NumberBoxButton name="spins" />
                  <Validator>
                    <CustomRule message="1970-01-01T00:00:00Z Minimum" validationCallback={() => validateRange(settings.filters.common.easyLoadValue, settings.filters.others.easyLoadValueType)} />
                  </Validator>
                </NumberBox>
                <SelectBox
                  style={{ width: '50%', height: '100%' }}
                  items={sd.easyLoadTypeDefine()}
                  value={settings.filters.others.easyLoadValueType}
                  onValueChanged={handleEasyLoadValueType}
                  valueExpr={'value'}
                  displayExpr="text"
                />
              </div>
            </div>
          )}
          <div className="dx-field">
            <div className="dx-field-label">{dqTranslateMsg('Page_Database_' + 'IgnoreDuplicatedValue')}</div>
            <div className="dx-field-value">
              <Switch items={sd.onOffTypeDefine} value={settings.filters.others.ignoreDuplicatedValue} onValueChanged={handleUseIgnoreDuplicatedValue} />
            </div>
          </div>
          <div className="dx-field">
            <div className="dx-field-label">{dqTranslateMsg('Page_Database_' + 'UseDateTime')}</div>
            <div className="dx-field-value">
              <Switch
                id="useDateTime"
                disabled={settings.filters.others.useEasyLoad === true ? true : false}
                items={sd.onOffTypeDefine}
                value={settings.filters.others.useDateTime}
                onValueChanged={handleUseDateTimeUpdated}
              />
              {settings.filters.others.useEasyLoad === true && (
                <Tooltip target={`#useDateTime`} showEvent="dxhoverstart" hideEvent="dxhoverend" contentRender={() => <div>When 'useEasyLoad' is enabled, this function cannot be used.</div>} />
              )}
            </div>
          </div>
          {settings.filters.others.useDateTime === true && (
            <>
              <div className="dx-field">
                <div className="dx-field-label">{dqTranslateMsg('Page_Database_' + 'StartDateTime')}</div>
                <div className="dx-field-value">
                  <DateBox
                    // defaultValue={filterList.common.startDateTime}
                    defaultValue={convertToDevExpressDate(settings.filters.common.startDateTime)}
                    showClearButton={false}
                    acceptCustomValue={false}
                    inputAttr={{ 'aria-label': 'Date Time' }}
                    type="datetime"
                    min={new Date(1970, 1, 1)}
                    max={new Date()}
                    onValueChanged={handleStartDateTimeChanged}
                  />
                </div>
              </div>
              <div className="dx-field">
                <div className="dx-field-label">{dqTranslateMsg('Page_Database_' + 'EndDateTime')}</div>
                <div className="dx-field-value">
                  <DateBox
                    defaultValue={convertToDevExpressDate(settings.filters.common.endDateTime)}
                    showClearButton={false}
                    acceptCustomValue={false}
                    inputAttr={{ 'aria-label': 'Date Time' }}
                    type="datetime"
                    min={new Date(1970, 1, 1)}
                    max={new Date()}
                    onValueChanged={handleEndDateTimeChanged}
                  />
                </div>
              </div>
            </>
          )}
          <div className="dx-field">
            <div className="dx-field-label">{dqTranslateMsg('Page_Database_' + 'PageCalculationInAdvance')}</div>
            <div className="dx-field-value">
              <Switch items={sd.onOffTypeDefine} value={settings.filters.others.pageCalculationInAdvance} onValueChanged={handlePageCalculationInAdvance} />
            </div>
          </div>
          {/* part condition */}
          <DataGrid
            ref={refFilterPartGrid}
            className="filter-grid-container"
            id="database-filter-part-datagrid"
            showBorders={true}
            showRowLines={true}
            dataSource={partDataSource}
            repaintChangesOnly={true}
            allowColumnResizing={true}
            height={'200px'}
            onSaved={handlePartFilterSave}
            noDataText={dqTranslateMsg('Page_Database_' + 'NoData')}
            scrolling={{
              showScrollbar: 'onHover',
              useNative: false,
            }}
          >
            <Toolbar>
              <Item location="before">
                <TextTitle text={dqTranslateMsg('Page_Database_' + 'Part')} width="100%" fontSize={'16px'} />
              </Item>
              <Item name="addRowButton" />
            </Toolbar>
            <Editing
              // refreshMode={refreshMode}
              mode="row"
              useIcons={true}
              allowAdding={true}
              allowDeleting={true}
              allowUpdating={true}
            />
            <Scrolling mode="virtual" />
            <Column dataField="field_id" caption={dqTranslateMsg('Page_Database_' + 'FieldID')} alignment="center">
              <Lookup dataSource={usePartFilterField} valueExpr="value" displayExpr="text" />
              <ValidationRule type="required" />
            </Column>
            <Column dataField="value" caption={dqTranslateMsg('Page_Database_' + 'ConditionValue')} alignment="center">
              <ValidationRule type="required" />
            </Column>
          </DataGrid>
          {/* char condition */}
          <DataGrid
            ref={refFilterCharGrid}
            className="filter-grid-container"
            id="database-filter-char-datagrid"
            dataSource={charDataSource}
            repaintChangesOnly={true}
            allowColumnResizing={true}
            scrolling={{
              showScrollbar: 'onHover',
              useNative: false,
            }}
            noDataText={dqTranslateMsg('Page_Database_' + 'NoData')}
            showColumnLines={true}
            showRowLines={true}
            showBorders={true}
            height={'200px'}
            rowAlternationEnabled={false}
            onSaved={handleCharFilterSave}
          >
            <Toolbar>
              <Item location="before">
                <TextTitle text={dqTranslateMsg('Page_Database_' + 'Characteristics')} width="100%" fontSize={'16px'} />
              </Item>
              <Item name="addRowButton" />
            </Toolbar>
            <Editing
              // refreshMode={refreshMode}
              mode="row"
              useIcons={true}
              allowAdding={true}
              allowDeleting={true}
              allowUpdating={true}
            />
            <Scrolling mode="virtual" />
            <Column dataField="field_id" caption={dqTranslateMsg('Page_Database_' + 'FieldID')} alignment="center">
              <Lookup dataSource={useCharFilterField} valueExpr="value" displayExpr="text" />
              <ValidationRule type="required" />
            </Column>
            <Column dataField="value" caption={dqTranslateMsg('Page_Database_' + 'ConditionValue')} alignment="center">
              <ValidationRule type="required" />
            </Column>
          </DataGrid>
          {/*  */}
          <DataGrid
            ref={refFilterValueGrid}
            className="filter-grid-container"
            id="database-filter-value-datagrid"
            dataSource={valueDataSource}
            repaintChangesOnly={true}
            allowColumnResizing={true}
            scrolling={{
              showScrollbar: 'onHover',
              useNative: false,
            }}
            noDataText={dqTranslateMsg('Page_Database_' + 'NoData')}
            showColumnLines={true}
            showRowLines={true}
            showBorders={true}
            rowAlternationEnabled={false}
            height={'200px'}
            onSaved={handleValueFilterSave}
          >
            <Toolbar>
              <Item location="before">
                <TextTitle text={dqTranslateMsg('Page_Database_' + 'Value')} width="100%" fontSize={'16px'} />
              </Item>
              <Item name="addRowButton" />
            </Toolbar>
            <Editing
              // refreshMode={refreshMode}
              mode="row"
              useIcons={true}
              allowAdding={true}
              allowDeleting={true}
              allowUpdating={true}
            />
            <Scrolling mode="virtual" />
            <Column dataField="field_id" caption={dqTranslateMsg('Page_Database_' + 'FieldID')} alignment="center">
              <Lookup dataSource={useValueFilterField} valueExpr="value" displayExpr="text" />
              <ValidationRule type="required" />
            </Column>
            <Column dataField="value" caption={dqTranslateMsg('Page_Database_' + 'ConditionValue')} alignment="center">
              <ValidationRule type="required" />
            </Column>
          </DataGrid>
        </div>
      </div>
    </div>
  )
}
