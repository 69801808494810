import { DataGrid } from 'devextreme-react'
import { Column, Editing, Item, Lookup, Toolbar } from 'devextreme-react/data-grid'
import { connect } from 'react-redux'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import './AdminEndUsers.scss'
import TextTitle from 'src/functions/TextTitle'

const mapStateToProps = (state) => ({
  common: state.common,
  chartRawData: state.chartRawData,
})

export default connect(mapStateToProps)(AdminEndUsers)

function makeCompanyDataSource() {
  const dataSource = [
    { No: 1, CompanyName: 'dynamicQS', useLicense: '' },
    { No: 2, CompanyName: 'dynamicGS', useLicense: '' },
  ]
  return dataSource
}

function makeLicenseDataSource(common) {
  const savedLicenses = common.pages.adminSettings.licenses
  if (savedLicenses) {
    const dataSource = savedLicenses.map((license, index) => {
      return {
        No: index,
        License: license,
      }
    })
    return dataSource
  }

  return []
}

function AdminEndUsers({ pageKey, common, chartRawData }) {
  const companies = makeCompanyDataSource(common)
  const licenses = makeLicenseDataSource(common)

  return (
    <div className="dx-theme-background-color">
      <div style={{ width: '100%', height: '100%' }}>
        <div style={{ width: '66%', height: '100%', display: 'flex' }}>
          <div
            style={{
              width: '50%',
              height: '100%',
              padding: '5px',
              display: 'flex',
            }}
          >
            <DataGrid dataSource={licenses} showRowLines showColumnLines showBorders>
              <Editing
                mode="row"
                useIcons={true}
                allowAdding={true}
                allowUpdating={true}
                // allowDeleting={isDeleteIconVisible}
              />
              <Toolbar>
                <Item location="before">
                  <TextTitle text={dqTranslateMsg('Page_AdminSettings_' + 'License')} width="100%" fontSize={'16px'} textAlign={'left'} />
                </Item>
                <Item name="addRowButton" />
                <Item name="saveButton" />
                <Item name="revertButton" />
              </Toolbar>
              <Column dataField="No" caption={dqTranslateMsg('Page_AdminSettings_' + 'Number')} width={'10%'} alignment="center" />
              <Column dataField="License" caption={dqTranslateMsg('Page_AdminSettings_' + 'License')} width={'90%'} alignment="center" />
            </DataGrid>
          </div>
          <div
            style={{
              width: '50%',
              height: '100%',
              padding: '5px',
              display: 'flex',
            }}
          >
            <DataGrid dataSource={companies} showRowLines showColumnLines showBorders>
              <Editing
                mode="row"
                useIcons={true}
                allowAdding={true}
                allowUpdating={true}
                // allowDeleting={isDeleteIconVisible}
              />
              <Toolbar>
                <Item location="before">
                  <TextTitle text={dqTranslateMsg('Page_AdminSettings_' + 'Company')} width="100%" fontSize={'16px'} textAlign={'left'} />
                </Item>

                <Item name="saveButton" />
                <Item name="addRowButton" />
                <Item name="revertButton" />
              </Toolbar>
              <Column dataField="No" caption={dqTranslateMsg('Page_AdminSettings_' + 'Number')} width={'10%'} alignment="center" />
              <Column dataField="CompanyName" caption={dqTranslateMsg('Page_AdminSettings_' + 'Company')} width={'45%'} alignment="center" />
              <Column dataField="useLicense" caption={dqTranslateMsg('Page_AdminSettings_' + 'License')} width={'45%'} alignment="center">
                <Lookup dataSource={licenses} displayExpr="License" valueExpr="No" />
              </Column>
            </DataGrid>
          </div>
        </div>
      </div>
    </div>
  )
}
