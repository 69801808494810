import DTOPart from './DTOPart'
// import DTOChar from './DTOChar'
import createDTOChar from './DTOChar'
import DTOValue from './DTOValue'
import DTOUser from './DTOUser'

export function makeUserInfoToDTOFormat(data) {
  let userInfo = null

  try {
    userInfo = new DTOUser(data)

    return userInfo
  } catch (error) {
    console.error(error)
    throw error
  }
}
export function makePartInfoToDTOFormat(data) {
  let partInfo = []

  try {
    data.forEach((element) => {
      partInfo.push(new DTOPart(element))
    })

    return partInfo
  } catch (error) {
    console.error(error)
    throw error
  }
}

export function makeCharInfoToDTOFormat(data, configSettings) {
  let charInfo = []

  try {
    data.forEach((element) => {
      charInfo.push(createDTOChar({ element, configSettings }))
    })

    return charInfo
  } catch (error) {
    console.error(error)
    throw error
  }
}

export function makeValueInfoToDTOFormat(data) {
  try {
    // const dtoValueInfo = data.map((array) =>
    //   array.map((value) => new DTOValue(value))
    // )

    const dtoValueInfo = data.map((value) => new DTOValue(value))

    return dtoValueInfo
  } catch (error) {
    console.error(error)
    throw error
  }
}
