import ApiStatus from '../defines/ApiStatus'

// Delete Admin Group
export const deleteAdminGroup = async (apiClient, data) => {
  try {
    const response = await apiClient.post('admin/deleteAdminGroup', data) // Adjust the endpoint as needed
    return response
  } catch (error) {
    console.error(error.message)
    throw error
  }
}

// Get a specific Admin Group
export const getAdminGroup = async (apiClient, data) => {
  try {
    const response = await apiClient.post('admin/getAdminGroup', data) // Adjust the endpoint as needed
    if (!ApiStatus.isSuccess(response.status)) {
      throw new Error(getErrCommMsg(response.status))
    }
    return response.data
  } catch (error) {
    console.error(error.message)
    throw error
  }
}

// Get the list of Admin Groups
export const getAdminGroupList = async (apiClient, data) => {
  try {
    const response = await apiClient.post('admin/getAdminGroupList', data) // Adjust the endpoint as needed
    if (!ApiStatus.isSuccess(response.status)) {
      throw new Error(getErrCommMsg(response.status))
    }
    return response.data
  } catch (error) {
    console.error(error.message)
    throw error
  }
}

// Update Admin Group
export const updateAdminGroup = async (apiClient, data) => {
  try {
    const response = await apiClient.post('admin/updateAdminGroup', data) // Adjust the endpoint as needed
    if (!ApiStatus.isSuccess(response.status)) {
      throw new Error(getErrCommMsg(response.status))
    }
    return response.data
  } catch (error) {
    console.error(error.message)
    throw error
  }
}
