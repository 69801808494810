import { SelectBox } from 'devextreme-react'
import Button from 'devextreme-react/button'
import { useEffect, useRef, useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import { useTheme } from 'src/contexts/theme'
import { convertUserRoleToString, userRoleLevel } from 'src/functions/constants/authLevelConstants'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import './AdminRibbon.scss'
import AdminRibbonDataGrid from './AdminRibbonDataGrid'
import AdminRibbonPreview from './AdminRibbonPreview'
import AdminRibbonTreeList from './AdminRibbonTreeList'
import DQToast from '../../../../../functions/DQToast'
import { useAuth } from 'src/contexts/auth'
import { defaultRibbon } from '../../settings/AdminSettings'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'
import produce from 'immer'

const mapStateToProps = (state) => ({
  common: state.common,
  chartRawData: state.chartRawData,
})

export default connect(mapStateToProps)(AdminRibbon)

const targetUserRoleList = () => [
  {
    value: convertUserRoleToString(userRoleLevel.superuser),
    text: dqTranslateMsg('Page_AdminSettings_' + 'SuperUser'),
  },
  {
    value: convertUserRoleToString(userRoleLevel.admin),
    text: dqTranslateMsg('Page_AdminSettings_' + 'Admin'),
  },
  {
    value: convertUserRoleToString(userRoleLevel.engineer),
    text: dqTranslateMsg('Page_AdminSettings_' + 'Engineer'),
  },
  {
    value: convertUserRoleToString(userRoleLevel.operator),
    text: dqTranslateMsg('Page_AdminSettings_' + 'Operator'),
  },
  {
    value: convertUserRoleToString(userRoleLevel.cmm),
    text: dqTranslateMsg('Page_AdminSettings_' + 'CMM'),
  },
  {
    value: convertUserRoleToString(userRoleLevel.qsense),
    text: dqTranslateMsg('Page_AdminSettings_' + 'QSense'),
  },
  {
    value: convertUserRoleToString(userRoleLevel.demo),
    text: dqTranslateMsg('Page_AdminSettings_' + 'Demo'),
  },
]

function AdminRibbon({ pageKey, common, chartRawData }) {
  const { getTheme } = useTheme()
  const dispatch = useDispatch()
  const { user } = useAuth()
  const toastRef = useRef(null)

  const [targetUserRole, setTargetUserRole] = useState('')
  useEffect(() => {
    setTargetUserRole(convertUserRoleToString(userRoleLevel.superuser))
  }, [])

  const [savedItems, setSavedItems] = useState([])
  useEffect(() => {
    // let items = common.pages.adminSettings.ribbon[targetUserRole === 'demo' ? 'engineer' : targetUserRole] || []
    let items = common.pages.adminSettings.ribbon[targetUserRole] || []

    setSavedItems(items)
  }, [targetUserRole])

  const entireItems = Object.entries(ps).reduce((acc, [key, value], currentIndex) => {
    // const savedItems = savedMenuArray.find((item) => item.name === key)
    const iconType = common.pages.configuration.menuIconType
    const theme = getTheme()
    const menuItem = {
      name: key,
      isDataNeed: value.showWithData,
      icon: value.getPageIcon(iconType, theme), // 메뉴 아이템의 아이콘을 가져옵니다. 이 메서드가 정의되어 있어야 합니다.
      parent: '',
      type: value.type,
    }

    // if (menuItem.name !== 'scrollBoard') {
    acc.push(menuItem) // 계산된 메뉴 아이템을 누산기 배열에 추가
    // }

    return acc
  }, [])

  const handleSaveButton = () => {
    const next = produce(common.pages.adminSettings, (draft) => {
      draft.ribbon[targetUserRole] = savedItems
    })

    dqsImpl.setCurBaseSettings(dispatch, user, null, 'adminSettings', next)
    toastRef.current.handleToastDisplay('success', dqTranslateMsg('Page_AdminSettings_' + 'SavedSettings'))
  }

  const handleResetButton = () => {
    const next = produce(common.pages.adminSettings, (draft) => {
      draft.ribbon[targetUserRole] = defaultRibbon[targetUserRole]
    })

    dqsImpl.setCurBaseSettings(dispatch, user, null, 'adminSettings', next)
    toastRef.current.handleToastDisplay('warning', dqTranslateMsg('Page_AdminSettings_' + 'ResetSettings'))
  }

  const handleTargetUserRole = (e) => {
    const useRole = e.value
    setTargetUserRole(useRole)
    console.log(useRole)
  }

  return (
    <div className="dx-theme-background-color">
      <DQToast ref={toastRef} />
      <div className="admin-language-control-container">
        <div style={{ width: '20%', height: '100%' }}>
          <SelectBox items={targetUserRoleList()} value={targetUserRole} onValueChanged={handleTargetUserRole} valueExpr="value" displayExpr="text" height={'100%'} />
        </div>
        <div className="button-container">
          <Button icon="save" text={dqTranslateMsg('Page_AdminSettings_' + 'Save')} width={'100%'} height={'100%'} onClick={handleSaveButton} />
        </div>
        <div className="button-container">
          <Button icon="undo" text={dqTranslateMsg('Page_AdminSettings_' + 'Reset')} width={'100%'} height={'100%'} onClick={handleResetButton} />
        </div>
      </div>
      <div style={{ width: '30%', height: '96%', padding: '10px' }}>
        <AdminRibbonDataGrid
          title={dqTranslateMsg('Page_AdminSettings_' + 'SelectionMenu')}
          userRole={targetUserRole}
          savedItems={savedItems}
          entireItems={entireItems}
          setSavedItems={setSavedItems}
        />
      </div>
      <div style={{ width: '30%', height: '96%', padding: '10px' }}>
        <AdminRibbonTreeList
          title={dqTranslateMsg('Page_AdminSettings_' + 'MakeHierarchy')}
          userRole={targetUserRole}
          savedItems={savedItems}
          entireItems={entireItems}
          setSavedItems={setSavedItems}
        />
      </div>
      <div
        style={{
          width: '40%',
          height: '96%',
          padding: '10px',
        }}
      >
        <div style={{ display: 'flex', width: '100%', height: '96%' }}>
          <div style={{ width: '50%', height: '100%' }}>
            <AdminRibbonPreview
              title={dqTranslateMsg('Page_AdminSettings_' + 'PreviewDataUnloaded')}
              dataLoaded={false}
              userRole={targetUserRole}
              savedItems={savedItems}
              entireItems={entireItems}
              ribbon={savedItems}
            />
          </div>
          <div style={{ width: '50%', height: '100%' }}>
            <AdminRibbonPreview
              title={dqTranslateMsg('Page_AdminSettings_' + 'PreviewDataLoaded')}
              dataLoaded={true}
              userRole={targetUserRole}
              savedItems={savedItems}
              entireItems={entireItems}
              ribbon={savedItems}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
