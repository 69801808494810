import { useTheme } from 'src/contexts/theme'
import './CompanyCopyRightForm.scss'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import { batch, connect } from 'react-redux'
import { getPyServerCurrentVersion, getServerCurrentVersion } from 'src/api/version'
import { useEffect, useState } from 'react'
import ApiStatus from 'src/api/defines/ApiStatus'

const mapStateToProps = (state) => ({
  common: state.common,
  chartRawData: state.chartRawData,
})

export default connect(mapStateToProps)(CompanyInfoForm)

function CompanyInfoForm({ common }) {
  /**
   * [Format]
   * 24.1
   * i-Analyzer : 1.0.0
   * i-Board : 1.0.0
   * i-Sense : 1.0.0
   * i-Server : 1.0.0 ( ML 1.0.0)
   */

  const salesReleaseDivision = '24.4' // [year.quarter]
  const iAnalyzerVersion = '1.1.20' // [BigVersion.SubVersion.BugFix]
  const iBoardVersion = '1.0.16' // [BigVersion.SubVersion.BugFix]
  const iSenseVersion = '1.0.0' // [BigVersion.SubVersion.BugFix]
  const [iServerVersion, setIServerVersion] = useState(null)
  const [iMLSenseVersion, setIMLServerVersion] = useState(null)

  useEffect(() => {
    const getBackendVersion = async () => {
      let commonServerVer
      const commonServerResult = await getServerCurrentVersion()
      if (ApiStatus.isSuccess(commonServerResult.status)) {
        commonServerVer = commonServerResult.data.version
      }

      let mlServerVer
      const mlServerResult = await getPyServerCurrentVersion()
      if (ApiStatus.isSuccess(mlServerResult.status)) {
        mlServerVer = mlServerResult.data.version
      }

      batch(() => {
        setIServerVersion(commonServerVer)
        setIMLServerVersion(mlServerVer)
      })
    }

    getBackendVersion()
  }, [])

  return (
    <div className={'dx-theme-background-color'}>
      <div
        className="company-copyright-content-container"
        style={{
          display: 'flex',
          flexDirection: 'row',
          fontSize: '13px',
          height: '100%',
        }}
      >
        <div style={{ position: 'relative', width: '50%', height: '100%' }}>
          <div
            style={{
              position: 'absolute',
              left: '0',
              bottom: '0',
              textAlign: 'left',
              width: '100%', // 부모의 50% 너비 차지
            }}
          >
            <p style={{ margin: 0 }}>{dqTranslateMsg('Page_CompanyInfo_' + 'SupportEmailMsg')}</p>
            <p style={{ margin: 0 }}>{dqTranslateMsg('Page_CompanyInfo_' + 'InstalledProductionMsg')}</p>
            <p style={{ margin: 0 }}>{salesReleaseDivision}</p>
            {/* <p style={{ margin: 0 }}>{dqTranslateMsg('Page_CompanyInfo_' + 'IAnalyzerProductionMsg') + iAnalyzerVersion + iAnalyzerMachineLearningVersion}</p> */}
            <p style={{ margin: 0 }}>{dqTranslateMsg('Page_CompanyInfo_' + 'IAnalyzerProductionMsg') + iAnalyzerVersion}</p>
            <p style={{ margin: 0 }}>{dqTranslateMsg('Page_CompanyInfo_' + 'IBoardProductionMsg') + iBoardVersion}</p>
            <p style={{ margin: 0 }}>{'i-Server: ' + iServerVersion + ' ( ML ' + iMLSenseVersion + ' )'}</p>
            <p style={{ margin: 0 }}>
              {dqTranslateMsg('Page_CompanyInfo_' + 'HomePageMsg')}
              <a href="https://processartz.com" target="_blank" rel="noopener noreferrer">
                https://processartz.com
              </a>
            </p>
          </div>
        </div>
        <div style={{ position: 'relative', width: '50%', height: '100%' }}>
          <div
            style={{
              position: 'absolute',
              right: '0',
              bottom: '0',
              textAlign: 'right',
              width: '100%', // 부모의 50% 너비 차지
            }}
          >
            <p style={{ margin: 0 }}>{dqTranslateMsg('Page_CompanyInfo_' + 'CopyRightFirst')}</p>
            <p style={{ margin: 0 }}>{dqTranslateMsg('Page_CompanyInfo_' + 'CopyRightSecond')}</p>
            <p style={{ margin: 0 }}>{dqTranslateMsg('Page_CompanyInfo_' + 'CopyRightThird')}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
