import { EmptyItem, GroupItem } from 'devextreme-react/form'
import { useDispatch, useSelector } from 'react-redux'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'
import RadioGroupSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/RadioGroupSimpleItem'
import * as sd from 'src/pages/components/side-panel-form/component/define/SettingsDefine'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'
import TextAreaSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/TextAreaSimpleItem'
import CheckBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/CheckBoxSimpleItem'

export default function SettingsExtendedLimitOption() {
  const dispatch = useDispatch()
  const pageKey = 'configuration'
  const pageState = useSelector((state) => state.common.pages[pageKey])

  const onNumberBoxDisplayDecimalPlacesValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const minOption = e.component.option('min')
    const next = produce(pageState, (draft) => {
      draft.commonSettings[dataField] = value === null ? minOption : value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onNumberBoxValueChange = (e, dataField) => {
    if (e.event === undefined) return

    let value = 0
    const maxOption = 20
    if (e.value === null) {
      value = maxOption
    } else {
      value = 100 - e.value
    }
    // const value = 100 - e.value

    const next = produce(pageState, (draft) => {
      draft.commonSettings[dataField] = value === null ? maxOption : value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onNumberBoxSigmaValueChange = (e, dataField) => {
    if (e.event === undefined) return

    const value = e.value
    const minOption = e.component.option('min')
    const next = produce(pageState, (draft) => {
      draft.commonSettings[dataField] = value === null ? minOption : value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`common-display-value-option-group-item`} colCount={2} caption={dqTranslateMsg('Page_Configuration_' + 'ExtendedLimitOption')}>
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('Page_Configuration_' + 'DefaultDecimalPlaces'),
        labelLocation: 'top',
        name: 'displayDecimalPlaces',
        min: 0,
        max: 15,
        step: 1,
        value: pageState.commonSettings.displayDecimalPlaces,
        disabled: false,
        onValueChanged: (e) => onNumberBoxDisplayDecimalPlacesValueChanged(e, 'displayDecimalPlaces'),
      })}
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('Page_Configuration_' + 'DefaultCalculatedDecimalPlaces'),
        labelLocation: 'top',
        name: 'calcDisplayDecimalPlaces',
        min: 0,
        max: 15,
        step: 1,
        value: pageState.commonSettings.calcDisplayDecimalPlaces,
        disabled: false,
        onValueChanged: (e) => onNumberBoxDisplayDecimalPlacesValueChanged(e, 'calcDisplayDecimalPlaces'),
      })}
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('Page_Configuration_' + 'ExponentialPosition'),
        labelLocation: 'top',
        name: 'isExponentialActive',
        min: 0,
        max: 15,
        step: 1,
        value: pageState.commonSettings.isExponentialActive,
        disabled: false,
        onValueChanged: (e) => onNumberBoxDisplayDecimalPlacesValueChanged(e, 'isExponentialActive'),
      })}
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('Page_Configuration_' + 'WarningLimit'),
        labelLocation: 'top',
        name: 'warningLimitPercentage',
        min: 1,
        max: 100,
        step: 1,
        value: 100 - pageState.commonSettings.warningLimitPercentage,
        disabled: false,
        onValueChanged: (e) => onNumberBoxValueChange(e, 'warningLimitPercentage'),
      })}
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('Page_Configuration_' + 'UseSigmaDegree'),
        labelLocation: 'top',
        name: 'sigmaDegree',
        min: 1,
        max: 3,
        step: 1,
        value: pageState.commonSettings.sigmaDegree,
        disabled: false,
        onValueChanged: (e) => onNumberBoxSigmaValueChange(e, 'sigmaDegree'),
      })}
    </GroupItem>
  )
}
