import Form, { ButtonItem, ButtonOptions, Item, Label, RequiredRule } from 'devextreme-react/form'
import LoadIndicator from 'devextreme-react/load-indicator'
import { useCallback, useEffect, useRef, useState } from 'react'
import { batch, useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import * as dtoUtils from 'src/dto/DTOImpl'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'
import * as rd from 'src/store/common'
import { useAuth } from '../../contexts/auth'
import './LoginForm.scss'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import { useLanguage } from 'src/contexts/languages'
import { useTheme } from 'src/contexts/theme'
import { pageType } from 'src/functions/table/PageTypeTable'
import { msgType } from 'src/functions/table/MessageTypeTable'
import { loginTable } from 'src/functions/table/LoginMessageTable'
import { getCommonMsg } from 'src/functions/CommonMassageUtils'

export default function LoginForm() {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { setTheme, getTheme } = useTheme()
  const { signIn } = useAuth()
  const { language, switchLanguage, updateLanguage } = useLanguage()
  const [loading, setLoading] = useState(false)
  const formData = useRef({ id: '', password: '' })
  const formRef = useRef(null)

  const emailEditorOptions = {
    stylingMode: 'filled',
    placeholder: getCommonMsg(msgType.Login, loginTable.M0002),
    mode: 'id',
  }
  const passwordEditorOptions = {
    stylingMode: 'filled',
    placeholder: getCommonMsg(msgType.Login, loginTable.M0003),
    mode: 'password',
  }
  const rememberMeEditorOptions = {
    text: 'Remember me',
    elementAttr: { class: 'form-text' },
  }

  const onSubmit = useCallback(
    // async (e) => {
    async (e) => {
      e.preventDefault()
      const { id, password } = formData.current
      setLoading(true)

      const userInfo = await signIn(id, password)
      const uiDTO = dtoUtils.makeUserInfoToDTOFormat(userInfo)
      // JSON 파싱을 위한 함수
      const parseJSONOrDefault = (data, defaultVal) => (data ? JSON.parse(data) : defaultVal)

      // 기본 설정값
      // const licenseSet = parseJSONOrDefault(uiDTO.license, [])
      // const companySet = parseJSONOrDefault(uiDTO.user_info, { company: null })
      // const configSet = parseJSONOrDefault(uiDTO.web_config, baseInit)
      const overviewSet = parseJSONOrDefault(uiDTO.chart_config_1, ps.overview.settings)
      const monitoringSet = parseJSONOrDefault(uiDTO.chart_config_2, ps.monitoring.settings)
      const databaseSet = parseJSONOrDefault(uiDTO.chart_config_3, ps.database.settings)
      const valueChartSet = parseJSONOrDefault(uiDTO.chart_config_4, ps.valueChart.settings)
      const histogramChartSet = parseJSONOrDefault(uiDTO.chart_config_5, ps.histogramChart.settings)
      const positionalTolerancesChartSet = parseJSONOrDefault(uiDTO.chart_config_6, ps.positionalTolerancesChart.settings)
      const boxPlotChartSet = parseJSONOrDefault(uiDTO.chart_config_7, ps.boxPlotChart.settings)
      const linearRegressionChartSet = parseJSONOrDefault(uiDTO.chart_config_8, ps.linearRegressionChart.settings)
      const correlationChartSet = parseJSONOrDefault(uiDTO.chart_config_9, ps.correlationChart.settings)
      const qualityControlChartSet = parseJSONOrDefault(uiDTO.chart_config_10, ps.qualityControlChart.settings)
      const capabilitySummaryChartSet = parseJSONOrDefault(uiDTO.chart_config_11, ps.capabilitySummaryChart.settings)
      const paretoChartSet = parseJSONOrDefault(uiDTO.chart_config_12, ps.paretoChart.settings)
      const probabilityPlotChartSet = parseJSONOrDefault(uiDTO.chart_config_13, ps.probabilityPlotChart.settings)
      const decisionTreeChartSet = parseJSONOrDefault(uiDTO.chart_config_14, ps.decisionTreeChart.settings)
      const valueListSet = parseJSONOrDefault(uiDTO.chart_config_15, ps.valueList.settings)
      const summaryListSet = parseJSONOrDefault(uiDTO.chart_config_16, ps.summaryList.settings)
      const valueSummaryListSet = parseJSONOrDefault(uiDTO.chart_config_17, ps.valueSummaryList.settings)
      const scrollBoardSet = parseJSONOrDefault(uiDTO.chart_config_18, ps.scrollBoard.settings)
      const configurationSet = parseJSONOrDefault(uiDTO.chart_config_19, ps.configuration.settings)
      const adminSettingsSet = parseJSONOrDefault(uiDTO.chart_config_20, ps.adminSettings.settings)
      const reportPrintMultiFunctionSet = parseJSONOrDefault(uiDTO.chart_config_21, ps.reportPrintMultiFunction.settings)
      const langType = uiDTO.language || 'en'

      batch(() => {
        dispatch(rd.setMode('sign-in'))
        dispatch(ps.overview.setPageSettingsToRedux(overviewSet))
        dispatch(ps.monitoring.setPageSettingsToRedux(monitoringSet))
        dispatch(ps.database.setPageSettingsToRedux(databaseSet))
        dispatch(ps.valueChart.setPageSettingsToRedux(valueChartSet))
        dispatch(ps.histogramChart.setPageSettingsToRedux(histogramChartSet))
        dispatch(ps.positionalTolerancesChart.setPageSettingsToRedux(positionalTolerancesChartSet))
        dispatch(ps.boxPlotChart.setPageSettingsToRedux(boxPlotChartSet))
        dispatch(ps.linearRegressionChart.setPageSettingsToRedux(linearRegressionChartSet))
        dispatch(ps.correlationChart.setPageSettingsToRedux(correlationChartSet))
        dispatch(ps.qualityControlChart.setPageSettingsToRedux(qualityControlChartSet))
        dispatch(ps.capabilitySummaryChart.setPageSettingsToRedux(capabilitySummaryChartSet))
        dispatch(ps.paretoChart.setPageSettingsToRedux(paretoChartSet))
        dispatch(ps.probabilityPlotChart.setPageSettingsToRedux(probabilityPlotChartSet))
        dispatch(ps.decisionTreeChart.setPageSettingsToRedux(decisionTreeChartSet))
        dispatch(ps.valueList.setPageSettingsToRedux(valueListSet))
        dispatch(ps.summaryList.setPageSettingsToRedux(summaryListSet))
        dispatch(ps.valueSummaryList.setPageSettingsToRedux(valueSummaryListSet))
        dispatch(ps.scrollBoard.setPageSettingsToRedux(scrollBoardSet))
        dispatch(ps.configuration.setPageSettingsToRedux(configurationSet))
        dispatch(ps.adminSettings.setPageSettingsToRedux(adminSettingsSet))
        dispatch(ps.reportPrintMultiFunction.setPageSettingsToRedux(reportPrintMultiFunctionSet))

        dispatch(rd.setLanguage(langType))
        const loadedTheme = configurationSet.theme
        setTheme(loadedTheme)
        updateLanguage(adminSettingsSet.language)
        switchLanguage(configurationSet.language)
      })
      // release loading
      setLoading(false)
    },
    [signIn]
  )

  // const onCreateAccountClick = useCallback(() => {
  //   navigate('/create-account')
  // }, [navigate])

  const handleDevPageButtonClick = useCallback(
    async (e) => {
      dispatch(rd.setMode('development-functions'))
      const { id, password } = formData.current
      const userInfo = await signIn(id, password)
      navigate('/development-functions')
    },
    [navigate]
  )

  const [inputSequence, setInputSequence] = useState('')
  const [devButtonVisible, setDevButtonVisible] = useState(false)
  const handleKeyDown = useCallback((event) => {
    // Update input sequence with the latest key
    setInputSequence((prevSequence) => {
      const newSequence = prevSequence + event.key
      if (newSequence.includes('10293')) {
        setDevButtonVisible(true)
        return '' // Reset input sequence
      }
      // Keep only the last 6 characters to match the specific sequence
      return newSequence.slice(-6)
    })
  }, [])

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown)
    // Cleanup event listener
    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleKeyDown])

  return (
    <form className={'login-form'} onSubmit={onSubmit}>
      {/* <form className={'login-form'}> */}
      <Form ref={formRef} formData={formData.current} disabled={loading}>
        <Item dataField={'id'} editorType={'dxTextBox'} editorOptions={emailEditorOptions}>
          {/* <RequiredRule message="Email is required" />
          <EmailRule message="Email is invalid" /> */}
          <Label visible={false} />
        </Item>
        <Item dataField={'password'} editorType={'dxTextBox'} editorOptions={passwordEditorOptions}>
          <RequiredRule message="Password is required" />
          <Label visible={false} />
        </Item>
        {/* <Item
          dataField={'rememberMe'}
          editorType={'dxCheckBox'}
          editorOptions={rememberMeEditorOptions}
        >
          <Label visible={false} />
        </Item> */}
        <ButtonItem>
          <ButtonOptions
            name="sign-in"
            width={'100%'}
            type={'default'}
            // onClick={() => handleDevPageButtonClick('sign-in')}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">{loading ? <LoadIndicator width={'24px'} height={'24px'} visible={true} /> : getCommonMsg(msgType.Login, loginTable.M0001)}</span>
          </ButtonOptions>
        </ButtonItem>
        <ButtonItem>
          <ButtonOptions
            name="development-function"
            width={'100%'}
            type={'success'}
            onClick={() => handleDevPageButtonClick('development-function')}
            visible={devButtonVisible}
            // useSubmitBehavior={true}
          >
            <span className="dx-button-text">Development Settings</span>
          </ButtonOptions>
        </ButtonItem>
        {/* <Item>
          <div className={'link'}>
            <Link to={'/reset-password'}>Forgot password?</Link>
          </div>
        </Item>
        <ButtonItem>
          <ButtonOptions
            text={'Create an account'}
            width={'100%'}
            onClick={onCreateAccountClick}
          />
        </ButtonItem> */}
      </Form>
    </form>
  )
}
