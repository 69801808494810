import Form, { GroupItem } from 'devextreme-react/form'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import AdvancedFilterItem from 'src/pages/components/dq-wrapper-ui-devexpress/AdvancedFilterItem'
import ButtonSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItem'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import produce from 'immer'
import CPUtils from 'src/functions/CommonPageUtils'
import { pageType } from 'src/functions/table/PageTypeTable'
import { msgType } from 'src/functions/table/MessageTypeTable'
import { getErrProcMsg } from 'src/functions/CommonMassageUtils'
import { procErrTable } from 'src/functions/table/ProcMessageTable'
import CatalogUtils from 'src/functions/CatalogUtils'
import { ScrollView } from 'devextreme-react'
import DBFieldAdvancedRealTimeFilter from './DBFieldAdvancedRealTimeFilter'
import DBFieldAdvancedSaveFilter from './DBFieldAdvancedSaveFilter'
import DBFieldAdvancedSavedFilter from './DBFieldAdvancedSavedFilter'
import DBFieldAdvancedPreviewFilter from './DBFieldAdvancedPreviewFilter'

export const DBFieldAdvancedOption = (pageKey, pageState, configState) => {
  const renderItems = () => {
    const items = [
      DBFieldAdvancedRealTimeFilter(pageKey, pageState, configState),
      // DBFieldAdvancedSaveFilter(pageKey, pageState, configState),
      // DBFieldAdvancedSavedFilter(pageKey, pageState, configState),
      // DBFieldAdvancedPreviewFilter(pageKey, pageState, configState),
    ]
    return items
  }

  return (
    <div style={{ width: '100%', height: '97%' }}>
      <ScrollView className="side-panel-tab-container" style={{ padding: '5px' }} showScrollbar="onHover" useNative={false}>
        <Form id="side-panel-value-chart-settings-form" colCount={1} style={{ paddingTop: '5px' }} labelMode="outside" showColonAfterLabel={false}>
          {renderItems()}
        </Form>
      </ScrollView>
    </div>
  )
}
