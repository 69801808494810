import { GroupItem } from 'devextreme-react/form'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import AdvancedFilterItem from 'src/pages/components/dq-wrapper-ui-devexpress/AdvancedFilterItem'
import ButtonSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItem'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import produce from 'immer'
import CPUtils from 'src/functions/CommonPageUtils'
import { pageType } from 'src/functions/table/PageTypeTable'
import { msgType } from 'src/functions/table/MessageTypeTable'
import { getErrProcMsg } from 'src/functions/CommonMassageUtils'
import { procErrTable } from 'src/functions/table/ProcMessageTable'
import CatalogUtils from 'src/functions/CatalogUtils'
import TextBoxNoValueSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/TextBoxNoValueSimpleItem'
import { getDatabaseInfo } from 'src/api/db'

const AdvancedModifyButton = (currentFilter, setCurrentFilter, isVisibleFilterPopup, setIsVisibleFilterPopup, pageKey, pageState, configState) => {
  const dispatch = useDispatch()
  const onSaveAllButtonClick = () => {
    console.log('Save All')
    const targetIndex = pageState.filters.advanced.savedFilters.findIndex((item) => item.filterName === currentFilter.filterName)

    if (targetIndex !== -1) {
      const next = produce(pageState, (draft) => {
        draft.filters.advanced.savedFilters[targetIndex] = currentFilter
      })
      // setCurrentFilter(next)
      setIsVisibleFilterPopup(false)
      dispatch(ps[pageKey].setPageSettingsToRedux(next))
    }
  }

  return (
    <GroupItem key={`db-field-advanced-modify-group-item`} colCount={2} caption={''}>
      {ButtonSimpleItem({
        colSpan: 2,
        type: 'normal',
        text: dqTranslateMsg('SidePanel_' + 'Save'),
        icon: 'add',
        width: '100%',
        height: '100%',
        onClick: onSaveAllButtonClick,
      })}
    </GroupItem>
  )
}

export default AdvancedModifyButton
