import { ButtonGroup, ScrollView } from 'devextreme-react'
import Form from 'devextreme-react/form'
import produce from 'immer'
import { useEffect, useRef, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useAuth } from 'src/contexts/auth.js'
import DQSaveReset from 'src/functions/DQSaveReset.js'
import DQToast from 'src/functions/DQToast.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage.js'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'
import { scrollBoardSettingsPrm } from '../settings/ScrollBoardSettings.js'
import './SidePanelScrollBoardTheme.scss'
import ThemeTabContainer from './components/theme/ThemeTabContainer.js'
import { useTheme } from 'src/contexts/theme.js'
import HeaderIcon from 'src/functions/HeaderIcon.js'
import IL from 'src/functions/IconLoader'

const tabIconPath = (type, menuIconType, theme, title) => {
  const mode = HeaderIcon.getIconMenuPath(menuIconType)
  const themeType = theme

  let path = ''
  switch (type) {
    case 'Configuration':
      path = IL.getNavImgPath(mode, themeType, IL.NAV.CONFIGURATION)
      break
    case 'BarChart':
      path = IL.getNavImgPath(mode, themeType, IL.NAV.HISTOGRAM_CHART)
      break
    case 'Diagram':
      path = IL.getNavImgPath(mode, themeType, IL.NAV.SUMMARY_LIST)
      break
    case 'TreeMap':
      path = IL.getNavImgPath(mode, themeType, IL.NAV.VALUE_LIST)
      break
    case 'ValueChart':
      path = IL.getNavImgPath(mode, themeType, IL.NAV.VALUE_CHART)
      break
    case 'QccChart':
      path = IL.getNavImgPath(mode, themeType, IL.NAV.QUALITY_CONTROL_CHART)
      break
    default:
      break
  }

  return path
}
export default function SidePanelScrollBoardTheme(props) {
  const { pageKey } = props
  const { user } = useAuth()
  const { getTheme } = useTheme()
  const theme = getTheme()
  const parentRef = useRef(null)
  const toastRef = useRef(null)
  const dispatch = useDispatch()

  const [settingTypeDataSource, setSettingTypeDataSource] = useState([])
  const [targetSettingMenu, setTargetSettingMenu] = useState(0)

  const common = useSelector((state) => state.common)
  const curPage = common.pages.scrollBoard
  const configPage = common.pages.configuration

  const handleCurPageSettingsSave = (updatePageData) => {
    dqsImpl.setCurPageSettings(dispatch, user, pageKey, updatePageData)
  }

  const handleSaveButtonClicked = () => {
    const next = produce(curPage, (draft) => {
      Object.keys(curPage).forEach((key) => {
        draft[key] = curPage[key]
      })
    })

    handleCurPageSettingsSave(next)
    toastRef.current.handleToastDisplay('success', dqTranslateMsg('SidePanel_' + 'SavedSettings'))
  }

  const handleResetButtonClicked = () => {
    const next = produce(curPage, (draft) => {
      Object.entries(scrollBoardSettingsPrm.theme).forEach(([key, value]) => {
        draft.theme[key] = value
      })
    })

    dqsImpl.setCurPageSettings(dispatch, user, pageKey, next)
    toastRef.current.handleToastDisplay('warning', dqTranslateMsg('SidePanel_' + 'ResetSettings'))
  }

  const targetSettingMenuClicked = (e) => {
    if (e.event === null) return
    setTargetSettingMenu(e.itemData.number)
  }

  const renderButton = (e) => {
    return (
      <div style={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
        {e.path !== '' && <img src={e.path} style={{ width: '15px', height: '15px', transform: e.type === 'BarChart' ? 'rotate(270deg) scaleY(-1)' : 'none' }} />}
        <span style={{ width: '100%', height: '50%' }}>{dqTranslateMsg('SidePanel_' + e.title)}</span>
      </div>
    )
  }

  // useEffect for initializing the button group data source
  useEffect(() => {
    const makeDataSource = () => {
      const useButtonsType = ['Configuration', 'ValueChart', 'BarChart', 'QccChart', 'TreeMap']
      const useButtonsTitle = ['Common', 'ValueChart', 'BarChart', 'QccChart', 'TreeMap']
      const useButtonsNo = [0, 1, 2, 3, 4]
      return useButtonsType.map((button, index) => ({
        path: tabIconPath(button, configPage.menuIconType, theme, 'Common'),
        type: button,
        title: useButtonsTitle[index],
        number: useButtonsNo[index],
      }))
    }
    setSettingTypeDataSource(makeDataSource())
  }, [configPage.menuIconType, theme]) // dependencies for useEffect

  const items = (pageState, configState) => [ThemeTabContainer(pageKey, pageState, configState)]

  return (
    <div ref={parentRef} style={{ padding: '5px', width: '100%', height: '100%' }}>
      <DQToast ref={toastRef} />
      <div style={{ width: '100%', height: '3%' }}>
        <DQSaveReset
          saveBtnText={dqTranslateMsg('SidePanel_' + 'Save')}
          resetBtnText={dqTranslateMsg('SidePanel_' + 'Reset')}
          saveCallback={handleSaveButtonClicked}
          resetCallback={handleResetButtonClicked}
        />
      </div>
      <div style={{ width: '100%', height: '5%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <ButtonGroup
          items={settingTypeDataSource}
          buttonRender={renderButton}
          keyExpr="number"
          stylingMode="text"
          selectedItemKeys={[targetSettingMenu]}
          onItemClick={targetSettingMenuClicked}
          width={'100%'}
        />
      </div>
      <div style={{ width: '100%', height: '95%' }}>
        <ScrollView className="side-panel-tab-container" style={{ width: '100%', height: '100%' }} showScrollbar={'onHover'} useNative={false}>
          {/* <Form id="side-panel-scroll-board-theme-form" style={{ width: '100%', height: '100%', padding: '5px' }} labelMode={'outside'} formData={curPage.page} showColonAfterLabel={false}> */}
          {/* {items(curPage, configPage)} */}
          {/* </Form> */}
          <ThemeTabContainer pageKey={pageKey} targetSettingMenu={targetSettingMenu} pageState={curPage} configState={configPage} />
        </ScrollView>
      </div>
    </div>
  )
}
