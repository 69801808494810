import { Animation, ArgumentAxis, Chart, CommonSeriesSettings, Crosshair, Export, Label, Legend, ScrollBar, Series, Tooltip, ValueAxis } from 'devextreme-react/chart'
import { useEffect, useRef, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { useTheme } from 'src/contexts/theme'
import CatalogUtils from 'src/functions/CatalogUtils'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import styled from 'styled-components'
import './ScrollBoardBarChart.scss'

const StyledChart = styled(Chart)`
  width: 100%;
  height: 100%;
`

function convertDataSource(targetStep, chartOrder, badVisible, warnVisible, goodVisible, configSettings) {
  let makeDataSource = []
  if (targetStep.field === '2000') {
    targetStep.data
      .map((item, index) => {
        let resultId = ''
        let display_name = ''
        let partId = 0
        let charId = 0

        try {
          const match = item.result_id.match(/\/(.*?)\(\u000f(\d+),(\d+)\)/)
          const [_, description, pValue, cValue] = match
          resultId = match ? match[1] : item.result_id
          partId = pValue
          charId = cValue

          let preName = ''
          if (description === '\u0010') {
            preName = 'unknown'
          } else if (description === '\u0011') {
            preName = ''
          } else {
            preName = description
          }
          display_name = `${preName}(P${pValue}, C${cValue})`

          let addItem = 0
          if (badVisible === 1 && item.result_ng_count > 0) {
            addItem++
          }
          if (warnVisible === 1 && item.result_warning_count > 0) {
            addItem++
          }
          if (goodVisible === 1 && item.result_good_count > 0) {
            addItem++
          }

          if (addItem > 0) {
            makeDataSource.unshift({
              id: index,
              part_id: partId,
              char_id: charId,
              display_name: display_name,
              result_id: resultId,
              result_key: item.result_key,
              result_field: item.result_field,
              result_user_id: item.result_user_id,
              result_good_count: item.result_good_count,
              result_warning_count: item.result_warning_count,
              result_ng_count: item.result_ng_count,
              result_total_count: item.result_total_count,
            })
          } else {
            return null
          }
        } catch (error) {
          console.error('Error processing result_id:', error)
          resultId = item.result_id
        }
      })
      .filter((item) => item !== null)
  } else if (CatalogUtils.isCatalogFieldNoPrefix(targetStep.field)) {
    targetStep.data
      .map((item, index) => {
        let resultId = ''
        let display_name = ''
        let partId = 0
        let charId = 0

        if (item.result_id === '\u0010') {
          display_name = 'unknown'
        } else if (item.result_id === '\u0011') {
          display_name = ''
        } else {
          display_name = CatalogUtils.convertByCatalogNoPrefix(targetStep.field, item.result_id, configSettings.catalogs)
        }

        try {
          resultId = item.result_id
          partId = null
          charId = null
          display_name = display_name
          makeDataSource.unshift({
            id: index,
            part_id: partId,
            char_id: charId,
            display_name: display_name,
            result_id: resultId,
            result_key: item.result_key,
            result_field: item.result_field,
            result_user_id: item.result_user_id,
            result_good_count: item.result_good_count,
            result_warning_count: item.result_warning_count,
            result_ng_count: item.result_ng_count,
            result_total_count: item.result_total_count,
          })
        } catch (error) {
          console.error('Error processing result_id:', error)
          resultId = item.result_id
        }
      })
      .filter((item) => item !== null)
  } else {
    targetStep.data
      .map((item, index) => {
        let resultId = ''
        let display_name = ''
        let partId = 0
        let charId = 0

        try {
          resultId = item.result_id
          partId = null
          charId = null

          if (item.result_id === '\u0010') {
            display_name = 'unknown'
          } else if (item.result_id === '\u0011') {
            display_name = ''
          } else {
            display_name = item.result_id
          }

          makeDataSource.unshift({
            id: index,
            part_id: partId,
            char_id: charId,
            display_name: display_name,
            result_id: resultId,
            result_key: item.result_key,
            result_field: item.result_field,
            result_user_id: item.result_user_id,
            result_good_count: item.result_good_count,
            result_warning_count: item.result_warning_count,
            result_ng_count: item.result_ng_count,
            result_total_count: item.result_total_count,
          })
        } catch (error) {
          console.error('Error processing result_id:', error)
          resultId = item.result_id
        }
      })
      .filter((item) => item !== null)
  }

  return makeDataSource
}

function ScrollBoardBarChart({
  id,
  style,
  mode,
  stepReference,
  setStepReference,
  handleNextItemClicked,
  useGoodItem,
  useWarningItem,
  useBadItem,
  goodItemColor,
  warningItemColor,
  badItemColor,
  xFontSize,
  yFontSize,
  countFontSize,
  fontColorCustomize,
  xFontColor,
  yFontColor,
  countFontColorCustomize,
  countFontColor,
}) {
  const chartContainerRef = useRef(null)
  const sbPageSettings = useSelector((state) => state.common.pages.scrollBoard)
  const configSettings = useSelector((state) => state.common.pages.configuration)
  const { getTheme } = useTheme()

  const curStep = stepReference.curStep
  const stepTotalLen = stepReference.totalSteps
  const tgStep = stepReference.steps.find((item) => item.step === curStep)
  const curStepField = tgStep.field
  const useGoodSeries = curStepField !== '2000' ? true : useGoodItem
  const useWarnSeries = curStepField !== '2000' ? true : useWarningItem
  const useBadSeries = curStepField !== '2000' ? true : useBadItem
  // const useGoodSeries = curStepField !== '2000' ? true : useGoodItem
  // const useWarnSeries = curStepField !== '2000' ? true : useWarningItem
  // const useBadSeries = curStepField !== '2000' ? true : useBadItem

  const [dataSource, setDataSource] = useState([])

  const onPointClick = (e) => {
    const lastPageStepIndex = stepReference.totalSteps - 1
    if (stepReference.curStep < lastPageStepIndex) {
      // const targetId = e.target.argument
      const targetId = e.target.data.result_id
      const partId = e.target.data.part_id
      const charId = e.target.data.char_id
      const nextStep = curStep < stepTotalLen ? curStep + 1 : 1
      handleNextItemClicked(nextStep, targetId, partId, charId)
    } else {
      console.warn('barchart last page')
      alert('this page is the last')
    }
  }

  const itemsPerPage = sbPageSettings.page.barChartLimitCount

  useEffect(() => {
    if (tgStep) {
      // const sortedTgStep = sortStepData(tgStep, sbPageSettings.page.barContentOrder)
      const converted = convertDataSource(tgStep, sbPageSettings.page.barContentOrder, sbPageSettings.page.chartBadVisible, sbPageSettings.page.chartWarningVisible, sbPageSettings.page.chartGoodVisible, configSettings)
      setDataSource(converted)
    }
  }, [tgStep, sbPageSettings, configSettings])

  return (
    <div id={id} style={style}>
      <div id={`${id}-chart-container`} ref={chartContainerRef} style={{ width: '100%', height: '100%' }}>
        <StyledChart id="scroll-board-bar-chart" dataSource={dataSource} onPointClick={mode === 'presentation' ? null : onPointClick} rotated={true} scrollBar={{ visible: true }} resolveLabelOverlapping="stack" zoomAndPan={{ argumentAxis: 'both' }}>
          <CommonSeriesSettings argumentField="display_name" type="fullstackedbar" hoverMode="allArgumentPoints" />
          {useGoodSeries && (
            <Series valueField="result_good_count" name={dqTranslateMsg('Page_ScrollBoard_' + 'Good')} color={goodItemColor}>
              <Label
                visible={true}
                position="inside"
                showForZeroValues={false}
                font={{
                  color: countFontColorCustomize ? countFontColor : '#000000',
                  size: countFontSize,
                }}
                customizeText={({ valueText }) => `${valueText}`}
              />
            </Series>
          )}
          {useWarnSeries && (
            <Series valueField="result_warning_count" name={dqTranslateMsg('Page_ScrollBoard_' + 'Warn')} color={warningItemColor}>
              <Label
                visible={true}
                position="inside"
                showForZeroValues={false}
                font={{
                  color: countFontColorCustomize ? countFontColor : '#000000',
                  size: countFontSize,
                }}
                customizeText={({ valueText }) => `${valueText}`}
              />
            </Series>
          )}
          {useBadSeries && (
            <Series valueField="result_ng_count" name={dqTranslateMsg('Page_ScrollBoard_' + 'Ng')} color={badItemColor}>
              <Label
                visible={true}
                position="inside"
                showForZeroValues={false}
                font={{
                  color: countFontColorCustomize ? countFontColor : '#000000',
                  size: countFontSize,
                }}
                customizeText={({ valueText }) => `${valueText}`}
              />
            </Series>
          )}
          <ValueAxis
            label={{
              font: {
                size: xFontSize,
                color: fontColorCustomize ? xFontColor : '#767676',
              },
            }}
          />
          <ArgumentAxis
            label={{
              font: {
                size: yFontSize,
                color: fontColorCustomize ? yFontColor : '#767676',
              },
            }}
            visualRange={{ startValue: 0, length: dataSource.length < itemsPerPage ? dataSource.length : itemsPerPage }} // 10개의 바만 보이도록 설정
            wholeRange={{ startValue: 0, length: dataSource.length }}
            // visualRange={{ startValue: 0, length: 10 }} // 10개의 바만 보이도록 설정
            // wholeRange={{ startValue: 0, length: dataSource.length }}
          />
          <Legend verticalAlignment="bottom" horizontalAlignment="center" itemTextPosition="top" />
          <Animation easing="linear" duration={300} maxPointCountSupported={100} />
          <Export enabled={true} />
          <Tooltip
            enabled={true}
            zIndex={2000}
            paddingLeftRight={0}
            paddingTopBottom={0}
            shadow={{ blur: 0, offsetY: 0 }}
            customizeTooltip={(arg) => {
              const category = arg.argumentText
              const data = dataSource.find((item) => item.display_name === category)

              if (!data) {
                return { text: 'No data available' }
              }

              const total = data.result_good_count + data.result_ng_count + data.result_warning_count
              const goodPercentage = ((data.result_good_count / total) * 100).toFixed(2)
              const ngPercentage = ((data.result_ng_count / total) * 100).toFixed(2)
              const warningPercentage = ((data.result_warning_count / total) * 100).toFixed(2)

              return {
                html: `<div style="background-color: black; color: white; padding: 10px; ">
                  <b>Category: ${category}</b><br>
                  <div style="display: flex; align-items: center;">
                    <div style="width: 10px; height: 10px; background-color: ${badItemColor}; margin-right: 5px;"></div>
                    <span style="color: white;">NG: ${data.result_ng_count} (${ngPercentage}%)</span>
                  </div>
                  <div style="display: flex; align-items: center;">
                    <div style="width: 10px; height: 10px; background-color: ${warningItemColor}; margin-right: 5px;"></div>
                    <span style="color: white;">Warning: ${data.result_warning_count} (${warningPercentage}%)</span>
                  </div>
                  <div style="display: flex; align-items: center;">
                    <div style="width: 10px; height: 10px; background-color: ${goodItemColor}; margin-right: 5px;"></div>
                    <span style="color: white;">Good: ${data.result_good_count} (${goodPercentage}%)</span>
                  </div>
                </div>`,
              }
            }}
          />
          <Crosshair enabled={true} color="#FF0000" width={1} dashStyle="dot">
            <Label visible={true} backgroundColor="#FF0000" />
          </Crosshair>
          <ScrollBar visible={true} />
        </StyledChart>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => ({
  useGoodItem: state.common.pages.scrollBoard.page.chartGoodVisible === 1,
  useWarningItem: state.common.pages.scrollBoard.page.chartWarningVisible === 1,
  useBadItem: state.common.pages.scrollBoard.page.chartBadVisible === 1,
  goodItemColor: state.common.pages.scrollBoard.theme.graphicGoodSetting,
  warningItemColor: state.common.pages.scrollBoard.theme.graphicWarningSetting,
  badItemColor: state.common.pages.scrollBoard.theme.graphicBadSetting,
  xFontSize: state.common.pages.scrollBoard.theme.barChartXFontSize,
  yFontSize: state.common.pages.scrollBoard.theme.barChartYFontSize,
  countFontSize: state.common.pages.scrollBoard.theme.barChartCountFontSize,
  fontColorCustomize: state.common.pages.scrollBoard.theme.barChartFontColorCustomize,
  xFontColor: state.common.pages.scrollBoard.theme.barChartXFontColor,
  yFontColor: state.common.pages.scrollBoard.theme.barChartYFontColor,
  countFontColorCustomize: state.common.pages.scrollBoard.theme.barChartCountFontColorCustomize,
  countFontColor: state.common.pages.scrollBoard.theme.barChartCountFontColor,
})

export default connect(mapStateToProps)(ScrollBoardBarChart)
