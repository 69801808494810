import { formatMessage } from 'devextreme/localization'

export function dqTranslateMsg(en_message) {
  const trans = formatMessage(en_message)
  return trans
}

export const languageData = {
  common: {
    data: {
      en: {
        LoadPanelLoading: 'Loading...',
      },
      ja: {
        LoadPanelLoading: '読み込み中...',
      },
      kr: {
        LoadPanelLoading: '로딩 중...',
      },
    },
  },
  login: {
    data: {
      en: {
        SignIn: 'Sign In',
        ID: 'ID',
        Password: 'Password',
        Reserved: 'N/A',
      },
      ja: {
        SignIn: 'サインイン',
        ID: 'ユーザーID',
        Password: 'パスワード',
        Reserved: 'N/A',
      },
      kr: {
        SignIn: '로그인',
        ID: '아이디',
        Password: '비밀번호',
        Reserved: 'N/A',
      },
    },
  },
  processErrorMsg: {
    data: {
      en: {
        E00001: 'Invalid Filter Condition',
        E00002: 'N/A',
        E00003: 'N/A',
        E00004: 'N/A',
        E00005: 'N/A',
        E00006: 'N/A',
        E00007: 'N/A',
        E00008: 'N/A',
        E00009: 'N/A',
        E00010: 'N/A',
        E00011: 'N/A',
        E00012: 'N/A',
        E00013: 'N/A',
        E00014: 'N/A',
        E00015: 'N/A',
        E00016: 'N/A',
        E00017: 'N/A',
        E00018: 'N/A',
        E00019: 'N/A',
        E00020: 'N/A',
        E00500: 'User Token expired. Please login again',
        E09999: 'N/A',
      },
      ja: {
        E00001: '無効なフィルター条件',
        E00002: 'N/A',
        E00003: 'N/A',
        E00004: 'N/A',
        E00005: 'N/A',
        E00006: 'N/A',
        E00007: 'N/A',
        E00008: 'N/A',
        E00009: 'N/A',
        E00010: 'N/A',
        E00011: 'N/A',
        E00012: 'N/A',
        E00013: 'N/A',
        E00014: 'N/A',
        E00015: 'N/A',
        E00016: 'N/A',
        E00017: 'N/A',
        E00018: 'N/A',
        E00019: 'N/A',
        E00020: 'N/A',
        E00500: 'ユーザートークンが期限切れです。再度ログインしてください。',
        E09999: 'N/A',
      },
      kr: {
        E00001: '잘못된 필터 조건',
        E00002: 'N/A',
        E00003: 'N/A',
        E00004: 'N/A',
        E00005: 'N/A',
        E00006: 'N/A',
        E00007: 'N/A',
        E00008: 'N/A',
        E00009: 'N/A',
        E00010: 'N/A',
        E00011: 'N/A',
        E00012: 'N/A',
        E00013: 'N/A',
        E00014: 'N/A',
        E00015: 'N/A',
        E00016: 'N/A',
        E00017: 'N/A',
        E00018: 'N/A',
        E00019: 'N/A',
        E00020: 'N/A',
        E00500: '사용자 토큰이 만료되었습니다. 다시 로그인해주세요.',
        E09999: 'N/A',
      },
    },
  },
  communicationErrorMsg: {
    data: {
      en: {
        E10000: 'Success',
        E10001: 'Fail',
        E10002: 'Skip',
        E10500: 'LIC – License not registered.',
        E10501: 'LIC - License exceeded',
        E10502: 'LIC – License expired.',
        E11001: 'Data - Process fail.',
        E11002: 'Data - Not exist value. ',
        E11003: 'Data - Deviation zero.',
        E12001: 'Auth - Expired token.',
        E12002: 'Auth - Invalid login password.',
        E12003: 'Auth – Not exist login id.',
        E12004: 'Auth – Invalid Signature key.',
        E13001: 'System – Service timeout.',
        E13002: 'System – Function not available.',
        E13003: 'System – Resource overload.',
        E14001: 'Network – No connection.',
        E14002: 'Network – Protocol error.',
        E14003: 'Network – Connection time out.',
        E15001: 'Parameter – Type incorrect.',
        E15002: 'Paramter – Required missing.',
        E15003: 'Paramter – Invalid range.',
        E16001: 'DB – Not accessible.',
        E16002: 'DB – Not exist data.',
        E16003: 'DB – Connection time out',
        E16004: 'DB – Driver data source err..',
        E16005: 'DB – Query fail..',
        E16006: 'DB – Data db no char data.',
        E16007: 'DB – Datal db no part data.',
        E16008: 'DB – Data db no value data.',
        E16009: 'DB – Filter part no data.',
        E16010: 'DB – Filter char no data.',
        E16011: 'DB – Catalog data error.',
        E16501: 'DB – Local db no connection.',
        E16502: 'DB – Local db no setting info.',
        E16503: 'DB – Local db no user id.',
        E16504: 'DB – Local db no char data.',
        E16505: 'DB – Local db no part data.',
        E17001: 'API – request format incorrect',
        E17002: 'API – Function not implemented.',
        E18001: 'ML/AI – no distribution.',
        E19999: 'Exception',
      },
      ja: {
        E10000: '成功',
        E10001: '失敗',
        E10002: 'スキップ',
        E10500: 'LIC – ライセンスが登録されていません',
        E10501: 'LIC - ライセンス数超過',
        E10502: 'LIC – ライセンスの有効期限が切れています',
        E11001: 'データ - 処理失敗',
        E11002: 'データ - 存在しない値',
        E11003: 'データ - 偏差ゼロ',
        E12001: '認証 - トークンの有効期限切れです',
        E12002: '認証 - ログインパスワードが無効です',
        E12003: '認証 – ログインIDが存在しません',
        E12004: '認証 – 署名キーが無効です',
        E13001: 'システム – サービスタイムアウト',
        E13002: 'システム – 機能が利用できません',
        E13003: 'システム – リソースオーバーロード',
        E14001: 'ネットワーク – 接続なし',
        E14002: 'ネットワーク – プロトコルエラー',
        E14003: 'ネットワーク – 接続タイムアウト',
        E15001: 'パラメータ – タイプ不正',
        E15002: 'パラメータ – 必須項目が不足しています',
        E15003: 'パラメータ – 範囲が無効です',
        E16001: 'DB – アクセスできません',
        E16002: 'DB – データが存在しません',
        E16003: 'DB – 接続タイムアウト',
        E16004: 'DB – ドライバデータソースエラー',
        E16005: 'DB – クエリ失敗',
        E16006: 'DB – データDBに特性データがありません',
        E16007: 'DB – データDBに部品データがありません',
        E16008: 'DB – データDBに値データがありません',
        E16009: 'DB – フィルタ部品にデータがありません',
        E16010: 'DB – フィルタ特性にデータがありません',
        E16011: 'DB – カタログデータエラー',
        E16501: 'DB – ローカルDBに接続できません',
        E16502: 'DB – ローカルDBの設定情報がありません',
        E16503: 'DB – ローカルDBのユーザIDがありません',
        E16504: 'DB – ローカルDBに特性データがありません',
        E16505: 'DB – ローカルDBに部品データがありません',
        E17001: 'API – リクエスト形式が不正',
        E17002: 'API – 機能が実装されていません',
        E18001: 'ML/AI – 分布がありません',
        E19999: '例外',
      },
      kr: {
        E10000: '성공',
        E10001: '실패',
        E10002: '건너뛰기',
        E10500: 'LIC – 라이선스 등록되지 않음.',
        E10501: 'LIC - 라이선스 초과',
        E10502: 'LIC – 라이선스 만료됨.',
        E11001: '데이터 - 처리 실패.',
        E11002: '데이터 - 값 없음.',
        E11003: '데이터 - 편차 제로.',
        E12001: '인증 - 만료된 토큰.',
        E12002: '인증 - 잘못된 로그인 비밀번호.',
        E12003: '인증 – 로그인 ID 없음.',
        E12004: '인증 – 잘못된 서명 키.',
        E13001: '시스템 – 서비스 시간 초과.',
        E13002: '시스템 – 기능 사용 불가.',
        E13003: '시스템 – 리소스 과부하.',
        E14001: '네트워크 – 연결 없음.',
        E14002: '네트워크 – 프로토콜 오류.',
        E14003: '네트워크 – 연결 시간 초과.',
        E15001: '매개변수 – 유형이 잘못됨.',
        E15002: '매개변수 – 필수 누락.',
        E15003: '매개변수 – 범위 잘못됨.',
        E16001: 'DB – 접근할 수 없음.',
        E16002: 'DB – 데이터 없음.',
        E16003: 'DB – 연결 시간 초과.',
        E16004: 'DB – 데이터 소스 드라이버 오류.',
        E16005: 'DB – 쿼리 실패.',
        E16006: 'DB – 문자 데이터 없음.',
        E16007: 'DB – 부분 데이터 없음.',
        E16008: 'DB – 값 데이터 없음.',
        E16009: 'DB – 필터 부분 데이터 없음.',
        E16010: 'DB – 필터 문자 데이터 없음.',
        E16011: 'DB – 카탈로그 데이터 오류.',
        E16501: 'DB – 로컬 DB 연결 없음.',
        E16502: 'DB – 로컬 DB 설정 정보 없음.',
        E16503: 'DB – 로컬 DB 사용자 ID 없음.',
        E16504: 'DB – 로컬 DB 문자 데이터 없음.',
        E16505: 'DB – 로컬 DB 부분 데이터 없음.',
        E17001: 'API – 요청 형식 잘못됨',
        E17002: 'API – 기능 구현되지 않음.',
        E18001: 'ML/AI – 분포 없음.',
        E19999: '예외',
      },
    },
  },
  header: {
    data: {
      en: {
        IconType: 'Icon Type',
        Language: 'Language',
        Font: 'Font',
        NavigationTheme: 'Navigation Theme',
        Theme: 'Theme',
        Mono: 'Mono',
        MonoBlue: 'Mono-Blue',
        MonoRed: 'Mono-Red',
        Color: 'Color',
        English: 'English',
        Japanese: 'Japanese',
        Korean: 'Korean',
        Standard: 'Standard',
        LinkedToTheme: 'Linked to Theme',
        generic_carmine_compact: 'generic.carmine.compact',
        generic_contrast_compact: 'generic.contrast.compact',
        generic_dark_compact: 'generic.dark.compact',
        generic_darkmoon_compact: 'generic.darkmoon.compact',
        generic_darkviolet_compact: 'generic.darkviolet.compact',
        generic_greenmist_compact: 'generic.greenmist.compact',
        generic_light_compact: 'generic.light.compact',
        material_blue_dark_compact: 'material.blue.dark.compact',
        material_blue_light_compact: 'material.blue.light.compact',
        material_lime_dark_compact: 'material.lime.dark.compact',
        material_lime_light_compact: 'material.lime.light.compact',
        material_orange_dark_compact: 'material.orange.dark.compact',
        material_orange_light_compact: 'material.orange.light.compact',
        material_purple_dark_compact: 'material.purple.dark.compact',
        material_purple_light_compact: 'material.purple.light.compact',
        material_teal_dark_compact: 'material.teal.dark.compact',
        material_teal_light_compact: 'material.teal.light.compact',
        Font_1: 'Sans-serif',
        Font_2: 'Roboto(Weight300)',
        Font_3: 'Roboto',
        Font_4: 'Helvetica',
        Font_5: 'Arial',
        Font_6: 'MPLUS1p-Regular',
        Font_7: 'MPLUS1p-Bold',
        Font_8: 'MPLUS1p-Thin',
        Font_9: 'MPLUS1p-Light',
        Font_10: 'MPLUS1p-Medium',
        Font_11: 'NotoSansJP-VariableFont_wght',
        Font_12: 'Spoqa-Han-Sans-Neo',
        Font_13: 'D2Coding',
        Font_14: 'Nanum-Gothic',
        Font_15: 'Nanum-Myeongjo',
      },
      ja: {
        IconType: 'アイコンタイプ',
        Language: '言語',
        Font: 'フォント',
        NavigationTheme: 'ナビゲーションテーマ',
        Theme: 'テーマ',
        Mono: 'モノクローム',
        MonoBlue: 'モノクロームブルー',
        MonoRed: 'モノクロームレッド',
        Color: 'カラー',
        English: '英語',
        Japanese: '日本語',
        Korean: '韓国語',
        Standard: '標準',
        LinkedToTheme: 'テーマにリンク',
        generic_carmine_compact: 'ジェネリック・カーマイン',
        generic_contrast_compact: 'ジェネリック・コントラスト',
        generic_dark_compact: 'ジェネリック・ダーク',
        generic_darkmoon_compact: 'ジェネリック・ダークムーン',
        generic_darkviolet_compact: 'ジェネリック・ダークバイオレット',
        generic_greenmist_compact: 'ジェネリック・グリーンミスト',
        generic_light_compact: 'ジェネリック・ライト',
        material_blue_dark_compact: 'マテリアル・ブルー・ダーク',
        material_blue_light_compact: 'マテリアル・ブルー・ライト',
        material_lime_dark_compact: 'マテリアル・ライム・ダーク',
        material_lime_light_compact: 'マテリアル・ライム・ライト',
        material_orange_dark_compact: 'マテリアル・オレンジ・ダーク',
        material_orange_light_compact: 'マテリアル・オレンジ・ライト',
        material_purple_dark_compact: 'マテリアル・パープル・ダーク',
        material_purple_light_compact: 'マテリアル・パープル・ライト',
        material_teal_dark_compact: 'マテリアル・ティール・ダーク',
        material_teal_light_compact: 'マテリアル・ティール・ライト',
        Font_1: 'Sans-serif',
        Font_2: 'Roboto（ウェイト300）',
        Font_3: 'Roboto',
        Font_4: 'Helvetica',
        Font_5: 'Arial',
        Font_6: 'MPLUS1p-Regular',
        Font_7: 'MPLUS1p-Bold',
        Font_8: 'MPLUS1p-Thin',
        Font_9: 'MPLUS1p-Light',
        Font_10: 'MPLUS1p-Medium',
        Font_11: 'NotoSansJP-VariableFont_wght',
        Font_12: 'Spoqa-Han-Sans-Neo',
        Font_13: 'D2Coding',
        Font_14: 'Nanum-Gothic',
        Font_15: 'Nanum-Myeongjo',
      },
      kr: {
        IconType: '아이콘타입',
        Language: '언어',
        Font: '글꼴',
        NavigationTheme: '메뉴테마',
        Theme: '테마',
        Mono: '모노',
        MonoBlue: '모노 블루',
        MonoRed: '모노 레드',
        Color: '컬러',
        English: '영어',
        Japanese: '일본어',
        Korean: '한국어',
        Standard: '표준',
        LinkedToTheme: '테마에 연결',
        generic_carmine_compact: '일반 카민 컴팩트',
        generic_contrast_compact: '일반 대조 컴팩트',
        generic_dark_compact: '일반 다크 컴팩트',
        generic_darkmoon_compact: '일반 다크문 컴팩트',
        generic_darkviolet_compact: '일반 다크바이올렛 컴팩트',
        generic_greenmist_compact: '일반 그린미스트 컴팩트',
        generic_light_compact: '일반 라이트 컴팩트',
        material_blue_dark_compact: '마테리얼 블루 다크 컴팩트',
        material_blue_light_compact: '마테리얼 블루 라이트 컴팩트',
        material_lime_dark_compact: '마테리얼 라임 다크 컴팩트',
        material_lime_light_compact: '마테리얼 라임 라이트 컴팩트',
        material_orange_dark_compact: '마테리얼 오렌지 다크 컴팩트',
        material_orange_light_compact: '마테리얼 오렌지 라이트 컴팩트',
        material_purple_dark_compact: '마테리얼 퍼플 다크 컴팩트',
        material_purple_light_compact: '마테리얼 퍼플 라이트 컴팩트',
        material_teal_dark_compact: '마테리얼 틸 다크 컴팩트',
        material_teal_light_compact: '마테리얼 틸 라이트 컴팩트',
        Font_1: '산세리프',
        Font_2: '로보토 (가중치 300)',
        Font_3: '로보토',
        Font_4: '헬베티카',
        Font_5: '아리얼',
        Font_6: '엠플러스1p-레귤러',
        Font_7: '엠플러스1p-볼드',
        Font_8: '엠플러스1p-씬',
        Font_9: '엠플러스1p-라이트',
        Font_10: '엠플러스1p-미디엄',
        Font_11: '노토산스JP-가변 폰트 무게',
        Font_12: '스포카 한 산스 네오',
        Font_13: 'D2코딩',
        Font_14: '나눔 고딕',
        Font_15: '나눔 명조',
      },
    },
  },
  navigation: {
    data: {
      en: {
        Overview: 'Overview',
        Monitoring: 'Monitoring',
        Database: 'Database',
        Charts: 'Charts',
        ValueChart: 'Value Chart',
        HistogramChart: 'Histogram',
        PositionalTolerancesChart: 'Positional Tolerances',
        BoxPlotChart: 'Box Plot Chart',
        LinearRegressionChart: 'Linear Regression',
        CorrelationChart: 'Correlation',
        QualityControlChart: 'Quality Control Chart',
        CapabilitySummaryChart: 'Capability Chart',
        ParetoChart: 'Paretogram',
        ProbabilityPlotChart: 'Probability Plot',
        DecisionTreeChart: 'Decision Tree',
        Lists: 'Lists',
        ValueList: 'Value List',
        SummaryList: 'Summary List',
        ValueSummaryList: 'Value Summary List',
        ScrollBoard: 'ScrollBoard',
        ReportPrint: 'Report',
        ReportPrintMultiFunction: 'MultiFunction Report',
        UploadFile: 'Upload File',
        Configuration: 'Configuration',
        AdminSettings: 'Admin Settings',
        CompanyInfo: 'Company Info',
      },
      ja: {
        Overview: '概要',
        Monitoring: '監視',
        Database: 'データベース',
        Charts: 'チャート',
        ValueChart: 'ライングラフ',
        HistogramChart: 'ヒストグラム',
        PositionalTolerancesChart: '位置公差',
        BoxPlotChart: '箱ひげ図',
        LinearRegressionChart: '線形回帰',
        CorrelationChart: '相関図',
        QualityControlChart: '品質管理図',
        CapabilitySummaryChart: '工程能力一覧',
        ParetoChart: 'パレート図',
        ProbabilityPlotChart: '確率プロット',
        DecisionTreeChart: '決定木',
        Lists: 'リスト',
        ValueList: '数値リスト',
        SummaryList: '工程能力リスト',
        ValueSummaryList: '測定結果一覧',
        ScrollBoard: 'スクロールボード',
        ReportPrint: 'レポート印刷',
        ReportPrintMultiFunction: '多機能レポート',
        UploadFile: 'ファイルアップロード',
        Configuration: '設定',
        AdminSettings: '管理者設定',
        CompanyInfo: '企業情報',
      },
      kr: {
        Overview: '개요',
        Monitoring: '모니터링',
        Database: '데이터베이스',
        Charts: '차트',
        ValueChart: '런차트',
        HistogramChart: '히스토그램',
        PositionalTolerancesChart: '위치공차',
        BoxPlotChart: '박스플롯',
        LinearRegressionChart: '선형회귀',
        CorrelationChart: '상관관계',
        QualityControlChart: '관리도',
        CapabilitySummaryChart: '공정능력차트',
        ParetoChart: '파레토그램',
        ProbabilityPlotChart: '확률도',
        DecisionTreeChart: '의사결정트리',
        Lists: '리스트',
        ValueList: '값 리스트',
        SummaryList: '분석요약 리스트',
        ValueSummaryList: '값 요약 리스트',
        ScrollBoard: '드릴다운보드',
        ReportPrint: '레포트',
        ReportPrintMultiFunction: '다기능 보고서',
        UploadFile: '파일업로드',
        Configuration: '일반설정',
        AdminSettings: '관리자설정',
        CompanyInfo: '회사정보',
      },
    },
  },
  sidePanel: {
    data: {
      en: {
        PrintContent: 'Print Content',
        Save: 'Save',
        Reset: 'Reset',
        Apply: 'Apply',
        ClearAll: 'Clear All',
        Clear: 'Clear',
        Database: 'Database',
        SearchPlaceHoler: 'Search...',
        SavedSettings: 'Saved Settings',
        FailedSettings: 'Failed Settings',
        ResetSettings: 'Reset Settings',
        DisplayType: 'Display Type',
        Main: 'Main',
        Sub: 'Sub',
        QualityStatusColors: 'Quality Status Colors',
        MonitoringType: 'Monitoring Type',
        AutomaticMonitoringDisplayType: 'Automatic Monitoring Display Type',
        ValueOrder: 'Value Order',
        CharOrder: 'Characteristics Order',
        Order: 'Order',
        MonitoringInterval: 'Monitoring Interval',
        MaxDisplayCount: 'Max Display Count',
        EasySettings: 'Easy Settings',
        Start: 'Start',
        End: 'End',
        Layout: 'Layout',
        Column: 'Column',
        Row: 'Row',
        DisplayType: 'Display Type',
        CalculationCount: 'Calculation Count',
        CalculationType: 'Calculation Type',
        ChartSplit: 'Chart Split',
        SplitItem: 'Split Item',
        Item: 'Item',
        ExtraXAxisLabels: 'Extra X Axis Labels',
        AddedExtraLabels: 'Added List',
        DateTimeDisplayFormat: 'Date Format',
        ToolTip: 'ToolTip(Balloon)',
        ToolTipAddedInfo: 'Added Info',
        ToolTipAddedInfoList: 'Info List',
        ToolTipSpecLimits: 'Specification Limit',
        ToolTipWarnLimits: 'Warn Limits',
        ToolTipCustomLimits: 'Control Limits',
        ARIMA: 'ARIMA',
        Auto: 'Auto',
        Show: 'Show',
        pValue: 'pValue',
        dValue: 'dValue',
        qValue: 'qValue',
        ForecastSize: 'Forecast Size',
        SampleSize: 'Sample Size',
        NGSymbol: 'NG Symbol',
        WarningSymbol: 'Warning Symbol',
        GoodSymbol: 'OK Symbol',
        ChartLine: 'Chart Line',
        LimitationsEnable: 'Limitations Enable',
        SpecificationLimit: 'Specification Limit',
        WarningLimit: 'Warning Limit',
        ControlLimit: 'Control Limit',
        CustomLimit: 'Custom Limit',
        NominalLimit: 'Nominal Limit',
        AverageLimit: 'Average Limit',
        LimitationsLabelEnable: 'Limitations Label Enable',
        SpecificationLimitLabel: 'Specification Limit',
        WarningLimitLabel: 'Warning Limit',
        ControlLimitLabel: 'Control Limit',
        QuantileLimitLabel: 'Quantile Limit',
        CustomLimitLabel: 'Custom Limit',
        NominalLimitLabel: 'Nominal Limit',
        AverageLimitLabel: 'Average Limit',
        MarkArea: 'MarkArea',
        NG: 'NG',
        Shape: 'Shape',
        Size: 'Size',
        Color: 'Color',
        NGSymbolShape: 'NG Symbol Shape',
        NGSymbolSize: 'NG Symbol Size',
        NGSymbolColor: 'NG Symbol Color',
        GoodSymbolShape: 'OK Symbol Shape',
        GoodSymbolSize: 'OK Symbol Size',
        GoodSymbolColor: 'OK Symbol Color',
        X99865Type: 'Xᵘᵖ³ Type',
        X99865Width: 'Xᵘᵖ³ Width',
        X99865Color: 'Xᵘᵖ³ Color',
        X0_135Type: 'Xˡᵒ³ Type',
        X0_135Width: 'Xˡᵒ³ Width',
        X0_135Color: 'Xˡᵒ³ Color',
        XBarType: 'X\u0304 Type',
        XBarWidth: 'X\u0304 Width',
        XBarColor: 'X\u0304 Color',
        X50Type: 'X⁵⁰ Type',
        X50Width: 'X⁵⁰ Width',
        X50Color: 'X⁵⁰ Color',
        Warn: 'Warn',
        Good: 'OK',
        None: 'None',
        Type: 'Type',
        Width: 'Width',
        ArimaChartLine: 'Arima ChartLine',
        OuterColor: 'Outer Color',
        InnerColor: 'Inner Color',
        ValueField: 'Value Field',
        YAxisType: 'Y Axis Type',
        CriteriaLine: 'Criteria Line',
        Normalization: 'Normalization',
        NoData: 'No data',
        ResultIcon: 'Result Icon',
        OutOfTolIcon: 'OOT Icon',
        SecMin5: 'sec (Min = 5 sec)',
        Count: 'Count',
        Profile: 'Profile',
        Logout: 'Logout',
        CompareType: 'CompareType',
        AdditionalDisplay: 'Additional Display',
        RelationLevel: 'Relation Level(ABS)',
        Strong: 'Strong',
        DisplayMethod: 'Display Method',
        By: 'by',
        Criteria: 'Criteria',
        AllOf: 'all of',
        UseStack: 'Use Stack',
        DefectUnitBarState: 'Defect Unit Bar State',
        DefectUnitBarColor: 'Defect Unit Bar Color',
        NGBar: 'NG Bar',
        WarnBar: 'Warn Bar',
        GoodBar: 'OK Bar',
        CpNGBar: 'Cp NG Bar',
        CpGoodBar: 'Cp OK Bar',
        CpkNGBar: 'Cpk NG Bar',
        CpkGoodBar: 'Cpk OK Bar',
        EmptyCharacteristics: 'Empty Characteristics',
        DisplayCount: 'Display Count',
        AddExtraFields: 'Add Extra Fields',
        List: 'List',
        CPGood: 'CP Criteria',
        CPKGood: 'CPK Criteria',
        Limit: 'Limit',
        Box: 'Box',
        BorderColor: 'Border Color',
        Outliers: 'Outliers',
        RelationColorBarChart: 'Relation Color (Bar Chart)',
        Others: 'Other',
        RelationColorHeatMap: 'Relation Color (Heat Map)',
        HistogramBar: 'Histogram Bar',
        BellCurve: 'Bell Curve',
        PTChart: 'PT Chart',
        CpLineForRunChart: 'Cp Line (For Run Chart)',
        CpkLineForRunChart: 'Cpk Line (For Run Chart)',
        NGBarForBarChart: 'NG Bar (For Bar Chart)',
        GoodBarForBarChart: 'OK Bar (For Bar Chart)',
        DefectUnitBar: 'Defect Unit Bar',
        Symbols: 'Symbols',
        GoodColor: 'OK Color',
        WarnColor: 'Warn Color',
        NGColor: 'NG Color',
        BadColor: 'Bad Color',
        NGSymbolForRunChart: 'NG Symbol (For Run Chart)',
        GoodSymbolForRunChart: 'OK Symbol (For Run Chart)',
        ShowDeletedValue: 'Show Deleted Value',
        Value: 'Value',
        AddedInfoTopChar: 'Show only top Char',
        EmptyCircle: 'EmptyCircle',
        Circle: 'Circle',
        Rect: 'Rect',
        RoundRect: 'Round Rect',
        Triangle: 'Triangle',
        Diamond: 'Diamond',
        Pin: 'Pin',
        Arrow: 'Arrow',
        None: 'None',
        Solid: 'Solid',
        Dotted: 'Dotted',
        Dashed: 'Dashed',
        Smile: 'Smile',
        Thumbs: 'Thumbs',
        DateTimeSecItem: 'Date/Time/Sec',
        DateTimeItem: 'Date/Time',
        DateItem: 'Date',
        TimeSecItem: 'Time/Sec',
        TimeItem: 'Time',
        TheoreticalValue: 'Theoretical-Value',
        TheoreticalQuantile: 'Theoretical-Quantile',
        OneMinute: '1minute',
        OneHour: '1hour',
        OneDay: '1day',
        OneWeek: '1week',
        Custom: 'Custom',
        OneSide: 'One-side',
        TwoSide: 'Two-side',
        AutoCalculation: 'Auto-calculation',
        NormalDistribution: 'Normal-distribution',
        LogNormalDistribution: 'Log-normal-distribution',
        FoldedNormalDistribution: 'Folded-normal-distribution',
        WeibullDistribution: 'Weibull-distribution',
        MinMax: 'Min-Max',
        MaxAbs: 'Max-Abs',
        Robust: 'Robust',
        Standard: 'Standard',
        Fixed: 'Fixed',
        Moving: 'Moving',
        ScatterSlope: 'Scatter+Slope',
        Slope: 'Slope',
        Scatter: 'Scatter',
        BarChart: 'Bar Chart',
        HeatMap: 'HeatMap',
        RunChart: 'Run Chart',
        Cart: 'Cart',
        RandomForest: 'RandomForest',
        All: 'All',
        Top10: 'Top 10',
        Top5: 'Top 5',
        Top3: 'Top 3',
        Single: 'Single',
        Part: 'Part',
        Characteristics: 'Characteristics',
        Event: 'Event',
        EventOption: 'Event Option',
        EventSymbolOption: 'Event Symbol Option',
        EventLineOption: 'Event Line Option',
        DefectUnit: 'Defect Unit',
        Stack: 'Stack',
        NotStack: 'Not Stack',
        TreeMap: 'TreeMap',
        Diagram: 'Diagram',
        ValueChart: 'Value Chart',
        QccChart: 'Qcc Chart',
        Ascending: 'Ascending',
        Descending: 'Descending',
        DataRange: 'Data Range',
        LastDays: 'Last Days',
        Last1Day: 'Last 1 Day',
        Last1Week: 'Last 1 Week',
        Last1Month: 'Last 1 Month',
        DayOffset: 'Day Offset',
        DayTimeEnable: 'Day Time Enable',
        DayTime: 'Day Time',
        IgnoreValue: 'Ignore Value',
        Enable: 'Enable',
        UslPercent: 'USL Percent',
        LslPercent: 'LSL Percent',
        CharacteristicClass: 'Characteristic Class',
        Unimportant: 'Unimportant',
        Secondary: 'Secondary',
        Standard: 'Standard',
        Important: 'Important',
        Critical: 'Critical',
        OrderChart: 'Order Chart',
        Field: 'Field',
        Add: 'Add',
        CharacteristicsVisible: 'Characteristics Visible',
        Bad: 'Bad',
        ValueChartOption: 'Value Chart Option',
        PopupMode: 'Popup Mode',
        BalloonField: 'Balloon Field',
        XAxisType: 'X Axis Type',
        BarChartOption: 'Bar Chart Option',
        LimitCount: 'Limit Count',
        ContentOrder: 'Content Order',
        QCCOption: 'QCC Option',
        QCCChartOption: 'QCC Chart Option',
        AutoPage: 'Auto Page',
        RestartIndex: 'Restart Index',
        LayoutColumn: 'Layout Column',
        LayoutRow: 'Layout Row',
        PageUpdateSec: 'Page Update Period',
        GraphicUpdateEnable: 'Data Update Enable',
        GraphicUpdateMin: 'Data Update Period ',
        SecondsShort: 'secs',
        MinutesShort: 'mins',
        PercentageShort: '%',
        SigmaShort: 'σ',
        TopOrder: 'Top Order',
        FontSize: 'Font Size',
        TopOrderColorCustomEnable: 'Top Order Color Custom Enable',
        TextColor: 'Text Color',
        Graphics: 'Graphics',
        TopTitleFontSize: 'Top Title Font Size',
        XAxisFontSize: 'X Axis Font Size',
        YAxisFontSize: 'Y Axis Font Size',
        RightLimitsFontSize: 'Right Limits Font Size',
        SpecLimitFontColor: 'Spec Limits Font Color',
        WarnLimitFontColor: 'Warn Limits Font Color',
        UslFontColor: 'USL Font Color',
        LslFontColor: 'LSL Font Color',
        UwlFontColor: 'UWL Font Color',
        LwlFontColor: 'LWL Font Color',
        XAxisFontColor: 'XAxis Font Color',
        YAxisFontColor: 'YAxis Font Color',
        CountFontSize: 'Count Font Size',
        AxisFontColorCustomEnable: 'Axis Font Color Custom Enable',
        CountFontColorCustomEnable: 'Count Font Color Custom Enable',
        CountFontColor: 'Count Font Color',
        TreeMapChart: 'Tree Map Chart',
        TileFontColorCustomEnable: 'Tile Font Color Custom Enable',
        TileFontColor: 'Tile Font Color',
        NgSizeRelative: 'NG Size Relative',
        WarnSizeRelative: 'Warn Size Relative',
        GoodSizeRelative: 'OK Size Relative',
        FilterConfig: 'Filter Config',
        Group: 'Group',
        Field: 'Field',
        Data: 'Data',
        Logic: 'Logic',
        None: 'None',
        LeftParenthesis: 'Left Parenthesis',
        RightParenthesis: 'Right Parenthesis',
        And: 'AND',
        Or: 'OR',
        Match: 'Match',
        Contains: 'Partial',
        Equal: 'Exact',
        CommonOption: 'Common Option',
        FilterList: 'Filter List',
        LastNFilter: 'Last N Filter',
        IgnoreDuplicatedValue: 'Ignore Duplicated Value',
        PageCalculationInAdvance: 'PageCalculation In Advance',
        Etcetera: 'Extra',
        DBFieldFilter: 'Quick Filter',
        DBFieldAdvancedFilter: 'Advanced Filter',
        SaveFilter: 'Save Advanced Filter',
        MakeFilter: 'Make Filter',
        SavedFilterList: 'Saved Filter List',
        SelectedFilter: 'Selected Filter',
        CreateFilter: 'Create Filter for Saving',
        FilterName: 'Filter Name',
        UseDateTime: 'Use Date/Time',
        StartDateTime: 'Start Date/Time',
        EndDateTime: 'End Date/Time',
        Arrangement: 'Arrangement',
        Sort: 'Sort',
        DateTimeSort: 'Date/Time Sort',
        PageCalculationInAdvance: 'Page Calculation in advance',
        Part: 'Part',
        FieldID: 'Field ID',
        ConditionValue: 'Condition Value',
        Characteristics: 'Characteristics',
        Value: 'Value',
        None: 'None',
        DateTime: 'DateTime',
        PartIdent: 'PartIdent',
        Order: 'Order',
        Ascending: 'Ascending',
        Descending: 'Descending',
        Count: 'Count',
        Hour: 'Hour',
        Day: 'Day',
        Week: 'Week',
        ErrorMsgInvalidDateTime: 'Please input correct Date/Time',
        ErrorMsgEmptyPart: 'Please select part',
        ErrorMsgEmptyChar: 'Please select characteristics',
        ErrorMsgNotMatchPartField: 'Part Field is not match with DB Field',
        ErrorMsgNotMatchCharField: 'Char Field is not match with DB Field',
        ErrorMsgNotMatchValueField: 'Value Field is not match with DB Field',
        ErrorMsgEmptyPartData: 'Part Data is empty',
        ErrorMsgEmptyCharData: 'Characteristics Data is empty',
        ErrorMsgEmptyValueData: 'Value Data is empty',
        ErrorMsgEmptyDeleteItem: 'Please choose the characteristics you wish to delete.',
        Importance: 'Importance',
        Analyze: 'Analyze',
        BarColor: 'Bar Color',
        Charts: 'Charts',
        Common: 'Common',
        Nominal: 'Nominal',
        Average: 'Average',
        DenseLabel: 'Dense Label',
        XAxisSettings: 'XAxis Settings',
        RegisteredItem: 'Registered Item',
        DateTimeDisplayType: 'Date/Time Display Type',
        SelectableItems: 'SelectableItems',
        OutOfControl: 'Out Of Control Limit',
        OutOfControlForQCC: 'Out Of Control Limit (QCC only)',
        QuantileLimit: 'Quantile Limit',
        ReportMode: 'Report Mode',
        EditorMode: 'Editor Mode',
        UserMode: 'User Mode',
        SavedTemplates: 'Saved Templates',
        PagePaddings: 'Page Paddings',
        ElementLists: 'Element Lists',
        ElementTypes: 'Element Types',
        ElementItems: 'Element Items',
        Refresh: 'Refresh',
        Load: 'Load',
        SwitchFilterMode: 'Switch Filter Mode',
        TakeoverFromDataset: 'Takeover From Dataset',
        DefaultSubgroupCount: 'Default Subgroup Count',
        DefaultSubgroupType: 'Default Subgroup Type',
        QccLocationChartType: 'Qcc Location Chart Type(xBar)',
        Raw: 'Raw',
        HandlingIncompleteSubgroup: 'Handling Incomplete Subgroup',
        CalculateIncompleteSubgroup: 'Calculate incomplete subgroup',
        DeleteIncompleteSubgroup: 'Delete incomplete subgroup',
        AllowanceLimit: 'Allowance Limit',
        PlausibilityLimit: 'Plausibility Limit',
        ScrapLimit: 'Scrap Limit',
        AcceptanceLimit: 'Acceptance Limit',
        AllowanceLimitLabel: 'Allowance Limit ',
        PlausibilityLimitLabel: 'Plausibility Limit',
        ScrapLimitLabel: 'Scrap Limit',
        AcceptanceLimitLabel: 'Acceptance Limit',
        UsePlausibilityFunction: 'Use Plausibility Limit',
        UseScrapFunction: 'Use Scrap Limit',
        UseNaturalBoundaryFunction: 'Use Natural Boundary',
        UseAdjustmentTolerancesPercentage: 'Use Tolerances',
        DisplayFieldNameWithNumber: 'Display Field Name with Number',
        New: 'New',
      },
      ja: {
        PrintContent: '印刷内容',
        Save: '保存',
        Reset: 'リセット',
        Apply: '適用',
        ClearAll: '全てクリア',
        Clear: 'クリア',
        Database: 'データベース',
        SearchPlaceHoler: '検索...',
        SavedSettings: '設定保存成功',
        FailedSettings: '設定保存失敗',
        ResetSettings: '設定リセット',
        DisplayType: '表示タイプ',
        Main: 'メイン',
        Sub: 'サブ',
        QualityStatusColors: '品質状態の色 ',
        MonitoringType: '監視タイプ',
        AutomaticMonitoringDisplayType: '自動監視表示タイプ',
        ValueOrder: '数値の順序',
        CharOrder: '特性の順序',
        Order: '順序',
        MonitoringInterval: '監視間隔',
        MaxDisplayCount: '最大表示数',
        EasySettings: '簡易設定',
        Start: '開始',
        End: '終了',
        Layout: 'レイアウト',
        Column: '列',
        Row: '行',
        DisplayType: '表示タイプ',
        CalculationCount: '計算カウント',
        CalculationType: '計算タイプ',
        ChartSplit: 'チャート分割',
        SplitItem: '分割項目',
        Item: '項目',
        ExtraXAxisLabels: '追加ラベル',
        AddedExtraLabels: '追加リスト',
        DateTimeDisplayFormat: '日付フォーマット',
        ToolTip: 'ツールチップ',
        ToolTipAddedInfo: '追加情報',
        ToolTipAddedInfoList: '情報リスト',
        ToolTipSpecLimits: '規格限界',
        ToolTipWarnLimits: '警告限界',
        ToolTipCustomLimits: '管理限界',
        ARIMA: 'ARIMA',
        Auto: '自動',
        Show: '表示',
        pValue: 'p値',
        dValue: 'd値',
        qValue: 'q値',
        ForecastSize: '予測サイズ',
        SampleSize: 'サンプルサイズ',
        NGSymbol: 'NGシンボル',
        WarningSymbol: '警告シンボル',
        GoodSymbol: 'OKシンボル',
        ChartLine: 'チャートライン',
        SpecificationLimit: '仕様限界',
        WarningLimit: '警告限界',
        ControlLimit: '管理限界',
        CustomLimit: 'カスタムリミット',
        NominalLimit: '公称値',
        AverageLimit: '平均',
        LimitationsLabelEnable: '制限ラベル有効',
        SpecificationLimitLabel: '仕様限界ラベル',
        WarningLimitLabel: '警告限界ラベル',
        ControlLimitLabel: '管理限界ラベル',
        QuantileLimitLabel: '分位數限界ラベル',
        CustomLimitLabel: 'カスタムリミットラベル',
        NominalLimitLabel: '公称値ラベル',
        AverageLimitLabel: '平均ラベル',
        MarkArea: 'マークエリア',
        NG: 'NG',
        Shape: '形状',
        Size: 'サイズ',
        Color: '色',
        NGSymbolShape: 'NGシンボル形状',
        NGSymbolSize: 'NGシンボルサイズ',
        NGSymbolColor: 'NGシンボル色',
        GoodSymbolShape: 'OKシンボル形状',
        GoodSymbolSize: 'OKシンボルサイズ',
        GoodSymbolColor: 'OKシンボル色',
        X99865Type: 'Xᵘᵖ³ タイプ',
        X99865Width: 'Xᵘᵖ³ 幅',
        X99865Color: 'Xᵘᵖ³ 色',
        X0_135Type: 'Xˡᵒ³ タイプ',
        X0_135Width: 'Xˡᵒ³ 幅',
        X0_135Color: 'Xˡᵒ³ 色',
        XBarType: 'X\u0304 タイプ',
        XBarWidth: 'X\u0304 幅',
        XBarColor: 'X\u0304 色',
        X50Type: 'X⁵⁰ タイプ',
        X50Width: 'X⁵⁰ 幅',
        X50Color: 'X⁵⁰ 色',
        Warn: '警告',
        Good: 'OK',
        None: 'なし',
        Type: 'タイプ',
        Width: '幅',
        ArimaChartLine: 'ARIMA チャートライン',
        OuterColor: '外側色',
        InnerColor: '塗りつぶし',
        ValueField: '数値フィールド',
        YAxisType: 'Y軸タイプ',
        CriteriaLine: '基準線',
        Normalization: '正規化',
        NoData: 'データなし',
        ResultIcon: '結果アイコン',
        OutOfTolIcon: '許容範囲外アイコン',
        SecMin5: '秒 (最小 = 5)',
        Count: 'カウント',
        Profile: 'プロファイル',
        Logout: 'ログアウト',
        CompareType: '比較タイプ',
        AdditionalDisplay: '追加表示',
        RelationLevel: '相関レベル(ABS)',
        Strong: '強い',
        DisplayMethod: '表示方法',
        By: 'グラフ',
        Criteria: '基準',
        AllOf: 'すべて',
        UseStack: '累積を使用',
        DefectUnitBarState: 'バー',
        DefectUnitBarColor: 'バー色',
        NGBar: 'NGバー',
        WarnBar: '警告バー',
        GoodBar: 'OKバー',
        CpNGBar: 'Cp NGバー',
        CpGoodBar: 'Cp OKバー',
        CpkNGBar: 'Cpk NGバー',
        CpkGoodBar: 'Cpk OKバー',
        EmptyCharacteristics: '空の特性を表示',
        DisplayCount: '表示数',
        AddExtraFields: 'フィールドを追加',
        List: 'リスト',
        CPGood: 'CP 良好',
        CPKGood: 'CPK 良好',
        Limit: '限界',
        Box: 'ボックス',
        BorderColor: '境界色',
        Outliers: '外れ値',
        RelationColorBarChart: '相関色（バーチャート）',
        Others: 'その他',
        RelationColorHeatMap: '相関色（ヒートマップ）',
        HistogramBar: 'ヒストグラムバー',
        BellCurve: '分布曲線',
        PTChart: 'PT チャート',
        CpLineForRunChart: 'Cp 線 (ランチャート用)',
        CpkLineForRunChart: 'Cpk 線 (ランチャート用)',
        NGBarForBarChart: 'NGバー (バーチャート用)',
        GoodBarForBarChart: 'OKバー (バーチャート用)',
        DefectUnitBar: 'NGバー',
        Symbols: 'シンボル',
        GoodColor: 'OK色',
        WarnColor: '警告色',
        NGColor: 'NG色',
        BadColor: '悪い色',
        NGSymbolForRunChart: 'NGシンボル (ランチャート用)',
        GoodSymbolForRunChart: 'OKシンボル (ランチャート用)',
        ShowDeletedValue: '削除された値を表示',
        Value: '値',
        AddedInfoTopChar: 'トップの特性のみを表示',
        EmptyCircle: '中空円',
        Circle: '円',
        Rect: '長方形',
        RoundRect: '角丸四角形',
        Triangle: '三角形',
        Diamond: 'ダイヤモンド',
        Pin: 'ピン',
        Arrow: '矢印',
        None: 'なし',
        Solid: '実線',
        Dotted: '点線',
        Dashed: '破線',
        Smile: 'スマイル',
        Thumbs: 'いいね',
        DateTimeSecItem: '日付/時刻/秒',
        DateTimeItem: '日付/時刻',
        DateItem: '日付',
        TimeSecItem: '時刻/秒',
        TimeItem: '時刻',
        TheoreticalValue: '理論値',
        TheoreticalQuantile: '理論分位数',
        OneMinute: '1分',
        OneHour: '1時間',
        OneDay: '1日',
        OneWeek: '1週間',
        Custom: 'カスタム',
        OneSide: '片面',
        TwoSide: '両面',
        AutoCalculation: '自動計算',
        NormalDistribution: '正規分布',
        LogNormalDistribution: '対数正規分布',
        FoldedNormalDistribution: '折りたたみ正規分布',
        WeibullDistribution: 'ワイブル分布',
        MinMax: '最小-最大',
        MaxAbs: '最大絶対値',
        Robust: 'ロバスト',
        Fixed: '固定',
        Moving: '移動',
        ScatterSlope: '分布+回帰線',
        Slope: '回帰線',
        Scatter: '分布',
        BarChart: 'バーチャート',
        HeatMap: 'ヒートマップ',
        RunChart: 'ランチャート',
        Cart: 'Cart',
        RandomForest: 'ランダムフォレスト',
        All: '全て',
        Top10: 'トップ10',
        Top5: 'トップ5',
        Top3: 'トップ3',
        Single: 'シングル',
        Part: '部品',
        Characteristics: '特性',
        Event: 'イベント',
        EventOption: 'イベントオプション',
        EventSymbolOption: 'イベントシンボルオプション',
        EventLineOption: 'イベントラインオプション',
        DefectUnit: '欠陥ユニット',
        Stack: '累積',
        NotStack: '累積しない',
        TreeMap: 'ツリーマップ',
        Diagram: 'ダイアグラム',
        ValueChart: 'ライングラフ',
        QccChart: 'Qccチャート',
        Ascending: '上昇',
        Descending: '降順',
        DataRange: 'データ範囲',
        LastDays: '日数',
        Last1Day: '過去1日',
        Last1Week: '過去1週間',
        Last1Month: '過去1か月',
        DayOffset: '過去n日',
        DayTimeEnable: '基準時刻有効',
        DayTime: '基準時刻',
        IgnoreValue: '外れ値除外',
        Enable: '有効',
        UslPercent: 'USL外れ値レベル(%)',
        LslPercent: 'LSL外れ値レベル(%)',
        CharacteristicClass: '特性クラス',
        Unimportant: '重要度1',
        Secondary: '重要度2',
        Standard: '重要度3',
        Important: '重要度4',
        Critical: '重要度5',
        OrderChart: 'チャート順',
        Field: '部品項目',
        Add: '追加',
        Visible: '可視',
        Bad: 'NG',
        ValueChartOption: 'ライングラフオプション',
        PopupMode: 'ポップアップモード',
        BalloonField: 'バルーンフィールド',
        XAxisType: 'X軸タイプ',
        BarChartOption: 'バーチャートオプション',
        LimitCount: '表示数',
        ContentOrder: '並び順',
        QCCOption: '品質管理図オプション',
        QCCChartOption: '品質管理図オプション',
        AutoPage: 'スライドショー',
        RestartIndex: 'ページ再開ポイント',
        LayoutColumn: 'グラフ表示数:列',
        LayoutRow: 'グラフ表示数:行',
        PageUpdateSec: 'ページ更新間隔',
        GraphicUpdateEnable: 'データ更新有効',
        GraphicUpdateMin: 'データ更新間隔',
        SecondsShort: '秒',
        MinutesShort: '分',
        PercentageShort: '%',
        SigmaShort: 'σ',
        TopOrder: '階層表示テキスト',
        FontSize: 'フォントサイズ',
        TopOrderColorCustomEnable: '階層表示テキスト色変更',
        TextColor: 'テキストの色',
        Graphics: 'グラフィックス',
        TopTitleFontSize: 'タイトルフォントサイズ',
        XAxisFontSize: 'X軸フォントサイズ',
        YAxisFontSize: 'Y軸フォントサイズ',
        RightLimitsFontSize: '右限界フォントサイズ',
        SpecLimitFontColor: '仕様限界フォントの色',
        WarnLimitFontColor: '警告限界フォントの色',
        UslFontColor: 'USLフォントの色',
        LslFontColor: 'LSLフォントの色',
        UwlFontColor: 'UWLフォントの色',
        LwlFontColor: 'LWLフォントの色',
        XAxisFontColor: 'X軸フォントの色',
        YAxisFontColor: 'Y軸フォントの色',
        CountFontSize: '個数のフォントサイズ',
        AxisFontColorCustomEnable: '軸フォント色変更',
        CountFontColorCustomEnable: '個数フォント色変更',
        CountFontColor: '個数のフォント色',
        TreeMapChart: 'ツリーマップチャート',
        TileFontColorCustomEnable: 'タイルフォント色変更',
        TileFontColor: 'タイルフォントの色',
        NgSizeRelative: 'NG 相対サイズ',
        WarnSizeRelative: '警告 相対サイズ',
        GoodSizeRelative: 'OK 相対サイズ',
        FilterConfig: 'フィルタ構成',
        Group: 'シンボル',
        Field: 'フィールド',
        Data: '検索文字',
        Logic: '論理演算',
        None: 'ナン',
        LeftParenthesis: '(',
        RightParenthesis: ')',
        And: 'AND',
        Or: 'OR',
        Match: '一致',
        Contains: '含む',
        Equal: '等',
        CommonOption: '共通オプション',
        FilterList: 'フィルタリスト',
        LastNFilter: '直近のNフィルタ',
        IgnoreDuplicatedValue: '重複値を無視する',
        PageCalculationInAdvance: '事前ページ計算',
        Etcetera: 'その他',
        DBFieldFilter: 'クイックフィルター',
        DBFieldAdvancedFilter: '詳細フィルター',
        SaveFilter: 'フィルター保存',
        MakeFilter: 'フィルター作成',
        SavedFilterList: '保存されたフィルターリスト',
        SelectedFilter: '選択されたフィルター',
        CreateFilter: '保存用フィルターの作成',
        FilterName: 'フィルター名',
        UseDateTime: '日付/時刻を使用',
        StartDateTime: '開始日時',
        EndDateTime: '終了日時',
        Arrangement: '整列',
        Sort: 'ソート',
        DateTimeSort: '日付/時刻 ソート',
        Part: '部品',
        FieldID: 'フィールドID',
        ConditionValue: '条件値',
        Characteristics: '特性',
        Value: '数値',
        None: 'なし',
        DateTime: '日付時刻',
        PartIdent: '部品識別番号',
        Order: 'ロット',
        Ascending: '昇順',
        Descending: '降順',
        Count: '個',
        Hour: '時',
        Day: '日',
        Week: '週',
        ErrorMsgInvalidDateTime: '正しい日付/時刻を入力してください',
        ErrorMsgEmptyPart: '部品を選択してください',
        ErrorMsgEmptyChar: '特性を選択してください',
        ErrorMsgNotMatchPartField: '部品フィールドがデータベースフィールドと一致しません',
        ErrorMsgNotMatchCharField: '特性フィールドがデータベースフィールドと一致しません',
        ErrorMsgNotMatchValueField: '数値フィールドがデータベースフィールドと一致しません',
        ErrorMsgEmptyPartData: '部品データが空です',
        ErrorMsgEmptyCharData: '特性データが空です',
        ErrorMsgEmptyValueData: '数値データが空です',
        ErrorMsgEmptyDeleteItem: '削除する特性を選択してください',
        Importance: '重要性',
        Analyze: '分析',
        BarColor: 'バーの色',
        Charts: 'チャート',
        Common: '共通',
        Nominal: '公称値',
        Average: '平均',
        DenseLabel: '密なラベル',
        LimitationsEnable: '制限有効',
        XAxisSettings: 'X軸設定',
        RegisteredItem: '登録されたアイテム',
        DateTimeDisplayType: '日付/時刻表示タイプ',
        SelectableItems: '選択可能なアイテム',
        OutOfControl: '管理限界外',
        OutOfControlForQCC: '管理限界外(QCCのみ)',
        QuantileLimit: '四分位数限界',
        Refresh: 'リフレッシュ',
        Load: 'ロード',
        CurrentTemplate: '現在のテンプレート',
        SwitchFilterMode: 'フィルターモード切り替え',
        TakeoverFromDataset: 'データセットからの引き継ぎ',
        DefaultSubgroupCount: 'デフォルトサブグループ数',
        DefaultSubgroupType: 'デフォルトサブグループタイプ',
        QccLocationChartType: 'Qcc位置チャートタイプ(xBar)',
        Raw: '生データ',
        HandlingIncompleteSubgroup: '不完全サブグループ処理',
        CalculateIncompleteSubgroup: '不完全なサブグループを計算する',
        DeleteIncompleteSubgroup: '不完全なサブグループを削除する',
        AllowanceLimit: '許容限界',
        PlausibilityLimit: '妥当性限界',
        ScrapLimit: '廃棄限界',
        AcceptanceLimit: '受け入れ限界',
        AllowanceLimitLabel: '許容限界ラベル',
        PlausibilityLimitLabel: '妥当性限界ラベル',
        ScrapLimitLabel: '廃棄限界ラベル',
        AcceptanceLimitLabel: '受け入れ限界ラベル',
        UsePlausibilityFunction: '妥当性を使用する',
        UseScrapFunction: '廃棄を使用する',
        UseNaturalBoundaryFunction: '自然境界を使用する',
        UseAdjustmentTolerancesPercentage: '公差調整を使用する',
        DisplayFieldNameWithNumber: '番号付きフィールド名を表示する',
        New: '新規',
      },
      kr: {
        PrintContent: '내용출력',
        Save: '저장',
        Reset: '재설정',
        Apply: '적용',
        ClearAll: '모두 지우기',
        Clear: '지우기',
        Database: '데이터베이스',
        SearchPlaceHoler: '검색...',
        SavedSettings: '설정 저장 완료',
        FailedSettings: '설정 저장 실패',
        ResetSettings: '설정 초기화',
        DisplayType: '표시 유형',
        Main: '메인',
        Sub: '서브',
        QualityStatusColors: '품질 상태 색상',
        MonitoringType: '모니터링 타입',
        AutomaticMonitoringDisplayType: '자동 모니터링 표시 타입',
        ValueOrder: '값 순서',
        CharOrder: '특성 순서',
        Order: '순서',
        MonitoringInterval: '모니터링 간격',
        MaxDisplayCount: '최대 디스플레이 수',
        EasySettings: '간단 설정',
        Start: '시작',
        End: '종료',
        Layout: '레이아웃',
        Column: '열',
        Row: '행',
        DisplayType: '표시 유형',
        CalculationCount: '계산 카운트',
        CalculationType: '계산 유형',
        ChartSplit: '차트 분할',
        SplitItem: '분할 항목',
        Item: '항목',
        ExtraXAxisLabels: '추가 X축 레이블',
        AddedExtraLabels: '추가된 목록',
        DateTimeDisplayFormat: '날짜 형식',
        ToolTip: '툴팁(말풍선)',
        ToolTipAddedInfo: '추가 정보',
        ToolTipAddedInfoList: '정보 목록',
        ToolTipSpecLimits: '규격 한계',
        ToolTipWarnLimits: '경고 한계',
        ToolTipCustomLimits: '관리 한계',
        ARIMA: 'ARIMA',
        Auto: '자동',
        Show: '표시',
        pValue: 'p값',
        dValue: 'd값',
        qValue: 'q값',
        ForecastSize: '예측 크기',
        SampleSize: '샘플 크기',
        NGSymbol: 'NG 심볼',
        WarningSymbol: '경고 심볼',
        GoodSymbol: 'OK 심볼',
        ChartLine: '차트 라인',
        SpecificationLimit: '공차',
        WarningLimit: '경고 한계',
        ControlLimit: '관리 한계',
        CustomLimit: '커스텀 한계',
        NominalLimit: '설계치',
        AverageLimit: '평균',
        LimitationsLabelEnable: '한계 라벨 활성화',
        SpecificationLimitLabel: '공차 라벨',
        WarningLimitLabel: '경고 한계 라벨',
        ControlLimitLabel: '관리 한계 라벨',
        QuantileLimitLabel: '분위수 한계 라벨',
        CustomLimitLabel: '커스텀 한계 라벨',
        NominalLimitLabel: '설계치 한계 라벨',
        AverageLimitLabel: '평균 한계 라벨',
        MarkArea: '마크 영역',
        NG: 'NG',
        Shape: '모양',
        Size: '크기',
        Color: '색상',
        NGSymbolShape: 'NG 심볼 모양',
        NGSymbolSize: 'NG 심볼 크기',
        NGSymbolColor: 'NG 심볼 색상',
        GoodSymbolShape: 'OK 심볼 모양',
        GoodSymbolSize: 'OK 심볼 크기',
        GoodSymbolColor: 'OK 심볼 색상',
        X99865Type: 'Xᵘᵖ³ 타입',
        X99865Width: 'Xᵘᵖ³ 너비',
        X99865Color: 'Xᵘᵖ³ 색',
        X0_135Type: 'Xˡᵒ³ 타입',
        X0_135Width: 'Xˡᵒ³ 너비',
        X0_135Color: 'Xˡᵒ³ 색',
        XBarType: 'X\u0304 타입',
        XBarWidth: 'X\u0304 너비',
        XBarColor: 'X\u0304 색상',
        X50Type: 'X⁵⁰ 타입',
        X50Width: 'X⁵⁰ 너비',
        X50Color: 'X⁵⁰ 색상',
        Warn: '경고',
        Good: 'OK',
        None: '없음',
        Type: '타입',
        Width: '너비',
        ArimaChartLine: 'ARIMA 차트 라인',
        OuterColor: '외부 색상',
        InnerColor: '내부 색상',
        ValueField: '값 필드',
        YAxisType: 'Y축 타입',
        CriteriaLine: '기준선',
        Normalization: '정규화',
        NoData: '데이터 없음',
        ResultIcon: '결과 아이콘',
        OutOfTolIcon: '공차위반 아이콘',
        SecMin5: '초(최소 = 5초)',
        Count: '카운트',
        Profile: '프로필',
        Logout: '로그아웃',
        CompareType: '비교 유형',
        AdditionalDisplay: '추가표시',
        RelationLevel: '관련 레벨(ABS)',
        Strong: '상관관계 강함',
        DisplayMethod: '표시 방법',
        By: '로',
        Criteria: '기준',
        AllOf: '모두',
        UseStack: '스택 사용',
        DefectUnitBarState: '불량 단위 막대 상태',
        DefectUnitBarColor: '불량 단위 막대 색상',
        NGBar: 'NG 바',
        WarnBar: '경고 바',
        GoodBar: 'OK 바',
        CpNGBar: 'Cp NG 바',
        CpGoodBar: 'Cp OK 바',
        CpkNGBar: 'Cpk NG 바',
        CpkGoodBar: 'Cpk OK 바',
        EmptyCharacteristics: '빈 특성',
        DisplayCount: '디스플레이 수',
        AddExtraFields: '필드 추가',
        List: '리스트',
        CPGood: 'Cp 기준',
        CPKGood: 'Cpk 기준',
        Limit: '한계',
        Box: '박스',
        BorderColor: '경계색',
        Outliers: '이상치',
        RelationColorBarChart: '관계색(바 차트)',
        Others: '기타',
        RelationColorHeatMap: '관계색(히트맵)',
        HistogramBar: '히스토그램 바',
        BellCurve: '벨 커브',
        PTChart: 'PT 차트',
        CpLineForRunChart: 'Cp 선(런 차트용)',
        CpkLineForRunChart: 'Cpk 선(런 차트용)',
        NGBarForBarChart: 'NG 바(바 차트용)',
        GoodBarForBarChart: 'OK 바(바 차트용)',
        DefectUnitBar: 'NG유닛바',
        Symbols: '심볼',
        GoodColor: 'OK 색상',
        WarnColor: '경고 색상',
        NGColor: 'NG 색상',
        BadColor: '불량색상',
        NGSymbolForRunChart: 'NG심볼(런 차트용)',
        GoodSymbolForRunChart: 'OK심볼(런 차트용)',
        ShowDeletedValue: '삭제된 값 표시',
        EmptyCircle: '빈원',
        Circle: '원',
        Rect: '사각형',
        RoundRect: '둥근사각형',
        Triangle: '삼각형',
        Diamond: '다이아몬드',
        Pin: '핀',
        Arrow: '화살표',
        None: '없음',
        Solid: '직선',
        Dotted: '도트',
        Dashed: '대쉬',
        Smile: '스마일',
        Thumbs: '엄지',
        DateTimeSecItem: '날짜/시간/초',
        DateTimeItem: '날짜/시간',
        DateItem: '날짜',
        TimeSecItem: '시간/초',
        TimeItem: '시간',
        TheoreticalValue: '이론값',
        TheoreticalQuantile: '이론 분위수',
        OneMinute: '1분',
        OneHour: '1시간',
        OneDay: '1일',
        OneWeek: '1주',
        Custom: '사용자 정의',
        OneSide: '편측',
        TwoSide: '양측',
        AutoCalculation: '자동 계산',
        NormalDistribution: '정규 분포',
        LogNormalDistribution: '로그 정규 분포',
        FoldedNormalDistribution: '접힌 정규 분포',
        WeibullDistribution: '와이블 분포',
        MinMax: '최소-최대',
        MaxAbs: '최대 절대값',
        Robust: '로버스트',
        Standard: '표준',
        Fixed: '고정',
        Moving: '이동',
        ScatterSlope: '산점도+기울기',
        Slope: '기울기',
        Scatter: '산점도',
        BarChart: '바차트',
        HeatMap: '히트맵',
        RunChart: '런차트',
        Cart: 'CART',
        RandomForest: '랜덤포레스트',
        All: '모두',
        Top10: '상위10',
        Top5: '상위5',
        Top3: '상위3',
        Single: '싱글',
        Part: '부품',
        Characteristics: '특성',
        Event: '이벤트',
        EventOption: '이벤트 옵션',
        EventSymbolOption: '이벤트 옵션',
        EventSymbolOption: '이벤트 심볼 옵션',
        EventLineOption: '이벤트 라인 옵션',
        DefectUnit: '결함유닛',
        Stack: '누적',
        NotStack: '누적안함',
        TreeMap: '트리맵',
        Diagram: '다이어그램',
        ValueChart: '런차트',
        QccChart: 'Qcc차트',
        Ascending: '오름차순',
        Descending: '내림차순',
        DataRange: '데이터 범위',
        LastDays: '최근 일수',
        Last1Day: '지난 1일',
        Last1Week: '지난 1주',
        Last1Month: '지난 1달',
        DayOffset: '날짜 간격',
        DayTimeEnable: '기준 시간 활성화',
        DayTime: '주간 시간',
        IgnoreValue: '값 무시',
        Enable: '활성',
        UslPercent: 'USL 백분율',
        LslPercent: 'LSL 백분율',
        CharacteristicClass: '중요도',
        Unimportant: '중요하지 않음',
        Secondary: '덜 중요',
        Standard: '표준',
        Important: '중요',
        Critical: '가장중요',
        OrderChart: '차트 정렬',
        Field: '필드',
        Add: '추가',
        Visible: '표시',
        Bad: 'NG',
        ValueChartOption: '런 차트 옵션',
        PopupMode: '팝업 모드',
        BalloonField: '풍선 필드',
        XAxisType: 'X 축 타입',
        BarChartOption: '바 차트 옵션',
        LimitCount: '제한 수량',
        ContentOrder: '내용 정렬',
        QCCOption: 'QCC 차트 옵션',
        QCCChartOption: 'QCC 차트 옵션',
        AutoPage: '자동슬라이드',
        RestartIndex: '재시작 인덱스',
        LayoutColumn: '레이아웃 열',
        LayoutRow: '레이아웃 행',
        PageUpdateSec: '페이지 업데이트 주기',
        GraphicUpdateEnable: '데이터 업데이트 활성화',
        GraphicUpdateMin: '데이터 업데이트 주기',
        SecondsShort: '초',
        MinutesShort: '분',
        PercentageShort: '%',
        SigmaShort: 'σ',
        TopOrder: '최상위 순서',
        FontSize: '글꼴 크기',
        TopOrderColorCustomEnable: '최상위 순서 색상 사용자 정의 활성화',
        TextColor: '텍스트 색상',
        Graphics: '그래픽',
        TopTitleFontSize: '타이틀 폰트 크기',
        XAxisFontSize: 'X 축 글꼴 크기',
        YAxisFontSize: 'Y 축 글꼴 크기',
        RightLimitsFontSize: '오른쪽 한계 폰트 크기',
        SpecLimitFontSize: '규격 한계 폰트 크기',
        WarnLimitFontSize: '경고 한계 폰트 크기',
        SpecLimitFontColor: '규격 한계 폰트 색상',
        WarnLimitFontColor: '경고 한계 폰트 색상',
        UslFontColor: 'USL 글꼴 색상',
        LslFontColor: 'LSL 글꼴 색상',
        UwlFontColor: 'UWL 글꼴 색상',
        LwlFontColor: 'LWL 글꼴 색상',
        XAxisFontColor: 'X축 글꼴 색상',
        YAxisFontColor: 'Y축 글꼴 색상',
        CountFontSize: '카운트 글꼴 색상',
        AxisFontColorCustomEnable: '축 글꼴 색상 사용자 지정 활성화',
        CountFontColorCustomEnable: '카운트 글꼴 색상 사용자 지정 활성화',
        CountFontColor: '카운트 글꼴 색상',
        TreeMapChart: '트리맵차트',
        TileFontColorCustomEnable: '타일 글꼴 색상 활성화',
        TileFontColor: '타일 글꼴 색상',
        NgSizeRelative: 'NG 크기 상대비율',
        WarnSizeRelative: '경고 크기 상대비율',
        GoodSizeRelative: 'OK 크기 상대비율',
        FilterConfig: '필터 구성',
        Group: '그룹',
        Field: '필드',
        Data: '데이터',
        Logic: '로직',
        Home: '홈',
        Back: '뒤로',
        Next: '다음',
        None: '없음',
        LeftParenthesis: '왼쪽 괄호',
        RightParenthesis: '오른쪽 괄호',
        And: 'AND',
        Or: 'OR',
        Contains: '포함',
        Match: '일치',
        Equal: '같음',
        CommonOption: '공통 옵션',
        FilterList: '필터 리스트',
        LastNFilter: '최근 N 필터',
        IgnoreDuplicatedValue: '중복 값 무시',
        PageCalculationInAdvance: '사전 페이지 계산',
        Etcetera: '기타',
        DBFieldFilter: '빠른 필터',
        DBFieldAdvancedFilter: '고급 필터',
        SaveFilter: '필터 저장',
        MakeFilter: '필터 생성',
        SavedFilterList: '저장된 필터 목록',
        SelectedFilter: '선택된 필터',
        CreateFilter: '저장용 필터 생성',
        FilterName: '필터 이름',
        UseDateTime: '날짜/시간 사용',
        StartDateTime: '시작 날짜 및 시간',
        EndDateTime: '종료 날짜 및 시간',
        Arrangement: '배치',
        Sort: '정렬',
        DateTimeSort: '날짜/시간 정렬',
        Part: '부품',
        FieldID: '필드 ID',
        ConditionValue: '조건값',
        Characteristics: '특성',
        Value: '값',
        None: '없음',
        DateTime: '날짜 시간',
        PartIdent: '부품 ID',
        Order: '순서',
        Ascending: '오름차순',
        Descending: '내림차순',
        Count: '카운트',
        Hour: '시간',
        Day: '일',
        Week: '주',
        ErrorMsgInvalidDateTime: '올바른 날짜/시간을 입력해 주세요.',
        ErrorMsgEmptyPart: '부품을 선택해주세요',
        ErrorMsgEmptyChar: '특성을 선택해주세요',
        ErrorMsgNotMatchPartField: '부품 필드가 데이터베이스 필드와 일치하지 않습니다',
        ErrorMsgNotMatchCharField: '특성 필드가 데이터베이스 필드와 일치하지 않습니다',
        ErrorMsgNotMatchValueField: '값 필드가 데이터베이스 필드와 일치하지 않습니다',
        ErrorMsgEmptyPartData: '부품 데이터가 비어 있습니다',
        ErrorMsgEmptyCharData: '특성 데이터가 비어 있습니다',
        ErrorMsgEmptyValueData: '값 데이터가 비어 있습니다',
        ErrorMsgEmptyDeleteItem: '삭제할 특성을 선택해주세요',
        Importance: '중요도',
        Analyze: '분석',
        BarColor: '막대 색상',
        Charts: '차트',
        Common: '공통',
        Nominal: '설계치',
        Average: '평균',
        DenseLabel: '밀집라벨',
        LimitationsEnable: '한계 활성화',
        XAxisSettings: 'X축 설정',
        RegisteredItem: '등록된 항목',
        DateTimeDisplayType: '날짜/시간 표시 형식',
        SelectableItems: '선택 가능한 항목',
        OutOfControl: '관리 한계 초과',
        OutOfControlForQCC: '관리 한계 초과 (QCC용)',
        QuantileLimit: '사분위수 한계',
        Refresh: '새로고침',
        Load: '불러오기',
        CurrentTemplate: '현재 템플릿',
        SwitchFilterMode: '필터 모드 전환',
        TakeoverFromDataset: '데이터셋에서 가져요기',
        DefaultSubgroupCount: '기본 하위그룹 개수',
        DefaultSubgroupType: '기본 하위 그룹 유형',
        QccLocationChartType: 'Qcc 위치 차트 유형 (xBar)',
        Raw: '원시 데이터',
        HandlingIncompleteSubgroup: '불완전 하위 그룹 처리 방법 ',
        CalculateIncompleteSubgroup: '불완전한 하위 그룹 계산',
        DeleteIncompleteSubgroup: '불완전한 하위 그룹 삭제',
        AllowanceLimit: '허용 한계',
        PlausibilityLimit: '타당성 한계',
        ScrapLimit: '폐기 한계',
        AcceptanceLimit: '수락 한계',
        AllowanceLimitLabel: '허용 한계 라벨',
        PlausibilityLimitLabel: '타당성 한계 라벨',
        ScrapLimitLabel: '폐기 한계 라벨',
        AcceptanceLimitLabel: '수락 한계 라벨',
        UsePlausibilityFunction: '타당성 사용',
        UseScrapFunction: '폐기 사용',
        UseNaturalBoundaryFunction: '자연 경계 사용',
        UseAdjustmentTolerancesPercentage: '일반경계 조정기능 사용',
        DisplayFieldNameWithNumber: '숫자와 함께 필드 이름 표시',
        New: '새로 만들기',
      },
    },
  },
  page: {
    overview: {
      data: {
        en: {
          PartOverview: 'Part Overview',
          OverviewByPart: 'Overview(Part)',
          OverviewBy: 'Overview',
          SelectedPart: 'Selected Part',
          NoneSelectedPart: 'No Selected Part',
          Characteristics: 'Characteristics',
          OperationName: 'Operation Number',
          Good: 'OK',
          Warning: 'Warning',
          NG: 'NG',
          None: 'None',
        },
        ja: {
          PartOverview: '部品概要',
          OverviewByPart: '概要(部品)',
          OverviewBy: '概要',
          SelectedPart: '選択した部品',
          NoneSelectedPart: '選択部品なし',
          Characteristics: '特性',
          OperationName: 'オペレーション名',
          Good: 'OK',
          Warning: '警告',
          NG: '公差外',
          None: 'なし',
        },
        kr: {
          PartOverview: '부품 개요',
          OverviewByPart: '개요(부품)',
          OverviewBy: '개요',
          SelectedPart: '선택된 부품',
          NoneSelectedPart: '선택된 부품 없음',
          Characteristics: '특성',
          OperationName: '공정번호',
          Good: 'OK',
          Warning: '경고',
          NG: 'NG',
          None: '없음',
        },
      },
    },
    monitoring: {
      data: {
        en: {
          MonitoringStart: 'Monitoring Start',
          MonitoringStop: 'Monitoring Stop',
          Operating: 'Operating',
          Stopped: 'Stopped',
          NoData: 'No data',
          EmptyPanelText: 'No selection',
          Part: 'Part',
          Characteristics: 'Characteristics',
          Data: 'Data',
          TimeDate: 'Time/Date',
          PartIdent: 'Part Ident',
          OrderNumber: 'Serial Number',
          Total: 'Total',
          Good: 'OK',
          Warn: 'Warn',
          NG: 'NG',
          ColumnChooserTitle: 'Column Chooser',
          ColumnChooserEmptyPanelText: 'No selection',
          ColumnChooserPlaceHoler: 'Search column',
          MonitoringNoDataMsg: 'There is no new data.',
          ErrorMsgRequireStopMonitoring: 'To show the Detail information, Please Stop monitoring',
          ValueInfo: 'Values',
        },
        ja: {
          MonitoringStart: '監視開始',
          MonitoringStop: '監視停止',
          Operating: '運転中',
          Stopped: '停止中',
          NoData: 'データなし',
          EmptyPanelText: '選択なし',
          Part: '部品',
          Characteristics: '特性',
          Data: 'データ',
          TimeDate: '時刻/日付',
          PartIdent: '部品識別番号',
          OrderNumber: 'ロット番号',
          Total: '合計',
          Good: 'OK',
          Warn: '警告',
          NG: 'NG',
          ColumnChooserTitle: '列選択',
          ColumnChooserEmptyPanelText: '選択なし',
          ColumnChooserPlaceHoler: '列を検索',
          MonitoringNoDataMsg: '新しいデータはありません',
          ErrorMsgRequireStopMonitoring: '詳細情報を表示するために、監視を停止してください',
          ValueInfo: '数値',
        },
        kr: {
          MonitoringStart: '모니터링 시작',
          MonitoringStop: '모니터링 중지',
          Operating: '실행중',
          Stopped: '중지됨',
          NoData: '데이터 없음',
          EmptyPanelText: '선택 없음',
          Part: '부품',
          Characteristics: '특성',
          Data: '데이터',
          TimeDate: '시간/날짜',
          PartIdent: '부품 식별번호',
          OrderNumber: 'Serial Number',
          Total: '합계',
          Good: 'OK',
          Warn: '경고',
          NG: 'NG',
          ColumnChooserTitle: '컬럼 선택',
          ColumnChooserEmptyPanelText: '선택 없음',
          ColumnChooserPlaceHoler: '컬럼 찾기',
          MonitoringNoDataMsg: '새 데이터가 없습니다. ',
          ErrorMsgRequireStopMonitoring: '세부 정보를 보기 위해, 모니터링을 중지해주세요',
          ValueInfo: '값',
        },
      },
    },
    database: {
      data: {
        en: {
          PartList: 'Part List',
          NoData: 'No data',
          DeleteSelected: 'Delete Selected',
          ConfirmMessage: 'Are you sure to do this function?',
          PartColumnChooserTitle: 'Part Column Chooser',
          ColumnChooserEmptyPanelText: 'No selection',
          ColumnChooserPlaceHoler: 'Search column',
          SearchPlaceHoler: 'Search...',
          EmptyPanelText: 'Drag a column header here to group',
          CharList: 'Char List',
          CharColumnChooserTitle: 'Characteristics Column Chooser',
          FilterList: 'Filter List',
          SavedSettings: 'Saved Settings',
          ResetSettings: 'Reset Settings',
          UseEasyLoad: 'Last N Filter',
          IgnoreDuplicatedValue: 'Ignore Duplicated Value',
          UseDateTime: 'Use Date/Time',
          StartDateTime: 'Start Date/Time',
          EndDateTime: 'End Date/Time',
          Arrangement: 'Arrangement',
          Sort: 'Sort',
          PageCalculationInAdvance: 'Page Calculation in advance',
          Part: 'Part',
          FieldID: 'Field ID',
          ConditionValue: 'Condition Value',
          Characteristics: 'Characteristics',
          Value: 'Value',
          None: 'None',
          DateTime: 'DateTime',
          PartIdent: 'PartIdent',
          Order: 'Order',
          Ascending: 'Ascending',
          Descending: 'Descending',
          Count: 'Count',
          Hour: 'Hour',
          Day: 'Day',
          Week: 'Week',
          ErrorMsgInvalidDateTime: 'Please input correct Date/Time',
          ErrorMsgEmptyPart: 'Please select part',
          ErrorMsgEmptyChar: 'Please select characteristics',
          ErrorMsgNotMatchPartField: 'Part Field is not match with DB Field',
          ErrorMsgNotMatchCharField: 'Char Field is not match with DB Field',
          ErrorMsgNotMatchValueField: 'Value Field is not match with DB Field',
          ErrorMsgEmptyPartData: 'Part Data is empty',
          ErrorMsgEmptyCharData: 'Characteristics Data is empty',
          ErrorMsgEmptyValueData: 'Value Data is empty',
          ErrorMsgEmptyDeleteItem: 'Please choose the characteristics you wish to delete.',
          SaveLayout: 'Save Layout',
          ClearLayout: 'Clear Layout',
        },
        ja: {
          PartList: '部品リスト',
          NoData: 'データなし',
          DeleteSelected: '選択を削除',
          ConfirmMessage: 'この操作を実行してもよろしいですか？',
          PartColumnChooserTitle: '部品列の選択',
          ColumnChooserEmptyPanelText: '選択なし',
          ColumnChooserPlaceHoler: '列を検索',
          SearchPlaceHoler: '検索...',
          EmptyPanelText: 'ヘッダーをドラッグしてグループ化',
          CharList: '特性リスト',
          CharColumnChooserTitle: '特性列の選択',
          FilterList: 'フィルタリスト',
          SavedSettings: '設定保存成功',
          ResetSettings: '設定リセット',
          UseEasyLoad: '直近のNフィルタ',
          IgnoreDuplicatedValue: '重複値を無視',
          UseDateTime: '日付/時刻を使用',
          StartDateTime: '開始日時',
          EndDateTime: '終了日時',
          Arrangement: '配置',
          Sort: 'ソート',
          PageCalculationInAdvance: '事前ページ計算',
          Part: '部品',
          FieldID: 'フィールドID',
          ConditionValue: '条件値',
          Characteristics: '特性',
          Value: '数値',
          None: 'なし',
          DateTime: '日付時刻',
          PartIdent: '部品識別番号',
          Order: 'ロット',
          Ascending: '昇順',
          Descending: '降順',
          Count: '個',
          Hour: '時',
          Day: '日',
          Week: '週',
          ErrorMsgInvalidDateTime: '正しい日付/時刻を入力してください',
          ErrorMsgEmptyPart: '部品を選択してください',
          ErrorMsgEmptyChar: '特性を選択してください',
          ErrorMsgNotMatchPartField: '部品フィールドがデータベースフィールドと一致しません',
          ErrorMsgNotMatchCharField: '特性フィールドがデータベースフィールドと一致しません',
          ErrorMsgNotMatchValueField: '数値フィールドがデータベースフィールドと一致しません',
          ErrorMsgEmptyPartData: '部品データが空です',
          ErrorMsgEmptyCharData: '特性データが空です',
          ErrorMsgEmptyValueData: '数値データが空です',
          ErrorMsgEmptyDeleteItem: '削除する特性を選択してください',
          SaveLayout: 'レイアウトを保存',
          ClearLayout: 'レイアウトをクリア',
        },
        kr: {
          PartList: '부품 리스트',
          NoData: '데이터 없음',
          DeleteSelected: '선택한 항목 삭제',
          ConfirmMessage: '이 작업을 수행하시겠습니까? ',
          PartColumnChooserTitle: '부품 컬럼 선택',
          ColumnChooserEmptyPanelText: '선택 없음',
          ColumnChooserPlaceHoler: '컬럼 찾기',
          SearchPlaceHoler: '검색...',
          EmptyPanelText: '그룹화를 위해 컬럼 헤더를 여기로 드래그하세요.',
          CharList: '특성 리스트',
          CharColumnChooserTitle: '특성 컬럼 선택',
          FilterList: '필터 리스트',
          SavedSettings: '설정 저장 완료',
          ResetSettings: '설정 초기화',
          UseEasyLoad: '최근 N 필터 사용',
          IgnoreDuplicatedValue: '중복 값 무시',
          UseDateTime: '날짜/시간 사용',
          StartDateTime: '시작 날짜 및 시간',
          EndDateTime: '종료 날짜 및 시간',
          Arrangement: '배치',
          Sort: '정렬',

          PageCalculationInAdvance: '사전 페이지 계산',
          Part: '부품',
          FieldID: '필드 ID',
          ConditionValue: '조건값',
          Characteristics: '특성',
          Value: '값',
          None: '없음',
          DateTime: '날짜 시간',
          PartIdent: '부품 식별번호',
          Order: '시리얼번호',
          Ascending: '오름차순',
          Descending: '내림차순',
          Count: '카운트',
          Hour: '시간',
          Day: '일',
          Week: '주',
          ErrorMsgInvalidDateTime: '올바른 날짜/시간을 입력해 주세요.',
          ErrorMsgEmptyPart: '부품을 선택해주세요',
          ErrorMsgEmptyChar: '특성을 선택해주세요',
          ErrorMsgNotMatchPartField: '부품 필드가 데이터베이스 필드와 일치하지 않습니다',
          ErrorMsgNotMatchCharField: '특성 필드가 데이터베이스 필드와 일치하지 않습니다',
          ErrorMsgNotMatchValueField: '값 필드가 데이터베이스 필드와 일치하지 않습니다',
          ErrorMsgEmptyPartData: '부품 데이터가 비어 있습니다',
          ErrorMsgEmptyCharData: '특성 데이터가 비어 있습니다',
          ErrorMsgEmptyValueData: '값 데이터가 비어 있습니다',
          ErrorMsgEmptyDeleteItem: '삭제할 특성을 선택해주세요',
          String: '문자열정렬',
          Numeric: '숫자정렬',
          SaveLayout: '레이아웃 저장',
          ClearLayout: '레이아웃 초기화',
        },
      },
    },
    valueChart: {
      data: {
        en: {
          DataIsNotSelected: 'Data is not Selected',
          DataIsNotEnough: 'Data is not enough',
          Value: 'Value',
          Index: 'Index',
          Limits: 'Limits',
          ValueInfo: 'Values',
          Prediction: 'Prediction',
          LowPred: 'Low_Pred',
          Pred: 'Pred',
          UpperPred: 'Upper_Pred',
          Nominal: 'Nominal',
          Average: 'Average',
        },
        ja: {
          DataIsNotSelected: 'データが選択されていません',
          DataIsNotEnough: 'データが不十分です',
          Value: 'データ',
          Index: 'インデックス',
          Limits: '限界',
          ValueInfo: '数値',
          Prediction: '予測',
          LowPred: '下限予測',
          Pred: '予測値',
          UpperPred: '上限予測',
          Nominal: '公称値',
          Average: '平均',
        },
        kr: {
          DataIsNotSelected: '데이터가 선택되지 않았습니다',
          DataIsNotEnough: '데이터가 부족합니다',
          Value: '값',
          Index: '인덱스',
          Limits: '한계',
          ValueInfo: '값',
          Prediction: '예측',
          LowPred: '하한 예측값',
          Pred: '예측값',
          UpperPred: '상한 예측값',
          Nominal: '설계치',
          Average: '평균',
        },
      },
    },
    histogramChart: {
      data: {
        en: {
          DataIsNotSelected: 'Data is not Selected',
          DataIsNotEnough: 'Data is not enough',
          Deviation: 'Deviation',
          Range: 'Range',
          From: 'From',
          To: 'To',
          Count: 'Count',
          Limits: 'Limits',
          Processing: 'Processing...',
        },
        ja: {
          DataIsNotSelected: 'データが選択されていません',
          DataIsNotEnough: 'データが不十分です',
          Deviation: '偏差',
          Range: '範囲',
          From: 'から',
          To: 'まで',
          Count: 'カウント',
          Limits: '限界',
          Processing: '処理中...',
        },
        kr: {
          DataIsNotSelected: '데이터가 선택되지 않았습니다',
          DataIsNotEnough: '데이터가 부족합니다',
          Deviation: '편차',
          Range: '범위',
          From: '부터',
          To: '까지',
          Count: '카운트',
          Limits: '한계',
          Processing: '처리중...',
        },
      },
    },
    positionalTolerancesChart: {
      data: {
        en: {
          DataIsNotSelected: 'Data is not Selected',
          GoodValueInfo: 'OK Values',
          NGValueInfo: 'NG Values',
          Value: 'Value',
          Limits: 'Limits',
          Number: 'Number',
        },
        ja: {
          DataIsNotSelected: 'データが選択されていません',
          GoodValueInfo: '公差内',
          NGValueInfo: 'NG値',
          Value: '値',
          Limits: '限界',
          Number: '番号',
        },
        kr: {
          DataIsNotSelected: '데이터가 선택되지 않았습니다',
          GoodValueInfo: 'OK 값',
          NGValueInfo: 'NG 값',
          Value: '값',
          Limits: '한계',
          Number: '번호',
        },
      },
    },
    boxPlotChart: {
      data: {
        en: {
          DataIsNotSelected: 'Data is not Selected',
          BoxPlot: 'Box Plot',
          Outlier: 'Outlier',
          Min: 'Min',
          Median: 'Median',
          Max: 'Max',
          Value: 'Value',
        },
        ja: {
          DataIsNotSelected: 'データが選択されていません',
          BoxPlot: 'ボックス',
          Outlier: '外れ値',
          Min: '最小',
          Median: '中央値',
          Max: '最大',
          Value: '値',
        },
        kr: {
          DataIsNotSelected: '데이터가 선택되지 않았습니다',
          BoxPlot: '박스플롯',
          Outlier: '이상치',
          Min: '최소',
          Median: '중앙값',
          Max: '최대',
          Value: '값',
        },
      },
    },
    linearRegressionChart: {
      data: {
        en: {
          DataIsNotSelected: 'Data is not Selected',
          DataIsNotEnough: 'Data is not enough',
          XData: 'X Data',
          YData: 'Y Data',
          YSlope: 'Y Slope',
          YIntercept: 'Y Intercept',
          R2Score: 'R² Score',
          TargetCharacteristics: 'Target Characteristics',
        },
        ja: {
          DataIsNotSelected: 'データが選択されていません',
          DataIsNotEnough: 'データが不十分です',
          XData: 'Xデータ',
          YData: 'Yデータ',
          YSlope: 'Yスロープ',
          YIntercept: 'Yの切片',
          R2Score: 'R²スコア',
          TargetCharacteristics: '目標特性',
        },
        kr: {
          DataIsNotSelected: '데이터가 선택되지 않았습니다',
          DataIsNotEnough: '데이터가 부족합니다',
          XData: 'X 데이터',
          YData: 'Y 데이터',
          YSlope: 'Y 기울기',
          YIntercept: 'Y 절편',
          R2Score: 'R² 점수',
          TargetCharacteristics: '목표 특성',
        },
      },
    },
    correlationChart: {
      data: {
        en: {
          DataIsNotSelected: 'Data is not Selected',
          DataIsNotEnough: 'Data is not enough',
          ChartNumber: 'Chart Number',
          ChartName: 'Chart Name',
          CorrelationData: 'Correlation Data',
          TargetCharacteristics: 'Target Characteristics',
          CorrelationValue: 'Correlation Value',
        },
        ja: {
          DataIsNotSelected: 'データが選択されていません',
          DataIsNotEnough: 'データが不十分です',
          ChartNumber: 'チャート番号',
          ChartName: 'チャート名',
          CorrelationData: '相関データ',
          TargetCharacteristics: '目標特性',
          CorrelationValue: '相関値',
        },
        kr: {
          DataIsNotSelected: '데이터가 선택되지 않았습니다',
          DataIsNotEnough: '데이터가 부족합니다',
          ChartNumber: '차트 번호',
          ChartName: '차트 이름',
          CorrelationData: '상관관계 데이터',
          TargetCharacteristics: '목표 특성',
          CorrelationValue: '상관값',
        },
      },
    },
    qualityControlChart: {
      data: {
        en: {
          DataIsNotSelected: 'Data is not Selected',
          DataIsNotEnough: 'Data is not enough',
          Range: 'Range',
          Number: 'Number',
          Value: 'Value',
          Limits: 'Limits',
        },
        ja: {
          DataIsNotSelected: 'データが選択されていません',
          DataIsNotEnough: 'データが不十分です',
          Range: '範囲',
          Number: '番号',
          Value: '値',
          Limits: '限界',
        },
        kr: {
          DataIsNotSelected: '데이터가 선택되지 않았습니다',
          DataIsNotEnough: '데이터가 부족합니다',
          Range: '범위',
          Number: '번호',
          Value: '값',
          Limits: '한계',
        },
      },
    },
    capabilitySummaryChart: {
      data: {
        en: {
          DataIsNotSelected: 'Data is not Selected',
          Criteria: 'Criteria',
          CapabilityCp: 'Capability Cp',
          CapabilityCpk: 'Capability Cpk',
        },
        ja: {
          DataIsNotSelected: 'データが選択されていません',
          Criteria: '基準',
          CapabilityCp: 'Cp',
          CapabilityCpk: 'Cpk',
        },
        kr: {
          DataIsNotSelected: '데이터가 선택되지 않았습니다',
          Criteria: '기준',
          CapabilityCp: '공정능력 Cp',
          CapabilityCpk: '공정능력 Cpk',
        },
      },
    },
    paretoChart: {
      data: {
        en: {
          DataIsNotSelected: 'Data is not Selected',
          GoodValueInfo: 'OK',
          WarnValueInfo: 'Warn',
          NGValueInfo: 'NG',
          Count: 'Count',
        },
        ja: {
          DataIsNotSelected: 'データが選択されていません',
          GoodValueInfo: 'OK',
          WarnValueInfo: '警告',
          NGValueInfo: 'NG',
          Count: 'カウント',
        },
        kr: {
          DataIsNotSelected: '데이터가 선택되지 않았습니다',
          GoodValueInfo: 'OK',
          WarnValueInfo: '경고',
          NGValueInfo: 'NG',
          Count: '카운트',
        },
      },
    },
    probabilityPlotChart: {
      data: {
        en: {
          DataIsNotSelected: 'Data is not Selected',
          DataIsNotEnough: 'Data is not enough',
          Deviation: 'Deviation',
          ValueInfo: 'Values',
          Limits: 'Limits',
          Range: 'Range',
          From: 'From',
          To: 'To',
          Count: 'Count',
          Processing: 'Processing...',
        },
        ja: {
          DataIsNotSelected: 'データが選択されていません',
          DataIsNotEnough: 'データが不十分です',
          Deviation: '偏差',
          ValueInfo: '値',
          Limits: '限界',
          Range: '範囲',
          From: 'から',
          To: 'まで',
          Count: 'カウント',
          Processing: '処理中...',
        },
        kr: {
          DataIsNotSelected: '데이터가 선택되지 않았습니다',
          DataIsNotEnough: '데이터가 부족합니다',
          Deviation: '편차',
          ValueInfo: '값',
          Limits: '한계',
          Range: '범위',
          From: '부터',
          To: '까지',
          Count: '카운트',
          Processing: '처리중...',
        },
      },
    },
    decisionTreeChart: {
      data: {
        en: {
          Nominal: 'Nominal',
          LSL: 'LSL',
          USL: 'USL',
          FitContent: 'Fit Content',
          Redraw: 'Redraw',
          TargetInformation: 'Target Information',
          CartImportance: 'Cart Importance (%)',
          RandomForestImportance: 'Random Forest Importance (%)',
          Proceed: 'Proceed',
          Min: 'Min',
          Max: 'Max',
          Average: 'Average',
          Result: 'Result',
          Name: 'Name',
          Min: 'Min',
          Max: 'Max',
          Cart: 'Cart',
          ErrorMsgInvalidTarget: 'Please select valid Target',
          ErrorMsgInvalidCharSelection: 'Please select one or more characteristics',
          ErrorMsgInvalidNominalValue: 'Nominal Value is invalid',
          ErrorMsgRackOfChar: 'Please select one or more characteristics',
          ErrorMsgInvalidTarget: 'Target data is not match',
          ErrorMsgChooseTarget: 'Please select valid Target',
          Importance: 'Importance',
          Processing: 'Processing...',
        },
        ja: {
          Nominal: '公称値',
          LSL: 'LSL',
          USL: 'USL',
          FitContent: '内容に合わせる',
          Redraw: '再描画',
          TargetInformation: '目標情報',
          CartImportance: 'Cartの重要度 (%)',
          RandomForestImportance: 'ランダムフォレストの重要度 (%)',
          Proceed: '進む',
          Min: '最小',
          Max: '最大',
          Average: '平均',
          Result: '結果',
          Name: '名前',
          Min: '最小',
          Max: '最大',
          Cart: 'Cart',
          ErrorMsgInvalidTarget: 'ターゲットデータが一致しません',
          ErrorMsgInvalidCharSelection: '一つ以上の特性を選択してください',
          ErrorMsgInvalidNominalValue: '公称値が無効です',
          ErrorMsgRackOfChar: '一つ以上の特性を選択してください',
          ErrorMsgInvalidTarget: 'ターゲットデータが一致しません',
          ErrorMsgChooseTarget: '有効なターゲットを選択してください',
          Importance: '重要度',
          Processing: '処理中...',
        },
        kr: {
          Nominal: '설계치',
          LSL: 'LSL',
          USL: 'USL',
          FitContent: '내용에 맞추기',
          Redraw: '다시 그리기',
          TargetInformation: '목표 특성 정보',
          CartImportance: 'CART 중요도(%)',
          RandomForestImportance: '랜덤 포레스트 중요도 (%)',
          Proceed: '분석시작',
          Min: '최소',
          Max: '최대',
          Average: '평균',
          Result: '결과',
          Name: '이름',
          Min: '최소',
          Max: '최대',
          Cart: 'CART',
          ErrorMsgInvalidTarget: '유효한 대상을 선택해주세요',
          ErrorMsgInvalidCharSelection: '하나 이상의 특성을 선택해주세요',
          ErrorMsgInvalidNominalValue: '기준 값이 유효하지 않습니다',
          ErrorMsgRackOfChar: '하나 이상의 특성을 선택해주세요',
          ErrorMsgInvalidTarget: '대상 데이터가 일치하지 않습니다',
          ErrorMsgChooseTarget: '유효한 대상을 선택해주세요',
          Importance: '중요도',
          Processing: '처리중...',
        },
      },
    },
    valueList: {
      data: {
        en: {
          SearchPlaceHoler: 'Search...',
          ColumnChooserTitle: 'Column Chooser',
          ColumnChooserEmptyPanelText: 'No selection',
          ColumnChooserPlaceHoler: 'Search column',
          NoData: 'No data',
          Arrangement: 'Arrangement',
          Value: 'Value',
          DataIsNotSelected: 'Data is not Selected',
          ID: 'ID',
          ExportToExcel: 'Export To Excel',
          ExportToPDF: 'Export To PDF',
          SaveLayout: 'Save Layout',
          ClearLayout: 'Clear Layout',
          DeleteCellFromDB: 'Delete Cell from DB',
          DeleteRowFromDB: 'Delete Row from DB',
        },
        ja: {
          SearchPlaceHoler: '検索...',
          ColumnChooserTitle: '列選択',
          ColumnChooserEmptyPanelText: '選択なし',
          ColumnChooserPlaceHoler: '列を検索',
          NoData: 'データなし',
          Arrangement: '配置',
          DataIsNotSelected: 'データが選択されていません',
          ID: 'ID',
          Value: '値',
          ExportToExcel: 'Excel にエクスポート',
          ExportToPDF: 'PDF にエクスポート',
          SaveLayout: 'レイアウトを保存',
          ClearLayout: 'レイアウトをクリア',
          DeleteCellFromDB: 'DBからセルを削除',
          DeleteRowFromDB: 'DBから行を削除',
        },
        kr: {
          SearchPlaceHoler: '검색...',
          ColumnChooserTitle: '컬럼 선택',
          ColumnChooserEmptyPanelText: '선택 없음',
          ColumnChooserPlaceHoler: '컬럼 찾기',
          NoData: '데이터 없음',
          Arrangement: '배치',
          DataIsNotSelected: '데이터가 선택되지 않았습니다',
          ID: 'ID',
          Value: '값',
          ExportToExcel: 'Excel로 내보내기',
          ExportToPDF: 'PDF로 내보내기',
          SaveLayout: '레이아웃 저장',
          ClearLayout: '레이아웃 초기화',
          DeleteCellFromDB: 'DB에서 셀 삭제',
          DeleteRowFromDB: 'DB에서 행 삭제',
        },
      },
    },
    summaryList: {
      data: {
        en: {
          NoData: 'No data',
          DataIsNotSelected: 'Data is not Selected',
          ColumnChooserTitle: 'Column Chooser',
          ColumnChooserEmptyPanelText: 'No selection',
          ColumnChooserPlaceHoler: 'Search column',
          Result: 'Result',
          EmptyPanelText: 'Drag a column header here to group',
          Description: 'Description',
          Average: 'Average',
          Median: 'Median',
          StandardDeviation: 'Standard Deviation',
          Minimum: 'Minimum',
          Maximum: 'Maximum',
          Cp: 'Cp',
          Cpk: 'Cpk',
          OutOfTol: 'Out Of Tol',
          Trend: 'Trend',
          Nominal: 'Nominal',
          UpperAllowance: 'Upper Allowance',
          LowerAllowance: 'Lower Allowance',
          SaveLayout: 'Save Layout',
          ClearLayout: 'Clear Layout',
          LSL: 'LSL',
          USL: 'USL',
        },
        ja: {
          NoData: 'データなし',
          DataIsNotSelected: 'データが選択されていません',
          ColumnChooserTitle: '列選択',
          ColumnChooserEmptyPanelText: '選択なし',
          ColumnChooserPlaceHoler: '列を検索',
          Result: '結果',
          EmptyPanelText: 'ヘッダーをドラッグしてグループ化',
          Description: '説明',
          Average: '平均',
          Median: '中央値',
          StandardDeviation: '標準偏差',
          Minimum: '最小',
          Maximum: '最大',
          Cp: 'Cp',
          Cpk: 'Cpk',
          OutOfTol: '公差範囲外',
          Trend: 'トレンド',
          Nominal: '名目値',
          UpperAllowance: '上限許容差',
          LowerAllowance: '下限許容差',
          SaveLayout: 'レイアウトを保存',
          ClearLayout: 'レイアウトをクリア',
          LSL: 'LSL',
          USL: 'USL',
        },
        kr: {
          NoData: '데이터 없음',
          DataIsNotSelected: '데이터가 선택되지 않았습니다',
          ColumnChooserTitle: '컬럼 선택',
          ColumnChooserEmptyPanelText: '선택 없음',
          ColumnChooserPlaceHoler: '컬럼 찾기',
          Result: '결과',
          EmptyPanelText: '컬럼 헤더를 여기로 드래그하여 그룹화',
          Description: '특성이름',
          Average: '평균',
          Median: '중앙값',
          StandardDeviation: '표준편차',
          Minimum: '최소',
          Maximum: '최대',
          Cp: 'Cp',
          Cpk: 'Cpk',
          OutOfTol: '공차위반',
          Trend: '추세',
          Nominal: '기준값',
          UpperAllowance: '상한 허용오차',
          LowerAllowance: '하한 허용오차',
          SaveLayout: '레이아웃 저장',
          ClearLayout: '레이아웃 초기화',
          LSL: 'LSL',
          USL: 'USL',
        },
      },
    },
    valueSummaryList: {
      data: {
        en: {
          NoData: 'No data',
          Value: 'Value',
          DataIsNotSelected: 'Data is not Selected',
          ID: 'ID',
          Description: 'Description',
          LSL: 'LSL',
          USL: 'USL',
          Nominal: 'Nominal',
          Result: 'Result',
          PartName: 'Part Name',
          MeasurementTime: 'MeasurementTime',
          Deviation: 'Deviation',
          EmptyPanelText: 'Drag a column header here to group',
          ColumnChooserTitle: 'Column Chooser',
          ColumnChooserEmptyPanelText: 'No selection',
          ColumnChooserPlaceHoler: 'Search column',
          SaveLayout: 'Save Layout',
          ClearLayout: 'Clear Layout',
        },
        ja: {
          NoData: 'データなし',
          Value: '値',
          DataIsNotSelected: 'データが選択されていません',
          ID: 'ID',
          Description: '説明',
          LSL: 'LSL',
          USL: 'USL',
          Nominal: '公称値',
          Result: '結果',
          PartName: '部品名',
          MeasurementTime: '測定時刻',
          Deviation: '偏差',
          EmptyPanelText: 'ヘッダーをドラッグしてグループ化',
          ColumnChooserTitle: '列選択',
          ColumnChooserEmptyPanelText: '選択なし',
          ColumnChooserPlaceHoler: '列を検索',
          SaveLayout: 'レイアウトを保存',
          ClearLayout: 'レイアウトをクリア',
        },
        kr: {
          NoData: '데이터 없음',
          Value: '값',
          DataIsNotSelected: '데이터가 선택되지 않았습니다',
          ID: 'ID',
          Description: '특성이름',
          LSL: 'LSL',
          USL: 'USL',
          Nominal: '설계치',
          Result: '결과',
          PartName: '품명',
          MeasurementTime: '측정 시간',
          Deviation: '편차',
          EmptyPanelText: '컬럼 헤더를 여기로 드래그하여 그룹화',
          ColumnChooserTitle: '열 선택',
          ColumnChooserEmptyPanelText: '선택 없음',
          ColumnChooserPlaceHoler: '열 찾기',
          SaveLayout: '레이아웃 저장',
          ClearLayout: '레이아웃 초기화',
        },
      },
    },
    scrollBoard: {
      data: {
        en: {
          ValueChart: 'Value Chart',
          ValueList: 'Value List',
          QualityControlChart: 'QualityControl Chart',
          ProcessCapability: 'Process Capability',
          ProcessCapabilityIndex: 'Process Capability Index',
          OtherInformation: 'Other Information',
          Cp: 'Cp',
          Cpk: 'Cpk',
          StdDev: 'Standard Deviation',
          DateTime: 'DateTime',
          SampleCount: 'Sample Count',
          Usl: 'USL',
          Lsl: 'LSL',
          Average: 'Average',
          Median: 'Median',
          Min: 'Min',
          Max: 'Max',
          CurrentTime: 'Current Time : ',
          UpdatedTime: 'Updated Time : ',
          DataPeriod: 'Data Period : ',
          UpdatedCount: 'Updated Count : ',
          Manual: 'Manual',
          AutoSlide: 'Auto Slide',
          Good: 'OK',
          Warn: 'Warn',
          Ng: 'Ng',
          Empty: 'Empty',
          Home: 'Home',
          Back: 'Back',
          Next: 'Next',
          SaveLayout: 'Save Layout',
          ClearLayout: 'Clear Layout',
          ManualMode: 'Manual Mode',
          PresentationMode: 'Presentation Mode',
          Operating: 'Operating',
          Stopped: 'Stopped',
          Index: 'Index',
          Limits: 'Limits',
          Nominal: 'Nominal',
          Average: 'Average',
          ValueInfo: 'Values',
          EventConfiguration: 'Event Configuration',
          Save: 'Save',
          Cancel: 'Cancel',
          Remove: 'Remove',
          ConfirmedValue: 'Confirmed Value',
          InputDirectlyAndPressEnter: 'Input directly and Press Enter',
          SelectFromCatalogEvent: 'Select from catalog (Event)',
          YourTagsWillAppearHere: 'Your tags will appear here',
        },
        ja: {
          ValueChart: 'ライングラフ',
          ValueList: '値リスト',
          QualityControlChart: '品質管理図',
          ProcessCapability: 'プロセス能力',
          ProcessCapabilityIndex: '工程能力指数',
          OtherInformation: 'その他の情報',
          Cp: 'Cp',
          Cpk: 'Cpk',
          StdDev: '標準偏差',
          DateTime: '日付と時刻',
          SampleCount: 'サンプル数',
          Usl: 'USL',
          Lsl: 'LSL',
          Average: '平均',
          Median: '中央値',
          Min: '最小',
          Max: '最大',
          CurrentTime: '現在時刻 : ',
          UpdatedTime: '更新時刻 : ',
          DataPeriod: 'データ範囲 : ',
          UpdatedCount: '更新数 : ',
          Manual: '手動',
          AutoSlide: 'スライドショー',
          Good: 'OK',
          Warn: '警告',
          Ng: 'NG',
          Empty: 'なし',
          Home: 'ホーム',
          Back: '戻る',
          Next: '次に',
          SaveLayout: 'レイアウトを保存',
          ClearLayout: 'レイアウトをクリア',
          ManualMode: 'マニュアルモード',
          PresentationMode: 'プレゼンテーションモード',
          Operating: '運転中',
          Stopped: '停止',
          Index: 'インデックス',
          Limits: '限界',
          Nominal: '公称値',
          Average: '平均',
          ValueInfo: '数値',
          EventConfiguration: 'イベント構成',
          Save: '保存',
          Cancel: 'キャンセル',
          Remove: '削除',
          ConfirmedValue: '確定した値',
          InputDirectlyAndPressEnter: '値を直接入力して、Enterキーを押してください',
          SelectFromCatalogEvent: 'カタログから選択 (イベント)',
          YourTagsWillAppearHere: 'タグはここに表示されます',
        },
        kr: {
          ValueChart: '런차트',
          ValueList: '값 리스트',
          QualityControlChart: '관리도',
          ProcessCapability: '공정능력',
          ProcessCapabilityIndex: '공정능력지수',
          OtherInformation: '기타 정보',
          Cp: 'Cp',
          Cpk: 'Cpk',
          StdDev: '표준편차',
          DateTime: '날짜 및 시간',
          SampleCount: '샘플 수량',
          Usl: 'USL',
          Lsl: 'LSL',
          Average: '평균',
          Median: '중앙값',
          Min: '최소',
          Max: '최대',
          CurrentTime: '현재시간 :',
          UpdatedTime: '업데이트시간 : ',
          DataPeriod: '데이터범위 : ',
          UpdatedCount: '업데이트개수 : ',
          Manual: '수동화면',
          AutoSlide: '자동슬라이드',
          Good: 'OK',
          Warn: '경고',
          Ng: 'NG',
          Empty: '없음',
          Home: '홈',
          Back: '뒤로',
          Next: '다음',
          SaveLayout: '레이아웃 저장',
          ClearLayout: '레이아웃 초기화',
          ManualMode: '수동 모드',
          PresentationMode: '프레젠테이션 모드',
          Operating: '작동 중',
          Stopped: '중지 됨',
          Index: '인덱스',
          Limits: '한계',
          Nominal: '설계치',
          Average: '평균',
          ValueInfo: '값',
          EventConfiguration: '이벤트 구성',
          Save: '저장',
          Cancel: '취소',
          Remove: '삭제',
          ConfirmedValue: '확정된 값',
          InputDirectlyAndPressEnter: '값을 직접 입력하고 Enter를 누르세요',
          SelectFromCatalogEvent: '카탈로그에서 선택 (이벤트)',
          YourTagsWillAppearHere: '여기에 태그가 표시됩니다',
        },
      },
    },
    reportPrint: {
      data: {
        en: {
          DataIsNotSelected: 'Data is not Selected',
          ProcessCapabilityReport: 'Process Capability Report',
          PartNumber: 'Part Number',
          PartDescription: 'Part Description',
          CharacteristicsName: 'Characteristics Name',
          Nominal: 'Nominal',
          USL: 'USL',
          LSL: 'LSL',
          NoData: 'NoData',
          Description: 'Description',
          Average: 'Average',
          Median: 'Median',
          StandardDeviation: 'Standard Deviation',
          Minimum: 'Minimum',
          Maximum: 'Maximum',
          Result: 'Result',
          OutOfTol: 'OutOfTol',
          Distribution: 'Distribution',
          PotentialPerformanceIndex: 'PotentialPerformanceIndex (Cp)',
          CriticalPerformanceIndex: 'CriticalPerformanceIndex (Cpk)',
          Report: 'Report',
          Cp: 'Cp',
          Cpk: 'Cpk',
          ValueInfo: 'Values',
          Processing: 'Processing...',
        },
        ja: {
          DataIsNotSelected: 'データが選択されていません',
          ProcessCapabilityReport: '工程能力レポート',
          PartNumber: '部品番号',
          PartDescription: '部品説明',
          CharacteristicsName: '特性名',
          Nominal: '公称',
          USL: 'USL',
          LSL: 'LSL',
          NoData: 'データなし',
          Description: '説明',
          Average: '平均',
          Median: '中央値',
          StandardDeviation: '標準偏差',
          Minimum: '最小値',
          Maximum: '最大値',
          Result: '結果',
          OutOfTol: '公差範囲外',
          Distribution: '分布',
          PotentialPerformanceIndex: '潜在的能力指数',
          CriticalPerformanceIndex: '臨界工程能力指数',
          Report: 'レポート',
          Cp: 'Cp',
          Cpk: 'Cpk',
          ValueInfo: '数値',
          Processing: '処理中...',
        },
        kr: {
          DataIsNotSelected: '데이터가 선택되지 않았습니다',
          ProcessCapabilityReport: '공정능력 보고서',
          PartNumber: '품번',
          PartDescription: '품명',
          CharacteristicsName: '특성이름',
          Nominal: '설계치',
          USL: 'USL',
          LSL: 'LSL',
          NoData: '데이터 없음',
          Description: '특성이름',
          Average: '평균',
          Median: '중앙값',
          StandardDeviation: '표준편차',
          Minimum: '최소값',
          Maximum: '최대값',
          Result: '결과',
          OutOfTol: '공차위반',
          Distribution: '분포',
          PotentialPerformanceIndex: '공정능력지수 (Cp)',
          CriticalPerformanceIndex: '공정능력지수 (CpK)',
          Report: '보고서',
          Cp: 'Cp',
          Cpk: 'Cpk',
          ValueInfo: '값',
          Processing: '처리중...',
        },
      },
    },
    uploadFile: {
      data: {
        en: {
          Encoding: 'Encoding',
          State: 'State',
          DropZoneDescription: 'Drag and drop a file here, or click to select a file.',
          DropZoneDescriptionExt: '( Only ‘.csv’, ‘.dfq’ and ‘.json‘ files supported )',
          NoData: 'No Data',
          UploadAll: 'Upload All',
          UploadSelected: 'Upload Selected',
          FileLastModifiedTime: 'File Last Modified Time',
          Upload: 'Upload',
          Uploaded: 'Uploaded',
          ClearList: 'Clear List',
          FileSize: 'File Size',
          UploadConfirmMsg: 'Would you like to upload the selected files?',
          UploadAllConfirmMsg: 'Are you sure you want to upload all files? This may take some time.',
          UploadSelectedConfirmMsg: 'Do you want to upload only the selected files?',
          ClearListConfirmMsg: 'Are you sure you want to clear the list?',
          Yes: 'Yes',
          No: 'No',
          ErrorMsgEmptyUploadFile: 'Please select upload file',
          Processing: 'Processing...',
          Process: 'Process',
          Completed: 'Completed',
        },
        ja: {
          Encoding: 'エンコーディング',
          State: '状態',
          DropZoneDescription: 'ファイルをここにドラッグアンドドロップするか、クリックしてファイルを選択してください',
          DropZoneDescriptionExt: '(「.csv」,「.dfq」と「.json」ファイルのみ対応)',
          NoData: 'データなし',
          UploadAll: 'すべてアップロード',
          UploadSelected: '選択をアップロード',
          FileLastModifiedTime: 'ファイルの最終変更時刻',
          Upload: 'アップロード',
          Uploaded: 'アップロード済み',
          ClearList: 'リストをクリア',
          FileSize: 'ファイルサイズ',
          UploadConfirmMsg: '選択したファイルをアップロードしますか？',
          UploadAllConfirmMsg: 'すべてのファイルをアップロードしてもよろしいですか？ これには時間がかかる場合があります',
          UploadSelectedConfirmMsg: '選択したファイルのみをアップロードしますか？',
          ClearListConfirmMsg: 'リストをクリアしてもよろしいですか？',
          Yes: 'はい',
          No: 'いいえ',
          ErrorMsgEmptyUploadFile: 'アップロードファイルを選択してください',
          Processing: '処理中...',
          Process: 'プロセス',
          Completed: '完了',
        },
        kr: {
          Encoding: '인코딩',
          State: '상태',
          DropZoneDescription: '파일을 여기로 드래그 앤 드롭하거나 클릭하여 파일을 선택합니다. ',
          DropZoneDescriptionExt: '( .csv, .dfq 및 .json 파일 만 지원 )',
          NoData: '데이터 없음',
          UploadAll: '모두 업로드',
          UploadSelected: '선택한 항목 업로드',
          FileLastModifiedTime: '파일의 마지막 변경 시간',
          Upload: '업로드',
          Uploaded: '업로드됨',
          ClearList: '리스트 지우기',
          FileSize: '파일 크기',
          UploadConfirmMsg: '선택한 파일을 업로드하시겠습니까? ',
          UploadAllConfirmMsg: '모든 파일을 업로드하시겠습니까? 이 작업에는 시간이 걸릴 수 있습니다. ',
          UploadSelectedConfirmMsg: '선택한 파일만 업로드하시겠습니까? ',
          ClearListConfirmMsg: '목록을 지우시겠습니까? ',
          Yes: '예',
          No: '아니오',
          ErrorMsgEmptyUploadFile: '업로드 파일을 선택해주세요.',
          Processing: '처리중...',
          Process: '진행',
          Completed: '완료',
        },
      },
    },
    configuration: {
      data: {
        en: {
          Encoding: 'Encoding',
          FieldUseSelection: 'Field Use Selection',
          Catalogs: 'Catalogs',
          CommonSettings: 'Common Settings',
          SavedSettings: 'Saved Settings',
          ResetSettings: 'Reset Settings',
          FailedSettings: 'Failed Settings',
          Save: 'Save',
          Reset: 'Reset',
          Apply: 'Apply',
          KeyFields: 'Key Fields',
          PartKey: 'Part Key',
          PartKeyList: 'Part Key List',
          CharacteristicKey: 'Characteristic Key',
          CharacteristicKeyList: 'Characteristic Key List',
          PartField: 'Part Field',
          CharacteristicsField: 'Characteristics Field',
          ValueField: 'Value Field',
          Common: 'Common',
          DecimalPlaces: 'Decimal Places',
          DefaultDecimalPlaces: 'Default Decimal Places',
          DefaultCalculatedDecimalPlaces: 'Calculated Decimal Places',
          DefaultSigmaDegree: 'Default Sigma Degree',
          TwoSideWarningLimit: '2 Side Warning Limit',
          OneSideWarningLimitNat: '1 Side Warning Limit (Nat.)',
          CustomLimit: 'Custom Limit',
          FindDistribution: 'Find Distribution',
          SavedDistribution: 'Saved Distribution',
          MeasuredQuantity: 'Measured Quantity',
          DefaultDistribution: 'Default Distribution',
          DefaultDistributionMethod: 'Method',
          OneSideDefaultDistribution: 'One Side',
          TwoSideDefaultDistribution: 'None/Two Side',
          NotUsingMeasuredQuantity: 'Not Using Measured Quantity',
          UsingMeasuredQuantity: 'Using Measured Quantity',
          ID: 'ID',
          Description: 'Description',
          MeasureQuantity: 'Measure Quantity',
          DistributionForMQ: 'DistributionForMQ',
          Value: 'Value',
          PreviewPreparing: 'Preview(Getting ready)',
          Import: 'Import',
          Export: 'Export',
          TemplateDownload: 'TemplateDownload',
          ExponentialPosition: 'Exponential Position',
          QualityControlParameters: 'Quality Control Parameters',
          DisplayValueOption: 'Display Value Option',
          ProcessCapabilityOption: 'Process Capability Option',
          ExtendedLimitOption: 'Extended Limit Option',
          OutlierOption: 'Outlier Option',
        },
        ja: {
          Encoding: 'エンコーディング',
          FieldUseSelection: 'フィールド使用選択',
          Catalogs: 'カタログ',
          CommonSettings: '共通設定',
          SavedSettings: '設定保存成功',
          ResetSettings: '設定リセット',
          FailedSettings: '設定保存失敗',
          Save: '保存',
          Reset: 'リセット',
          Apply: '適用',
          KeyFields: 'キーフィールド',
          PartKey: '部品キー',
          PartKeyList: '部品キーリスト',
          CharacteristicKey: '特性キー',
          CharacteristicKeyList: '特性キーリスト',
          PartField: '部品フィールド',
          CharacteristicsField: '特性フィールド',
          ValueField: '数値フィールド',
          Common: '共通',
          DecimalPlaces: '小数点以下の桁数',
          DefaultDecimalPlaces: 'デフォルト小数点以下桁数',
          DefaultCalculatedDecimalPlaces: '計算された小数点以下桁数',
          DefaultSigmaDegree: 'デフォルトシグマ値',
          WarningLimit: '警告限界',
          WarningLimitNat: '警告限界 (Nat.)',
          CustomLimit: 'カスタム限界',
          FindDistribution: '分布検索',
          SavedDistribution: '保存された分布',
          MeasuredQuantity: '測定量',
          DefaultDistribution: 'デフォルトの分布',
          DefaultDistributionMethod: '方法',
          OneSideDefaultDistribution: '片側',
          TwoSideDefaultDistribution: 'なし/両側',
          NotUsingMeasuredQuantity: '測定量を使用しない',
          UsingMeasuredQuantity: '測定量を使用する',
          ID: 'ID',
          Description: '説明',
          MeasureQuantity: '測定量',
          DistributionForMQ: 'MQ用分布',
          Value: '値',
          PreviewPreparing: 'プレビュー（準備中）',
          Import: 'インポート',
          Export: 'エクスポート',
          TemplateDownload: 'テンプレートダウンロード',
          ExponentialPosition: '指数表現を有効にする',
          QualityControlParameters: '品質管理パラメータ',
          DisplayValueOption: '表示値オプション',
          ProcessCapabilityOption: 'プロセス能力オプション',
          ExtendedLimitOption: '拡張限界オプション',
          OutlierOption: '外れ値オプション',
        },
        kr: {
          Encoding: '인코딩',
          FieldUseSelection: '필드 사용 선택',
          Catalogs: '카탈로그',
          CommonSettings: '공통 설정',
          SavedSettings: '설정 저장 완료',
          ResetSettings: '설정 초기화',
          FailedSettings: '설정 저장 실패',
          Save: '저장',
          Reset: '재설정',
          Apply: '적용',
          KeyFields: '키 필드',
          PartKey: '부품 키',
          PartKeyList: '부품 키 목록',
          CharacteristicKey: '특성 키',
          CharacteristicKeyList: '특성 키 목록',
          PartField: '부품 필드',
          CharacteristicsField: '특성 필드',
          ValueField: '값 필드',
          Common: '공통',
          DecimalPlaces: '소수점 표시 자릿수',
          DefaultDecimalPlaces: '기본 소수점 이하 자릿수',
          DefaultCalculatedDecimalPlaces: '분석값 소수점 표시 자릿수',
          DefaultSigmaDegree: '기본 시그마 값',
          WarningLimit: '경고 한계',
          WarningLimitNat: '경고 한계 (Nat.)',
          CustomLimit: '관리 한계',
          FindDistribution: '분포 찾기',
          SavedDistribution: '저장된 분포',
          MeasuredQuantity: '측정종류',
          DefaultDistribution: '기본 분포',
          DefaultDistributionMethod: '계산법',
          OneSideDefaultDistribution: '편측',
          TwoSideDefaultDistribution: '없음/양측',
          NotUsingMeasuredQuantity: '측정종류별 설정 사용 안함',
          UsingMeasuredQuantity: '측정종류별 설정 사용',
          ID: 'ID',
          Description: '특성이름',
          MeasureQuantity: '측정종류',
          DistributionForMQ: 'MQ에 대한 배포',
          Value: '값',
          PreviewPreparing: '미리보기(준비중)',
          Import: '가져오기',
          Export: '내보내기',
          TemplateDownload: '템플릿 다운로드',
          ExponentialPosition: '지수 표현 활성화',
          QualityControlParameters: '품질 관리 매개변수',
          DisplayValueOption: '값 표시 옵션',
          ProcessCapabilityOption: '프로세스 능력 옵션',
          ExtendedLimitOption: '확장 한계 옵션',
          OutlierOption: '이상치 옵션',
        },
      },
    },
    adminSettings: {
      data: {
        en: {
          SearchPlaceHoler: 'Search...',
          NoData: 'No data',
          PartField: 'Part Field',
          CharacteristicsField: 'Characteristics Field',
          ValueField: 'Value Field',
          SavedSettings: 'Saved Settings',
          ResetSettings: 'Reset Settings',
          FailedSettings: 'Failed Settings',
          Save: 'Save',
          Reset: 'Reset',
          Apply: 'Apply',
          Company: 'Company',
          Account: 'Account',
          License: 'License',
          Language: 'Language',
          English: 'English',
          Japanese: 'Japanese',
          Korean: 'Korean',
          DBField: 'DB Field',
          DBInfo: 'DB Info',
          Type: 'Type',
          Page: 'Page',
          ID: 'ID',
          UserID: 'User ID',
          UserPassword: 'User Password',
          UserRole: 'User Role',
          UserName: 'User Name',
          UserJob: 'User Job',
          PasswordCondition: 'Password Condition',
          MinTotalLength: 'min Total length',
          MinSpecialCharacters: 'min S-Character',
          MinUpperCase: 'min UpperCase',
          MinLetter: 'min Letter',
          Data: 'Data',
          Description: 'Description',
          DBName: 'DBName',
          IPHostAddress: 'IP/Host Address',
          Port: 'Port',
          State: 'State',
          ResetPassword: 'Reset Password',
          Empty: 'Empty',
          ConfirmMessage: 'Are you sure to do this function?',
          Ribbon: 'Ribbon',
          Key: 'Key',
          SuperUser: 'SuperUser',
          Admin: 'Admin',
          Engineer: 'Engineer',
          Operator: 'Operator',
          CMM: 'CMM',
          QSense: 'QSense',
          Demo: 'Demo',
          Address: 'Address',
          Password: 'Password',
          Port: 'Port',
          UpdateDBName: 'Update DB Name',
          MySQL: 'MySQL',
          MsSQL: 'MsSQL',
          MongoDB: 'MongoDB',
          FailSave: 'Fail Save',
          FailUpdate: 'Fail Update',
          ColumnChooserTitle: 'Column Chooser',
          ColumnChooserEmptyPanelText: 'No selection',
          ColumnChooserPlaceHoler: 'Search column',
          SelectionMenu: 'Selection Menu',
          MakeHierarchy: 'Make Hierarchy',
          PreviewDataUnloaded: 'Preview (Data Unloaded)',
          PreviewDataLoaded: 'Preview (Data Loaded)',
          ErrorMsgPasswordCondition: 'Please check password condition',
          MinLengthConditionError: 'min Total Length Value need to set over Condition Sum, ( S chars + Upper Letters + Letters )',
          Number: 'Number',
          Remove: 'Remove',
          Add: 'Add',
          Page: 'Page',
          Directory: 'Directory',
          None: 'None',
          Navigator: 'Navigator',
          PasswordResetSuccess: 'Password Reset Success',
          PasswordResetFail: 'Password Reset Fail',
          PushSettings: 'Push Settings',
        },
        ja: {
          SearchPlaceHoler: '検索...',
          NoData: 'データなし',
          PartField: '部品フィールド',
          CharacteristicsField: '特性フィールド',
          valueExpr: '数値フィールド',
          SavedSettings: '設定保存成功',
          ResetSettings: '設定リセット',
          FailedSettings: '設定保存失敗',
          Save: '保存',
          Reset: 'リセット',
          Apply: '適用',
          Company: '社名',
          Account: 'アカウント',
          License: 'ライセンス',
          Language: '言語',
          English: '英語',
          Japanese: '日本語',
          Korean: '韓国語',
          DBField: 'DBフィールド',
          DBInfo: 'DB情報',
          Type: 'タイプ',
          Page: 'ページ',
          ID: 'ID',
          UserID: 'ユーザID',
          UserPassword: 'ユーザパスワード',
          UserRole: 'ユーザロール',
          UserName: 'ユーザ名',
          UserJob: 'ユーザの職業',
          PasswordCondition: 'パスワード条件',
          MinTotalLength: '最小合計長',
          MinSpecialCharacters: '最小特殊文字数',
          MinUpperCase: '最小大文字数',
          MinLetter: '最小文字数',
          Data: 'データ',
          Description: '説明',
          DBName: 'DB名',
          IPHostAddress: 'IP/ホストアドレス',
          Port: 'ポート',
          State: '状態',
          ResetPassword: 'パスワードをリセット',
          Empty: '空',
          ConfirmMessage: 'この操作を実行してよろしいですか？',
          Ribbon: 'リボン',
          Key: 'キー',
          SuperUser: 'スーパーユーザ',
          Admin: '管理者',
          Engineer: 'エンジニア',
          Operator: 'オペレーター',
          CMM: 'CMM',
          QSense: 'Q-Sense',
          Demo: 'デモ',
          Address: '住所',
          Password: 'パスワード',
          Port: 'ポート',
          UpdateDBName: 'DB名 更新 ',
          MySQL: 'MySQL',
          MsSQL: 'MsSQL',
          MongoDB: 'MongoDB',
          FailSave: '保存失敗',
          FailUpdate: 'アップデート失敗',
          ColumnChooserTitle: '列選択',
          ColumnChooserEmptyPanelText: '選択なし',
          ColumnChooserPlaceHoler: '列を検索',
          SelectionMenu: '選択メニュー',
          MakeHierarchy: '階層の作成',
          PreviewDataUnloaded: 'プレビュー (データアンロード)',
          PreviewDataLoaded: 'プレビュー (データロード)',
          ErrorMsgPasswordCondition: 'パスワード条件を確認して下さい',
          MinLengthConditionError: '最小文字長は(特殊文字+大文字数＋文字数)を満たすこと',
          Number: '番号',
          Remove: '削除',
          Add: '追加',
          Page: 'ページ',
          Directory: 'ディレクトリ',
          None: 'なし',
          Navigator: 'ナビゲーター',
          PasswordResetSuccess: 'パスワードのリセットに成功しました',
          PasswordResetFail: 'パスワードのリセットに失敗しました',
          PushSettings: 'プッシュ設定',
        },
        kr: {
          SearchPlaceHoler: '검색...',
          NoData: '데이터 없음',
          PartField: '부품 필드',
          CharacteristicsField: '특성 필드',
          ValueField: '값 필드',
          SavedSettings: '설정 저장 완료',
          ResetSettings: '설정 초기화',
          FailedSettings: '설정 저장 실패',
          Save: '저장',
          Reset: '재설정',
          Apply: '적용',
          Company: '회사',
          Account: '계정',
          License: '라이센스',
          Language: '언어',
          English: '영어',
          Japanese: '일본어',
          Korean: '한국어',
          DBField: 'DB 필드',
          DBInfo: 'DB 정보',
          Type: '유형',
          Page: '페이지',
          ID: '아이디',
          UserID: '사용자 ID',
          UserPassword: '사용자 암호',
          UserRole: '사용자 역할',
          UserName: '사용자 이름',
          UserJob: '사용자 직업',
          PasswordCondition: '비밀번호 조건',
          MinTotalLength: '최소 총 길이',
          MinSpecialCharacters: '최소 특수 문자',
          MinUpperCase: '최소 대문자',
          MinLetter: '최소 문자 수',
          Data: '데이터',
          Description: '설명',
          DBName: 'DB 이름',
          IPHostAddress: 'IP/호스트 주소',
          Port: '포트',
          State: '상태',
          ResetPassword: '비밀번호 재설정',
          Empty: '빈',
          ConfirmMessage: '이 작업을 수행하시겠습니까? ',
          Ribbon: '리본',
          Key: '키',
          SuperUser: '슈퍼유저',
          Admin: '관리자',
          Engineer: '엔지니어',
          Operator: '운영자',
          CMM: 'CMM',
          QSense: 'Q센스',
          Demo: '데모',
          Address: '주소',
          Password: '암호',
          Port: '포트',
          UpdateDBName: 'DB 이름 갱신',
          MySQL: 'MySQL',
          MsSQL: 'MsSQL',
          MongoDB: 'MongoDB',
          FailSave: '저장실패',
          FailUpdate: '업데이트실패',
          ColumnChooserTitle: '열 선택',
          ColumnChooserEmptyPanelText: '선택 없음',
          ColumnChooserPlaceHoler: '열 찾기',
          SelectionMenu: '선택 메뉴',
          MakeHierarchy: '계층 만들기',
          PreviewDataUnloaded: '미리보기(데이터 언로드됨)',
          PreviewDataLoaded: '미리보기(데이터 로드됨)',
          ErrorMsgPasswordCondition: '비밀번호 조건을 확인하세요',
          MinLengthConditionError: '최소 총 길이 값은 조건 합계(특수 문자 + 대문자 + 문자) 이상이어야 합니다',
          Number: '번호',
          Remove: '제거',
          Add: '추가',
          Page: '페이지',
          Directory: '디렉터리',
          None: '없음',
          Navigator: '네비게이터',
          PasswordResetSuccess: '비밀번호 재설정 성공',
          PasswordResetFail: '비밀번호 재설정 실패',
          PushSettings: '푸시 설정',
        },
      },
    },
    companyInfo: {
      data: {
        en: {
          SupportEmailMsg: 'Support E-mail Address : support@processartz.com',
          InstalledProductionMsg: 'Installed Production',
          IAnalyzerProductionMsg: 'i-Analyzer : ',
          IBoardProductionMsg: 'i-Board : ',
          ISenseProductionMsg: 'i-Sense : ',
          IServerProductionMsg: 'i-Server : ',
          HomePageMsg: 'HomePage : ',
          CopyRightFirst: 'Copyright ©️ 2019-2024. Process Artz Co. ltd.',
          CopyRightSecond: 'Dynamic QS Co. ltd.',
          CopyRightThird: 'All Rights Reserved.',
        },
        ja: {
          SupportEmailMsg: 'サポートメールアドレス: support@processartz.com',
          InstalledProductionMsg: 'インストールされている製品',
          IAnalyzerProductionMsg: 'i-Analyzer : ',
          IBoardProductionMsg: 'i-Board : ',
          ISenseProductionMsg: 'i-Sense : ',
          IServerProductionMsg: 'i-Server : ',
          HomePageMsg: 'ホームページ :',
          CopyRightFirst: 'Copyright ©️ 2019-2024. Process Artz Co. ltd.',
          CopyRightSecond: 'Dynamic QS Co. ltd.',
          CopyRightThird: 'All Rights Reserved.',
        },
        kr: {
          SupportEmailMsg: '지원 이메일 주소: support@processartz.com',
          InstalledProductionMsg: '설치된 프로덕션',
          IAnalyzerProductionMsg: 'i-Analyzer : ',
          IBoardProductionMsg: 'i-Board : ',
          ISenseProductionMsg: 'i-Sense : ',
          IServerProductionMsg: 'i-Server : ',
          HomePageMsg: '홈페이지:',
          CopyRightFirst: '저작권 ©️ 2019-2024. Process Artz Co. ltd.',
          CopyRightSecond: 'Dynamic QS Co. ltd.',
          CopyRightThird: '모든 저작권 소유. ',
        },
      },
    },
  },
  db_field: {
    part: {
      data: {
        en: {
          Part_id: 'Part ID',
          User_id: 'User ID',
          User_role: 'User Role',
          P1000: 'Part',
          P1001: 'Part Number',
          P1002: 'Part Description',
          P1003: 'Part Abbreviation',
          P1005: 'Product',
          P1007: 'Part Number (short)',
          P1008: 'Part Type',
          P1009: 'Part Code',
          P1011: 'Version',
          P1012: 'ID Number Annex',
          P1013: 'ID number Index',
          P1014: 'Part Ident',
          P1015: 'Type of Study',
          P1022: 'Manufacturer Description',
          P1032: 'Material Description',
          P1046: 'Drawing Description',
          P1052: 'Contractor Description',
          P1053: 'Contract',
          P1062: 'Customer Description',
          P1072: 'Supplier Description',
          P1082: 'Machine Description',
          P1085: 'Machine Location',
          P1086: 'Work Cycle',
          P1087: 'Work Cycle Description',
          P1091: 'Line Number',
          P1092: 'Line Description',
          P1100: 'Area / Plant Section',
          P1101: 'Department',
          P1102: 'Workshop',
          P1103: 'Cost Centre',
          P1104: 'Shift',
          P1203: 'Reason for Test',
          P1206: 'Test Location',
          P1303: 'Plant',
          P1802: 'User Field Contents',
          P1812: 'User Field Contents',
          P1822: 'User Field Contents',
          P1832: 'User Field Contents',
          P1842: 'User Field Contents',
          P1852: 'User Field Contents',
          P1862: 'User Field Contents',
          P1872: 'User Field Contents',
          P1882: 'User Field Contents',
          P1892: 'User Field Contents',
          P1900: 'Remark',
          UPDATED_DATETIME: 'UPDATED_DATETIME',
        },
        ja: {
          Part_id: '部品ID',
          User_id: 'ユーザID',
          User_role: 'ユーザロール',
          P1000: '部品',
          P1001: '部品番号',
          P1002: '部品説明',
          P1003: '部品略語',
          P1005: '製品',
          P1007: '部品番号（短縮）',
          P1008: '部品タイプ',
          P1009: '部品コード',
          P1011: 'バージョン',
          P1012: 'ID番号付録',
          P1013: 'ID番号索引',
          P1014: '部品識別',
          P1015: '分析タイプ', //'研究の種類',
          P1022: '製造者説明',
          P1032: '材料説明',
          P1046: '図面説明',
          P1052: '請負業者説明',
          P1053: '請負業者',
          P1062: '顧客説明',
          P1072: 'サプライヤの説明', //'供給者説明',
          P1082: '機械の説明',
          P1085: '機械の位置',
          P1086: '作業サイクル',
          P1087: '作業サイクル説明',
          P1091: 'ライン番号',
          P1092: 'ライン説明',
          P1100: '区画/工場セクション',
          P1101: '部署',
          P1102: 'ワークショップ',
          P1103: 'コストセンタ',
          P1104: 'シフト',
          P1203: 'テストの理由',
          P1206: 'テスト場所',
          P1303: 'プラント',
          P1802: 'ユーザフィールド内容1',
          P1812: 'ユーザフィールド内容2',
          P1822: 'ユーザフィールド内容3',
          P1832: 'ユーザフィールド内容4',
          P1842: 'ユーザフィールド内容5',
          P1852: 'ユーザフィールド内容6',
          P1862: 'ユーザフィールド内容7',
          P1872: 'ユーザフィールド内容8',
          P1882: 'ユーザフィールド内容9',
          P1892: 'ユーザフィールド内容10',
          P1900: '備考',
          UPDATED_DATETIME: '更新日時',
        },
        kr: {
          Part_id: '부품 ID',
          User_id: '사용자 ID',
          User_role: '사용자 역할',
          P1000: '부품',
          P1001: '품번',
          P1002: '품명',
          P1003: '품명 약어',
          P1005: '제품',
          P1007: '품번(짧은)',
          P1008: '부품타입',
          P1009: '부품코드',
          P1011: '버전',
          P1012: 'ID 번호 부록',
          P1013: 'ID 번호 색인',
          P1014: '부품식별',
          P1015: '분석타입',
          P1022: '제조업자명',
          P1032: '재료명',
          P1046: '도면명',
          P1052: '계약자명',
          P1053: '계약자',
          P1062: '고객명',
          P1072: '공급업자명',
          P1082: '호기명',
          P1085: '호기위치',
          P1086: '작업주기',
          P1087: '작업 주기 이름',
          P1091: '라인번호',
          P1092: '라인설명',
          P1100: '지역 / 플랜트 섹션',
          P1101: '부서',
          P1102: '작업장',
          P1103: '비용센터',
          P1104: '시프트',
          P1203: '테스트이유',
          P1206: '테스트위치',
          P1303: '플랜트',
          P1802: '사용자 필드 내용',
          P1812: '사용자 필드 내용',
          P1822: '사용자 필드 내용',
          P1832: '사용자 필드 내용',
          P1842: '사용자 필드 내용',
          P1852: '사용자 필드 내용',
          P1862: '사용자 필드 내용',
          P1872: '사용자 필드 내용',
          P1882: '사용자 필드 내용',
          P1892: '사용자 필드 내용',
          P1900: '비고',
          UPDATED_DATETIME: '업데이트된 시간',
        },
      },
    },
    characteristics: {
      data: {
        en: {
          Part_id: 'Part ID',
          Char_id: 'Char ID',
          Group_index: 'Group Index',
          C2000: 'Char',
          C2001: 'Characteristic Number',
          C2002: 'Characteristic Description',
          C2003: 'Characteristic Abbreviation',
          C2004: 'Characteristic Type',
          C2005: 'Characteristic Class',
          C2006: 'Control Item',
          C2007: 'Control Type',
          C2008: 'Group Type',
          C2009: 'Measured Quantity',
          C2011: 'Saved Distribution',
          C2015: 'Tool Wear Type',
          C2016: '100% Measurement',
          C2021: 'Logical Operation Formula',
          C2022: 'Decimal Places',
          C2035: 'Calibration Date',
          C2041: 'Recording type',
          C2042: 'Recording Device number',
          C2043: 'Recording Device Name',
          C2051: 'Interface',
          C2091: 'Characteristic index',
          C2092: 'Operation Number', //'Characteristic text',
          C2093: 'Processing Status',
          C2100: 'Target Value',
          C2101: 'Nominal Value',
          C2110: 'Lower Specification Limit',
          C2111: 'Upper Specification Limit',
          C2112: 'Lower Allowance',
          C2113: 'Upper Allowance',
          C2114: 'Lower Scrap Limit',
          C2115: 'Upper Scrap Limit',
          C2116: 'Lower Acceptance Limit',
          C2117: 'Upper Acceptance Limit',
          C2120: 'Lower Limit Type',
          C2121: 'Upper Limit Type',
          C2130: 'Lower Plausibility Limit',
          C2131: 'Upper Plausibility Limit',
          C2142: 'Unit Description',
          C2161: 'Re-work Cost',
          C2162: 'Rejects Cost',
          C2163: 'Error Cost',
          C2202: 'Evaluation Type',
          C2205: 'Number of Parts',
          C2213: 'Master Actual Value',
          C2220: 'Number of Operators',
          C2221: 'Number of Trials',
          C2222: 'No. of Reference Measurements',
          C2225: 'Determinded Cg value',
          C2226: 'Determinded Cgk value',
          C2227: 'Deviation GC Type 3 – GC Type 1',
          C2243: 'Drawing file name',
          C2244: 'Drawing Reference point X',
          C2245: 'Drawing Reference point Y',
          C2246: 'Drawing Reference point Z',
          C2302: 'Machine Description',
          C2402: 'Gage Description',
          C2410: 'Test Location',
          C2413: 'Gage Graphic',
          C2415: 'Gage Serial Number',
          C2802: 'User Field Contents 1',
          C2812: 'User Field Contents 2',
          C2822: 'User Field Contents 3',
          C2832: 'User Field Contents 4',
          C2842: 'User Field Contents 5',
          C2852: 'User Field Contents 6',
          C2862: 'User Field Contents 7',
          C2872: 'User Field Contents 8',
          C2882: 'User Field Contents 9',
          C2892: 'User Field Contents 10',
          C2900: 'Remark',
          C2999: 'Remark',
          C8006: 'Lower Alarm Limit',
          C8007: 'Upper Alarm Limit',
          C8011: 'Central Position (location)',
          C8012: 'Lower Control Limit LCL (location)',
          C8013: 'Upper Control Limit UCL (location)',
          C8014: 'Lower Warning Limit LWL (location)',
          C8015: 'Upper Warning Limit UWL (location)',
          C8106: 'Lower Alarm limit (variation)',
          C8107: 'Upper Alarm limit (variation)',
          C8111: 'Central Position (variation)',
          C8112: 'Lower Control limit LCL (variation)',
          C8113: 'Upper Control Limit UCL (variation)',
          C8114: 'Lower Warning Limit LWL (variation)',
          C8115: 'Upper Warning Limit UWL (variation)',
          C8500: 'Subgroup Size (total)',
          C8501: 'Subgroup Type',
          C8531: 'Recorded Process Capability (Cp)',
          C8532: 'Recorded Process Capability (Cpk)',
          C8540: 'Evaluation',
          Value_quantity: 'Value Quantity',
        },
        ja: {
          Part_id: '部品ID',
          Char_id: '特性ID',
          Group_index: 'グループインデックス',
          C2000: '特性',
          C2001: '特性番号',
          C2002: '特性の説明',
          C2003: '特性略称',
          C2004: '特性タイプ',
          C2005: '特性クラス',
          C2006: '制御アイテム',
          C2007: '制御タイプ',
          C2008: 'グループタイプ',
          C2009: '測定量',
          C2011: '保存した分布',
          C2015: 'ツール摩耗タイプ',
          C2016: '全数検査',
          C2021: '論理演算式',
          C2022: '小数点以下桁数',
          C2035: '校正日',
          C2041: '記録タイプ',
          C2042: '記録デバイス番号',
          C2043: '記録デバイス名',
          C2051: 'インターフェース',
          C2091: '特性インデックス',
          C2092: '特性テキスト',
          C2093: '処理状態',
          C2100: '目標値',
          C2101: '規格値',
          C2110: '下方仕様限界',
          C2111: '上方仕様限界',
          C2112: '下方許容差',
          C2113: '上方許容差',
          C2114: '下方廃棄限界',
          C2115: '上方廃棄限界',
          C2116: '下方合否判定限界',
          C2117: '上方合否判定限界',
          C2120: '下限タイプ',
          C2121: '上限タイプ',
          C2130: '下方妥当性限界',
          C2131: '上方妥当性限界',
          C2142: '単位の説明',
          C2161: '修正コスト',
          C2162: '廃棄コスト',
          C2163: 'エラーコスト',
          C2202: '評価タイプ',
          C2205: '部品数',
          C2213: 'マスター実測値',
          C2220: 'オペレーター数',
          C2221: '試行回数',
          C2222: '参照計測数',
          C2225: '決定Cg値',
          C2226: '決定Cgk値',
          C2227: '偏差GCタイプ3 - GCタイプ1',
          C2243: '図面ファイル名',
          C2244: '図面基準点X',
          C2245: '図面基準点Y',
          C2246: '図面基準点Z',
          C2302: '機械の説明',
          C2402: 'ゲージの説明',
          C2410: 'テスト場所',
          C2413: 'ゲージグラフィック',
          C2415: 'ゲージシリアル番号',
          C2802: 'ユーザフィールドコンテンツ1',
          C2812: 'ユーザフィールドコンテンツ2',
          C2822: 'ユーザフィールドコンテンツ3',
          C2832: 'ユーザフィールドコンテンツ4',
          C2842: 'ユーザフィールドコンテンツ5',
          C2852: 'ユーザフィールドコンテンツ6',
          C2862: 'ユーザフィールドコンテンツ7',
          C2872: 'ユーザフィールドコンテンツ8',
          C2882: 'ユーザフィールドコンテンツ9',
          C2892: 'ユーザフィールドコンテンツ10',
          C2900: '備考',
          C2999: '特性選択タイプ',
          C8006: '下方警報限界',
          C8007: '上方警報限界',
          C8011: '中央位置（位置）',
          C8012: '下方管理限界LCL（位置）',
          C8013: '上方管理限界UCL（位置）',
          C8014: '下方警告限界LWL（位置）',
          C8015: '上方警告限界UWL（位置）',
          C8106: '下方警報限界（変動）',
          C8107: '上方警報限界（変動）',
          C8111: '中央位置（変動）',
          C8112: '下方管理限界LCL（変動）',
          C8113: '上方管理限界UCL（変動）',
          C8114: '下方警告限界LWL（変動）',
          C8115: '上方警告限界UWL（変動）',
          C8500: 'サブグループサイズ（合計）',
          C8501: 'サブグループタイプ',
          C8531: '記録された工程能力（Cp）',
          C8532: '記録された工程能力（Cpk）',
          C8540: '評価',
          Value_quantity: '値の数量',
        },
        kr: {
          Part_id: '부품 ID',
          Char_id: '특성 ID',
          Group_index: '그룹 인덱스',
          C2000: '특성',
          C2001: '특성번호',
          C2002: '특성명',
          C2003: '특성명 약어',
          C2004: '특성타입',
          C2005: '중요도',
          C2006: '관리아이템',
          C2007: '관리타입',
          C2008: '그룹타입',
          C2009: '측정종류',
          C2011: '저장된 분포',
          C2015: '툴마모 타입',
          C2016: '전수검사',
          C2021: '논리연산식',
          C2022: '소수자리',
          C2035: '보정일자',
          C2041: '리코드타입',
          C2042: '리코드장치번호',
          C2043: '리코드장치명',
          C2051: '인터페이스',
          C2091: '특성인덱스',
          C2092: '공정번호', //'특성 텍스트',
          C2093: '처리 상태',
          C2100: '목표치',
          C2101: '설계치',
          C2110: '규격하한',
          C2111: '규격상한',
          C2112: '허용하한차',
          C2113: '허용상한차',
          C2114: '스크랩하한',
          C2115: '스크랩상한',
          C2116: '수용하한',
          C2117: '수용상한',
          C2120: '하한타입',
          C2121: '상한타입',
          C2130: '이상하한치',
          C2131: '이상상한치',
          C2142: '단위',
          C2161: '리워크비용',
          C2162: '불합격비용',
          C2163: '오류비용',
          C2202: '평가타입',
          C2205: '부품수량',
          C2213: '마스터 실제값',
          C2220: '작업자 수',
          C2221: '반복측정 횟수',
          C2222: '참조 측정 수',
          C2225: '결정된 Cg 값',
          C2226: '결정된 Cgk 값',
          C2227: '편차 GC 타입 3 - GC 타입 1',
          C2243: '도면 파일명',
          C2244: '도면 참조점 X',
          C2245: '도면 참조점 Y',
          C2246: '도면 참조점 Z',
          C2302: '호기명',
          C2402: '측정기명',
          C2410: '테스트위치',
          C2413: '측정기그래픽',
          C2415: '측정기 일련 번호',
          C2802: '사용자 필드 내용 1',
          C2812: '사용자 필드 내용 2',
          C2822: '사용자 필드 내용 3',
          C2832: '사용자 필드 내용 4',
          C2842: '사용자 필드 내용 5',
          C2852: '사용자 필드 내용 6',
          C2862: '사용자 필드 내용 7',
          C2872: '사용자 필드 내용 8',
          C2882: '사용자 필드 내용 9',
          C2892: '사용자 필드 내용 10',
          C2900: '비고',
          C2999: '비고',
          C8006: '경고하한',
          C8007: '경고상한',
          C8011: '중심 위치 (위치)',
          C8012: '관리하한 LCL (위치)',
          C8013: '관리상한 UCL (위치)',
          C8014: ' 경고하한 LWL (위치)',
          C8015: '경고상한 UWL (위치)',
          C8106: '경고하한 (산포)',
          C8107: '경고상한 (산포)',
          C8111: '중심 위치 (산포)',
          C8112: '관리하한 LCL (산포)',
          C8113: '관리상한 UCL (산포)',
          C8114: '경고하한 LWL (변동)',
          C8115: '경고상한 UWL (변동)',
          C8500: '서브그룹 크기 (총합)',
          C8501: '서브그룹 타입',
          C8531: '기록된 공정 능력 (Cp)',
          C8532: '기록된 공정 능력 (Cpk)',
          C8540: '평가',
          Value_quantity: '샘플수량',
        },
      },
    },
    value: {
      data: {
        en: {
          Part_id: 'Part ID',
          Char_id: 'Char ID',
          Value_id: 'Value ID',
          Out_of_tol: 'Out of tolerances',
          Nominal: 'Nominal',
          V_usl: 'V_USL',
          V_lsl: 'V_LSL',
          V0001: 'Values',
          V0002: 'Attribute',
          V0004: 'Time/Date',
          V0005: 'Events',
          V0006: 'Batch number',
          V0007: 'Cavity number',
          V0008: 'Operator name',
          V0009: 'Text',
          V0010: 'Machine number',
          V0011: 'Process parameter',
          V0012: 'Gage number',
          V0014: 'Part Ident',
          V0016: 'Production number',
          V0017: 'Work piece fixture number',
          V0053: 'Serial Number',
          V0054: 'User define 1',
          V0055: 'User define 2',
          V0056: 'User define 3',
          V0057: 'User define 4',
          V0058: 'User define 5',
          V0059: 'User define 6',
          V0060: 'User define 7',
          V0061: 'User define 8',
          V0062: 'User define 9',
          V0063: 'User define 10',
        },
        ja: {
          Part_id: '部品ID',
          Char_id: '特性ID',
          Value_id: '値のID',
          Out_of_tol: '公差範囲外',
          Nominal: '規格値',
          V_usl: 'V_USL',
          V_lsl: 'V_LSL',
          V0001: '値',
          V0002: '属性',
          V0004: '日付/時刻',
          V0005: 'イベント',
          V0006: 'バッチ番号',
          V0007: 'キャビティ番号',
          V0008: 'オペレーター名',
          V0009: 'テキスト',
          V0010: 'マシン番号',
          V0011: 'プロセスパラメータ',
          V0012: 'ゲージ番号',
          V0014: '部品識別ID',
          V0016: '生産番号',
          V0017: '治具番号',
          V0053: 'ロット番号',
          V0054: 'ユーザ定義1',
          V0055: 'ユーザ定義2',
          V0056: 'ユーザ定義3',
          V0057: 'ユーザ定義4',
          V0058: 'ユーザ定義5',
          V0059: 'ユーザ定義6',
          V0060: 'ユーザ定義7',
          V0061: 'ユーザ定義8',
          V0062: 'ユーザ定義9',
          V0063: 'ユーザ定義10',
        },
        kr: {
          Part_id: '부품 ID',
          Char_id: '특성 ID',
          Value_id: '값 ID',
          Out_of_tol: '공차위반',
          Nominal: '설계치',
          V_usl: 'V_USL',
          V_lsl: 'V_LSL',
          V0001: '값',
          V0002: '속성',
          V0004: '시간/날짜',
          V0005: '이벤트',
          V0006: '배치 번호',
          V0007: '캐비티 번호',
          V0008: '작업자명',
          V0009: '텍스트',
          V0010: '호기번호',
          V0011: '공정파라미터',
          V0012: '측정기번호',
          V0014: '부품식별번호',
          V0016: '생산번호',
          V0017: '워크고정기번호',
          V0053: '시리얼번호',
          V0054: '사용자 정의 1',
          V0055: '사용자 정의 2',
          V0056: '사용자 정의 3',
          V0057: '사용자 정의 4',
          V0058: '사용자 정의 5',
          V0059: '사용자 정의 6',
          V0060: '사용자 정의 7',
          V0061: '사용자 정의 8',
          V0062: '사용자 정의 9',
          V0063: '사용자 정의 10',
        },
      },
    },
  },
}

export function convertLanguageStructureForDevLocale(languageData) {
  const format = languageData
  const converted = convertLanguageForDev(format)
  return converted
}

export function convertLanguageStructureForTreeList(languageData) {
  const format = languageData
  const convertTreeList = createLocalizationTreeAdjusted(format)
  return convertTreeList
}

export function convertLanguageStructureForDatagrid(languageData) {
  const format = languageData
  const convertTreeList = flattenLanguageDataToItems(format)
  return convertTreeList
}

function convertLanguageForDev(data) {
  // Initialize empty objects for each language
  const flattened = {
    en: {},
    ja: {},
    kr: {},
  }

  // Function to capitalize the first letter of each word and replace dots with underscores
  // This function is already correctly capitalizing each segment, so it should work as intended.
  function formatKey(key) {
    return (
      key
        .split('.')
        // .map((part) => part.charAt(0).toUpperCase() + part.slice(1))
        .join('_')
    )
  }

  // Recursive function to process each node
  function processNode(node, path = '') {
    Object.keys(node).forEach((key) => {
      const value = node[key]
      if (key === 'data' && typeof value === 'object') {
        // Process language-specific keys directly under the current path
        Object.keys(value).forEach((lang) => {
          Object.keys(value[lang]).forEach((langKey) => {
            // Ensure the path segments are properly capitalized before joining
            let fullPath = path ? `${path}_${langKey}` : langKey
            fullPath = formatKey(fullPath) // Apply formatting to ensure correct capitalization
            flattened[lang][fullPath] = value[lang][langKey]
          })
        })
      } else if (typeof value === 'object' && !(value instanceof Array)) {
        // Adjust the path construction here to ensure segments are capitalized before being added to the path
        let newPath = path ? `${path}_${key.charAt(0).toUpperCase() + key.slice(1)}` : key.charAt(0).toUpperCase() + key.slice(1) // Capitalize the key before adding it to the path
        processNode(value, newPath)
      }
      // Non-object values are not directly added since they are not expected in this data structure
    })
  }

  // Start processing from the root
  processNode(data)

  return flattened
}

function createLocalizationTreeAdjusted(data, parentId = 0, path = '') {
  let tree = []
  let currentId = 1 // Start with an ID that increments for each node

  const processNode = (node, parentId, path) => {
    Object.keys(node).forEach((key) => {
      const item = node[key]
      const nodeId = currentId++
      const nodeText = `${key[0].toUpperCase() + key.slice(1)}`

      if (typeof item === 'object' && item.hasOwnProperty('data')) {
        // If the object has a 'data' field, treat this object as a leaf node
        tree.push({
          id: nodeId,
          parentId: parentId,
          text: nodeText, // Use the parent key as the node label
          isLeaf: true,
        })
      } else if (typeof item === 'object' && !(item instanceof Array)) {
        // If it's a non-'data' object, add the node and process its children
        tree.push({
          id: nodeId,
          parentId: parentId,
          text: nodeText,
          isLeaf: false,
        })
        processNode(item, nodeId, nodeText)
      }
    })
  }

  processNode(data, parentId, path)

  return tree
}

function flattenLanguageDataToItems(languageData) {
  const handleScroll = (e) => {
    setScrollPosition(e.scrollOffset.top)
  }

  const items = [] // This will hold the flattened items
  let idCounter = 1 // Initialize counter for item IDs

  function processNode(prefix, node, type = '-', page = '-') {
    // Default values set to '-'
    // Check if the node is a 'data' node, indicating the need to create items
    if (node.hasOwnProperty('data')) {
      const data = node.data
      Object.keys(data.en).forEach((key) => {
        const item = {
          id: idCounter++, // Use and increment the counter for each item
          type: type !== '' ? type : '-', // Ensure type is not empty
          page: page !== '' ? page : '-', // Ensure page is not empty
          key: key,
          en: data.en[key],
          ja: data.ja[key],
          kr: data.kr[key],
        }
        items.push(item)
      })
    } else {
      // If not a 'data' node, recurse through the structure
      Object.keys(node).forEach((subKey) => {
        const newPath = prefix ? `${prefix}_${subKey}` : subKey
        let nextType = type,
          nextPage = page
        if (type === '-') {
          // Check if type is the default placeholder
          nextType = subKey
        } else if (page === '-') {
          // Check if page is the default placeholder
          nextPage = subKey
        }
        processNode(newPath, node[subKey], nextType, nextPage)
      })
    }
  }

  processNode('', languageData) // Start processing from the root

  return items // Return the flattened structure
}
