import { Suspense, lazy, useCallback, useEffect, useRef, useState } from 'react'
import { useTheme } from 'src/contexts/theme'
import './ProbabilityPlotChartForm.scss'
// import * as vcImpl from 'src/components/'
import { batch, connect } from 'react-redux'
import { AutoSizer, Grid } from 'react-virtualized'
import DQLoadPanel from 'src/components/dq-load-form/DQLoadPanel'
import { useLanguage } from 'src/contexts/languages'
import CPUtils from 'src/functions/CommonPageUtils'
import PageWarningMessage from 'src/functions/PageWarningMessage'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import { makeChartOption } from './chart-option/ProbabilityPlotChartOption'
import CCUtils from 'src/functions/CommonCalcUtils'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import { getProbability } from 'src/api/probability-plot'
import ApiStatus from 'src/api/defines/ApiStatus'
import produce from 'immer'
import * as probImpl from './chart-option/ProbabilityPlotChartOptionImpl'
import DNUtils from 'src/functions/DisplayNumberUtils'
import { getErrCommMsg } from 'src/functions/CommonMassageUtils'
import { outlierFilterValues } from 'src/functions/ValueProcessUtils'

const mapStateToProps = (state) => ({
  common: state.common,
  chartRawData: state.chartRawData,
})

export default connect(mapStateToProps)(ProbabilityPlotChartForm)

// Lazy load the component
const ApacheEChartPTComponent = lazy(() => import('../chart/ApacheEChartPTComponent'))

const styles = {
  chartContainer: {
    padding: '5px',
  },
  chartInnerContainer: {
    width: '100%',
    height: '100%',
    padding: '10px',
    borderRadius: '10px',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
    overflow: 'visible',
  },
}

function distributionName(dist) {
  const defineUseDistribution = [
    { value: 0, type: 'Auto-calculation' },
    { value: 1, type: 'Normal-distribution' },
    { value: 2, type: 'Log-normal-distribution' },
    { value: 21, type: 'Folded-normal-distribution' },
    { value: 30, type: 'Weibull-distribution' },
  ]

  const targetDistribution = defineUseDistribution.find((item) => item.value === dist)

  return targetDistribution.type
}

function makeSettings(curPage, configSettings, targetChar, targetValue) {
  const { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset } = PCUtils.getAllLimits(targetChar, configSettings)
  const limits = { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset }
  const filteredValue = outlierFilterValues(limits, targetChar, targetValue, configSettings)
  const { lcl, ucl, average, minValue, maxValue, v0001s } = PCUtils.getCalculatedPrms(filteredValue, configSettings)

  // const srcData = targetValue.map((value) => value.v0001)
  const srcData = v0001s

  const x99_865 = ucl
  const x0_135 = lcl

  const minValues = [minValue, lsl, x0_135].filter((value) => value !== null)
  const maxValues = [maxValue, usl, x99_865].filter((value) => value !== null)
  const min = Math.min(...minValues)
  const max = Math.max(...maxValues)
  const xAxisLeft = min - (max - min) * 0.1
  const xAxisRight = max + (max - min) * 0.1
  const decimalPlaces = configSettings.commonSettings.calcDisplayDecimalPlaces

  const settings = {
    ...curPage,
    lsl,
    usl,
    lwl,
    uwl,
    upl,
    lpl,
    url,
    lrl,
    uacl,
    lacl,
    x0_135,
    x99_865,
    nominal,
    char: targetChar,
    valuesReference: filteredValue,
    nominal,
    // warnOffset,
    xAxisLeft,
    xAxisRight,
    decimalPlaces,
    srcData,
    ...configSettings,
  }

  // const configSettings = configPage

  return { targetChar, targetValue, settings, configSettings }
}

function ProbabilityPlotChartForm({ pageKey, common, chartRawData }) {
  const { getTheme } = useTheme()
  const { language } = useLanguage()
  const [isLoadingPanel, setIsLoadingPanel] = useState(false)

  // const probChartRefs = new Array(numCharts).fill(null).map(() => useRef({ element: null, chartElement: null, id: null }))
  const probChartRefs = useRef(null)
  const [rawData, setRawData] = useState([])
  const [calcDataTheoValue, setCalcDataTheoValue] = useState([])
  const [calcDataTheoQualtile, setCalcDataTheoQualtile] = useState([])
  const [chartOptions, setChartOptions] = useState([])

  const gridRef = useRef(null) // Create a ref for the Grid component

  const curPage = common.pages.probabilityPlotChart
  const configPage = common.pages.configuration
  const currentSel = common.current_sel
  const selectedChar = common.loaded_all[pageKey]

  const columnCount = curPage.page.layout.column
  const rowCount = Math.ceil(chartOptions.length / columnCount)

  const makeProbabilityPlotOption = async (settings, configSettings, targetChar, targetValue) => {
    try {
      const result = await getProbability(targetChar, targetValue, settings, configSettings)
      if (!ApiStatus.isSuccess(result.status)) {
        alert(getErrCommMsg(result.status))
      }

      const data = result.data
      return data
    } catch (error) {
      console.error('Error in makeHistogramOption:', error)
    }

    return []
  }

  useEffect(() => {
    const { parts, chars, values } = CPUtils.loadRefChartData(chartRawData)
    const { charArray } = CPUtils.loadRefOtherData(common, chars, pageKey)
    const selectedValueWithDummy = CPUtils.checkMatchCharsAndValues(charArray, values)
    const valuesArranged = CPUtils.removeDummyInValues(selectedValueWithDummy)
    const numCharts = chars.length
    probChartRefs.current = new Array(numCharts).fill(null).map(() => ({ element: null, chartElement: null, id: null }))

    const processChartOptions = async () => {
      setIsLoadingPanel(true)
      try {
        // Fetch all chart options concurrently
        let probPlotData = []
        let targetCalcData = calcDataTheoValue
        let targetSetCalcData = setCalcDataTheoValue

        if (curPage.page.yAxisType === 'Theoretical-Value') {
          targetCalcData = calcDataTheoValue
          targetSetCalcData = setCalcDataTheoValue
        } else {
          targetCalcData = calcDataTheoQualtile
          targetSetCalcData = setCalcDataTheoQualtile
        }

        if (valuesArranged.length > 0) {
          await Promise.all(
            valuesArranged.map(async (value) => {
              const targetChar = value.charRaw
              const targetValue = value.valueRaw

              if (targetValue.length < 5) {
                probPlotData.push({
                  info: { part_id: targetChar.part_id, char_id: targetChar.char_id },
                  targetChar,
                  targetValue,
                  settings: null,
                  configSettings: null,
                  responseData: null,
                  error: { name: 'DataIsNotEnough', extra: targetValue.length },
                })
                return
              }

              const { settings } = makeSettings(curPage, configPage, targetChar, targetValue)
              const isAdded = targetCalcData.some((data) => data.info.part_id === value.part_id && data.info.char_id === value.char_id)

              if (!isAdded) {
                const responseData = await makeProbabilityPlotOption(settings, configPage, targetChar, settings.valuesReference)
                if (responseData.hasOwnProperty('line_x_values')) {
                  probPlotData.push({
                    info: { part_id: targetChar.part_id, char_id: targetChar.char_id },
                    targetChar,
                    targetValue,
                    settings,
                    // configSettings: configPage,
                    responseData,
                    error: { name: null, extra: null },
                  })
                } else {
                  probPlotData.push({
                    info: { part_id: targetChar.part_id, char_id: targetChar.char_id },
                    targetChar,
                    targetValue,
                    settings: null,
                    // configSettings: null,
                    responseData: null,
                    error: { name: 'Deviation', extra: 0 },
                  })
                }
              } else {
                const tgData = targetCalcData.find((data) => data.info.part_id === value.part_id && data.info.char_id === value.char_id)
                probPlotData.push(tgData)
              }
            })
          )

          probPlotData.sort((a, b) => {
            if (a.info.part_id !== b.info.part_id) {
              return a.info.part_id - b.info.part_id
            } else {
              return a.info.char_id - b.info.char_id
            }
          })

          const probPlotChartOptions = probPlotData.map((data) => {
            const targetChar = data.targetChar
            const targetValue = data.targetValue
            const settings = data.settings
            // const configSettings = data.configSettings
            const responseData = data.responseData

            if (data.error.name !== null) {
              return { info: { part_id: targetChar.part_id, char_id: targetChar.char_id, charReference: targetChar }, option: null, error: { name: data.error.name, extra: data.error.extra } }
            }

            const settingsValue = { ...settings, ...curPage }
            const configSettings = configPage

            const valueIntegerLineX = CCUtils.convertExponentialArrayToInt(responseData.line_x_values)
            const valueIntegerLineY = CCUtils.convertExponentialArrayToInt(responseData.line_y_values)
            const valueIntegerPointX = CCUtils.convertExponentialArrayToInt(responseData.point_x_values)
            const valueIntegerPointY = CCUtils.convertExponentialArrayToInt(responseData.point_y_values)

            const valueIntegerX0_135 = CCUtils.convertExponentialValueToInt(responseData.x0_135)
            const valueIntegerX99_869 = CCUtils.convertExponentialValueToInt(responseData.x99_865)

            const lineMin = Math.min(...valueIntegerLineX)
            const lineMax = Math.max(...valueIntegerLineX)
            const pointMin = Math.min(...valueIntegerPointX)
            const pointMax = Math.max(...valueIntegerPointX)
            const dataMin = lineMin < pointMin ? lineMin : pointMin
            const dataMax = pointMax < lineMax ? pointMax : lineMax

            const minValues = [dataMin, settingsValue.lsl].filter((value) => value !== null)
            const maxValues = [dataMax, settingsValue.usl].filter((value) => value !== null)

            const min = Math.min(...minValues, valueIntegerX0_135)
            const max = Math.max(...maxValues, valueIntegerX99_869)
            const xAxisLeft = min - (max - min) * 0.1
            const xAxisRight = max + (max - min) * 0.1
            const yAxisBottom = dataMin - (dataMax - dataMin) * 0.1
            const yAxisTop = dataMax + (dataMax - dataMin) * 0.1

            // const probOption = chartRef.current.getEchartsInstance().getOption()
            const probOption = makeChartOption(pageKey, curPage, configSettings, targetChar, targetValue)

            const next = produce(probOption, (draft) => {
              if (pageKey !== 'reportPrint') {
                draft.xAxis[0].axisLabel.fontSize = ((settingsValue.page.fontSize - 2) / (settingsValue.page.layout.row + settingsValue.page.layout.column)) * 3
                draft.yAxis.axisLabel.fontSize = ((settingsValue.page.fontSize - 2) / (settingsValue.page.layout.row + settingsValue.page.layout.column)) * 3
              } else {
                draft.xAxis[0].axisLabel.fontSize = 10
                draft.yAxis.axisLabel.fontSize = 10
              }
              draft.series = []
              draft.series.push({
                type: 'line',
                name: 'line',
                symbol: 'none',
                // data: responseData.line_x_values.map((x, index) => [x, responseData.line_y_values[index]]),
                data: valueIntegerLineX.map((x, index) => [x, valueIntegerLineY[index]]),
                animation: true,
                lineStyle: {
                  type: settingsValue.lineChart.chartLines.type,
                  width: settingsValue.lineChart.chartLines.width,
                  color: settingsValue.lineChart.chartLines.color,
                },
                animation: true,
              })
              draft.series.push({
                type: 'scatter',
                name: dqTranslateMsg('Page_ProbabilityPlotChart_' + 'ValueInfo'),
                xAxisIndex: 0,
                yAxisIndex: 0, // Ensure it's linked to the second (right) Y-axis
                // data: responseData.point_x_values.map((x, index) => [x, CCUtils.convertExponentialValueToInt(responseData.point_y_values[index])]),
                data: valueIntegerPointX.map((x, index) => [x, valueIntegerPointY[index]]),
                symbol: (value, params) => probImpl.makeSymbol(value, params, settingsValue),
                symbolSize: (value, params) => probImpl.makeSymbolSize(value, params, settingsValue),
                animation: true,
                itemStyle: {
                  color: (params) => probImpl.makeSymbolColor(params, settingsValue),
                },
              })
              draft.series.push({
                type: 'line',
                name: dqTranslateMsg('Page_ProbabilityPlotChart_' + 'Limits'),
                yAxisIndex: 0,
                markLine: {
                  animation: false,
                  symbol: 'none',
                  precision: 10,
                  // precision: settingsValue.lineChart.markLines.precision,
                  data: probImpl.convertToMarkLine(responseData, settingsValue, configSettings),
                  emphasis: {
                    disabled: true,
                  },
                },
              })

              if (pageKey !== 'reportPrint') {
                draft.title.text = `${targetChar.c2002 ?? 'empty'}P:${targetChar.part_id ?? '?'}/C:${targetChar.char_id ?? '?'}`
                draft.title.text += ' : ' + distributionName(responseData.dist)
                draft.title.subtext = 'R² = ' + DNUtils.calcValueDisplay(responseData.r2, configSettings)
              }

              draft.xAxis[0].min = xAxisLeft
              draft.xAxis[0].max = xAxisRight
              // draft.xAxis[0].axisLabel.formatter = (value, index) => DNUtils.calcValueDisplay(value, configSettings)
              // draft.yAxis.axisLabel.formatter = (value, index) => DNUtils.calcValueDisplay(value, configSettings)
              draft.xAxis[0].axisLabel.formatter = function (value) {
                // return DNUtils.formatToExponential(PCUtils.applyDecimalPlaces(value, decimalPlaces), isExponential)
                // return DNUtils.calcValueDisplay(values, configSettings)
                const axisMax = xAxisRight
                const axisMin = xAxisLeft

                // 최대값과 최소값은 레이블을 표시하지 않음
                if (value === axisMin || value === axisMax) {
                  return ''
                }

                return value // 그 외의 값은 그대로 표시
              }

              draft.yAxis.min = settings.page.yAxisType === 'Theoretical-Value' ? yAxisBottom : 0
              draft.yAxis.max = settings.page.yAxisType === 'Theoretical-Value' ? yAxisTop : 100
              draft.yAxis.axisLabel.formatter = function (value) {
                if (settings.page.yAxisType === 'Theoretical-Value') {
                  const axisMax = yAxisTop
                  const axisMin = yAxisBottom

                  if (value === axisMin || value === axisMax) {
                    return ''
                  }

                  return value
                } else {
                  return value
                }
              }
            })

            return { info: { part_id: targetChar.part_id, char_id: targetChar.char_id, charReference: targetChar }, option: next, error: { name: 'DataIsNotEnough', extra: targetValue.length } }
          })

          batch(() => {
            targetSetCalcData(probPlotData)
            setChartOptions(probPlotChartOptions)
          })
        } else {
          batch(() => {
            targetSetCalcData([])
            setChartOptions([])
          })
        }
      } catch (error) {
        console.error('Error fetching chart options:', error)
      } finally {
        setIsLoadingPanel(false)
      }
    }

    processChartOptions()
  }, [selectedChar, curPage, configPage])

  useEffect(() => {
    const targetRefId = `${currentSel.part_id}_${currentSel.char_id}`
    const targetIndex = chartOptions.findIndex((chart) => `${chart.info.part_id}_${chart.info.char_id}` === targetRefId)

    if (targetIndex !== -1 && gridRef.current) {
      const rowIndex = Math.floor(targetIndex / columnCount)
      const columnIndex = targetIndex % columnCount

      gridRef.current.scrollToCell({ columnIndex, rowIndex })

      setTimeout(() => {
        const targetElement = probChartRefs?.current[targetIndex]?.element
        if (targetElement) {
          targetElement.classList.add('target-element')
          targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' })

          // 애니메이션이 끝난 후 클래스를 제거합니다.
          targetElement.addEventListener(
            'animationend',
            () => {
              targetElement.classList.remove('target-element')
            },
            { once: true }
          )
        }
      }, 0)
    }
  }, [currentSel, probChartRefs, chartOptions, columnCount])

  /**
   * Render Component
   */

  const renderCell = useCallback(({ columnIndex, rowIndex, style, key, parent }) => {
    const index = rowIndex * columnCount + columnIndex
    if (index >= chartOptions.length) return null

    const chart = chartOptions[index]
    return (
      <div
        key={`${chart.info.part_id}_${chart.info.char_id}`}
        ref={(e) => {
          probChartRefs.current[index].element = e
          probChartRefs.current[index].id = `${chart.info.part_id}_${chart.info.char_id}`
        }}
        style={{ ...style, padding: '3px' }} // Apply padding for the gap
      >
        <div style={styles.chartInnerContainer}>
          <Suspense fallback={<div>{dqTranslateMsg('Common_' + 'LoadPanelLoading')}</div>}>
            {chart.option === null ? (
              <PageWarningMessage
                fontSize={(curPage.page.fontSize / (curPage.page.layout.row + curPage.page.layout.column)) * 5}
                message={
                  `${chart.info.charReference.c2002 ?? 'empty'} P${chart.info.part_id ?? '?'}/C${chart.info.char_id ?? '?'}` +
                  ' - ' +
                  dqTranslateMsg('Page_ProbabilityPlotChart_' + chart.error.name) +
                  ` (${chart.error.extra})`
                }
              />
            ) : (
              <ApacheEChartPTComponent
                key={`${chart.info.part_id}_${chart.info.char_id}_chart`}
                rawData={{
                  parts: rawData.parts,
                  chars: rawData.chars,
                  values: rawData.values,
                  charArray: rawData.charArray,
                  curPage,
                  configPage,
                  valuesArranged: rawData.valuesArranged,
                }}
                info={chart.info}
                option={chart.option}
                theme={getTheme()}
                pageKey={pageKey}
              />
            )}
          </Suspense>
        </div>
      </div>
    )
  })

  return (
    <div className={'dx-theme-background-color'}>
      <DQLoadPanel open={isLoadingPanel} message={dqTranslateMsg('Common_' + 'LoadPanelLoading')} />
      <div className="chart-form-content" style={{ width: '100%', height: '100%' }}>
        {chartOptions.length > 0 ? (
          <AutoSizer>
            {({ height, width }) => (
              <Grid
                ref={gridRef} // Assign the ref to the Grid component
                className="custom-scrollbar"
                columnCount={columnCount}
                columnWidth={width / curPage.page.layout.column} // Adjust column width to account for the margin
                height={height}
                rowCount={rowCount}
                rowHeight={height / curPage.page.layout.row} // Adjust row height to account for the margin
                width={width}
                cellRenderer={renderCell}
                style={{ overflowX: 'hidden', overflowY: 'auto' }}
              />
            )}
          </AutoSizer>
        ) : null}
      </div>
    </div>
  )
}
