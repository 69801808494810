import { Form, ScrollView } from 'devextreme-react'
import produce from 'immer'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from 'src/contexts/auth'
import DQSaveReset from 'src/functions/DQSaveReset'
import DQToast from 'src/functions/DQToast'
import { valueChartSettingsPrm } from 'src/pages/chart-value/components/settings/ValueChartSettings'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'
import './SidePanelReportPrintMultiFunctionSettings.scss'
import SettingsReportMode from './components/settings/SettingsReportMode'
import SettingsSelectTemplate from './components/settings/SettingsSelectTemplate'
import SettingsPageLayoutPadding from './components/settings/SettingsPageLayoutPadding'
import SettingsRectElementsList from './components/settings/SettingsRectElementsList'
import SettingsRectElementsTypeAndItemList from './components/settings/SettingsRectElementsTypeAndItemList'
import { reportPrintMultiFunctionSettingsPrm } from '../settings/ReportPrintMultiFunctionSettings'

export default function SidePanelReportPrintMultiFunctionSettings(props) {
  const { pageKey } = props
  const dispatch = useDispatch()
  const parentRef = useRef(null)
  const toastRef = useRef(null)

  const [scrollPosition, setScrollPosition] = useState(0)
  const { user } = useAuth()
  const common = useSelector((state) => state.common)
  const curPage = common.pages.reportPrintMultiFunction
  const configPage = common.pages.configuration

  const isCommonEmpty = Object.keys(configPage.useFieldList).length === 0
  const isLsUseFieldListEmpty = Object.keys(configPage.useFieldList).length === 0

  let useFieldList = {}
  if (isCommonEmpty === false) {
    useFieldList = configPage.useFieldList
  } else if (isLsUseFieldListEmpty === false) {
    useFieldList = configPage.useFieldList
  } else {
    useFieldList = {
      part: [],
      char: [],
      value: [],
    }
  }

  const handleCurPageSettingsSave = (updatePageData) => {
    dqsImpl.setCurPageSettings(dispatch, user, pageKey, updatePageData)
  }

  const handleSaveButtonClicked = () => {
    const next = produce(curPage, (draft) => {
      Object.keys(curPage).forEach((key) => {
        draft[key] = curPage[key]
      })
    })

    handleCurPageSettingsSave(next)
    toastRef.current.handleToastDisplay('success', dqTranslateMsg('SidePanel_' + 'SavedSettings'))
  }
  const handleResetButtonClicked = () => {
    const next = produce(curPage, (draft) => {
      Object.entries(reportPrintMultiFunctionSettingsPrm.page).forEach(([key, value]) => {
        draft.page[key] = value
      })
    })

    handleCurPageSettingsSave(next)
    toastRef.current.handleToastDisplay('warning', dqTranslateMsg('SidePanel_' + 'ResetSettings'))
  }
  // const items = [SettingsReportMode(pageKey), SettingsSelectTemplate(pageKey), SettingsPageLayoutPadding(), SettingsRectElementsList(), SettingsRectElementsTypeAndItemList()]
  const items = [SettingsReportMode(pageKey), SettingsSelectTemplate(pageKey)]

  return (
    <div ref={parentRef} style={{ padding: '5px', width: '100%', height: '100%' }}>
      <DQToast ref={toastRef} />
      {/* Function Buttons */}
      <div style={{ width: '100%', height: '3%' }}>
        <DQSaveReset saveBtnText={dqTranslateMsg('SidePanel_' + 'Save')} resetBtnText={dqTranslateMsg('SidePanel_' + 'Reset')} saveCallback={handleSaveButtonClicked} resetCallback={handleResetButtonClicked} />
      </div>
      <div style={{ width: '100%', height: '97%' }}>
        <ScrollView className="side-panel-tab-container" style={{ padding: 0 }} showScrollbar={'onHover'} useNative={false}>
          <Form id="side-panel-report-multi-function-settings-form" colCount={1} style={{ paddingTop: '5px' }} labelMode={'outside'} formData={curPage.page} showColonAfterLabel={false}>
            {items}
          </Form>
        </ScrollView>
      </div>
    </div>
  )
}
