import * as defines from './constants/constants'

const getTargetPreDBDefine = (field) => {
  let compData = null
  if (field === 'part') {
    compData = defines.DBPart
  } else if (field === 'char') {
    compData = defines.DBChar
  } else {
    compData = defines.DBValue
  }
  return compData
}

export const getFieldData = (obj, key) => {
  try {
    if (!obj.hasOwnProperty(key)) {
      console.error(`Key "${key}" is not defined in the preDef object`)
      return false
    }

    return obj[key]
  } catch (err) {
    console.error('(getFieldData) Unexpected error:', err)
    throw err
  }
}

/**
 * <Validation>
 * src = []
 * preDef = {}
 */

const checkValidationInner = (src, preDef) => {
  try {
    src.forEach((element) => {
      for (const key in element) {
        if (element[key] === null) {
          continue
        }

        if (typeof element[key] === preDef[key].type) {
          continue
        }

        if (typeof element[key] !== preDef[key].type) {
          console.error(`"${key}" is not Valid (Desired : ${preDef[key].type}, Current type : ${typeof element[key]}, Current value : ${element[key]}`)
        }
      }
    })

    return true
  } catch (err) {
    console.error(`${key}`)
    throw err
  }
}

export const checkPartValidation = (src) => {
  try {
    const preDef = getTargetPreDBDefine('part')
    const result = checkValidationInner(src, preDef)
    return result
  } catch (err) {
    throw err
  }
}
export const checkCharValidation = (src) => {
  try {
    const preDef = getTargetPreDBDefine('char')
    const result = checkValidationInner(src, preDef)
    return result
  } catch (err) {
    throw err
  }
}
export const checkValueValidation = (src) => {
  try {
    const preDef = getTargetPreDBDefine('value')
    const result = checkValidationInner(src, preDef)
    return result
  } catch (err) {
    throw err
  }
}

export const getCurrentFilterData = (rdxPages) => {
  try {
    const databasePage = rdxPages['database']
    const filterMode = databasePage.page.filterMode
    let filterData = null

    let filterTypeObj
    if (filterMode === 'quick') {
      filterTypeObj = databasePage.filters.quick
    } else {
      filterTypeObj = databasePage.filters.advanced
    }

    if (filterTypeObj) {
      const filterNo = filterTypeObj.selectedFilterNo
      const savedFilters = filterTypeObj.savedFilters
      if (filterNo === 0) {
        filterData = {
          common: filterTypeObj.common,
          others: filterTypeObj.others,
          data: filterTypeObj.data,
        }
      } else {
        if (savedFilters.length > 0 && filterNo <= savedFilters.length) {
          filterData = filterTypeObj.savedFilters[filterNo - 1]
        } else {
          console.error('Filter Selected No is not valid')
          filterData = null
        }
      }
    }

    return { mode: filterMode, data: filterData }
  } catch (err) {
    console.error(err)
  }
}
