import { Button } from 'devextreme-react'
import { useState } from 'react'
import SidePanelForm from 'src/pages/components/side-panel-form/SidePanelForm'
import './PageContainer.scss'

const PageContainer = ({ pageKey, ChartFormComponent }) => {
  const [showSidePanel, setShowSidePanel] = useState(true)
  const [buttonTop, setButtonTop] = useState('95%')
  const [dragging, setDragging] = useState(false)
  const [selectedTabIndex, setSelectedTabIndex] = useState(0)
  const [disableSidePanel, setDisableSidePanel] = useState(false)
  const [hover, setHover] = useState(false)

  const handleMouseDown = (e) => {
    setDragging(true)
  }

  const handleMouseMove = (e) => {
    if (dragging) {
      const containerRect = e.currentTarget.getBoundingClientRect()
      const newTop = e.clientY - containerRect.top
      const newTopVh = (newTop / window.innerHeight) * 100

      if (newTopVh >= 5 && newTopVh <= 90) {
        setButtonTop(`${newTopVh}vh`)
      }
    }
  }

  const handleMouseUp = () => {
    setDragging(false)
  }

  const handleMouseEnter = () => {
    setHover(true)
  }

  const handleMouseLeave = () => {
    if (!dragging) {
      setHover(false)
    }
  }
  return (
    <div className="page-container" style={{ position: 'relative', display: 'flex' }} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp}>
      <div
        className="chart-container"
        style={{
          flexGrow: 1,
          transition: 'width 0.3s',
          width: showSidePanel ? (pageKey === 'scrollBoard' ? '75%' : '80%') : '100%',
        }}
      >
        <ChartFormComponent pageKey={pageKey} setDisableSidePanel={setDisableSidePanel} />
      </div>
      {showSidePanel && (
        <div className="side-panel-container" style={{ width: pageKey === 'scrollBoard' ? '25%' : '20%', transition: 'width 0.3s' }}>
          <SidePanelForm pageKey={pageKey} disable={disableSidePanel} setSidePanelState={setDisableSidePanel} selectedTabIndex={selectedTabIndex} setSelectedTabIndex={setSelectedTabIndex} />
        </div>
      )}
      <div
        onMouseDown={handleMouseDown}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          position: 'absolute',
          top: buttonTop,
          right: '10px',
          zIndex: 1000,
          transform: 'translateY(-50%)',
          cursor: 'grab',
        }}
      >
        {hover && <Button icon={'expand'} />}
        <Button onClick={() => setShowSidePanel(!showSidePanel)} icon={showSidePanel ? 'chevrondoubleright' : 'chevrondoubleleft'} />
      </div>
    </div>
  )
}

export default PageContainer
