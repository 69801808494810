// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dx-theme-background-color {
  width: 100%;
  height: 100%;
  padding: 5px;
  display: flex;
}
.dx-theme-background-color .admin-push-settings-detail-container {
  width: 80%;
  height: 100%;
}
.dx-theme-background-color .admin-push-settings-detail-container .view-settings-grid {
  width: 100%;
  height: 100%;
}
.dx-theme-background-color .admin-push-settings-target-container {
  width: 20%;
  height: 100%;
}
.dx-theme-background-color .admin-push-settings-target-container .view-user-tree {
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/components/main-view/push-settings/AdminPushSettings.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;AACF;AACE;EACE,UAAA;EACA,YAAA;AACJ;AACI;EACE,WAAA;EACA,YAAA;AACN;AAGE;EACE,UAAA;EACA,YAAA;AADJ;AAGI;EACE,WAAA;EACA,YAAA;AADN","sourcesContent":[".dx-theme-background-color {\r\n  width: 100%;\r\n  height: 100%;\r\n  padding: 5px;\r\n  display: flex;\r\n\r\n  .admin-push-settings-detail-container {\r\n    width: 80%;\r\n    height: 100%;\r\n\r\n    .view-settings-grid {\r\n      width: 100%;\r\n      height: 100%;\r\n    }\r\n  }\r\n\r\n  .admin-push-settings-target-container {\r\n    width: 20%;\r\n    height: 100%;\r\n\r\n    .view-user-tree {\r\n      width: 100%;\r\n      height: 100%;\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
