import ApiStatus from 'src/api/defines/ApiStatus'
import produce from 'immer'
import { extractPresentationIds } from './ScrollBoardValueChartImpl'
import { makeIdsForCharData } from './ScrollBoardValueChartImpl'
import { makeFilters } from './ScrollBoardValueChartImpl'
import { makeIdsForValueData } from './ScrollBoardValueChartImpl'
import * as DBUtils from 'src/functions/DatabaseUtils'
import * as dtoUtils from 'src/dto/DTOImpl'
import { makeArrangedData } from 'src/pages/components/dq-arrange/DQArrange'
import { batch } from 'react-redux'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import { getErrCommMsg } from 'src/functions/CommonMassageUtils'

const parseContent = (stepReference) => {
  const charIndex = stepReference.fields.findIndex((item) => item === '2000')
  const fields = stepReference.fields
  const targetStepData = stepReference.steps[charIndex]
  if (targetStepData) {
    const tgKey = targetStepData.key
    const filterArray = []
    if (tgKey) {
      let splitted = tgKey.split('\u0014')

      for (let ii = 0; ii < charIndex; ii++) {
        filterArray.push({ [fields[ii]]: splitted[ii] })
      }
    }
    return filterArray
  }
}

const procValueChartPage = (tempStepReference, userId, springApi, setStepReference, curPage, configSettings, dbFilterInfo) => {
  const curStep = tempStepReference.current.curStep
  const charStep = tempStepReference.current.steps.find((item) => item.step === curStep - 1)
  const fields = tempStepReference.current.fields
  const types = tempStepReference.current.types

  const chartIDList = extractPresentationIds(charStep.data)
  if (chartIDList) {
    const stepFilter = {
      part: {},
      char: {},
      value: {},
    }
    const idForChar = makeIdsForCharData(chartIDList)
    const filters = makeFilters(curPage, stepFilter)
    springApi.getCharListFilter(idForChar, filters).then((result) => {
      if (!ApiStatus.isSuccess(result.status)) {
        alert(getErrCommMsg(result.status))
      }

      console.log('<CharListFilter Load Success>')
      const charAllData = result.data.flat()
      const convertedCharDTO = dtoUtils.makeCharInfoToDTOFormat(charAllData, configSettings)
      if (!DBUtils.checkCharValidation(convertedCharDTO)) {
        throw new Error(`Invalid Char Data`)
      }

      const charTargetData = chartIDList.map((id) => {
        const matchedData = convertedCharDTO.find((data) => data.part_id === id.part_id && data.char_id === id.char_id)
        return matchedData || {} // Return the found object or an empty object if no match is found
      })

      const idsForValue = makeIdsForValueData(chartIDList)
      const userFilters = parseContent(tempStepReference.current)
      springApi.getValueInfoScrollBoard(idsForValue, curPage.page, filters, userFilters).then((result) => {
        if (!ApiStatus.isSuccess(result.status)) {
          alert(getErrCommMsg(result.status))
        }

        const data = result.data
        if (data.length !== 0) {
          const isValid = DBUtils.checkValueValidation(data)
          if (isValid) {
            const filterData = dbFilterInfo.data
            const valueDTO = dtoUtils.makeValueInfoToDTOFormat(data)
            const arrangeOpt = filterData.others.arrangement
            const latestOpt = filterData.others.ignoreDuplicatedValue
            const sortOpt = filterData.common.dateTimeSort

            charAllData.forEach((id) => valueDTO.find((char) => char.part_id === id.part_id && char.char_id === id.char_id))

            const partIds = charAllData.map((item) => item.part_id)
            const uniquePartIds = new Set(partIds)
            const uniquePartObjects = Array.from(uniquePartIds).map((part_id) => ({
              part_id,
            }))

            const arrangedData = makeArrangedData(arrangeOpt, latestOpt, sortOpt, uniquePartObjects, charTargetData, valueDTO)
            const sortedData = arrangedData.sort((a, b) => {
              if (a.part_id === b.part_id) {
                return a.char_id - b.char_id
              }
              return a.part_id - b.part_id
            })

            batch(() => {
              const valueChartData = {
                char: charTargetData,
                value: sortedData,
              }

              const nextStepReference = {
                step: curStep,
                key: '',
                data: valueChartData,
                dataIndex: 0,
                // dataIndex: dataIndex,
                dataTotalLen: valueChartData.char.length,
                field: fields[curStep],
                type: types[curStep],
              }
              const next = produce(tempStepReference.current, (draft) => {
                draft.curStep = curStep
                const existIdx = draft.steps.findIndex((step) => step.step === curStep)
                if (existIdx !== -1) {
                  draft.steps[existIdx] = nextStepReference
                } else {
                  draft.steps.push(nextStepReference)
                }
              })

              tempStepReference.current = next
              setStepReference(next)
            })
          }
        }
      })
    })
  }
}
const procCommonPage = async (tempStepReference, userId, springApi) => {
  const { curStep, nextStep, curKey } = tempStepReference.current

  try {
    const result = await springApi.getIBoardResultData({
      user_id: userId,
      field_data: curStep,
      key_data: curStep === 0 ? undefined : curKey, // 조건부 속성 추가
    })

    if (!ApiStatus.isSuccess(result.status)) {
      alert(getErrCommMsg(result.status))
    }

    return result.data
  } catch (error) {
    console.error('Error in procCommonPage:', error)
    throw error // 프로미스에서 에러를 던져서 상위 호출자에서 처리할 수 있도록 합니다.
  }
}

export const goNextPageAuto = (tempStepReference, userId, springApi, setStepReference, curPage, configSettings, isValueChartOperating, pageField, dbFilterInfo) => {
  const { curStep, nextStep, curKey, types } = tempStepReference.current
  const curType = types[curStep]
  if (curType === 'ValueChart') {
    isValueChartOperating.current = true
    procValueChartPage(tempStepReference, userId, springApi, setStepReference, curPage, configSettings, dbFilterInfo)
  } else {
    let promise
    promise = procCommonPage(tempStepReference, userId, springApi)
    promise
      .then((data) => {
        const curStepData = tempStepReference.current.steps.find((item) => item.step === curStep)

        // data 처리
        // char visible
        let filteredData
        if (pageField === '2000') {
          const isBadVisible = curPage.page.chartBadVisible
          const isWarnVisible = curPage.page.chartWarningVisible
          const isGoodVisible = curPage.page.chartGoodVisible

          filteredData = data.filter((item) => {
            const badVisibleCondition = isBadVisible ? item.result_ng_count > 0 : false
            const warnVisibleCondition = isWarnVisible ? item.result_warning_count > 0 : false
            const goodVisibleCondition = isGoodVisible ? item.result_good_count > 0 : false

            return badVisibleCondition || warnVisibleCondition || goodVisibleCondition
          })
        } else {
          filteredData = data
        }

        const chartOrder = curPage.page.barContentOrder
        if (chartOrder === 'none') {
          filteredData = filteredData
        } else if (chartOrder === 'asc') {
          filteredData.sort((a, b) => {
            return a.result_id.localeCompare(b.result_id)
          })
        } else {
          // desc
          filteredData.sort((a, b) => {
            return b.result_id.localeCompare(a.result_id)
          })
        }

        let updated
        if (curStepData) {
          updated = produce(curStepData, (draft) => {
            draft.key = curKey
            draft.data = filteredData
            draft.dataTotalLen = filteredData.length
            draft.field = tempStepReference.current.fields[curStep]
            draft.type = tempStepReference.current.types[curStep]
          })
        } else {
          updated = {
            step: curStep,
            key: curKey,
            data: filteredData,
            dataIndex: 0,
            dataTotalLen: filteredData.length,
            field: tempStepReference.current.fields[curStep],
            type: tempStepReference.current.types[curStep],
          }
        }

        const next = produce(tempStepReference.current, (draft) => {
          const existIdx = draft.steps.findIndex((item) => item.step === curStep)
          if (existIdx !== -1) {
            draft.steps[existIdx] = updated
          } else {
            draft.steps.push(updated)
          }
        })

        tempStepReference.current = next
        setStepReference(next)
      })
      .catch((error) => {
        console.error('Error processing page:', error)
      })
  }
}
