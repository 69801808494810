import CCUtils from 'src/functions/CommonCalcUtils'
import * as DBUtils from 'src/functions/DatabaseUtils'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'
import CatalogUtils from './CatalogUtils'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'
import { toolTipBalloonCallback, makeXAxisLabelFormat } from './ValueChartOptionUtils'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import { getInvertedRGBA, setBorderColor } from './apache-echarts/ApacheEChartsUtils'
import * as CDSUtils from 'src/functions/CheckDataStateUtils'
import { outlierFilterValues } from './ValueProcessUtils'
import { makeSymbol, makeSymbolColor, makeSymbolSize } from './apache-echarts/ApacheEChartsSymbolCallback'

// class ValueChartUtils {
/**
 * Storage
 */

export function adjustedForecastSampleSize(settingForecastSize, settingSampleSize, valueSize) {
  // forecast size is less than settingSampleSize - 3 (api spec)
  const sampleSize = settingSampleSize < valueSize ? settingSampleSize : valueSize
  const forecastSize = settingForecastSize < sampleSize - 4 ? settingForecastSize : sampleSize - 4

  return { sampleSize, forecastSize }
}

/**
 * ValueChart Parameter
 */
export function checkMatchCharsAndValues(selectedChars, values) {
  const arrangedSrc = values.filter((value) => {
    return selectedChars.some((char) => char.part_id === value.part_id && char.char_id === value.char_id)
  })
  return arrangedSrc
}

export function removeDummyInValues(valuesWithDummy) {
  const removedValues = valuesWithDummy.map((item) => ({
    ...item,
    valueRaw: item.valueRaw.filter((value) => value.v0002 === 0),
  }))

  return removedValues
}

export const calculateYAxisSpace = (props) => {
  const { settings, usl, lsl, uwl, lwl, upl, lpl, url, lrl, uacl, lacl, maxValue, minValue } = props
  const {
    page: { specificationLimitEnable, warningLimitEnable, plausibilityLimitEnable, scrapLimitEnable, acceptanceLimitEnable },
  } = settings

  const t_usl = specificationLimitEnable ? usl ?? maxValue : maxValue
  const t_lsl = specificationLimitEnable ? lsl ?? minValue : minValue

  const t_uwl = warningLimitEnable ? uwl ?? maxValue : maxValue
  const t_lwl = warningLimitEnable ? lwl ?? minValue : minValue

  const t_upl = plausibilityLimitEnable ? upl ?? maxValue : maxValue
  const t_lpl = plausibilityLimitEnable ? lpl ?? minValue : minValue

  const t_url = scrapLimitEnable ? url ?? maxValue : maxValue
  const t_lrl = scrapLimitEnable ? lrl ?? minValue : minValue

  const t_uacl = acceptanceLimitEnable ? uacl ?? maxValue : maxValue
  const t_lacl = acceptanceLimitEnable ? lacl ?? minValue : minValue

  const max = Math.max(t_usl, t_uwl, t_upl, t_url, t_uacl, maxValue)
  const min = Math.min(t_lsl, t_lwl, t_lpl, t_lrl, t_lacl, minValue)

  let space = Math.abs(max - min)
  if (space === 0) space = 1

  const additionalSpace = space * 0.1

  return { max, min, additionalSpace }
}

export const makeYAxisTop = (props) => {
  const { max, additionalSpace } = calculateYAxisSpace(props)
  return max + additionalSpace
}

export const makeYAxisBottom = (props) => {
  const { min, additionalSpace } = calculateYAxisSpace(props)
  return min - additionalSpace
}

// export const makeSymbol = (value, params, settings) => {
//   const val = params.data[1]
//   const { lineChart, usl, uwl, lwl, lsl } = settings

//   const natMode = PCUtils.getTypeUpperWarningLimit(settings.char) === 2 || PCUtils.getTypeLowerWarningLimit(settings.char) === 2
//   const symbols = lineChart.symbols

//   const prmProps = { val, usl, lsl, uwl, lwl, natMode }
//   const valueState = CDSUtils.getValueState(prmProps)

//   if (valueState === CDSUtils.GOOD_STATE) {
//     return symbols.good.shape
//   } else if (valueState === CDSUtils.WARN_UPPER_STATE) {
//     return symbols.warn.upper.shape
//   } else if (valueState === CDSUtils.WARN_LOWER_STATE) {
//     return symbols.warn.lower.shape
//   } else if (valueState === CDSUtils.NG_UPPER_STATE) {
//     return symbols.error.upper.shape
//   } else {
//     //if (valueState === CDSUtils.NG_LOWER_STATE) {
//     return symbols.error.lower.shape
//   }
// }

// export const makeSymbolSize = (value, params, settings) => {
//   const val = params.data[1]
//   const { lineChart, usl, uwl, lwl, lsl } = settings
//   // const natMode = settings.commonSettings.useNaturalBoundary
//   const natMode = PCUtils.getTypeUpperWarningLimit(settings.char) === 2 || PCUtils.getTypeLowerWarningLimit(settings.char) === 2
//   const symbols = lineChart.symbols

//   const prmProps = { val, usl, lsl, uwl, lwl, natMode }
//   const valueState = CDSUtils.getValueState(prmProps)

//   if (valueState === CDSUtils.GOOD_STATE) {
//     return symbols.good.size
//   } else if (valueState === CDSUtils.WARN_UPPER_STATE) {
//     return symbols.warn.upper.size
//   } else if (valueState === CDSUtils.WARN_LOWER_STATE) {
//     return symbols.warn.lower.size
//   } else if (valueState === CDSUtils.NG_UPPER_STATE) {
//     return symbols.error.upper.size
//   } else {
//     //if (valueState === CDSUtils.NG_LOWER_STATE) {
//     return symbols.error.lower.size
//   }
// }

// export const makeSymbolColor = (params, settings) => {
//   const val = params.data[1]
//   const { lineChart, usl, uwl, lwl, lsl } = settings
//   // const natMode = settings.commonSettings.useNaturalBoundary
//   const natMode = PCUtils.getTypeUpperWarningLimit(settings.char) === 2 || PCUtils.getTypeLowerWarningLimit(settings.char) === 2
//   const symbols = lineChart.symbols

//   const prmProps = { val, usl, lsl, uwl, lwl, natMode }
//   const valueState = CDSUtils.getValueState(prmProps)

//   if (valueState === CDSUtils.GOOD_STATE) {
//     return symbols.good.color
//   } else if (valueState === CDSUtils.WARN_UPPER_STATE) {
//     return symbols.warn.upper.color
//   } else if (valueState === CDSUtils.WARN_LOWER_STATE) {
//     return symbols.warn.lower.color
//   } else if (valueState === CDSUtils.NG_UPPER_STATE) {
//     return symbols.error.upper.color
//   } else {
//     //if (valueState === CDSUtils.NG_LOWER_STATE) {
//     return symbols.error.lower.color
//   }
// }

export const convertToChartLine = (settings) => ({
  type: settings.lineChart.chartLines.type,
  width: settings.lineChart.chartLines.width,
  color: settings.lineChart.chartLines.color,
})

export const convertToMarkLine = (settings, pageKey) => {
  const createMarkLine = (enabled, labelEnabled, labelName, yAxisValue, lineSettings, natState) => {
    const position = 'insideEndTop'
    const fontFamily = 'Arial'
    const fontSize = pageKey === 'reportPrint' ? 7 : (settings.page.fontSize / (settings.page.layout.row + settings.page.layout.column)) * 3

    return {
      show: yAxisValue !== null && enabled,
      name: labelEnabled ? labelName : '',
      yAxis: yAxisValue,
      label: {
        position: position,
        formatter: (args) => args.name,
        fontFamily: fontFamily,
        fontSize: fontSize,
      },
      lineStyle: {
        type: natState ? 'dotted' : lineSettings?.type,
        color: lineSettings?.color,
        width: lineSettings?.width,
      },
      labelLayout: { moveOverlap: 'shiftY' },
    }
  }

  const { specificationLimitEnable, warningLimitEnable, nominalLimitEnable, averageLimitEnable, plausibilityLimitEnable, scrapLimitEnable, acceptanceLimitEnable } = settings.page
  const { specificationLimitLabelEnable, warningLimitLabelEnable, nominalLimitLabelEnable, averageLimitLabelEnable, plausibilityLimitLabelEnable, scrapLimitLabelEnable, acceptanceLimitLabelEnable } =
    settings.page

  const { specLimit, warnLimit, nominal, average, plausibilityLimit, scrapLimit, acceptanceLimit } = settings.lineChart.markLines

  // const useNat = settings.commonSettings.useNaturalBoundary
  const isUpperNat = PCUtils.getTypeUpperWarningLimit(settings.char) === 2
  const isLowerNat = PCUtils.getTypeLowerWarningLimit(settings.char) === 2

  const targetML = [
    {
      limitEnable: specificationLimitEnable,
      labelEnable: specificationLimitLabelEnable,
      name: 'LSL',
      value: settings.lsl,
      config: specLimit,
      // natState: useNat && isLowerNat
      natState: isLowerNat,
    },
    {
      limitEnable: specificationLimitEnable,
      labelEnable: specificationLimitLabelEnable,
      name: 'USL',
      value: settings.usl,
      config: specLimit,
      // natState: useNat && isUpperNat
      natState: isUpperNat,
    },
    { limitEnable: warningLimitEnable, labelEnable: warningLimitLabelEnable, name: 'LWL', value: settings.lwl, config: warnLimit, natState: false },
    { limitEnable: warningLimitEnable, labelEnable: warningLimitLabelEnable, name: 'UWL', value: settings.uwl, config: warnLimit, natState: false },
    { limitEnable: nominalLimitEnable, labelEnable: nominalLimitLabelEnable, name: 'Nominal', value: settings.nominal, config: nominal, natState: false },
    { limitEnable: averageLimitEnable, labelEnable: averageLimitLabelEnable, name: 'Average', value: settings.average, config: average, natState: false },
    { limitEnable: plausibilityLimitEnable, labelEnable: plausibilityLimitLabelEnable, name: 'UPL', value: settings.upl, config: plausibilityLimit, natState: false },
    { limitEnable: plausibilityLimitEnable, labelEnable: plausibilityLimitLabelEnable, name: 'LPL', value: settings.lpl, config: plausibilityLimit, natState: false },
    { limitEnable: scrapLimitEnable, labelEnable: scrapLimitLabelEnable, name: 'URL', value: settings.url, config: scrapLimit, natState: false },
    { limitEnable: scrapLimitEnable, labelEnable: scrapLimitLabelEnable, name: 'LRL', value: settings.lrl, config: scrapLimit, natState: false },
    { limitEnable: acceptanceLimitEnable, labelEnable: acceptanceLimitLabelEnable, name: 'UAcl', value: settings.uacl, config: acceptanceLimit, natState: false },
    { limitEnable: acceptanceLimitEnable, labelEnable: acceptanceLimitLabelEnable, name: 'LAcl', value: settings.lacl, config: acceptanceLimit, natState: false },
  ]

  // Create markLines using map
  const markLines = targetML.map((item) => createMarkLine(item.limitEnable, item.labelEnable, item.name, item.value, item.config, item.natState))

  // Filter out mark lines where show is false, and remove 'show' property
  return markLines.filter((line) => line.show).map(({ show, ...rest }) => rest)
}
export const convertToMarkArea = (settings) => {
  const createMarkArea = (showCondition, yAxisStart, yAxisEnd, color) => [{ show: showCondition }, { yAxis: yAxisStart }, { yAxis: yAxisEnd, itemStyle: { color: color } }]

  const { usl, lsl, uwl, lwl, yAxisTop, yAxisBottom } = settings
  const { error, warn, good } = settings.lineChart.markAreas

  // Define targetMA with all mark area objects
  const targetMA = [
    { show: usl !== null && yAxisTop !== null, fromValue: usl, toValue: yAxisTop, color: error.color },
    { show: lsl !== null && yAxisBottom !== null, fromValue: lsl, toValue: yAxisBottom, color: error.color },
    { show: uwl !== null && usl !== null, fromValue: uwl, toValue: usl, color: warn.color },
    { show: lwl !== null && lsl !== null, fromValue: lwl, toValue: lsl, color: warn.color },
    { show: lwl !== null && uwl !== null, fromValue: lwl, toValue: uwl, color: good.color },
  ]

  // Generate markAreas using map based on targetMA
  const markAreas = targetMA
    .filter((item) => item.show) // Only create areas where the show condition is true
    .map((item) => createMarkArea(item.show, item.fromValue, item.toValue, item.color))

  // Filter out areas where 'show' is false and remove the 'show' property
  const removeShowMarkAreas = markAreas.map((innerArray) => innerArray.filter((item) => !item.show))

  return removeShowMarkAreas
}

export function makeSeriesDataSplit(settings) {
  const flatValues = settings.filteredData.flat()
  const listItem = flatValues.flat().map((value) => value[settings.page.splitItem[0]])
  const uniqueList = [...new Set(listItem)]

  const valueData = uniqueList.map((item) => {
    return {
      name: item ?? '',
      value: flatValues
        .map((obj, index) => {
          if (obj[settings.page.splitItem[0]] === item) {
            return [index + 1, obj.v0001]
          }
        })
        .filter((item) => item !== undefined),
    }
  })

  console.log(valueData)

  return valueData
}

export const makeSeries = (settings, pageKey, theme) => {
  let dispValue = []
  let isSplitChart = false
  if (settings.page.splitItem.length > 0) {
    isSplitChart = true
    dispValue = makeSeriesDataSplit(settings)
  } else {
    const flatValues = settings.filteredData.flat()
    dispValue = [
      {
        name: dqTranslateMsg('Page_ValueChart_' + 'ValueInfo'),
        value: flatValues.map((value, index) => {
          return [index + 1, value.v0001]
        }),
      },
    ]
  }

  let seriesData = []

  seriesData.push({
    type: 'line',
    markLine: {
      silent: true, // 마우스 이벤트 무시
      animation: false,
      symbol: 'none',
      precision: 10,
      data: convertToMarkLine(settings, pageKey),
    },
  })
  seriesData.push({
    type: 'line',
    markArea: {
      // silent: true, // 마우스 이벤트 무시
      data: convertToMarkArea(settings),
    },
  })

  // splited
  dispValue.map((data, index) =>
    seriesData.push({
      type: 'line',
      name: isSplitChart === true ? CatalogUtils.convertByCatalog(settings.page.splitItem[0], data.name, settings.catalogs) : `${data.name}`,
      data: data.value,
      //
      symbol: (value, params) => makeSymbol(value, params, settings),
      symbolSize: (value, params) => makeSymbolSize(value, params, settings),
      animation: true,
      itemStyle: {
        color: (params) => makeSymbolColor(params, settings),
      },
      ...(isSplitChart === false
        ? {
            lineStyle: convertToChartLine(settings),
          }
        : null),
      emphasis: {
        disabled: false,
        focus: 'none',
        scale: 2,
        itemStyle: {
          borderColor: setBorderColor(theme),
        },
      },
    })
  )

  return seriesData
}

export function makePCData(configSettings, char, value) {
  const { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset } = PCUtils.getAllLimits(char, configSettings)
  const limits = { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset }
  const filteredValue = outlierFilterValues(limits, char, value, configSettings)

  const { lcl, ucl, average, minValue, maxValue, v0001s } = PCUtils.getCalculatedPrms(filteredValue, configSettings)

  return {
    lsl,
    usl,
    nominal,
    lwl,
    uwl,
    upl,
    lpl,
    url,
    lrl,
    lacl,
    uacl,
    ucl,
    lcl,
    valuesReference: filteredValue,
    v0001s,
    average,
    minValue,
    maxValue,
  }
}

export function makeChartData(pageSet, configSettings, pcSet, char, values) {
  const titleText = `${DBUtils.getFieldData(char, 'c2002') ?? 'empty'} P:${DBUtils.getFieldData(char, 'part_id') ?? '?'} / C:${DBUtils.getFieldData(char, 'char_id') ?? '?'}`
  const xAxisRight = pcSet.v0001s.length + 1
  const xAxisLeft = 0

  const topBottomCalcArray = {
    settings: pageSet,
    usl: pcSet.usl,
    lsl: pcSet.lsl,
    uwl: pcSet.uwl,
    lwl: pcSet.lwl,
    upl: pcSet.upl,
    lpl: pcSet.lpl,
    url: pcSet.url,
    lrl: pcSet.lrl,
    uacl: pcSet.uacl,
    lacl: pcSet.lacl,
    maxValue: pcSet.maxValue,
    minValue: pcSet.minValue,
  }

  const yAxisTop = makeYAxisTop(topBottomCalcArray)
  const yAxisBottom = makeYAxisBottom(topBottomCalcArray)
  const decimalPlaces = DNUtils.getValueDecimalPlaces(char, configSettings)

  const filteredData = pcSet.valuesReference
  const maxValue = pcSet.maxValue
  const minValue = pcSet.minValue

  return {
    titleText,
    maxValue,
    minValue,
    xAxisRight,
    xAxisLeft,
    yAxisBottom,
    yAxisTop,
    decimalPlaces,
    filteredData,
  }
}

export function makeChartOption(pageKey, pageSet, configSettings, pcSet, chartSet, char, values, theme) {
  try {
    // const pcSet = makePCData(configSettings, char, values)
    // const chartSet = makeChartData(pageSet, configSettings, pcSet, char, values)

    const settings = {
      ...pageSet,
      ...configSettings,
      ...pcSet,
      ...chartSet,
      char,
    }

    return {
      title:
        pageKey !== 'reportPrint'
          ? {
              text: settings.titleText,
              left: 'center',
              textStyle: {
                fontFamily: 'Arial',
                fontSize: (settings.page.fontSize / (settings.page.layout.row + settings.page.layout.column)) * 4,
              },
            }
          : null,
      animationDuration: 0,
      backgroundColor: 'rgba(255, 255, 255, 0)',
      grid: {
        top: '15%',
        bottom: '7%',
        left: '0%',
        right: '5%',
        containLabel: true,
      },
      legend:
        settings.page.layout.column > 3
          ? null
          : {
              type: 'scroll',
              top: '6%',
              orient: 'horizontal',
            },
      tooltip: {
        trigger: 'axis',
        appendToBody: true,
        position: function (pos, params, el, elRect, size) {
          var obj = { top: 10 }
          obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30
          return obj
        },
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        extraCssText: 'width: auto',
        formatter: function (args) {
          return toolTipBalloonCallback(args, settings, configSettings, values, char)
        },
        textStyle: {
          fontFamily: 'Arial',
        },
        borderColor: undefined,
        axisPointer: { type: 'cross', z: 9999 },
      },
      toolbox:
        pageKey !== 'reportPrint'
          ? {
              feature: {
                saveAsImage: {},
              },
              itemSize: (15 / (settings.page.layout.row + settings.page.layout.column)) * 4,
            }
          : null,
      xAxis: {
        type: 'value',
        min: settings.xAxisLeft,
        max: settings.xAxisRight,
        minInterval: 1,
        maxInterval: settings.page.xAxisDenseLabel ? 2 : settings.xAxisRight < 10 ? 2 : Math.abs(Math.floor(settings.xAxisRight / 5)),
        data: values.map((value, index) => [index + 1]),
        axisLabel: {
          rotate: 90,
          fontFamily: 'Arial',
          fontSize: pageKey !== 'reportPrint' ? ((settings.page.fontSize - 2) / (settings.page.layout.row + settings.page.layout.column)) * 3 : 10,
          hideOverlap: true,
          showMinLabel: false,
          showMaxLabel: false,
          formatter: (value, index) => makeXAxisLabelFormat(value, index, settings, values),
        },
        axisLine: {
          onZero: false,
          // hideOverlap: true,
        },
        splitLine: {
          show: false,
        },
      },
      yAxis: {
        min: settings.yAxisBottom, // y축 최소값
        max: settings.yAxisTop, // y축 최대값
        splitNumber: 10, // 축의 분할 개수
        nameTextStyle: {
          fontFamily: 'Arial', // 축 이름 텍스트의 폰트 설정
        },
        axisLabel: {
          fontFamily: 'Arial', // 축 레이블의 폰트 설정
          fontSize:
            pageKey !== 'reportPrint'
              ? ((settings.page.fontSize - 2) / (settings.page.layout.row + settings.page.layout.column)) * 3 // 페이지 크기에 따른 폰트 크기 계산
              : 10, // 'reportPrint' 페이지에서는 고정 크기
          showMinLabel: false, // 최소값 레이블 표시 여부
          showMaxLabel: false, // 최대값 레이블 표시 여부
          formatter: function (value) {
            const axisMax = settings.yAxisTop
            const axisMin = settings.yAxisBottom

            // 최대값과 최소값은 레이블을 표시하지 않음
            if (value === axisMin || value === axisMax) {
              return ''
            }

            return value // 그 외의 값은 그대로 표시
          },
        },
      },
      dataZoom: [
        {
          show: true,
          type: 'slider',
          orient: 'horizontal',
          xAxisIndex: 0,
          height: `3%`,
          bottom: '3%',
          brushSelect: true,
          showDataShadow: true,
          showDetail: false,
          filterMode: 'none',
        },
        {
          show: true,
          type: 'slider',
          orient: 'vertical',
          width: `3%`,
          brushSelect: true,
          showDataShadow: true,
          showDetail: false,
          filterMode: 'none',
        },
        {
          show: true,
          type: 'inside',
          yAxisIndex: 0,
          filterMode: 'none',
          zoomLock: false, // Allow zooming
          zoomOnMouseWheel: 'shift', // Zoom on Shift + MouseWheel
        },
        {
          show: true,
          type: 'inside',
          xAxisIndex: 0,
          filterMode: 'none',
        },
      ],
      series: makeSeries(settings, pageKey, theme),
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}
