export function extractIdsInfo(input) {
  const regex = /\u000f(\d+),(\d+)/
  const match = input.match(regex)

  if (match && match.length >= 3) {
    return {
      part_id: parseInt(match[1], 10),
      char_id: parseInt(match[2], 10),
    }
  } else {
    return undefined
  }
}

export const extractIds = (data) => {
  const extractItemsIds = (key) => {
    // const itemIds = keys.map((key) => {
    // "Korea\u0014EDWARDS KOREA A41100036 iGX 3rd Stage Rotor Y ISSUE C TDS 9236\u0014unknown\u00142/Diameter_BORE'A'FRONT(\u000f14,2)"
    const itemIds = extractIdsInfo(key)
    return itemIds
  }

  // const resultKes = data.map((item) => item.result_key)
  const resultKes = data
  const extracted = extractItemsIds(resultKes)
  const ids = extracted

  return ids
}

export const extractPresentationIds = (data) => {
  const extractItemsIds = (keys) => {
    const itemIds = keys.map((key) => extractIdsInfo(key))

    return itemIds
  }

  const resultKeys = data.map((item) => item.result_key)
  // const resultKes = data
  const extracted = extractItemsIds(resultKeys)
  const ids = extracted

  return ids
}

export const makeStepFilter = (orderChartField, currentStep) => {
  let splittedField = orderChartField.split('|')
  let splitted = currentStep.resultKey.split('\u0014')

  const stepFilter = {
    part: [],
    char: [],
    value: [],
  }
  for (let idx = 0; idx < currentStep.step; idx++) {
    if (Number(splittedField[idx]) > 0 && Number(splittedField[idx]) < 1000) {
      stepFilter.value.push({ ['v' + splittedField[idx]]: splitted[idx] })
    } else if (Number(splittedField[idx]) >= 1000 && Number(splittedField[idx]) < 2000) {
      stepFilter.part.push({ ['p' + splittedField[idx]]: splitted[idx] })
    } else {
      stepFilter.char.push({ ['c' + splittedField[idx]]: splitted[idx] })
    }
  }

  return stepFilter
}

export const makeIdsForCharData = (idsArray) => {
  const partIds = idsArray.map((item) => item.part_id)
  const uniquePartIds = new Set(partIds)
  const uniquePartObjects = Array.from(uniquePartIds).map((part_id) => ({
    part_id,
  }))
  return uniquePartObjects
}

export const parseIdForCharData = (idArray) => {
  const partId = idArray.part_id
  const uniquePartObjects = [{ part_id: partId }]
  return uniquePartObjects
}

export const makeIdsForValueData = (idsArray) => {
  const idsForApi = idsArray.reduce((acc, { part_id, char_id }) => {
    const existingEntry = acc.find((entry) => entry.part_id === part_id)

    if (existingEntry) {
      existingEntry.char_ids.push(char_id)
    } else {
      acc.push({ part_id, char_ids: [char_id] })
    }

    return acc
  }, [])

  return idsForApi
}

export function calcStartDateTime(isDateCalender, dateRangeDayTime, dateRange, dateOffset) {
  if (isDateCalender === 1) {
    return dateRangeDayTime
  }

  switch (dateRange) {
    case 10000:
      return daysAgo(1)
    case 10001:
      return weeksAgo(1)
    case 10002:
      return monthsAgo(1)
    default: // 10003
      return daysAgo(dateOffset)
  }
}

export function calcEndDateTime() {
  return daysAgo(0)
}

export function formatDatetimeWithOffset(days = 0, weeks = 0, months = 0) {
  const now = new Date()

  // 날짜 조정
  now.setDate(now.getDate() - days - weeks * 7)
  now.setMonth(now.getMonth() - months)

  const year = now.getFullYear()
  const month = String(now.getMonth() + 1).padStart(2, '0') // 월은 0부터 시작하므로 1을 더해줍니다.
  const day = String(now.getDate()).padStart(2, '0')
  const hours = String(now.getHours()).padStart(2, '0')
  const minutes = String(now.getMinutes()).padStart(2, '0')
  const seconds = String(now.getSeconds()).padStart(2, '0')

  return `${year}${month}${day}${hours}${minutes}${seconds}`
}

export function daysAgo(days) {
  return formatDatetimeWithOffset(days)
}

export function weeksAgo(weeks) {
  return formatDatetimeWithOffset(0, weeks)
}

export function monthsAgo(months) {
  return formatDatetimeWithOffset(0, 0, months)
}

export const makeFilters = (sbSettings, stepFilter) => {
  const filters = {
    common: {
      easyLoadValue: 1,
      dateTimeSort: 'Ascending',
      startDateTime: calcStartDateTime(sbSettings.page.dateRangeIsDayTime, sbSettings.page.dateRangeDayTime, sbSettings.page.dateRangeSetting, sbSettings.page.dateRangeDayOffset),
      endDateTime: calcEndDateTime(),
    },
    // part: stepFilter.part,
    // char: stepFilter.char,
    // value: stepFilter.value,
    data: {
      part: {},
      char: {},
      value: {},
    },
    others: {
      arrangement: 'None',
      dateTimeEasySetting: 'last1m',
      useEasyLoad: false,
      easyLoadValueType: 'count',
      ignoreDuplicatedValue: false,
      useDateTime: false,
      pageCalculationInAdvance: false,
    },
  }

  return { mode: 'quick', data: filters }
}
