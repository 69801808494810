import { Form, Switch } from 'devextreme-react'
import { Button } from 'devextreme-react/button'
import { Column, DataGrid, Editing, Item, Lookup, Scrolling, SearchPanel, Selection, Toolbar } from 'devextreme-react/data-grid'
import RadioGroup from 'devextreme-react/radio-group'
import { Toast } from 'devextreme-react/toast'
import produce from 'immer'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DQLoadPanel from 'src/components/dq-load-form/DQLoadPanel'
import { useAuth } from 'src/contexts/auth'
import { defMeasuredQuantity } from 'src/default/find-distribution/find-distribution-define'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'
import * as sd from 'src/pages/components/side-panel-form/component/define/SettingsDefine'
import { setConfiguration } from 'src/store/common'
import { configurationSettingsPrm } from '../../settings/ConfigSettings'
import './FindDistribution.scss'
import { useLanguage } from 'src/contexts/languages'
import { GroupItem, SimpleItem } from 'devextreme-react/form'

export default function FindDistribution({ pageKey }) {
  const dispatch = useDispatch()
  const { user } = useAuth()
  const { language } = useLanguage()
  const [loading, setLoading] = useState(false)

  const usingMQGridRef = useRef(null)
  const reservedMQGridRef = useRef(null)

  const common = useSelector((state) => state.common)
  const configPage = common.pages.configuration
  const configAll = common.base
  const settings = configPage

  const allowedMQ = defMeasuredQuantity
    .map((item1) => {
      const matchingItem = settings.findDistribution.allowMeasuredQuantity.find((item2) => item2[0] === item1.value)

      if (matchingItem) {
        return { ...item1, DistributionForMQ: matchingItem[1] }
      } else {
        return null
      }
    })
    .filter((item) => item !== null)
  const reservedMQ = defMeasuredQuantity.map((item) => (settings.findDistribution.allowMeasuredQuantity.includes(item.value) === false ? item : null)).filter((item) => item !== null)

  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: 'info',
    message: '',
  })

  const handleToastDisplay = (type, message) => {
    setToastConfig({
      ...toastConfig,
      isVisible: true,
      type,
      message,
    })
  }

  function onHiding() {
    setToastConfig({
      ...toastConfig,
      isVisible: false,
    })
  }

  const handleSaveButton = () => {
    dqsImpl.setCurBaseSettings(dispatch, user, configAll, 'configuration', configPage)
    handleToastDisplay('success', dqTranslateMsg('Page_Configuration_' + 'SavedSettings'))
  }

  const handleResetButton = () => {
    const next = produce(configPage, (draft) => {
      draft.findDistribution = configurationSettingsPrm.findDistribution
    })

    dqsImpl.setCurBaseSettings(dispatch, user, configAll, 'configuration', next)
    handleToastDisplay('warning', dqTranslateMsg('Page_Configuration_' + 'ResetSettings'))
  }

  const handleSavedDistributionChange = (target) => {
    const next = produce(settings, (draft) => {
      draft.findDistribution.useSavedDistribution = target.value
    })

    dispatch(setConfiguration(next))
  }
  const handleMeasuredQuantityChange = (target) => {
    const next = produce(settings, (draft) => {
      draft.findDistribution.useMeasuredQuantity = target.value
    })

    dispatch(setConfiguration(next))
  }
  const handleDefaultDistributionChange = (target) => {
    const next = produce(settings, (draft) => {
      draft.findDistribution.useDefaultDistribution = target.value
    })

    dispatch(setConfiguration(next))
  }

  const handleOneSideDefaultDistributionChange = (target) => {
    const next = produce(settings, (draft) => {
      draft.findDistribution.oneSideDefaultDistribution = target.value
    })

    dispatch(setConfiguration(next))
  }
  const handleTwoSideDefaultDistributionChange = (target) => {
    const next = produce(settings, (draft) => {
      draft.findDistribution.twoSideDefaultDistribution = target.value
    })

    dispatch(setConfiguration(next))
  }
  const handleAddMeasureQuantity = () => {
    const selRowData = reservedMQGridRef.current.instance.getSelectedRowsData()

    const next = produce(settings, (draft) => {
      selRowData.forEach((item2) => {
        if (!draft.findDistribution.allowMeasuredQuantity.some((item1) => item1[0] === item2.value)) {
          draft.findDistribution.allowMeasuredQuantity.push([item2.value, sd.defaultDistributionSideTypeDetailsDefine()[0].value])
        }
      })
    })
    dispatch(setConfiguration(next))
  }

  const handleRemoveMeasureQuantity = () => {
    const selRowData = usingMQGridRef.current.instance.getSelectedRowsData()
    // const next = produce(settings, (draft) => {
    //   draft.findDistribution.reservedMeasuredQuantity = selRowData
    // })

    const next = produce(settings, (draft) => {
      selRowData.forEach((item2) => {
        const index = draft.findDistribution.allowMeasuredQuantity.findIndex((item1) => item1[0] === item2.value)
        if (index !== -1) {
          draft.findDistribution.allowMeasuredQuantity.splice(index, 1)
        }
      })
    })
    dispatch(setConfiguration(next))
  }

  const onRowUpdating = (e) => {
    let is_cancel = true
    const targetMQ = settings.findDistribution.allowMeasuredQuantity.find((item) => item[0] === e.oldData.value)
    let updatedTargetMQ
    if (targetMQ) {
      // 새로운 배열을 생성하고 변경된 값을 설정
      updatedTargetMQ = [targetMQ[0], e.newData.DistributionForMQ]
      const next = produce(settings, (draft) => {
        const index = draft.findDistribution.allowMeasuredQuantity.findIndex((item) => item[0] === e.oldData.value)
        if (index !== -1) {
          draft.findDistribution.allowMeasuredQuantity.splice(index, 1)
        }
        draft.findDistribution.allowMeasuredQuantity.push(updatedTargetMQ)
      })

      dispatch(setConfiguration(next))
      is_cancel = false
    }

    e.cancel = is_cancel
  }

  return (
    <div className="find-distribution-container">
      <DQLoadPanel open={loading} />
      <Toast width={400} visible={toastConfig.isVisible} message={toastConfig.message} type={toastConfig.type} onHiding={onHiding} displayTime={600} />
      <div className="find-distribution-control-container">
        <div className="button-container">
          <Button id="partLoadButton" icon="save" text={dqTranslateMsg('Page_Configuration_' + 'Save')} width={'100%'} height={'100%'} onClick={handleSaveButton} />
        </div>
        <div className="button-container">
          <Button id="partLoadButton" icon="undo" text={dqTranslateMsg('Page_Configuration_' + 'Reset')} width={'100%'} height={'100%'} onClick={handleResetButton} />
        </div>
      </div>
      <div className="find-distribution-content-container">
        <div className="find-distribution-two-division-container">
          <div className="find-distribution-base-settings-content">
            <div className="find-distribution-grid-container">
              <DataGrid
                className="find-distribution-datagrid-content-reserved"
                ref={reservedMQGridRef}
                id="reservedMQGrid"
                keyExpr="value"
                dataSource={reservedMQ}
                columnResizingMode="widget"
                allowColumnReordering={true}
                allowColumnResizing={true}
                // selectedRowKeys={selectedRowKeys}
                noDataText={dqTranslateMsg('Page_Configuration_' + 'NoData')}
                paging={{ enabled: false }}
                showColumnLines={true}
                showRowLines={true}
                showBorders={true}
                rowAlternationEnabled={false}
                hoverStateEnabled={true}
              >
                <Toolbar>
                  <Item location="before">
                    <div className="grid-title" style={{ fontSize: '18px' }}>
                      {dqTranslateMsg('Page_Configuration_' + 'NotUsingMeasuredQuantity')}
                    </div>
                  </Item>
                  <Item name="searchPanel" />
                </Toolbar>
                <Selection mode="multiple" />
                <Scrolling mode="standard" rowRenderingMode="standard" useNative={false} />
                <SearchPanel visible={true} width={100} placeholder={dqTranslateMsg('Page_Configuration_' + 'SearchPlaceHoler')} />
                <Column dataField={'value'} caption={dqTranslateMsg('Page_Configuration_' + 'Value')} alignment="center" width={'15%'} />
                <Column dataField={ETCUtils.checkLang(language)} caption={dqTranslateMsg('Page_Configuration_' + 'MeasureQuantity')} alignment="center" />
              </DataGrid>
              <div className="find-distribution-move-button-container">
                <Button className="find-distribution-move-button-content" text=">>" type="success" onClick={handleAddMeasureQuantity} />
                <Button className="find-distribution-move-button-content" text="<<" type="danger" onClick={handleRemoveMeasureQuantity} />
              </div>
              <DataGrid
                className="find-distribution-datagrid-content-assigned"
                ref={usingMQGridRef}
                id="usingMQGrid"
                keyExpr="value"
                dataSource={allowedMQ}
                columnResizingMode="widget"
                allowColumnReordering={true}
                allowColumnResizing={true}
                // selectedRowKeys={selectedRowKeys}
                noDataText={dqTranslateMsg('Page_Configuration_' + 'NoData')}
                paging={{ enabled: false }}
                showColumnLines={true}
                showRowLines={true}
                showBorders={true}
                rowAlternationEnabled={false}
                hoverStateEnabled={true}
                onRowUpdating={onRowUpdating}
              >
                <Toolbar>
                  <Item location="before">
                    <div className="grid-title" style={{ fontSize: '18px' }}>
                      {dqTranslateMsg('Page_Configuration_' + 'UsingMeasuredQuantity')}
                    </div>
                  </Item>
                  <Item name="searchPanel" />
                </Toolbar>
                <Editing mode="cell" allowUpdating={true} />
                <Selection mode="multiple" />
                <Scrolling mode="standard" rowRenderingMode="standard" useNative={false} />
                <SearchPanel visible={true} width={100} placeholder={dqTranslateMsg('Page_Configuration_' + 'SearchPlaceHoler')} />
                <Column dataField={'value'} allowEditing={false} caption={dqTranslateMsg('Page_Configuration_' + 'Value')} alignment="center" width={'10%'} />
                <Column dataField={ETCUtils.checkLang(language)} allowEditing={false} caption={dqTranslateMsg('Page_Configuration_' + 'MeasureQuantity')} alignment="center" />
                <Column dataField="DistributionForMQ" caption={dqTranslateMsg('Page_Configuration_' + 'DistributionForMQ')} alignment="center">
                  <Lookup dataSource={sd.defaultDistributionSideTypeDetailsDefine()} valueExpr="value" displayExpr="text" />
                </Column>
              </DataGrid>
            </div>
            <div
              className="find-distribution-preview-container"
              style={{
                fontSize: '18px',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                // textAlign: 'center',
              }}
            >
              {dqTranslateMsg('Page_Configuration_' + 'PreviewPreparing')}
            </div>
            <div className="find-distribution-lists-container">
              <Form id="configuration-find-distribution-form" style={{ width: '100%', height: '100%' }}>
                <GroupItem caption={dqTranslateMsg('Page_Configuration_' + 'SavedDistribution')}>
                  <SimpleItem>
                    <Switch items={sd.onOffTypeDefine} value={settings.findDistribution.useSavedDistribution} onValueChanged={handleSavedDistributionChange} />
                  </SimpleItem>
                </GroupItem>

                <GroupItem caption={dqTranslateMsg('Page_Configuration_' + 'MeasuredQuantity')}>
                  <SimpleItem>
                    <Switch items={sd.onOffTypeDefine} value={settings.findDistribution.useMeasuredQuantity} onValueChanged={handleMeasuredQuantityChange} />
                  </SimpleItem>
                </GroupItem>

                <GroupItem caption={dqTranslateMsg('Page_Configuration_' + 'DefaultDistribution')}>
                  <SimpleItem>
                    <Switch items={sd.onOffTypeDefine} value={settings.findDistribution.useDefaultDistribution} onValueChanged={handleDefaultDistributionChange} />
                  </SimpleItem>
                </GroupItem>

                <GroupItem caption={dqTranslateMsg('Page_Configuration_' + 'OneSideDefaultDistribution')}>
                  <SimpleItem>
                    <RadioGroup
                      items={sd.defaultDistributionSideTypeDetailsDefine()}
                      value={settings.findDistribution.oneSideDefaultDistribution}
                      onValueChanged={handleOneSideDefaultDistributionChange}
                      valueExpr="value"
                      displayExpr="text"
                    />
                  </SimpleItem>
                </GroupItem>

                <GroupItem caption={dqTranslateMsg('Page_Configuration_' + 'TwoSideDefaultDistribution')}>
                  <SimpleItem>
                    <RadioGroup
                      items={sd.defaultDistributionSideTypeDetailsDefine()}
                      value={settings.findDistribution.twoSideDefaultDistribution}
                      onValueChanged={handleTwoSideDefaultDistributionChange}
                      valueExpr="value"
                      displayExpr="text"
                    />
                  </SimpleItem>
                </GroupItem>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
