const bodyFormat = {
  requestMsg: {},
}

export const makeDeleteAdminCompanyPrms = (company, plant) => {
  let body = { ...bodyFormat }
  body.requestMsg = {
    company: company,
    plant: plant,
  }
  return body
}

export const makeGetAdminCompanyPrms = (company, plant) => {
  let body = { ...bodyFormat }
  body.requestMsg = {
    company: company,
    plant: plant,
  }
  return body
}

export const makeGetAdminCompanyListPrms = () => {
  let body = { ...bodyFormat }
  body.requestMsg = {}
  return body
}

export const makeGetAdminCompanyPlantListPrms = (company) => {
  let body = { ...bodyFormat }
  body.requestMsg = {
    company: company,
  }
  return body
}

const makeUpdateAdminCompanyPrms = (company, plant, updateData) => {
  let body = { ...bodyFormat }
  body.requestMsg = {
    company: company,
    plant: plant,
    ...updateData,
  }
  return body
}
