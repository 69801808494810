export const scrollBoardSettingsPrm = {
  page: {
    savedDataRangeStartDateTime: '',
    savedDataRangeEndDateTime: '',

    // data range
    dateRangeSetting: 10000, // assuming integer value
    dateRangeIsDayTime: 0, // assuming integer value (boolean 0 or 1)
    dateRangeDayOffset: 10, // assuming integer value (boolean 0 or 1)
    dateRangeDayTime: '20240419010100', // assuming string value for time
    dateRangeLastValues: 3, // assuming integer value

    // value option
    ignoreValuesEnable: 0, // assuming integer value (boolean 0 or 1)
    ignoreValuesUslPercentSetting: 101, // assuming float value for percentage
    ignoreValuesLslPercentSetting: 101, // assuming float value for percentage

    // order
    orderChartListOrderValid: true,
    orderChartField: '1303|1002|1072|2000|1', // assuming string value
    orderChartType: 'BarChart|TreeMap|BarChart|BarChart|ValueChart', // assuming string value
    orderChartClass: '1|1|1|1|1', // assuming string value

    // visible
    chartBadVisible: 1, // assuming integer value (boolean 0 or 1)
    chartWarningVisible: 1, // assuming integer value (boolean 0 or 1)
    chartGoodVisible: 1, // assuming integer value (boolean 0 or 1)

    // limitation
    warningOutPercentSetting: 80.0, // assuming float value for percentage
    errorOutPercentSetting: 100.0, // assuming float value for percentage

    // value chart option
    valueChartBalloonField: ['none'], // assuming string value
    valueChartXAxisType: ['none'], // assuming integer value
    // valueChartXAxisAutoGrid: 0, // assuming integer value (boolean 0 or 1)
    // valueChartMultipleView: 0, // assuming integer value (boolean 0 or 1)
    valueChartPopupMode: false, // assuming integer value (boolean 0 or 1)

    // bar chart option
    barChartLimitCount: 10, // assuming integer value
    barContentOrder: 'none', // assuming integer value for order

    // auto page option
    autoPageRestartIndex: 3, // assuming integer value
    autoPageValueChartLayoutX: 3, // Placeholders, unclear description
    autoPageValueChartLayoutY: 2, // Placeholders, unclear description
    autoPageValueChartDeleteDays: 0, // assuming integer value
    autoPageUpdateTime: 10, // assuming integer value for seconds
    autoGraphicUpdateTimeEnable: false,
    autoGraphicUpdateTime: 10, // assuming integer value for seconds

    // etc
    mode: 'manual',
    dataUpdated: false,

    // event modify update flag
    updateEventFlag: false,

    // value chart settings
    current_sel: { part_id: null, char_id: null },
    splitItem: [],

    // toolTipUseSpecLimits: true,
    // toolTipUseWarnLimits: true,
    valueChart: {
      specificationLimitEnable: true,
      warningLimitEnable: true,
      averageLimitEnable: true,
      nominalLimitEnable: true,
      plausibilityLimitEnable: true,
      scrapLimitEnable: true,
      acceptanceLimitEnable: true,
      specificationLimitLabelEnable: true,
      warningLimitLabelEnable: true,
      averageLimitLabelEnable: true,
      nominalLimitLabelEnable: true,
      plausibilityLimitLabelEnable: true,
      scrapLimitLabelEnable: true,
      acceptanceLimitLabelEnable: true,
      xAxisLabelItems: ['v0014'],
      dateTimeLabelDisplayFormat: 'date/time',
      xAxisDenseLabel: true,
      toolTipInfoItems: ['v0001', 'v0004', 'v0010', 'v0014'],
      dateTimeTooltipFormat: 'date/time/sec',
      decimalPlaces: 3,
      fontSize: 18,
      arima: {
        show: false,
        pValue: 2,
        dValue: 0,
        qValue: 2,
        forecastSize: 100,
        sampleSize: 200,
        auto: false,
      },
      showDeletedValue: false,
    },
    qualityControlChart: {
      calculationCount: 5,
      calculationType: 'fixed',
      takeoverFromDataset: false,
      locationChartType: 'average', // average , raw
      handlingIncompleteSubgroup: 'delete', // calculate , delete
      xAxisDenseLabel: true,
      specificationLimitEnable: true,
      warningLimitEnable: true,
      controlLimitEnable: true,
      averageLimitEnable: true,
      nominalLimitEnable: true,
      plausibilityLimitEnable: true,
      scrapLimitEnable: true,
      acceptanceLimitEnable: true,
      specificationLimitLabelEnable: true,
      warningLimitLabelEnable: true,
      controlLimitLabelEnable: true,
      averageLimitLabelEnable: true,
      nominalLimitLabelEnable: true,
      plausibilityLimitLabelEnable: true,
      scrapLimitLabelEnable: true,
      acceptanceLimitLabelEnable: true,
    },

    // value list settings
    dateTimeExtraFieldDisplayFormat: 'date/time/sec', // 'date/time', 'date' (formatting -> yyyy-mm-dd hh:mm:ss)

    calculationType: 'fixed', // or 'fixed'
    // updatedTime
    updatedTime: '',
    updatedCount: 0,
  },
  filter: {
    // part filter
    partFilter: [], // assuming string value
  },
  common: {
    mode: 'manual',
    settingValidation: {
      dataRange: true,
      ignoreValue: true,
      characteristicsClass: true,
      orderChart: true,
    },
  },
  theme: {
    // top order
    topOrderTextFontSize: 12, // assuming integer value
    topOrderTextFontCustomColor: false, // assuming string value
    topOrderTextFontColor: 'rgba(0, 0, 0, 1)', // assuming string value

    // state
    graphicBadSetting: 'rgba(255,0,0,1)', // assuming string value
    graphicWarningSetting: 'rgba(255, 174, 0, 1)', // assuming string value
    graphicGoodSetting: 'rgba(2, 152, 212, 1)', // assuming string value

    // value chart
    valueChartTitleFontSize: 12, // assuming integer value
    valueChartXAxisFontSize: 8, // assuming integer value
    valueChartYAxisFontSize: 8, // assuming integer value
    valueChartSpecLimitsFontSize: 8, // assuming integer value
    valueChartWarnLimitsFontSize: 8, // assuming integer value
    valueChartAutoYAxisFontSize: 18, // assuming integer value
    valueChartYAxisSpecLimitFontColor: 'rgba(255,0,0,1)', // assuming string value
    valueChartYAxisWarnLimitFontColor: 'rgba(255, 174, 0, 1)', // assuming string value
    valueChartYAxisAverageFontColor: 'rgba(0,0,255,1)', // assuming string value

    // bar chart
    barChartXFontSize: 12, // assuming integer value
    barChartYFontSize: 12, // assuming integer value
    barChartFontColorCustomize: false, // assuming string value
    barChartXFontColor: 'rgba(76,76,76,1)', // assuming string value
    barChartYFontColor: 'rgba(76,76,76,1)', // assuming string value
    barChartCountFontSize: 15, // assuming integer value
    barChartCountFontColorCustomize: false, // assuming string value
    barChartCountFontColor: 'rgba(0,0,0,1)', // assuming string value

    // Qcc chart
    qccChartTitleFontSize: 12, // assuming integer value
    qccChartXAxisFontSize: 12, // assuming integer value
    qccChartYAxisFontSize: 12, // assuming integer value
    qccChartRightLimitsFontSize: 12, // assuming integer value

    // rect chart
    treeMapChartFontSize: 15, // assuming integer value
    treeMapChartFontColorCustomize: false, // assuming string value
    treeMapChartFontColor: 'rgba(255,255,255,1)', // assuming string value
    treeMapChartNGRectSize: 10, // assuming string value
    treeMapChartWarnRectSize: 7.5, // assuming string value
    treeMapChartGoodRectSize: 5, // assuming string value

    valueChart: {
      lineChart: {
        symbols: {
          error: {
            upper: {
              shape: 'triangle',
              size: 5,
              color: 'rgba(255, 0, 0, 1)',
            },
            lower: {
              shape: 'path://M 100 100 L 300 100 L 200 300 z',
              size: 5,
              color: 'rgba(255, 0, 0, 1)',
            },
          },
          warn: {
            upper: {
              shape: 'circle',
              size: 4,
              color: 'rgba(255, 136, 0, 1)',
            },
            lower: {
              shape: 'circle',
              size: 4,
              color: 'rgba(255, 136, 0, 1)',
            },
          },
          good: {
            shape: 'none',
            size: 4,
            color: 'rgba(2, 152, 212, 1)',
          },
        },
        chartLines: {
          type: 'solid',
          width: 1,
          color: 'rgba(2, 152, 212, 1)',
        },
        markLines: {
          precision: 10,
          specLimit: {
            show: true,
            type: 'solid',
            color: 'rgba(255, 0, 0, 1)',
            width: 1,
            fontSize: 12,
          },
          warnLimit: {
            show: true,
            type: 'dashed',
            color: 'rgba(255, 136, 0, 1)',
            width: 1,
            fontSize: 12,
          },
          controlLimit: {
            show: true,
            type: 'dashed',
            color: 'rgba(255, 136, 0, 1)',
            width: 1,
            fontSize: 12,
          },
          center: {
            show: true,
            type: 'dashed',
            color: 'rgba(255, 136, 0, 0)',
            width: 1,
            fontSize: 12,
          },
          nominal: {
            show: true,
            type: 'dashed',
            color: 'rgba(0, 0, 255, 1)',
            width: 1,
            fontSize: 12,
          },
          average: {
            show: true,
            type: 'dashed',
            color: 'rgba(0, 0, 255, 1)',
            width: 1,
            fontSize: 12,
          },
          plausibilityLimit: {
            show: true,
            type: 'dashed',
            color: 'rgba(0, 0, 255, 1)',
            width: 1,
            fontSize: 12,
          },
          scrapLimit: {
            show: true,
            type: 'dashed',
            color: 'rgba(0, 0, 255, 1)',
            width: 1,
            fontSize: 12,
          },
          acceptanceLimit: {
            show: true,
            type: 'dashed',
            color: 'rgba(0, 0, 255, 1)',
            width: 1,
            fontSize: 12,
          },
        },
        markAreas: {
          error: {
            show: true,
            min: 0,
            max: 0,
            color: 'rgba(255, 8, 8, 0.09)',
          },
          warn: {
            show: true,
            min: 0,
            max: 0,
            color: 'rgba(255, 255, 0, 0.11)',
          },
          good: {
            show: true,
            min: 0,
            max: 0,
            color: 'rgba(255, 255, 0, 0)',
          },
        },
      },
    },
    qualityControlChart: {
      lineChart: {
        symbols: {
          error: {
            upper: {
              shape: 'triangle',
              size: 5,
              color: 'rgba(255, 0, 0, 1)',
            },
            lower: {
              shape: 'path://M 100 100 L 300 100 L 200 300 z',
              size: 5,
              color: 'rgba(255, 0, 0, 1)',
            },
          },
          warn: {
            upper: {
              shape: 'rect',
              size: 3,
              color: 'rgba(255, 136, 0, 1)',
            },
            lower: {
              shape: 'rect',
              size: 3,
              color: 'rgba(255, 136, 0, 1)',
            },
          },
          good: {
            shape: 'none',
            size: 3,
            color: 'rgba(2, 152, 212, 1)',
          },
        },
        chartLines: {
          type: 'solid',
          width: 1,
          color: 'rgba(2, 152, 212, 1)',
        },
        markLines: {
          precision: 10,
          specLimit: {
            show: true,
            type: 'solid',
            color: 'rgba(255, 0, 0, 1)',
            width: 1,
            fontSize: 12,
          },
          warnLimit: {
            show: true,
            type: 'dashed',
            color: 'rgba(255, 136, 0, 1)',
            width: 1,
            fontSize: 12,
          },
          controlLimit: {
            show: true,
            type: 'dashed',
            color: 'rgba(0, 127, 207, 1)',
            width: 1,
            fontSize: 12,
          },
          center: {
            show: true,
            type: 'dashed',
            color: 'rgba(0, 127, 207, 1)',
            width: 1,
            fontSize: 12,
          },
          nominal: {
            show: true,
            type: 'dashed',
            color: 'rgba(128, 0, 128, 1)',
            width: 1,
            fontSize: 12,
          },
          average: {
            show: true,
            type: 'dashed',
            color: 'rgba(0, 0, 255, 1)',
            width: 1,
            fontSize: 12,
          },
          plausibilityLimit: {
            show: true,
            type: 'dashed',
            color: 'rgba(0, 0, 255, 1)',
            width: 1,
            fontSize: 12,
          },
          scrapLimit: {
            show: true,
            type: 'dashed',
            color: 'rgba(0, 0, 255, 1)',
            width: 1,
            fontSize: 12,
          },
          acceptanceLimit: {
            show: true,
            type: 'dashed',
            color: 'rgba(0, 0, 255, 1)',
            width: 1,
            fontSize: 12,
          },
        },
        markAreas: {
          error: {
            show: true,
            min: 0,
            max: 0,
            color: 'rgba(255, 8, 8, 0)',
          },
          warn: {
            show: true,
            min: 0,
            max: 0,
            color: 'rgba(255, 255, 0, 0)',
          },
          good: {
            show: true,
            min: 0,
            max: 0,
            color: 'rgba(0, 255, 0, 0)',
          },
          outOfControl: {
            show: true,
            min: 0,
            max: 0,
            color: 'rgba(127, 127, 127, 0)',
          },
        },
      },
    },
  },
}
