import { Suspense, lazy, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { batch, connect } from 'react-redux'
import { AutoSizer, Grid } from 'react-virtualized'
import DQLoadPanel from 'src/components/dq-load-form/DQLoadPanel'
import { useLanguage } from 'src/contexts/languages'
import { useTheme } from 'src/contexts/theme'
import CPUtils from 'src/functions/CommonPageUtils'
import * as HCUtils from 'src/functions/HistogramChartUtils'
import PageWarningMessage from 'src/functions/PageWarningMessage'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import CCUtils from 'src/functions/CommonCalcUtils'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import { makeChartOption } from 'src/functions/HistogramChartUtils'
import { getHistogram } from 'src/api/histogram'
import ApiStatus from 'src/api/defines/ApiStatus'
import produce from 'immer'
import * as histImpl from 'src/pages/chart-histogram/components/main-view/chart-option/HistogramChartOptionImpl'

import './HistogramChartForm.scss'
import { getErrCommMsg } from 'src/functions/CommonMassageUtils'
import { outlierFilterValues } from 'src/functions/ValueProcessUtils'

const mapStateToProps = (state) => ({
  common: state.common,
  chartRawData: state.chartRawData,
})

export default connect(mapStateToProps)(HistogramChartForm)

// Lazy load the component
const ApacheEChartHistogramComponent = lazy(() => import('../chart/ApacheEChartHistogramComponent'))

const styles = {
  chartContainer: {
    padding: '5px',
  },
  chartInnerContainer: {
    width: '100%',
    height: '100%',
    padding: '10px',
    borderRadius: '10px',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
    overflow: 'visible',
  },
}

function distributionName(dist) {
  const defineUseDistribution = [
    { value: 0, type: 'Auto-calculation' },
    { value: 1, type: 'Normal-distribution' },
    { value: 2, type: 'Log-normal-distribution' },
    { value: 21, type: 'Folded-normal-distribution' },
    { value: 30, type: 'Weibull-distribution' },
  ]

  const targetDistribution = defineUseDistribution.find((item) => item.value === dist)

  return targetDistribution.type
}

// function makeSettings(info, curPage, configPage, selected) {
function makeSettings(curPage, configSettings, targetChar, targetValue) {
  const { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset } = PCUtils.getAllLimits(targetChar, configSettings)
  const limits = { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset }
  const filteredValue = outlierFilterValues(limits, targetChar, targetValue, configSettings)
  const { lcl, ucl, average, minValue, maxValue, v0001s } = PCUtils.getCalculatedPrms(filteredValue, configSettings)
  const x0_135 = lcl
  const x99_865 = ucl
  const minValues = [minValue, lsl, x0_135].filter((value) => value !== null)
  const maxValues = [maxValue, usl, x99_865].filter((value) => value !== null)
  const min = Math.min(...minValues)
  const max = Math.max(...maxValues)
  const xAxisLeft = min - (max - min) * 0.1
  const xAxisRight = max + (max - min) * 0.1

  const decimalPlaces = configSettings.commonSettings.calcDisplayDecimalPlaces

  const settings = {
    lsl,
    usl,
    lwl,
    uwl,
    upl,
    lpl,
    url,
    lrl,
    uacl,
    lacl,
    char: targetChar,
    valuesReference: filteredValue,
    x0_135,
    x99_865,
    nominal,
    xAxisLeft,
    xAxisRight,
    decimalPlaces,
    srcData: v0001s,
    ...configSettings,
  }

  return { settings }
}

function makeUpdateOption(data, settings) {
  console.log('data.bin_count_list')
  console.log(data.bin_count_list)
  const bin_count_list = [...data.bin_count_list]

  const bin_density_list = [...data.bin_density_list]
  const bin_width_start_list = [...data.bin_width_start_list]
  const bin_width_center_list = [...data.bin_width_center_list]
  const bin_width_end_list = [...data.bin_width_end_list]
  settings.xAxisLeft = Math.min(settings.xAxisLeft, data.x0_135)
  settings.xAxisRight = Math.max(settings.xAxisRight, data.x99_865)

  const addStartMinArray = []
  const addStartCenterArray = []
  const addStartMaxArray = []
  const addZeroCountMinArray = []
  let dec = bin_width_end_list[0] - bin_width_start_list[0]
  let ii = bin_width_start_list[0]
  do {
    ii = ii - dec
    addStartMinArray.push(ii)
    addStartCenterArray.push(ii + dec / 2)
    addStartMaxArray.push(ii + dec)
    addZeroCountMinArray.push(0)
  } while (ii >= settings.xAxisLeft - dec)

  addStartMinArray.sort((a, b) => a - b)
  addStartCenterArray.sort((a, b) => a - b)
  addStartMaxArray.sort((a, b) => a - b)

  const addEndMaxArray = []
  const addEndCenterArray = []
  const addEndMinArray = []
  const addZeroCountMaxArray = []
  dec = bin_width_end_list[0] - bin_width_start_list[0]
  let i = bin_width_end_list[bin_width_end_list.length - 1]
  do {
    i = i + dec
    addEndMinArray.push(i - dec)
    addEndCenterArray.push(i - dec / 2)
    addEndMaxArray.push(i)
    addZeroCountMaxArray.push(0)
  } while (i <= settings.xAxisRight + dec)

  addEndMaxArray.sort((a, b) => a - b)
  addEndCenterArray.sort((a, b) => a - b)
  addEndMinArray.sort((a, b) => a - b)

  bin_count_list.unshift(...addZeroCountMinArray)
  bin_count_list.push(...addZeroCountMaxArray)

  bin_density_list.unshift(...addZeroCountMinArray)
  bin_density_list.push(...addZeroCountMaxArray)

  bin_width_start_list.unshift(...addStartMinArray)
  bin_width_start_list.push(...addEndMinArray)

  bin_width_center_list.unshift(...addStartCenterArray)
  bin_width_center_list.push(...addEndCenterArray)

  bin_width_end_list.unshift(...addStartMaxArray)
  bin_width_end_list.push(...addEndMaxArray)

  return {
    bin_count_list: bin_count_list,
    bin_density_list: bin_density_list,
    bin_width_start_list: bin_width_start_list,
    bin_width_center_list: bin_width_center_list,
    bin_width_end_list: bin_width_end_list,
    curve_x_values: data.curve_x_values,
    curve_y_values: data.curve_y_values,
    xBar: data.xBar,
    x99_865: data.x99_865,
    x50: data.x50,
    x0_135: data.x0_135,
  }
}

function HistogramChartForm({ pageKey, common, chartRawData }) {
  const { getTheme } = useTheme()
  const { language } = useLanguage()
  const [isLoadingPanel, setIsLoadingPanel] = useState(false)

  const histChartRefs = useRef(null)

  const [rawData, setRawData] = useState([])
  const [calculationData, setCalculationData] = useState([])
  const [chartOptions, setChartOptions] = useState([])

  const gridRef = useRef(null)

  const curPage = common.pages.histogramChart
  const configPage = common.pages.configuration
  const currentSel = common.current_sel
  const selectedChar = common.loaded_all[pageKey]

  const columnCount = curPage.page.layout.column
  const rowCount = Math.ceil(chartOptions.length / columnCount)

  const makeHistogramOption = async (settings, configSettings, targetChar, targetValue) => {
    try {
      const result = await getHistogram(targetChar, targetValue, settings, configSettings)
      if (!ApiStatus.isSuccess(result.status)) {
        alert(getErrCommMsg(result.status))
      }

      const data = result.data
      return data
    } catch (error) {
      console.error('Error in makeHistogramOption:', error)
    }

    return []
  }

  useEffect(() => {
    const { parts, chars, values } = CPUtils.loadRefChartData(chartRawData)
    const { charArray } = CPUtils.loadRefOtherData(common, chars, pageKey)
    const selectedValueWithDummy = CPUtils.checkMatchCharsAndValues(charArray, values)
    const valuesArranged = CPUtils.removeDummyInValues(selectedValueWithDummy)
    const numCharts = chars.length
    histChartRefs.current = new Array(numCharts).fill(null).map(() => ({ element: null, chartElement: null, id: null }))

    const processChartOptions = async () => {
      setIsLoadingPanel(true)

      try {
        // Fetch all chart options concurrently
        let histogramData = []
        if (valuesArranged.length > 0) {
          await Promise.all(
            valuesArranged.map(async (value) => {
              const targetChar = value.charRaw
              const targetValue = value.valueRaw

              if (targetValue.length < 5) {
                histogramData.push({
                  info: { part_id: targetChar.part_id, char_id: targetChar.char_id },
                  targetChar,
                  targetValue,
                  settings: null,
                  responseData: null,
                  error: { name: 'DataIsNotEnough', extra: targetValue.length },
                })
                return
              }

              const { settings } = makeSettings(curPage, configPage, targetChar, targetValue)
              const isAdded = calculationData.some((data) => data.info.part_id === value.part_id && data.info.char_id === value.char_id)

              if (!isAdded) {
                const responseData = await makeHistogramOption(settings, configPage, targetChar, settings.valuesReference)
                if (responseData.hasOwnProperty('curve_x_values')) {
                  histogramData.push({ info: { part_id: targetChar.part_id, char_id: targetChar.char_id }, targetChar, targetValue, settings, responseData, error: { name: null, extra: null } })
                } else {
                  histogramData.push({
                    info: { part_id: targetChar.part_id, char_id: targetChar.char_id },
                    targetChar,
                    targetValue,
                    settings: null,
                    responseData: null,
                    error: { name: 'Deviation', extra: 0 },
                  })
                }
              } else {
                const tgData = calculationData.find((data) => data.info.part_id === value.part_id && data.info.char_id === value.char_id)
                histogramData.push(tgData)
              }
            })
          )

          histogramData.sort((a, b) => {
            if (a.info.part_id !== b.info.part_id) {
              return a.info.part_id - b.info.part_id
            } else {
              return a.info.char_id - b.info.char_id
            }
          })

          const histChartOptions = histogramData.map((data) => {
            const targetChar = data.targetChar
            const targetValue = data.targetValue
            const settings = data.settings
            // const configSettings = data.configSettings
            const responseData = data.responseData

            if (data.error.name !== null) {
              return { info: { part_id: targetChar.part_id, char_id: targetChar.char_id, charReference: targetChar }, option: null, error: { name: data.error.name, extra: data.error.extra } }
            }
            const settingsValue = { ...settings, ...curPage }
            const configSettings = configPage

            const options = makeChartOption(pageKey, curPage, configPage, targetChar, targetValue)
            const optionBaseData = makeUpdateOption(responseData, settingsValue)
            const bellCurveData = optionBaseData.curve_x_values.map((x, index) => [x, optionBaseData.curve_y_values[index]])

            const next = produce(options, (draft) => {
              draft.series = []
              draft.series.push({
                type: 'bar',
                name: 'count',
                // xAxisIndex: 0,
                yAxisIndex: 0, // Ensure it's linked to the second (right) Y-axis
                // data: optionBaseData.bin_density_list,
                data: optionBaseData.bin_count_list,
                itemStyle: {
                  color: settingsValue.barChart.histogramBar.color,
                },
                barWidth: '99%',
              })
              draft.series.push({
                type: 'line',
                name: 'curve',
                xAxisIndex: 1,
                yAxisIndex: 1, // Ensure it's linked to the second (right) Y-axis
                data: bellCurveData,
                // data: optionBaseData.curve_y_values,
                symbol: 'none',
                itemStyle: {
                  color: settingsValue.barChart.bellCurve.color,
                },
                lineStyle: {
                  type: settingsValue.lineChart.chartLines.type,
                  width: settingsValue.lineChart.chartLines.width,
                },
              })
              draft.series.push({
                type: 'line',
                name: 'Lines',
                xAxisIndex: 1,
                markLine: {
                  animation: false,
                  symbol: 'none',
                  precision: 10,
                  data: histImpl.convertToMarkLine(responseData.dist, settingsValue, configSettings, optionBaseData),
                  emphasis: {
                    disabled: true,
                  },
                },
              })

              draft.xAxis[0].data = optionBaseData.bin_width_center_list
              draft.xAxis[0].axisLabel.formatter = (value, index) => histImpl.makeXAxisLabelFormat(value, configSettings)
              // draft.xAxis[0].min = optionBaseData.bin_width_center_list[0]
              // draft.xAxis[0].max = optionBaseData.bin_width_center_list[optionBaseData.bin_width_center_list.length - 1]

              draft.xAxis[1].min = optionBaseData.bin_width_center_list[0]
              draft.xAxis[1].max = optionBaseData.bin_width_center_list[optionBaseData.bin_width_center_list.length - 1]

              // draft.yAxis[0].data = optionBaseData.bin_count_list
              // draft.yAxis[0].min = Math.min(...optionBaseData.bin_count_list)
              // draft.yAxis[0].max = Math.max(...optionBaseData.bin_count_list)
              draft.yAxis[0].name = 'count'
              draft.yAxis[0].position = 'left'

              // draft.yAxis[1].data = optionBaseData.curve_y_values
              // draft.yAxis[1].min = Math.min(...optionBaseData.curve_y_values)
              // draft.yAxis[1].max = Math.max(...optionBaseData.curve_y_values)

              draft.yAxis[1].name = 'curve'
              draft.yAxis[1].position = 'right'

              draft.tooltip.formatter = function (args) {
                return histImpl.toolTipBalloonCallback(args, targetChar, settingsValue, configSettings, responseData.dist, optionBaseData)
              }

              draft.title.text = `${targetChar.c2002 ?? 'empty'} P${targetChar.part_id ?? '?'}/C${targetChar.char_id ?? '?'}` + ' : ' + distributionName(responseData.dist)
              draft.title.textStyle.fontSize = (settingsValue.page.fontSize / (settingsValue.page.layout.row + settingsValue.page.layout.column)) * 4
            })
            return { info: { part_id: targetChar.part_id, char_id: targetChar.char_id, charReference: targetChar }, option: next, error: { name: null, extra: null } }
          })

          batch(() => {
            setCalculationData(histogramData)
            setChartOptions(histChartOptions)
          })
        } else {
          batch(() => {
            setCalculationData([])
            setChartOptions([])
          })
        }
      } catch (error) {
        console.error('Error fetching chart options:', error)
      } finally {
        setIsLoadingPanel(false)
      }
    }

    processChartOptions()
  }, [selectedChar, curPage, configPage])

  useEffect(() => {
    const targetRefId = `${currentSel.part_id}_${currentSel.char_id}`
    const targetIndex = chartOptions.findIndex((chart) => `${chart.info.part_id}_${chart.info.char_id}` === targetRefId)

    if (targetIndex !== -1 && gridRef.current) {
      const rowIndex = Math.floor(targetIndex / columnCount)
      const columnIndex = targetIndex % columnCount

      gridRef.current.scrollToCell({ columnIndex, rowIndex })

      setTimeout(() => {
        const targetElement = histChartRefs?.current[targetIndex]?.element
        if (targetElement) {
          targetElement.classList.add('target-element')
          targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' })

          targetElement.addEventListener(
            'animationend',
            () => {
              targetElement.classList.remove('target-element')
            },
            { once: true }
          )
        }
      }, 0)
    }
  }, [currentSel, histChartRefs, chartOptions, columnCount])

  const renderCell = useCallback(({ columnIndex, rowIndex, style, key, parent }) => {
    const index = rowIndex * columnCount + columnIndex
    if (index >= chartOptions.length) return null

    const chart = chartOptions[index]
    return (
      <div
        key={`${chart.info.part_id}_${chart.info.char_id}`}
        ref={(e) => {
          histChartRefs.current[index].element = e
          histChartRefs.current[index].id = `${chart.info.part_id}_${chart.info.char_id}`
        }}
        style={{ ...style, padding: '3px' }} // Apply padding for the gap
      >
        <div style={styles.chartInnerContainer}>
          <Suspense fallback={<div>{dqTranslateMsg('Common_' + 'LoadPanelLoading')}</div>}>
            {chart.option === null ? (
              <PageWarningMessage
                fontSize={(curPage.page.fontSize / (curPage.page.layout.row + curPage.page.layout.column)) * 5}
                message={
                  `${chart.info.charReference.c2002 ?? 'empty'} P${chart.info.part_id ?? '?'}/C${chart.info.char_id ?? '?'}` +
                  ' - ' +
                  dqTranslateMsg('Page_HistogramChart_' + chart.error.name) +
                  ` (${chart.error.extra})`
                }
              />
            ) : (
              <ApacheEChartHistogramComponent
                key={`${chart.info.part_id}_${chart.info.char_id}_chart`}
                rawData={{
                  parts: rawData.parts,
                  chars: rawData.chars,
                  values: rawData.values,
                  charArray: rawData.charArray,
                  curPage,
                  configPage,
                  valuesArranged: rawData.valuesArranged,
                }}
                info={chart.info}
                option={chart.option}
                theme={getTheme()}
                pageKey={pageKey}
              />
            )}
          </Suspense>
        </div>
      </div>
    )
  })

  return (
    <div className={'dx-theme-background-color'}>
      <DQLoadPanel open={isLoadingPanel} message={dqTranslateMsg('Common_' + 'LoadPanelLoading')} />
      <div className="chart-form-content" style={{ width: '100%', height: '100%' }}>
        {chartOptions.length > 0 ? (
          <AutoSizer>
            {({ height, width }) => (
              <Grid
                ref={gridRef} // Assign the ref to the Grid component
                className="custom-scrollbar"
                columnCount={columnCount}
                columnWidth={width / curPage.page.layout.column} // Adjust column width to account for the margin
                height={height}
                rowCount={rowCount}
                rowHeight={height / curPage.page.layout.row} // Adjust row height to account for the margin
                width={width}
                cellRenderer={renderCell}
                style={{ overflowX: 'hidden', overflowY: 'auto' }}
              />
            )}
          </AutoSizer>
        ) : null}
      </div>
    </div>
  )
}
