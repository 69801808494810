import { Document, Font, Image, PDFViewer, Page, Text, View } from '@react-pdf/renderer'
import { Button, DataGrid } from 'devextreme-react'
import ReactECharts from 'echarts-for-react'
import { useEffect, useMemo, useState } from 'react'
import { batch, useSelector } from 'react-redux'
import { useLanguage } from 'src/contexts/languages'
import { useTheme } from 'src/contexts/theme'
import CPUtils from 'src/functions/CommonPageUtils'
import * as VCUtils from 'src/functions/ValueChartUtils'
import * as HCUtils from 'src/functions/HistogramChartUtils'
import { getProbabilityPlotChartOption } from 'src/pages/report-print/components/main-view/chart-option/ReportPrintProbabilityPlotChartOption'
import ReportLayoutPage from './ReportLayoutPage'
import ApacheEChartHistogramComponent from 'src/pages/chart-histogram/components/chart/ApacheEChartHistogramComponent'
import ApacheEChartPTComponent from 'src/pages/chart-probability-plot/components/chart/ApacheEChartPTComponent'
import { Column } from 'devextreme-react/data-grid'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import ApacheEChartValueChartComponent from 'src/pages/chart-value/components/arima/ApacheEChartValueChartComponent'
import ApacheEChartHistogramComponentReport from './charts/ApacheEChartHistogramComponentReport'
//
Font.register({
  family: 'Oswald',
  src: 'https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf',
})

const createStyleSheet = (pageState) => {
  const styles = pageState.page.templates.map((item) => {
    const template = {
      name: item.template.name,
      hint: item.template.hint,
    }
    const page = {
      size: item.page.size,
      orientation: item.page.orientation,
      style: {
        flexDirection: 'column',
        width: '100%',
        ...item.page.style,
      },
    }
    const elements = item.elements.map((element) => {
      return {
        name: element.name,
        columnCount: element.columnCount,
        columnContent: element.columnContent,
        style: {
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          ...element.style,
        },
      }
    })

    return { template, page, elements }
  })

  return styles
}

const chartOptions = {
  title: {
    text: 'ECharts example',
  },
  tooltip: {},
  xAxis: {
    data: ['A', 'B', 'C', 'D', 'E'],
  },
  yAxis: {},
  series: [
    {
      name: 'Sales',
      type: 'bar',
      data: [5, 20, 36, 10, 10, 20],
    },
  ],
}

const probChartOption = {
  xAxis: {},
  yAxis: {},
  series: [
    {
      symbolSize: 20,
      data: [
        [10.0, 8.04],
        [8.07, 6.95],
        [13.0, 7.58],
        [9.05, 8.81],
        [11.0, 8.33],
        [14.0, 7.66],
        [13.4, 6.81],
        [10.0, 6.33],
        [14.0, 8.96],
        [12.5, 6.82],
        [9.15, 7.2],
        [11.5, 7.2],
        [3.03, 4.23],
        [12.2, 7.83],
        [2.02, 4.47],
        [1.05, 3.33],
        [4.05, 4.96],
        [6.03, 7.24],
        [12.0, 6.26],
        [12.0, 8.84],
        [7.08, 5.82],
        [5.02, 5.68],
      ],
      type: 'scatter',
    },
  ],
}

export default function ReportPrintPage({ pageKey }) {
  const chartRawData = useSelector((state) => state.chartRawData)
  const common = useSelector((state) => state.common)

  const { getTheme } = useTheme()
  const { language } = useLanguage()
  const theme = getTheme()
  // const chartRef = useRef(null)

  const [showLayoutPdfViewer, setShowLayoutPdfViewer] = useState(true)
  const [dataSourceState, setDataSourceState] = useState([])

  const { parts, chars, values } = CPUtils.loadRefChartData(chartRawData)
  const { charArray, curPage, configPage, currentSel } = CPUtils.loadRefOtherData(common, chars, pageKey)
  const valuePage = common.pages.valueChart
  const histoPage = common.pages.histogramChart
  const probPage = common.pages.probabilityPlotChart
  const summaryListPage = common.pages.summaryList

  const selectedValueWithDummy = CPUtils.checkMatchCharsAndValues(charArray, values)
  const valuesArranged = CPUtils.removeInvalidInValues(selectedValueWithDummy, curPage.page.showDeletedValue)
  const numCharts = chars.length
  const chartRefs = useMemo(() => new Array(numCharts).fill(null).map(() => ({ current: { element: null, chartElement: null, id: null } })), [numCharts])
  const histoRefs = useMemo(() => new Array(numCharts).fill(null).map(() => ({ current: { element: null, chartElement: null, id: null } })), [numCharts])
  const probRefs = useMemo(() => new Array(numCharts).fill(null).map(() => ({ current: { element: null, chartElement: null, id: null } })), [numCharts])
  const valueOptions =
    valuesArranged.length > 0
      ? valuesArranged.map((value) => {
          const char = chars.find((char) => char.part_id === value.part_id && char.char_id === value.char_id)

          const option = VCUtils.makeChartOption(pageKey, valuePage, configPage, char, value, theme)

          return { option, value }
        })
      : []

  const histoOptions =
    valuesArranged.length > 0
      ? valuesArranged.map((value) => {
          const char = chars.find((char) => char.part_id === value.part_id && char.char_id === value.char_id)

          const option = HCUtils.makeChartOption(pageKey, histoPage, configPage, char, value)

          return { option, value }
        })
      : []

  const probOptions =
    valuesArranged.length > 0
      ? valuesArranged.map((value) => {
          const char = chars.find((char) => char.part_id === value.part_id && char.char_id === value.char_id)

          const option = getProbabilityPlotChartOption(probPage, configPage, char, value)

          return { option, value }
        })
      : []

  const summaryDataGrid = dataSourceState.length > 0 ? dataSourceState : []

  const combinedChartOption = valueOptions.map((element1, index) => {
    const info = {
      part_id: element1.value.part_id,
      char_id: element1.value.char_id,
    }

    const targetSub1Chart = histoOptions.find((hist) => hist.value.part_id === element1.value.part_id && hist.value.char_id === element1.value.char_id)
    const targetSub2Chart = probOptions.find((prob) => prob.value.part_id === element1.value.part_id && prob.value.char_id === element1.value.char_id)
    const targetMainList = summaryDataGrid.find((summ) => summ.part_id === element1.value.part_id && summ.char_id === element1.value.char_id)

    return [element1, targetSub1Chart, targetSub2Chart, targetMainList ?? null, info]
  })

  const styles = createStyleSheet(curPage)
  const pageSettings = curPage.page
  const themeSettings = curPage.theme
  const configSettings = configPage

  // const [svgDataUri, setSvgDataUri] = useState(null)
  // const [svgDataUri, setSvgDataUri] = useState(
  //   'data:image/svg+xml;base64,PHN2ZyB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgdmVyc2lvbj0iMS4xIiBjbGFzcz0iaGlnaGNoYXJ0cy1yb290IiBzdHlsZT0iZm9udC1mYW1pbHk6SGVsdmV0aWNhO2ZvbnQtc2l6ZToxMnB4OyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB3aWR0aD0iNjAwIiBoZWlnaHQ9IjYwMCIgdmlld0JveD0iMCAwIDYwMCA2MDAiPgogIDxnIGNsYXNzPSJoaWdoY2hhcnRzLXNlcmllcyBoaWdoY2hhcnRzLXNlcmllcy0wIGhpZ2hjaGFydHMtYmFyLXNlcmllcyBoaWdoY2hhcnRzLWNvbG9yLTAiIGRhdGEtei1pbmRleD0iMC4xIiBvcGFjaXR5PSIxIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSg1OTAsNTA5KSByb3RhdGUoOTApIHNjYWxlKC0xLDEpIHNjYWxlKDEgMSkiIGNsaXAtcGF0aD0idXJsKCNoaWdoY2hhcnRzLW8xa2JoMHotMzEzLSkiPgo8cmVjdCB4PSIzNDQuNSIgeT0iMjkxLjUiIHdpZHRoPSI1MCIgaGVpZ2h0PSIyMDQiIGZpbGw9IiNBOURBQ0MiIHN0cm9rZT0iI2ZmZmZmZiIgc3Ryb2tlLXdpZHRoPSIxIiBvcGFjaXR5PSIxIiBjbGFzcz0iaGlnaGNoYXJ0cy1wb2ludCBoaWdoY2hhcnRzLWNvbG9yLTAiPjwvcmVjdD4KPHJlY3QgeD0iMTIwLjUiIHk9IjUxLjUiIHdpZHRoPSI1MCIgaGVpZ2h0PSI0NDQiIGZpbGw9IiNBOURBQ0MiIHN0cm9rZT0iI2ZmZmZmZiIgc3Ryb2tlLXdpZHRoPSIxIiBvcGFjaXR5PSIxIiBjbGFzcz0iaGlnaGNoYXJ0cy1wb2ludCBoaWdoY2hhcnRzLWNvbG9yLTAiPjwvcmVjdD4KPC9nPgo8L3N2Zz4='
  // )
  const [valueImage, setValueImage] = useState(Array(numCharts).fill(null))
  const [histoImage, setHistoImage] = useState(Array(numCharts).fill(null))
  const [probImage, setProbImage] = useState(Array(numCharts).fill(null))

  useEffect(() => {
    const valueImageArrayState = chartRefs.map((chartRef, index) => {
      if (chartRef.current.element) {
        const chartInstance = chartRef.current.element.getEchartsInstance()
        return chartInstance.getDataURL({
          type: 'png', // Request the SVG format
          pixelRatio: 5, // 원하는 해상도를 설정할 수 있습니다.
          backgroundColor: '#fff', // 배경색을 설정할 수 있습니다.
        })
      }
      return null
    })

    const histoImageArrayState = histoRefs.map((chartRef, index) => {
      if (chartRef.current.element) {
        const chartInstance = chartRef.current.element.getEchartsInstance()
        return chartInstance.getDataURL({
          type: 'png', // Request the SVG format
          pixelRatio: 5, // 원하는 해상도를 설정할 수 있습니다.
          backgroundColor: '#fff', // 배경색을 설정할 수 있습니다.
        })
      }
      return null
    })

    const probImageArrayState = probRefs.map((chartRef, index) => {
      if (chartRef.current.element) {
        const chartInstance = chartRef.current.element.getEchartsInstance()
        return chartInstance.getDataURL({
          type: 'png', // Request the SVG format
          pixelRatio: 5, // 원하는 해상도를 설정할 수 있습니다.
          backgroundColor: '#fff', // 배경색을 설정할 수 있습니다.
        })
      }
      return null
    })

    batch(() => {
      setValueImage(valueImageArrayState) // Save the Base64 encoded SVG data URL
      setHistoImage(histoImageArrayState) // Save the Base64 encoded SVG data URL
      setProbImage(probImageArrayState) // Save the Base64 encoded SVG data URL
    })
  }, [chartRefs, charArray])

  return (
    <div style={{ display: 'flex', width: '100%', height: '100%' }}>
      <div style={{ display: 'flex', justifyContent: 'center', width: '100%', height: '100%' }}>
        {showLayoutPdfViewer && (
          <div style={{ width: '50%', height: '100%' }}>
            <PDFViewer key={'layout-pdf-viewer'} style={{ width: '100%', height: '100%', paddingTop: '60px' }} showToolbar={false}>
              <Document>
                <ReportLayoutPage styles={styles} pageSettings={pageSettings} configSettings={configSettings} valuesArranged={valuesArranged} theme={theme} language={language} />
              </Document>
            </PDFViewer>
          </div>
        )}
        <Button
          icon={showLayoutPdfViewer ? 'chevronleft' : 'chevronright'}
          onClick={() => setShowLayoutPdfViewer(!showLayoutPdfViewer)}
          type="success"
          stylingMode="contained"
          style={{
            alignSelf: 'center',
            height: '40px',
            width: '40px',
            borderRadius: '50%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            margin: '10px',
          }}
        />
        <div style={{ width: showLayoutPdfViewer ? '50%' : '100%', height: '100%' }}>
          <PDFViewer key={'data-pdf-viewer'} style={{ width: '100%', height: '100%' }} showToolbar={true}>
            <Document>
              {valuesArranged.map((values, index) => {
                // const targetStyle = styles[0]
                const targetStyle = styles[1]
                return (
                  <Page size={targetStyle.page.size} style={targetStyle.page.style} orientation={targetStyle.page.orientation} debug={false}>
                    {targetStyle.elements.map((viewElement, viewIndex) => {
                      return (
                        <View key={`view-element-${viewIndex}`} style={viewElement.style} debug={false}>
                          {viewElement.columnContent.map((el, elIndex) => {
                            // return <View style={{ width: '100%', height: '100%' }}>{chartDataUrl && <Image style={targetStyle.chartImage} src={chartDataUrl} />}</View>
                            if (el.type === 'text') {
                              return <Text style={el.style}>{el.content}</Text>
                            } else if (el.type === 'valueChart') {
                              return <Image style={el.style} src={valueImage[index]} />
                            } else if (el.type === 'histogramChart') {
                              return <Image style={el.style} src={histoImage[index]} />
                            } else if (el.type === 'probabilityPlotChart') {
                              return <Image style={el.style} src={probImage[index]} />
                            } else {
                              return <Text style={el.style}>Not Use element type</Text>
                            }
                          })}
                        </View>
                      )
                    })}
                  </Page>
                )
              })}
            </Document>
          </PDFViewer>
        </div>
      </div>
      <div style={{ display: 'none' }}>
        {combinedChartOption.length > 0
          ? combinedChartOption.map((target, index) => {
              return (
                <div
                  key={`${target[0].value.part_id}_${target[0].value.char_id}`}
                  // style={{ ...style, padding: '3px' }} // Apply padding for the gap
                  // onClick={() => handleCellClick(index)}
                >
                  <ReactECharts
                    ref={(e) => {
                      chartRefs[index].current.element = e
                      chartRefs[index].current.id = `${target[0].value.part_id}_${target[0].value.char_id}`
                    }}
                    style={{ width: '300px', height: '200px', display: 'none' }}
                    option={target[0].option}
                    // onChartReady={handleChartRendered}
                    // onEvents={{ rendered: handleChartRendered }}
                    opts={{
                      renderer: 'svg',
                    }}
                  />
                  <ReactECharts
                    ref={(e) => {
                      histoRefs[index].current.element = e
                      histoRefs[index].current.id = `${target[1].value.part_id}_${target[1].value.char_id}`
                    }}
                    style={{ width: '300px', height: '200px', display: 'none' }}
                    option={chartOptions}
                    // onChartReady={handleChartRendered}
                    // onEvents={{ rendered: handleChartRendered }}
                    opts={{
                      renderer: 'svg',
                    }}
                  />
                  {/* <ApacheEChartHistogramComponentReport
                    ref={histoRefs}
                    setHistoImage={setHistoImage}
                    index={index}
                    rawData={{
                      parts,
                      chars,
                      values,
                      charArray,
                      curPage: histoPage,
                      configPage,
                      valuesArranged,
                    }}
                    info={{
                      part_id: target[1].value.part_id,
                      char_id: target[1].value.char_id,
                    }}
                    option={target[1].option}
                    theme={getTheme()}
                    pageKey={pageKey}
                  /> */}
                  <ReactECharts
                    ref={(e) => {
                      probRefs[index].current.element = e
                      probRefs[index].current.id = `${target[2].value.part_id}_${target[2].value.char_id}`
                    }}
                    style={{ width: '300px', height: '200px', display: 'none' }}
                    option={probChartOption}
                    // onChartReady={handleChartRendered}
                    // onEvents={{ rendered: handleChartRendered }}
                    opts={{
                      renderer: 'svg',
                    }}
                  />
                  {/* <DataGrid
                    // ref={summaryListGrid}
                    className="rpf-main-list"
                    id="report-print-summary-list-datagrid"
                    ref={(e) => {
                      summaryListRefs[index].current.chartElement = e
                    }}
                    style={{ display: 'none' }}
                    key={index}
                    dataSource={[dataSourceState[index]]}
                    // dataSource={target[3].tableDataArray}
                    allowColumnReordering={true}
                    allowColumnResizing={true}
                    columnResizingMode={'widget'}
                    filterRow={{ visible: false }}
                    noDataText={dqTranslateMsg('Page_ReportPrint_' + 'NoData')}
                    paging={{ enabled: false }}
                    showColumnLines={true}
                    showRowLines={true}
                    showBorders={true}
                    rowAlternationEnabled={false}
                    hoverStateEnabled={true}
                    onRowPrepared={onRowPrepared}
                  >
                    <Column dataField="desc" alignment="center" caption={dqTranslateMsg('Page_ReportPrint_' + 'Description')} width={'15%'} />
                    <Column dataField="average" alignment="center" caption={dqTranslateMsg('Page_ReportPrint_' + 'Average')} width={'10%'} cellRender={(cellData) => renderAverage(cellData, summaryListPage, configPage)} />
                    <Column dataField="median" alignment="center" caption={dqTranslateMsg('Page_ReportPrint_' + 'Median')} width={'10%'} visible={false} cellRender={(cellData) => renderMedian(cellData, summaryListPage, configPage)} />
                    <Column dataField="stdDev" caption={dqTranslateMsg('Page_ReportPrint_' + 'StandardDeviation')} alignment="center" width={'10%'} cellRender={(cellData) => renderStandardDeviation(cellData, summaryListPage, configPage)} />
                    <Column dataField="min" caption={dqTranslateMsg('Page_ReportPrint_' + 'Minimum')} alignment="center" width={'10%'} cellRender={(cellData) => renderMinimum(cellData, summaryListPage, configPage)} />
                    <Column dataField="max" caption={dqTranslateMsg('Page_ReportPrint_' + 'Maximum')} alignment="center" width={'10%'} cellRender={(cellData) => renderMaximum(cellData, summaryListPage, configPage)} />
                    <Column dataField="cp" alignment="center" caption={dqTranslateMsg('Page_ReportPrint_' + 'Cp')} width={'10%'} cellRender={(cellData) => renderCp(cellData, summaryListPage, configPage)} />
                    <Column dataField="cpk" alignment="center" caption={dqTranslateMsg('Page_ReportPrint_' + 'Cpk')} width={'10%'} cellRender={(cellData) => renderCpk(cellData, summaryListPage, configPage)} />
                    <Column dataField="result" alignment="center" caption={dqTranslateMsg('Page_ReportPrint_' + 'Result')} width={'10%'} cellRender={(cellData) => renderResult(cellData, summaryListPage, configPage)} />
                    <Column dataField="out_of_tol" alignment="center" caption={dqTranslateMsg('Page_ReportPrint_' + 'OutOfTol')} width={'10%'} cellRender={(cellData) => renderOutOfTol(cellData, summaryListPage, configPage)} />
                  </DataGrid> */}
                </div>
              )
            })
          : null}
      </div>
    </div>
  )
}
