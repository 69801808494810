export const defPartField = [
  { configuration: false, numValue: null, number: null, value: 'part_id', text: 'Part_id', visible: true, dataType: 'number' },
  { configuration: false, numValue: null, number: null, value: 'user_id', text: 'User_id', visible: true, dataType: 'string' },
  { configuration: false, numValue: null, number: null, value: 'user_role', text: 'User_role', visible: true, dataType: 'number' },
  { configuration: true, numValue: '1001', number: 1001, value: 'p1001', text: 'P1001', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1002', number: 1002, value: 'p1002', text: 'P1002', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1003', number: 1003, value: 'p1003', text: 'P1003', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1004', number: 1005, value: 'p1005', text: 'P1005', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1007', number: 1007, value: 'p1007', text: 'P1007', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1008', number: 1008, value: 'p1008', text: 'P1008', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1009', number: 1009, value: 'p1009', text: 'P1009', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1011', number: 1011, value: 'p1011', text: 'P1011', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1012', number: 1012, value: 'p1012', text: 'P1012', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1013', number: 1013, value: 'p1013', text: 'P1013', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1014', number: 1014, value: 'p1014', text: 'P1014', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1015', number: 1015, value: 'p1015', text: 'P1015', visible: true, dataType: 'number' },
  { configuration: true, numValue: '1022', number: 1022, value: 'p1022', text: 'P1022', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1032', number: 1032, value: 'p1032', text: 'P1032', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1046', number: 1046, value: 'p1046', text: 'P1046', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1052', number: 1052, value: 'p1052', text: 'P1052', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1053', number: 1053, value: 'p1053', text: 'P1053', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1062', number: 1062, value: 'p1062', text: 'P1062', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1072', number: 1072, value: 'p1072', text: 'P1072', visible: true, dataType: 'string' },
  // { configuration: true, numValue: '1081', number: 1081, value: 'p1081', text: 'P1081', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1082', number: 1082, value: 'p1082', text: 'P1082', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1085', number: 1085, value: 'p1085', text: 'P1085', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1086', number: 1086, value: 'p1086', text: 'P1086', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1087', number: 1087, value: 'p1087', text: 'P1087', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1091', number: 1091, value: 'p1091', text: 'P1091', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1092', number: 1092, value: 'p1092', text: 'P1092', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1100', number: 1100, value: 'p1100', text: 'P1100', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1101', number: 1101, value: 'p1101', text: 'P1101', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1102', number: 1102, value: 'p1102', text: 'P1102', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1103', number: 1103, value: 'p1103', text: 'P1103', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1104', number: 1104, value: 'p1104', text: 'P1104', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1203', number: 1203, value: 'p1203', text: 'P1203', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1206', number: 1206, value: 'p1206', text: 'P1206', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1303', number: 1303, value: 'p1303', text: 'P1303', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1802', number: 1802, value: 'p1802', text: 'P1802', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1812', number: 1812, value: 'p1812', text: 'P1812', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1822', number: 1822, value: 'p1822', text: 'P1822', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1832', number: 1832, value: 'p1832', text: 'P1832', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1842', number: 1842, value: 'p1842', text: 'P1842', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1852', number: 1852, value: 'p1852', text: 'P1852', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1862', number: 1862, value: 'p1862', text: 'P1862', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1872', number: 1872, value: 'p1872', text: 'P1872', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1882', number: 1882, value: 'p1882', text: 'P1882', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1892', number: 1892, value: 'p1892', text: 'P1892', visible: true, dataType: 'string' },
  { configuration: true, numValue: '1900', number: 1900, value: 'p1900', text: 'P1900', visible: true, dataType: 'string' },
  { configuration: false, numValue: null, number: null, value: 'UPDATED_DATETIME', text: 'UPDATED_DATETIME', visible: true, dataType: 'string' },
]
