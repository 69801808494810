import CCUtils from 'src/functions/CommonCalcUtils'

export function isNullOrUndefined(data) {
  return data === null || data === undefined
}
// export const calculateAverage = (data) => {
//   const sum = data.reduce((acc, value) => acc + value, 0)

//   return sum / data.length
// }
export const calculateAverage = (data) => {
  if (data.length === 0) {
    return 0 // 빈 배열의 경우 0을 반환하거나 다른 적절한 기본값을 선택할 수 있습니다.
  }
  let sum = 0
  for (const value of data) {
    sum += value
  }
  return sum / data.length
}
// Calculate the median
export const calculateMedian = (data) => {
  const sortedData = data.slice().sort((a, b) => a - b)
  const mid = Math.floor(sortedData.length / 2)

  if (sortedData.length % 2 === 0) {
    return (sortedData[mid - 1] + sortedData[mid]) / 2
  } else {
    return sortedData[mid]
  }
}

// Calculate the standard deviation
export const calculateStandardDeviation = (data) => {
  const average = calculateAverage(data)
  const squaredDifferences = data.map((value) => Math.pow(value - average, 2))
  // const sumSquaredDifferences = squaredDifferences.reduce((acc, value) => acc + value, 0)
  let sumSquaredDifferences = 0
  for (const value of squaredDifferences) {
    sumSquaredDifferences += value
  }
  const variance = sumSquaredDifferences / data.length
  const standardDeviation = Math.sqrt(variance)

  return standardDeviation
}

// Max - Min Calculation
export const makeQccLowerSeriesData = (chartType, optionType, optionCount, handlingIncompleteSubgroup, valueData) => {
  const differences = []

  if (isNullOrUndefined(valueData) || valueData.length == 0) {
    return []
  }

  const valueArray = valueData

  if (optionType === 'moving') {
    for (let i = 0; i <= valueArray.length - optionCount; i++) {
      // Handle 'delete' case
      if (handlingIncompleteSubgroup === 'delete' && i + optionCount > valueArray.length - optionCount) {
        break // Exit loop if less than optionCount elements remain
      }
      // Handle 'calculate' case
      let values = valueArray.slice(i, i + optionCount).map((item) => item.v0001)
      if (handlingIncompleteSubgroup === 'calculate' && i + optionCount > valueArray.length - optionCount) {
        values = valueArray.slice(i).map((item) => item.v0001) // Take the remaining values
      }

      // const values = valueArray.slice(i, i + optionCount).map((item) => item.v0001)
      const max = CCUtils.getMax(values)
      const min = CCUtils.getMin(values)
      const difference = max - min
      const format = [i + 1, difference]
      differences.push(format)
    }
  } else {
    let xAxis = 1
    for (let i = 0; i <= valueArray.length - optionCount; i = i + optionCount) {
      // Handle 'delete' case
      if (handlingIncompleteSubgroup === 'delete' && i + optionCount > valueArray.length - optionCount) {
        break // Exit loop if less than optionCount elements remain
      }

      // Handle 'calculate' case
      let values = valueArray.slice(i, i + optionCount).map((item) => item.v0001)
      if (handlingIncompleteSubgroup === 'calculate' && i + optionCount > valueArray.length - optionCount) {
        values = valueArray.slice(i).map((item) => item.v0001) // Take the remaining values
      }

      // const values = valueArray.slice(i, i + optionCount).map((item) => item.v0001)
      const max = CCUtils.getMax(values)
      const min = CCUtils.getMin(values)
      const difference = max - min
      const format = [xAxis, difference]
      differences.push(format)
      xAxis++
    }
  }

  return differences
}

export const makeSymbol = (value, params, settings) => {
  const val = params.data[1]

  if (settings.usl === null && settings.lsl === null) {
    return 'none'
  }

  if (val > settings.ucl) {
    return settings.lineChart.symbols.error.upper.shape
  } else if (val >= settings.lcl) {
    return settings.lineChart.symbols.good.shape
  } else {
    return settings.lineChart.symbols.error.lower.shape
  }
  // return settings.lineChart.symbols.good.shape
}

export const makeSymbolSize = (value, params, settings) => {
  const val = params.data[1]
  if (settings.usl === null && settings.lsl === null) {
    return 0
  }
  if (val > settings.ucl) {
    return settings.lineChart.symbols.error.upper.size
  } else if (val >= settings.lcl) {
    return settings.lineChart.symbols.good.size
  } else {
    return settings.lineChart.symbols.error.lower.size
  }
  // return settings.lineChart.symbols.good.size
}

export const makeSymbolColor = (params, settings) => {
  const val = params.data[1]

  if (settings.usl === null && settings.lsl === null) {
    return 'rgba(255,255,255,0)'
  }
  if (val > settings.ucl) {
    return settings.lineChart.symbols.error.upper.color
  } else if (val >= settings.lcl) {
    return settings.lineChart.symbols.good.color
  } else {
    return settings.lineChart.symbols.error.lower.color
  }
  // return settings.lineChart.symbols.good.color
}

const calculateYAxisSpace = (props) => {
  const { settings, usl, lsl, ucl, lcl, uwl, lwl, upl, lpl, url, lrl, uacl, lacl, maxValue, minValue } = props
  const {
    page: { specificationLimitEnable, warningLimitEnable, plausibilityLimitEnable, scrapLimitEnable, acceptanceLimitEnable, controlLimitEnable },
  } = settings

  const t_ucl = controlLimitEnable ? ucl ?? maxValue : maxValue
  const t_lcl = controlLimitEnable ? lcl ?? minValue : minValue

  const max = Math.max(t_ucl, maxValue)
  const min = Math.min(t_lcl, minValue)

  let space = Math.abs(max - min)
  if (space === 0) space = 1

  const additionalSpace = space * 0.1

  return { max, min, additionalSpace }
}

export const makeYAxisTop = (props) => {
  const { max, additionalSpace } = calculateYAxisSpace(props)
  return max + additionalSpace
}

export const makeYAxisBottom = (props) => {
  const { min, additionalSpace } = calculateYAxisSpace(props)
  return min - additionalSpace
}

export const findLargestNumber = (arr) => {
  if (!Array.isArray(arr) || arr.length === 0) {
    return undefined // Return undefined for invalid or empty arrays
  }

  return CCUtils.getMax(arr)
}

export const findSmallestNumber = (arr) => {
  if (!Array.isArray(arr) || arr.length === 0) {
    return undefined // Return undefined for invalid or empty arrays
  }

  return CCUtils.getMin(arr)
}

export const convertToChartLine = (settings) => {
  const rtnChartLine = {
    type: settings.lineChart.chartLines.type,
    width: settings.lineChart.chartLines.width,
    color: settings.lineChart.chartLines.color,
  }

  return rtnChartLine
}

// export const convertToMarkLine = (settings) => {
//   const position = 'insideEndTop'
//   const fontFamily = 'Arial'

//   const markLines = [
//     {
//       show: settings.average === null || !settings.page.averageLimitEnable ? false : true,
//       yAxis: settings.average,
//       name: settings.page.averageLimitLabelEnable ? 'X\u0304' : '',
//       label: {
//         position: position,
//         formatter: function (args) {
//           return args.name
//         },
//         fontFamily: fontFamily,
//         fontSize: (settings.page.fontSize / (settings.page.layout.row + settings.page.layout.column)) * 3,
//       },
//       lineStyle: { color: 'blue' },
//     },
//     {
//       show: settings.ucl === null || !settings.page.controlLimitEnable ? false : true,
//       yAxis: settings.ucl,
//       name: settings.page.controlLimitLabelEnable ? 'UCL' : '',
//       label: {
//         position: position,
//         formatter: function (args) {
//           return args.name
//         },
//         fontFamily: fontFamily,
//         fontSize: (settings.page.fontSize / (settings.page.layout.row + settings.page.layout.column)) * 3,
//       },
//       lineStyle: {
//         type: settings.lineChart.markLines.controlLimit?.type,
//         color: settings.lineChart.markLines.controlLimit?.color,
//         width: settings.lineChart.markLines.controlLimit?.width,
//       },
//     },
//     {
//       show: settings.lcl === null || !settings.page.controlLimitEnable ? false : true,
//       yAxis: settings.lcl,
//       name: settings.page.controlLimitLabelEnable ? 'LCL' : '',
//       label: {
//         position: position,
//         formatter: function (args) {
//           return args.name
//         },
//         fontFamily: fontFamily,
//         fontSize: (settings.page.fontSize / (settings.page.layout.row + settings.page.layout.column)) * 3,
//       },
//       lineStyle: {
//         type: settings.lineChart.markLines.controlLimit?.type,
//         color: settings.lineChart.markLines.controlLimit?.color,
//         width: settings.lineChart.markLines.controlLimit?.width,
//       },
//     },
//   ]

//   const validLines = markLines.filter((line) => {
//     if (line.show === true) {
//       const { show, ...rest } = line // Remove the 'show' property
//       return rest
//     }
//   })

//   return validLines
// }
export const convertToMarkLine = (settings, pageKey) => {
  const createMarkLine = (enabled, labelEnabled, labelName, yAxisValue, lineSettings, natState) => {
    const position = 'insideEndTop'
    const fontFamily = 'Arial'
    const fontSize = (settings.page.fontSize / (settings.page.layout.row + settings.page.layout.column)) * 3

    return {
      show: yAxisValue !== null && enabled,
      name: labelEnabled ? labelName : '',
      yAxis: yAxisValue,
      label: {
        position: position,
        formatter: (args) => args.name,
        fontFamily: fontFamily,
        fontSize: fontSize,
      },
      lineStyle: {
        type: natState ? 'dotted' : lineSettings?.type,
        color: lineSettings?.color,
        width: lineSettings?.width,
      },
      labelLayout: { moveOverlap: 'shiftY' },
    }
  }

  const { averageLimitEnable, controlLimitEnable } = settings.page
  const { averageLimitLabelEnable, controlLimitLabelEnable } = settings.page

  const { average, controlLimit } = settings.lineChart.markLines

  const targetML = [
    { limitEnable: controlLimitEnable, labelEnable: controlLimitLabelEnable, name: 'LCL', value: settings.lcl, config: controlLimit },
    { limitEnable: controlLimitEnable, labelEnable: controlLimitLabelEnable, name: 'UCL', value: settings.ucl, config: controlLimit },
    { limitEnable: averageLimitEnable, labelEnable: averageLimitLabelEnable, name: 'X\u0304', value: settings.average, config: average },
  ]

  // Create markLines using map
  const markLines = targetML.map((item) => createMarkLine(item.limitEnable, item.labelEnable, item.name, item.value, item.config, item.natState))

  // Filter out mark lines where show is false, and remove 'show' property
  return markLines.filter((line) => line.show).map(({ show, ...rest }) => rest)
}
export const convertToMarkArea = (settings) => {
  const rtnMarkArea = [
    [
      {
        yAxis: settings.usl,
        name: settings.lineChart.markAreas.error.upper.name,
      },
      {
        yAxis: settings.yAxisTop,
        itemStyle: {
          color: settings.lineChart.markAreas.error.upper.color,
          opacity: settings.lineChart.markAreas.error.upper.show ? settings.lineChart.markAreas.error.upper.opacity : 0,
        },
      },
    ],
    [
      {
        yAxis: settings.lsl,
        name: settings.lineChart.markAreas.error.lower.name,
      },
      {
        yAxis: settings.yAxisBottom,
        itemStyle: {
          color: settings.lineChart.markAreas.error.lower.color,
          opacity: settings.lineChart.markAreas.error.lower.show ? settings.lineChart.markAreas.error.lower.opacity : 0,
        },
      },
    ],
    [
      {
        yAxis: settings.uwl,
        name: settings.lineChart.markAreas.warn.name,
      },
      {
        yAxis: settings.usl,
        itemStyle: {
          color: settings.lineChart.markAreas.warn.color,
          opacity: settings.lineChart.markAreas.warn.show ? settings.lineChart.markAreas.warn.opacity : 0,
        },
      },
    ],
    [
      {
        yAxis: settings.lwl,
        name: settings.lineChart.markAreas.warn.name,
      },
      {
        yAxis: settings.lsl,
        itemStyle: {
          color: settings.lineChart.markAreas.warn.color,
          opacity: settings.lineChart.markAreas.warn.show ? settings.lineChart.markAreas.warn.opacity : 0,
        },
      },
    ],
    [
      {
        yAxis: settings.lwl,
        name: settings.lineChart.markAreas.good.name,
      },
      {
        yAxis: settings.uwl,
        itemStyle: {
          color: settings.lineChart.markAreas.good.color,
          opacity: settings.lineChart.markAreas.good.show ? settings.lineChart.markAreas.good.opacity : 0,
        },
      },
    ],
  ]

  return rtnMarkArea
}
