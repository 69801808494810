import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'

export const defScrollBoardField = () => [
  {
    value: 'p1000',
    text: dqTranslateMsg('SidePanel_' + 'Part'),
  },
  {
    value: 'c2000',
    text: dqTranslateMsg('SidePanel_' + 'Characteristics'),
  },
]

export const defScrollBoardNoneField = () => [
  {
    value: 'none',
    text: dqTranslateMsg('SidePanel_' + 'None'),
  },
]
