import { pageName } from 'src/functions/constants/pageName'
import { updateUserInfoForSettings } from 'src/api/user'
import * as commonRx from 'src/store/common/index'
import { formatMessage } from 'devextreme/localization'
import { MONO_BLUE, MONO_RED, COLOR } from './iconPathDefine'
import { pageSettings } from 'src/pages/settings/PageSettings'

const basePath = '/images'

const bindSettingDB = (pageKey) => {
  const defineSettingsDB = {
    overview: 'chart_config_1',
    monitoring: 'chart_config_2',
    database: 'chart_config_3',
    valueChart: 'chart_config_4',
    histogramChart: 'chart_config_5',
    positionalTolerancesChart: 'chart_config_6',
    boxPlotChart: 'chart_config_7',
    linearRegressionChart: 'chart_config_8',
    correlationChart: 'chart_config_9',
    qualityControlChart: 'chart_config_10',
    capabilitySummaryChart: 'chart_config_11',
    paretoChart: 'chart_config_12',
    probabilityPlotChart: 'chart_config_13',
    decisionTreeChart: 'chart_config_14',
    valueList: 'chart_config_15',
    summaryList: 'chart_config_16',
    valueSummaryList: 'chart_config_17',
    scrollBoard: 'chart_config_18',
    configuration: 'chart_config_19',
    adminSettings: 'chart_config_20',
    reportPrintMultiFunction: 'chart_config_21',
  }

  const tgDBColumn = defineSettingsDB[pageKey]

  return tgDBColumn
}

const getIconPath = (path, iconType, theme) => {
  let iconPath

  if (iconType === MONO_BLUE) {
    iconPath = path.icon.monoBlue[theme]
  } else if (iconType === MONO_RED) {
    iconPath = path.icon.monoRed[theme]
  } else {
    iconPath = path.icon.color
  }

  return iconPath
}

const getPageIconInner = (path, iconType, theme) => {
  const curTheme = theme.includes('dark') || theme.includes('contrast') ? 'dark' : 'light'

  return getIconPath(path, iconType, curTheme)
}

export const pageStatusList = {
  overview: {
    type: 'page',
    showWithData: true,
    key: pageName.overview,
    label: 'Overview',
    path: {
      page: '/overview',
      icon: {
        monoBlue: {
          light: '/images/monoBlue/light/overview.svg',
          dark: '/images/monoBlue/dark/overview.svg',
        },
        monoRed: {
          light: '/images/monoRed/light/overview.svg',
          dark: '/images/monoRed/dark/overview.svg',
        },
        color: '/images/color/overview.svg',
      },
    },
    auth: ['superuser', 'admin', 'engineer', 'operator', 'cmm', 'qsense'],
    func: {
      sidePanel: {
        use: true,
        target: false,
      },
    },
    settings: pageSettings.overview,
    getPageIcon: function (iconType, theme) {
      return getPageIconInner(this.path, iconType, theme)
    },
    setPageSettingsToRedux: (payload) => commonRx.setOverviewPage(payload),
    updateUserSettingToDB: ({ userId, updateData }) => updateUserInfoForSettings(userId, bindSettingDB('overview'), updateData),
  },
  monitoring: {
    type: 'page',
    showWithData: false,
    key: pageName.monitoring,
    label: 'Monitoring',
    path: {
      page: '/monitoring',
      icon: {
        monoBlue: {
          light: '/images/monoBlue/light/monitoring.svg',
          dark: '/images/monoBlue/dark/monitoring.svg',
        },
        monoRed: {
          light: '/images/monoRed/light/monitoring.svg',
          dark: '/images/monoRed/dark/monitoring.svg',
        },
        color: '/images/color/monitoring.svg',
      },
    },
    auth: ['superuser', 'admin', 'engineer', 'operator', 'cmm', 'qsense'],
    func: {
      sidePanel: {
        use: true,
        target: false,
      },
    },
    settings: pageSettings.monitoring,
    getPageIcon: function (iconType, theme) {
      return getPageIconInner(this.path, iconType, theme)
    },
    setPageSettingsToRedux: (payload) => commonRx.setMonitoringPage(payload),
    updateUserSettingToDB: ({ userId, updateData }) => updateUserInfoForSettings(userId, bindSettingDB('monitoring'), updateData),
  },
  database: {
    type: 'page',
    showWithData: false,
    key: pageName.database,
    label: 'Database',
    path: {
      page: '/database',
      icon: {
        monoBlue: {
          light: '/images/monoBlue/light/database.svg',
          dark: '/images/monoBlue/dark/database.svg',
        },
        monoRed: {
          light: '/images/monoRed/light/database.svg',
          dark: '/images/monoRed/dark/database.svg',
        },
        color: '/images/color/database.svg',
      },
    },
    auth: ['superuser', 'admin', 'engineer', 'operator', 'cmm', 'qsense'],
    func: {
      sidePanel: {
        use: true,
        target: false,
      },
    },
    settings: pageSettings.database,
    getPageIcon: function (iconType, theme) {
      return getPageIconInner(this.path, iconType, theme)
    },
    setPageSettingsToRedux: (payload) => commonRx.setDatabasePage(payload),
    updateUserSettingToDB: ({ userId, updateData }) => updateUserInfoForSettings(userId, bindSettingDB('database'), updateData),
  },
  charts: {
    type: 'directory',
    showWithData: true,
    key: 'charts',
    label: 'Charts',
    path: {
      icon: {
        monoBlue: {
          light: '/images/monoBlue/light/charts.svg',
          dark: '/images/monoBlue/dark/charts.svg',
        },
        monoRed: {
          light: '/images/monoRed/light/charts.svg',
          dark: '/images/monoRed/dark/charts.svg',
        },
        color: '/images/color/charts.svg',
      },
    },
    getPageIcon: function (iconType, theme) {
      return getPageIconInner(this.path, iconType, theme)
    },
  },
  valueChart: {
    type: 'page',
    showWithData: true,
    key: pageName.valueChart,
    label: 'ValueChart',
    path: {
      page: '/chart-value',
      icon: {
        monoBlue: {
          light: '/images/monoBlue/light/value-chart.svg',
          dark: '/images/monoBlue/dark/value-chart.svg',
        },
        monoRed: {
          light: '/images/monoRed/light/value-chart.svg',
          dark: '/images/monoRed/dark/value-chart.svg',
        },
        color: '/images/color/value-chart.svg',
      },
    },
    auth: ['superuser', 'admin', 'engineer', 'operator', 'cmm', 'qsense'],
    func: {
      sidePanel: {
        use: true,
        target: true,
      },
    },
    settings: pageSettings.valueChart,
    getPageIcon: function (iconType, theme) {
      return getPageIconInner(this.path, iconType, theme)
    },
    setPageSettingsToRedux: (payload) => commonRx.setValueChartPage(payload),
    updateUserSettingToDB: ({ userId, updateData }) => updateUserInfoForSettings(userId, bindSettingDB('valueChart'), updateData),
  },
  histogramChart: {
    type: 'page',
    showWithData: true,
    key: pageName.histogramChart,
    label: 'HistogramChart',
    path: {
      page: '/chart-histogram',
      icon: {
        monoBlue: {
          light: '/images/monoBlue/light/histogram.svg',
          dark: '/images/monoBlue/dark/histogram.svg',
        },
        monoRed: {
          light: '/images/monoRed/light/histogram.svg',
          dark: '/images/monoRed/dark/histogram.svg',
        },
        color: '/images/color/histogram.svg',
      },
    },
    auth: ['superuser', 'admin', 'engineer', 'operator', 'cmm', 'qsense'],
    func: {
      sidePanel: {
        use: true,
        target: true,
      },
    },
    settings: pageSettings.histogramChart,
    getPageIcon: function (iconType, theme) {
      return getPageIconInner(this.path, iconType, theme)
    },
    setPageSettingsToRedux: (payload) => commonRx.setHistogramChartPage(payload),
    updateUserSettingToDB: ({ userId, updateData }) => updateUserInfoForSettings(userId, bindSettingDB('histogramChart'), updateData),
  },
  positionalTolerancesChart: {
    type: 'page',
    showWithData: true,
    key: pageName.positionalTolerancesChart,
    label: 'PositionalTolerancesChart',
    path: {
      page: '/chart-positional-tolerances',
      icon: {
        monoBlue: {
          light: '/images/monoBlue/light/positional-tolerances.svg',
          dark: '/images/monoBlue/dark/positional-tolerances.svg',
        },
        monoRed: {
          light: '/images/monoRed/light/positional-tolerances.svg',
          dark: '/images/monoRed/dark/positional-tolerances.svg',
        },
        color: '/images/color/positional-tolerances.svg',
      },
    },
    auth: ['superuser', 'admin', 'engineer', 'operator', 'qsense'],
    func: {
      sidePanel: {
        use: true,
        target: true,
      },
    },
    settings: pageSettings.positionalTolerancesChart,
    getPageIcon: function (iconType, theme) {
      return getPageIconInner(this.path, iconType, theme)
    },
    setPageSettingsToRedux: (payload) => commonRx.setPTChartPage(payload),
    updateUserSettingToDB: ({ userId, updateData }) => updateUserInfoForSettings(userId, bindSettingDB('positionalTolerancesChart'), updateData),
  },
  boxPlotChart: {
    type: 'page',
    showWithData: true,
    key: pageName.boxPlotChart,
    label: 'BoxPlotChart',
    path: {
      page: '/chart-box-plot',
      icon: {
        monoBlue: {
          light: '/images/monoBlue/light/box-plot-chart.svg',
          dark: '/images/monoBlue/dark/box-plot-chart.svg',
        },
        monoRed: {
          light: '/images/monoRed/light/box-plot-chart.svg',
          dark: '/images/monoRed/dark/box-plot-chart.svg',
        },
        color: '/images/color/box-plot-chart.svg',
      },
    },
    auth: ['superuser', 'admin', 'engineer', 'operator', 'qsense'],
    func: {
      sidePanel: {
        use: true,
        target: true,
      },
    },
    settings: pageSettings.boxPlotChart,
    getPageIcon: function (iconType, theme) {
      return getPageIconInner(this.path, iconType, theme)
    },
    setPageSettingsToRedux: (payload) => commonRx.setBoxPlotChartPage(payload),
    updateUserSettingToDB: ({ userId, updateData }) => updateUserInfoForSettings(userId, bindSettingDB('boxPlotChart'), updateData),
  },
  linearRegressionChart: {
    type: 'page',
    showWithData: true,
    key: pageName.linearRegressionChart,
    label: 'LinearRegressionChart',
    path: {
      page: '/chart-linear-regression',
      icon: {
        monoBlue: {
          light: '/images/monoBlue/light/linear-regression.svg',
          dark: '/images/monoBlue/dark/linear-regression.svg',
        },
        monoRed: {
          light: '/images/monoRed/light/linear-regression.svg',
          dark: '/images/monoRed/dark/linear-regression.svg',
        },
        color: '/images/color/linear-regression.svg',
      },
    },
    auth: ['superuser', 'admin', 'engineer', 'operator'],
    func: {
      sidePanel: {
        use: true,
        target: true,
      },
    },
    settings: pageSettings.linearRegressionChart,
    getPageIcon: function (iconType, theme) {
      return getPageIconInner(this.path, iconType, theme)
    },
    setPageSettingsToRedux: (payload) => commonRx.setLRChartPage(payload),
    updateUserSettingToDB: ({ userId, updateData }) => updateUserInfoForSettings(userId, bindSettingDB('linearRegressionChart'), updateData),
  },
  correlationChart: {
    type: 'page',
    showWithData: true,
    key: pageName.correlationChart,
    label: 'CorrelationChart',
    path: {
      page: '/chart-correlation',
      icon: {
        monoBlue: {
          light: '/images/monoBlue/light/correlation.svg',
          dark: '/images/monoBlue/dark/correlation.svg',
        },
        monoRed: {
          light: '/images/monoRed/light/correlation.svg',
          dark: '/images/monoRed/dark/correlation.svg',
        },
        color: '/images/color/correlation.svg',
      },
    },
    auth: ['superuser', 'admin', 'engineer', 'operator', 'qsense'],
    func: {
      sidePanel: {
        use: true,
        target: true,
      },
    },
    settings: pageSettings.correlationChart,
    getPageIcon: function (iconType, theme) {
      return getPageIconInner(this.path, iconType, theme)
    },
    setPageSettingsToRedux: (payload) => commonRx.setCorrChartPage(payload),
    updateUserSettingToDB: ({ userId, updateData }) => updateUserInfoForSettings(userId, bindSettingDB('correlationChart'), updateData),
  },
  qualityControlChart: {
    type: 'page',
    showWithData: true,
    key: pageName.qualityControlChart,
    label: 'QualityControlChart',
    path: {
      page: '/chart-quality-control',
      icon: {
        monoBlue: {
          light: '/images/monoBlue/light/quality-control-chart.svg',
          dark: '/images/monoBlue/dark/quality-control-chart.svg',
        },
        monoRed: {
          light: '/images/monoRed/light/quality-control-chart.svg',
          dark: '/images/monoRed/dark/quality-control-chart.svg',
        },
        color: '/images/color/quality-control-chart.svg',
      },
    },
    auth: ['superuser', 'admin', 'engineer', 'operator', 'qsense'],
    func: {
      sidePanel: {
        use: true,
        target: true,
      },
    },
    settings: pageSettings.qualityControlChart,
    getPageIcon: function (iconType, theme) {
      return getPageIconInner(this.path, iconType, theme)
    },
    setPageSettingsToRedux: (payload) => commonRx.setQCChartPage(payload),
    updateUserSettingToDB: ({ userId, updateData }) => updateUserInfoForSettings(userId, bindSettingDB('qualityControlChart'), updateData),
  },
  capabilitySummaryChart: {
    type: 'page',
    showWithData: true,
    key: pageName.capabilitySummaryChart,
    label: 'CapabilitySummaryChart',
    path: {
      page: '/chart-capability-summary',
      icon: {
        monoBlue: {
          light: '/images/monoBlue/light/capability-summary.svg',
          dark: '/images/monoBlue/dark/capability-summary.svg',
        },
        monoRed: {
          light: '/images/monoRed/light/capability-summary.svg',
          dark: '/images/monoRed/dark/capability-summary.svg',
        },
        color: '/images/color/capability-summary.svg',
      },
    },
    auth: ['superuser', 'admin', 'engineer', 'operator'],
    func: {
      sidePanel: {
        use: true,
        target: false,
      },
    },
    settings: pageSettings.capabilitySummaryChart,
    getPageIcon: function (iconType, theme) {
      return getPageIconInner(this.path, iconType, theme)
    },
    setPageSettingsToRedux: (payload) => commonRx.setCSChartPage(payload),
    updateUserSettingToDB: ({ userId, updateData }) => updateUserInfoForSettings(userId, bindSettingDB('capabilitySummaryChart'), updateData),
  },
  paretoChart: {
    type: 'page',
    showWithData: true,
    key: pageName.paretoChart,
    label: 'ParetoChart',
    path: {
      page: '/chart-pareto',
      icon: {
        monoBlue: {
          light: '/images/monoBlue/light/pareto.svg',
          dark: '/images/monoBlue/dark/pareto.svg',
        },
        monoRed: {
          light: '/images/monoRed/light/pareto.svg',
          dark: '/images/monoRed/dark/pareto.svg',
        },
        color: '/images/color/pareto.svg',
      },
    },
    auth: ['superuser', 'admin', 'engineer', 'operator'],
    func: {
      sidePanel: {
        use: true,
        target: true,
      },
    },
    settings: pageSettings.paretoChart,
    getPageIcon: function (iconType, theme) {
      return getPageIconInner(this.path, iconType, theme)
    },
    setPageSettingsToRedux: (payload) => commonRx.setParetoChartPage(payload),
    updateUserSettingToDB: ({ userId, updateData }) => updateUserInfoForSettings(userId, bindSettingDB('paretoChart'), updateData),
  },
  probabilityPlotChart: {
    type: 'page',
    showWithData: true,
    key: pageName.probabilityPlotChart,
    label: 'ProbabilityPlotChart',
    path: {
      page: '/chart-probability-plot',
      icon: {
        monoBlue: {
          light: '/images/monoBlue/light/probability-plot.svg',
          dark: '/images/monoBlue/dark/probability-plot.svg',
        },
        monoRed: {
          light: '/images/monoRed/light/probability-plot.svg',
          dark: '/images/monoRed/dark/probability-plot.svg',
        },
        color: '/images/color/probability-plot.svg',
      },
    },
    auth: ['superuser', 'admin', 'engineer', 'operator'],
    func: {
      sidePanel: {
        use: true,
        target: true,
      },
    },
    settings: pageSettings.probabilityPlotChart,
    getPageIcon: function (iconType, theme) {
      return getPageIconInner(this.path, iconType, theme)
    },
    setPageSettingsToRedux: (payload) => commonRx.setProbPlotChartPage(payload),
    updateUserSettingToDB: ({ userId, updateData }) => updateUserInfoForSettings(userId, bindSettingDB('probabilityPlotChart'), updateData),
  },
  decisionTreeChart: {
    type: 'page',
    showWithData: true,
    key: pageName.decisionTreeChart,
    label: 'DecisionTreeChart',
    path: {
      page: '/chart-decision-tree',
      icon: {
        monoBlue: {
          light: '/images/monoBlue/light/cart.svg',
          dark: '/images/monoBlue/dark/cart.svg',
        },
        monoRed: {
          light: '/images/monoRed/light/cart.svg',
          dark: '/images/monoRed/dark/cart.svg',
        },
        color: '/images/color/cart.svg',
      },
    },
    auth: ['superuser', 'admin', 'engineer', 'operator', 'qsense'],
    func: {
      sidePanel: {
        use: true,
        target: true,
      },
    },
    settings: pageSettings.decisionTreeChart,
    getPageIcon: function (iconType, theme) {
      return getPageIconInner(this.path, iconType, theme)
    },
    setPageSettingsToRedux: (payload) => commonRx.setDecisionTreeChartPage(payload),
    updateUserSettingToDB: ({ userId, updateData }) => updateUserInfoForSettings(userId, bindSettingDB('decisionTreeChart'), updateData),
    // translate: (type, message) => formatMessage('Page_DecisionTreeChart_' + message),
  },
  lists: {
    type: 'directory',
    showWithData: true,
    key: 'lists',
    label: 'Lists',
    path: {
      icon: {
        monoBlue: {
          light: '/images/monoBlue/light/lists.svg',
          dark: '/images/monoBlue/dark/lists.svg',
        },
        monoRed: {
          light: '/images/monoRed/light/lists.svg',
          dark: '/images/monoRed/dark/lists.svg',
        },
        color: '/images/color/lists.svg',
      },
    },
    getPageIcon: function (iconType, theme) {
      return getPageIconInner(this.path, iconType, theme)
    },
  },
  valueList: {
    type: 'page',
    showWithData: true,
    key: pageName.valueList,
    label: 'ValueList',
    path: {
      page: '/list-value',
      icon: {
        monoBlue: {
          light: '/images/monoBlue/light/value-list.svg',
          dark: '/images/monoBlue/dark/value-list.svg',
        },
        monoRed: {
          light: '/images/monoRed/light/value-list.svg',
          dark: '/images/monoRed/dark/value-list.svg',
        },
        color: '/images/color/value-list.svg',
      },
    },
    auth: ['superuser', 'admin', 'engineer', 'operator', 'cmm', 'qsense'],
    func: {
      sidePanel: {
        use: true,
        target: true,
      },
    },
    settings: pageSettings.valueList,
    getPageIcon: function (iconType, theme) {
      return getPageIconInner(this.path, iconType, theme)
    },
    setPageSettingsToRedux: (payload) => commonRx.setValueListPage(payload),
    updateUserSettingToDB: ({ userId, updateData }) => updateUserInfoForSettings(userId, bindSettingDB('valueList'), updateData),
  },
  summaryList: {
    type: 'page',
    showWithData: true,
    key: pageName.summaryList,
    label: 'SummaryList',
    path: {
      page: '/list-summary',
      icon: {
        monoBlue: {
          light: '/images/monoBlue/light/summary-list.svg',
          dark: '/images/monoBlue/dark/summary-list.svg',
        },
        monoRed: {
          light: '/images/monoRed/light/summary-list.svg',
          dark: '/images/monoRed/dark/summary-list.svg',
        },
        color: '/images/color/summary-list.svg',
      },
    },
    auth: ['superuser', 'admin', 'engineer', 'operator', 'cmm', 'qsense'],
    func: {
      sidePanel: {
        use: true,
        target: true,
      },
    },
    settings: pageSettings.summaryList,
    getPageIcon: function (iconType, theme) {
      return getPageIconInner(this.path, iconType, theme)
    },
    setPageSettingsToRedux: (payload) => commonRx.setSummaryListPage(payload),
    updateUserSettingToDB: ({ userId, updateData }) => updateUserInfoForSettings(userId, bindSettingDB('summaryList'), updateData),
  },
  valueSummaryList: {
    type: 'page',
    showWithData: true,
    key: pageName.valueSummaryList,
    label: 'ValueSummaryList',
    path: {
      page: '/list-value-summary',
      icon: {
        monoBlue: {
          light: '/images/monoBlue/light/value-summary-list.svg',
          dark: '/images/monoBlue/dark/value-summary-list.svg',
        },
        monoRed: {
          light: '/images/monoRed/light/value-summary-list.svg',
          dark: '/images/monoRed/dark/value-summary-list.svg',
        },
        color: '/images/color/value-summary-list.svg',
      },
    },
    auth: ['superuser', 'admin', 'engineer', 'operator', 'qsense'],
    func: {
      sidePanel: {
        use: true,
        target: true,
      },
    },
    settings: pageSettings.valueSummaryList,
    getPageIcon: function (iconType, theme) {
      return getPageIconInner(this.path, iconType, theme)
    },
    setPageSettingsToRedux: (payload) => commonRx.setVSListPage(payload),
    updateUserSettingToDB: ({ userId, updateData }) => updateUserInfoForSettings(userId, bindSettingDB('valueSummaryList'), updateData),
  },
  scrollBoard: {
    type: 'page',
    showWithData: false,
    key: pageName.scrollBoard,
    label: 'ScrollBoard',
    path: {
      page: '/scroll-board',
      icon: {
        monoBlue: {
          light: '/images/monoBlue/light/scroll-board.svg',
          dark: '/images/monoBlue/dark/scroll-board.svg',
        },
        monoRed: {
          light: '/images/monoRed/light/scroll-board.svg',
          dark: '/images/monoRed/dark/scroll-board.svg',
        },
        color: '/images/color/scroll-board.svg',
      },
    },
    auth: ['superuser', 'admin', 'engineer', 'operator', 'cmm', 'qsense'],
    func: {
      sidePanel: {
        use: true,
        target: true,
      },
    },
    settings: pageSettings.scrollBoard,
    getPageIcon: function (iconType, theme) {
      return getPageIconInner(this.path, iconType, theme)
    },
    setPageSettingsToRedux: (payload) => commonRx.setScrollBoardPage(payload),
    updateUserSettingToDB: ({ userId, updateData }) => updateUserInfoForSettings(userId, bindSettingDB('scrollBoard'), updateData),
  },
  reportPrint: {
    type: 'page',
    showWithData: true,
    key: pageName.reportPrint,
    label: 'ReportPrint',
    path: {
      page: '/report-print',
      icon: {
        monoBlue: {
          light: '/images/monoBlue/light/report-print.svg',
          dark: '/images/monoBlue/dark/report-print.svg',
        },
        monoRed: {
          light: '/images/monoRed/light/report-print.svg',
          dark: '/images/monoRed/dark/report-print.svg',
        },
        color: '/images/color/report-print.svg',
      },
    },
    auth: ['superuser', 'admin', 'engineer', 'operator', 'cmm', 'qsense'],
    func: {
      sidePanel: {
        use: true,
        target: true,
      },
    },
    settings: pageSettings.reportPrint,
    getPageIcon: function (iconType, theme) {
      return getPageIconInner(this.path, iconType, theme)
    },
    setPageSettingsToRedux: (payload) => commonRx.setReportPrintPage(payload),
    updateUserSettingToDB: () => null,
  },
  uploadFile: {
    type: 'page',
    showWithData: false,
    key: pageName.uploadFile,
    label: 'UploadFile',
    path: {
      page: '/upload-file',
      icon: {
        monoBlue: {
          light: 'upload',
          dark: 'upload',
        },
        monoRed: {
          light: 'upload',
          dark: 'upload',
        },
        color: 'upload',
      },
    },
    auth: ['superuser', 'admin', 'engineer', 'operator', 'cmm', 'qsense'],
    func: {
      sidePanel: {
        use: false,
        target: false,
      },
    },
    settings: pageSettings.uploadFile,
    getPageIcon: function (iconType, theme) {
      return getPageIconInner(this.path, iconType, theme)
    },
    setPageSettingsToRedux: () => null,
    updateUserSettingToDB: ({ userId, updateData }) => updateUserInfoForSettings(userId, bindSettingDB('uploadFile'), updateData),
  },
  configuration: {
    type: 'page',
    showWithData: false,
    key: pageName.configuration,
    label: 'Configuration',
    path: {
      page: '/config',
      icon: {
        monoBlue: {
          light: '/images/monoBlue/light/configuration.svg',
          dark: '/images/monoBlue/dark/configuration.svg',
        },
        monoRed: {
          light: '/images/monoRed/light/configuration.svg',
          dark: '/images/monoRed/dark/configuration.svg',
        },
        color: '/images/color/configuration.svg',
      },
    },
    auth: ['superuser', 'admin', 'engineer'],
    func: {
      sidePanel: {
        use: false,
        target: false,
      },
    },
    settings: pageSettings.configuration,
    getPageIcon: function (iconType, theme) {
      return getPageIconInner(this.path, iconType, theme)
    },
    setPageSettingsToRedux: (payload) => commonRx.setConfiguration(payload),
    updateUserSettingToDB: ({ userId, updateData }) => updateUserInfoForSettings(userId, bindSettingDB('configuration'), updateData),
  },
  adminSettings: {
    type: 'page',
    showWithData: false,
    key: pageName.adminSettings,
    label: 'AdminSettings',
    path: {
      page: '/admin',
      icon: {
        monoBlue: {
          light: '/images/monoBlue/light/admin-configuration.svg',
          dark: '/images/monoBlue/dark/admin-configuration.svg',
        },
        monoRed: {
          light: '/images/monoRed/light/admin-configuration.svg',
          dark: '/images/monoRed/dark/admin-configuration.svg',
        },
        color: '/images/color/admin-configuration.svg',
      },
    },
    auth: ['superuser', 'admin'],
    func: {
      sidePanel: {
        use: false,
        target: false,
      },
    },
    settings: pageSettings.adminSettings,
    getPageIcon: function (iconType, theme) {
      return getPageIconInner(this.path, iconType, theme)
    },
    setPageSettingsToRedux: (payload) => commonRx.setAdminSettingsPage(payload),
    updateUserSettingToDB: ({ userId, updateData }) => updateUserInfoForSettings(userId, bindSettingDB('adminSettings'), updateData),
  },
  companyInfo: {
    type: 'page',
    showWithData: false,
    key: pageName.companyInfo,
    label: 'CompanyInfo',
    path: {
      page: '/info-company',
      icon: {
        monoBlue: {
          light: '/images/monoBlue/light/company-info.svg',
          dark: '/images/monoBlue/dark/company-info.svg',
        },
        monoRed: {
          light: '/images/monoRed/light/company-info.svg',
          dark: '/images/monoRed/dark/company-info.svg',
        },
        color: '/images/color/company-info.svg',
      },
    },
    auth: ['superuser', 'admin', 'engineer', 'operator', 'cmm', 'qsense'],
    func: {
      sidePanel: {
        use: false,
        target: false,
      },
    },
    // settings: adminSettingsPrm,
    getPageIcon: function (iconType, theme) {
      return getPageIconInner(this.path, iconType, theme)
    },
    setPageSettingsToRedux: () => null,
    updateUserSettingToDB: () => null,
  },
  reportPrintMultiFunction: {
    type: 'page',
    showWithData: true,
    key: pageName.reportPrintMultiFunction,
    label: 'ReportPrintMultiFunction',
    path: {
      page: '/report-print-multi-function',
      icon: {
        monoBlue: {
          light: '/images/monoBlue/light/report-print.svg',
          dark: '/images/monoBlue/dark/report-print.svg',
        },
        monoRed: {
          light: '/images/monoRed/light/report-print.svg',
          dark: '/images/monoRed/dark/report-print.svg',
        },
        color: '/images/color/report-print.svg',
      },
    },
    auth: ['superuser', 'admin', 'engineer', 'operator', 'cmm', 'qsense'],
    func: {
      sidePanel: {
        use: true,
        target: true,
      },
    },
    settings: pageSettings.reportPrintMultiFunction,
    getPageIcon: function (iconType, theme) {
      return getPageIconInner(this.path, iconType, theme)
    },
    setPageSettingsToRedux: (payload) => commonRx.setReportMultiFunctionPage(payload),
    updateUserSettingToDB: ({ userId, updateData }) => updateUserInfoForSettings(userId, bindSettingDB('reportPrintMultiFunction'), updateData),
  },
}
