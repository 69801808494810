import { Column, ColumnChooser, ColumnChooserSearch, ColumnChooserSelection, DataGrid, Export, Item, Position, SearchPanel, Toolbar } from 'devextreme-react/data-grid'
import { exportDataGrid } from 'devextreme/excel_exporter'
import { Workbook } from 'exceljs'
import { saveAs } from 'file-saver-es'
import 'jspdf-autotable'
import { useCallback, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import CatalogUtils from 'src/functions/CatalogUtils'
import CPUtils from 'src/functions/CommonPageUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'
import useCustomStateStore from 'src/hook/devexpress-custom-state-storing/useDevStateStoring'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'

function handleValueRender(e, dataField, lsl, usl, char, settings, configSettings) {
  const hasKey = e.data.hasOwnProperty(dataField)

  if (!hasKey) {
    return
  }

  const value = e.data[dataField]
  if (!dataField.includes('v0001')) {
    let convertedValue = value
    if (dataField.includes('v0004')) {
      convertedValue = CPUtils.getDisplayDateTimeSec(settings.page.dateTimeExtraFieldDisplayFormat, value)
    } else {
      if (CatalogUtils.isCatalogField(dataField)) {
        convertedValue = CatalogUtils.convertByCatalog(dataField, value, configSettings.catalogs)
      }
    }

    return (
      <div className="value-list-cp-cpk-container">
        <div style={{ fontSize: '12px' }}>{convertedValue !== 'NaN' ? convertedValue : null}</div>
      </div>
    )
  }

  // if (value !== 'NaN') {
  if (!isNaN(value)) {
    if (lsl === null && usl === null) {
      return (
        <div className="value-list-cp-cpk-container">
          <div style={{ fontSize: '12px' }}>{DNUtils.valueDisplay(value, char, configSettings)}</div>
        </div>
      )
    } else if (lsl !== null && usl !== null) {
      if (lsl > value || usl < value) {
        return (
          <div className="value-list-cp-cpk-container">
            <div style={{ color: 'red' }}>{DNUtils.valueDisplay(value, char, configSettings)}</div>
            <img src={'/images/color/warning-exclamation.svg'} width="15" height="15" />
          </div>
        )
      }

      return (
        <div className="value-list-cp-cpk-container">
          <div style={{ fontSize: '12px' }}>{DNUtils.valueDisplay(value, char, configSettings)}</div>
        </div>
      )
    } else if (lsl !== null && usl === null) {
      if (lsl > value) {
        return (
          <div className="value-list-cp-cpk-container">
            <div style={{ color: 'red' }}>{DNUtils.valueDisplay(value, char, configSettings)}</div>
            <img src={'/images/color/warning-exclamation.svg'} width="15" height="15" />
          </div>
        )
      } else {
        return (
          <div className="value-list-cp-cpk-container">
            <div style={{ fontSize: '12px' }}>{DNUtils.valueDisplay(value, char, configSettings)}</div>
          </div>
        )
      }
    } else {
      if (usl < value) {
        return (
          <div className="value-list-cp-cpk-container">
            <div style={{ color: 'red' }}>{DNUtils.valueDisplay(value, char, configSettings)}</div>
            <img src={'/images/color/warning-exclamation.svg'} width="15" height="15" />
          </div>
        )
      } else {
        return (
          <div className="value-list-cp-cpk-container">
            <div style={{ fontSize: '12px' }}>{DNUtils.valueDisplay(value, char, configSettings)}</div>
          </div>
        )
      }
    }
  } else {
    return (
      <div className="value-list-cp-cpk-container">
        <div style={{ fontSize: '12px' }}></div>
      </div>
    )
  }
}

const onExporting = (e, ref, char) => {
  const dataSource = ref?.current.instance.option('dataSource')

  if (dataSource.length > 0) {
    const fileName = char.c2001 + '_' + char.c2002
    if (e.format === 'xlsx') {
      const workbook = new Workbook()
      const worksheet = workbook.addWorksheet('Main sheet')
      exportDataGrid({
        component: e.component,
        worksheet,
        autoFilterEnabled: true,
      }).then(() => {
        workbook.xlsx.writeBuffer().then((buffer) => {
          saveAs(new Blob([buffer], { type: 'application/octet-stream' }), `${fileName}.xlsx`)
        })
      })
    }
  }
}

function ScrollBoardValueList(props) {
  const { value, char } = props
  const scDatagridRef = useRef(null)
  const { saveState, loadState, clearState } = useCustomStateStore({ stateKey: 'scroll-board-value-list', excludedFields: [] }) // Pass your custom state key here

  const sbPageSettings = useSelector((state) => state.common.pages.scrollBoard)
  const configSettings = useSelector((state) => state.common.pages.configuration)

  const isCommonEmpty = Object.keys(configSettings.useFieldList).length === 0
  const isLsUseFieldListEmpty = Object.keys(configSettings.useFieldList).length === 0
  let useFieldList = {}
  if (isCommonEmpty === false) {
    useFieldList = configSettings.useFieldList
  } else if (isLsUseFieldListEmpty === false) {
    useFieldList = configSettings.useFieldList
  } else {
    useFieldList = {
      part: [],
      char: [],
      value: [],
    }
  }

  const dataSource = value.valueRaw.map((value, index) => {
    return {
      ID: index + 1,
      ...value,
    }
  })

  const columns = useFieldList.value.map((field, index) => {
    return (
      <Column
        key={index + 1}
        dataField={field.value}
        caption={dqTranslateMsg('Db_field_' + 'Value_' + field.text)}
        alignment="center"
        dataType={field.dataType}
        width={'auto'}
        minWidth={100}
        allowReordering={true}
        cellRender={(cellData) => handleValueRender(cellData, field.value, char.c2110, char.c2111, char, sbPageSettings, configSettings)}
      />
    )
  })

  const handleColumnVisibilityChange = useCallback((e) => {
    if (e.component && e.fullName.startsWith('columns[') && e.fullName.endsWith('].visible')) {
      const columnIndex = parseInt(e.fullName.match(/\d+/)[0], 10)
      const isVisible = e.value
      const gridInstance = e.component

      if (isVisible) {
        const visibleColumns = gridInstance.getVisibleColumns()
        const lastIndex = visibleColumns.length - 1
        gridInstance.columnOption(columnIndex, 'visibleIndex', lastIndex)
      }
    }
  }, [])

  const handleSaveState = () => {
    if (scDatagridRef.current) {
      const state = scDatagridRef.current.instance.state()
      saveState(state)
    }
  }

  const handleLoadState = () => {
    if (scDatagridRef.current) {
      const state = loadState()
      if (state) {
        scDatagridRef.current.instance.state(state)
      }
    }
  }

  const handleClearState = () => {
    clearState()
    if (scDatagridRef.current) {
      const emptyState = {
        columns: [],
        sorting: [],
        grouping: [],
        selectionFilter: [],
        pageIndex: 0,
        pageSize: 10,
      }
      scDatagridRef.current.instance.state(emptyState)
    }
  }

  useEffect(() => {
    handleLoadState()
  }, [])

  return (
    <div className="dx-theme-background-color">
      {dataSource.length > 0 ? (
        <div className="value-list-grid-container">
          {/* <DQLoadPanel open={isLoadingPanel} /> */}
          <DataGrid
            ref={scDatagridRef}
            dataSource={dataSource}
            className="value-list-grid-container"
            allowColumnReordering={true}
            showColumnLines={true}
            showRowLines={true}
            showBorders={true}
            rowAlternationEnabled={false}
            noDataText={dqTranslateMsg('Page_ValueList_' + 'NoData')}
            allowColumnResizing={true}
            columnResizingMode={'widget'}
            // columnMinWidth={100}
            // columnAutoWidth={true}
            hoverStateEnabled={true}
            paging={{ enabled: false }}
            renderAsync={true}
            keyExpr="ID"
            scrolling={{
              columnRenderingMode: 'standard',
              rowRenderingMode: 'virtual',
              mode: 'virtual',
              preloadEnabled: false,
              renderAsync: false,
              showScrollbar: 'onHover',
              useNative: false,
              scrollByContent: true,
              scrollByThumb: true,
            }}
            onExporting={(e) => onExporting(e, scDatagridRef, char)}
            onOptionChanged={handleColumnVisibilityChange}
          >
            {/* <StateStoring enabled={true} type="localStorage" storageKey="scroll-board-value-list" savingTimeout={500} /> */}
            {/* <StateStoring enabled={true} type={'localStorage'} customLoad={loadState} customSave={saveState} /> */}
            <Column dataField={'ID'} caption={dqTranslateMsg('Page_ValueList_' + 'ID')} alignment="center" width={'100px'} showInColumnChooser={false} fixed={true} />
            {columns}
            <Toolbar>
              <Item
                widget="dxButton"
                options={{
                  icon: 'save',
                  text: dqTranslateMsg('Page_ScrollBoard_' + 'SaveLayout'),
                  onClick: handleSaveState,
                }}
                location="before"
              />
              <Item
                widget="dxButton"
                options={{
                  icon: 'clear',
                  text: dqTranslateMsg('Page_ScrollBoard_' + 'ClearLayout'),
                  onClick: handleClearState,
                }}
                location="before"
              />
              <Item name="saveButton" location="after" />
              <Item name="revertButton" location="after" />
              <Item name="searchPanel" location="after" />
              <Item name="columnChooserButton" locateInMenu="never" location="after" />
              <Item name="exportButton" location="after" />
            </Toolbar>
            <SearchPanel visible={true} width={140} placeholder={dqTranslateMsg('Page_ValueList_' + 'SearchPlaceHoler')} />
            <ColumnChooser
              enabled={true}
              mode={'select'}
              width={300}
              height={500}
              title={dqTranslateMsg('Page_ValueList_' + 'ColumnChooserTitle')}
              emptyPanelText={dqTranslateMsg('Page_ValueList_' + 'ColumnChooserEmptyPanelText')}
            >
              <Position my="center" at="center" of={window} />
              <ColumnChooserSearch
                enabled={true}
                editorOptions={{
                  placeholder: dqTranslateMsg('Page_ValueList_' + 'ColumnChooserPlaceHoler'),
                }}
              />
              <ColumnChooserSelection allowSelectAll={true} selectByClick={true} recursive={true} />
            </ColumnChooser>
            {/* <Export enabled={true} formats={['pdf']} /> */}
            <Export
              enabled={true}
              formats={['xlsx']}
              texts={{
                exportAll: dqTranslateMsg('Page_ValueList_' + 'ExportToExcel'),
              }}
            />
          </DataGrid>
        </div>
      ) : (
        <>
          <img src={'/images/color/warning-exclamation.svg'} width="30" height="30" />
          <div style={{ fontSize: '20px' }}>{dqTranslateMsg('Page_ValueList_' + 'DataIsNotSelected')}</div>
        </>
      )}
    </div>
  )
}

export default ScrollBoardValueList
