import React, { Fragment } from 'react'
import { GroupItem } from 'devextreme-react/form'
import RadioGroupSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/RadioGroupSimpleItem'
import { useDispatch, useSelector } from 'react-redux'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import produce from 'immer'
import SelectBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItem'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'

const displayType = () => [
  { value: 'single', text: dqTranslateMsg('SidePanel_' + 'Single') },
  { value: 'all', text: dqTranslateMsg('SidePanel_' + 'All') },
]

const mainDisplay = () => [{ value: 'part', text: dqTranslateMsg('SidePanel_' + 'Part') }]
const extraDisplay = () => [
  { value: 'char', text: dqTranslateMsg('SidePanel_' + 'Characteristics') },
  { value: 'c2092', text: dqTranslateMsg('Db_field_Characteristics_' + 'C2092') },
]

const DisplayType = (pageKey, pageState, configState) => {
  const dispatch = useDispatch()
  // const pageKey = 'overview'

  const ovPageSettings = pageState
  const configSettings = configState

  const isCommonEmpty = Object.keys(configSettings.useFieldList).length === 0
  const isLsUseFieldListEmpty = Object.keys(configSettings.useFieldList).length === 0

  let useFieldList = {}
  if (isCommonEmpty === false) {
    useFieldList = configSettings.useFieldList
  } else if (isLsUseFieldListEmpty === false) {
    useFieldList = configSettings.useFieldList
  } else {
    useFieldList = {
      part: [],
      char: [],
      value: [],
    }
  }

  // const mainDisplay
  const subDisplayDepth1 = extraDisplay()
  const subDisplayDepth2 = useFieldList.value.map((item) => {
    return { ...item, text: dqTranslateMsg('Db_field_Value_' + item.text) }
  })

  const singleDisplay = [...mainDisplay(), ...subDisplayDepth1, ...subDisplayDepth2]
  const subDisplay = [...subDisplayDepth1, ...subDisplayDepth2]

  const onRadioGroupDisplayTypeValueChanged = (e, dataField) => {
    if (e.event === undefined) return
    const value = e.value
    const next = produce(ovPageSettings, (draft) => {
      if (value === 'all') {
        draft.page.display[dataField] = value
        draft.page.display.main = 'part'
      } else {
        draft.page.display[dataField] = value
      }
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onSelectBoxValueChanged = (e, dataField) => {
    const value = e.itemData.value
    const next = produce(ovPageSettings, (draft) => {
      draft.page.display[dataField] = value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`display-type-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'DisplayType')}>
      {RadioGroupSimpleItem({
        ref: null,
        colSpan: 2,
        label: '',
        labelLocation: 'top',
        name: 'type',
        items: displayType(),
        layout: 'horizontal',
        valueExpr: 'value',
        displayExpr: 'text',
        value: ovPageSettings.page.display.type,
        disabled: false,
        onValueChanged: (e) => onRadioGroupDisplayTypeValueChanged(e, 'type'),
      })}
      {/* {ovPageSettings.page.display.type === 'single' && */}
      {SelectBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Main'),
        labelLocation: 'top',
        name: 'singleMain',
        items: singleDisplay,
        valueExpr: 'value',
        displayExpr: 'text',
        value: ovPageSettings.page.display.main,
        disabled: ovPageSettings.page.display.type === 'all',
        onItemClick: (e) => onSelectBoxValueChanged(e, 'main'),
      })}
      {SelectBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Sub'),
        labelLocation: 'top',
        name: 'allSub',
        items: subDisplay,
        valueExpr: 'value',
        displayExpr: 'text',
        value: ovPageSettings.page.display.sub,
        disabled: false,
        visible: ovPageSettings.page.display.type === 'all',
        onItemClick: (e) => onSelectBoxValueChanged(e, 'sub'),
      })}
    </GroupItem>
  )
}

export default DisplayType
