import { Form, ScrollView } from 'devextreme-react'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth } from 'src/contexts/auth'
import { useLanguage } from 'src/contexts/languages'
import DQSaveReset from 'src/functions/DQSaveReset'
import DQToast from 'src/functions/DQToast'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import './SidePanelTemplate.scss'

export default function SidePanelTemplate({ pageKey, formComponents, saveCallback, resetCallback, extraButtons, toastRef }) {
  const dispatch = useDispatch()
  const { user } = useAuth()
  const { language } = useLanguage()
  const parentRef = useRef(null)

  const [scrollPosition, setScrollPosition] = useState(0)
  const common = useSelector((state) => state.common)
  const curPage = common.pages[pageKey]

  const handleScroll = (e) => {
    setScrollPosition(e.scrollOffset.top)
  }

  return (
    <div ref={parentRef} style={{ padding: '5px', width: '100%', height: '100%' }}>
      <DQToast ref={toastRef} />
      <div style={{ width: '100%', height: '3%' }}>
        <DQSaveReset saveBtnText={dqTranslateMsg('SidePanel_' + 'Save')} resetBtnText={dqTranslateMsg('SidePanel_' + 'Reset')} saveCallback={saveCallback} resetCallback={resetCallback} />
      </div>
      <div style={{ width: '100%', height: '97%' }}>
        {extraButtons}
        <ScrollView
          className="side-panel-tab-container"
          showScrollbar={'onHover'}
          useNative={false}
          onScroll={handleScroll}
          onContentReady={(e) => {
            e.component.scrollTo({ top: scrollPosition })
          }}
        >
          <Form id={`side-panel-form`} style={{ width: '100%', height: '97%', paddingTop: '10px' }} labelMode={'outside'} formData={curPage.page} showColonAfterLabel={false}>
            {formComponents}
          </Form>
        </ScrollView>
      </div>
    </div>
  )
}
