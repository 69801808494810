import ReactECharts from 'echarts-for-react'
import { useEffect, useMemo, useRef, useState, useCallback, Suspense, lazy } from 'react'
import { batch, connect } from 'react-redux'
import { useTheme } from 'src/contexts/theme'
import CPUtils from 'src/functions/CommonPageUtils'
import * as VCUtils from 'src/functions/ValueChartUtils'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import './ValueChartForm.scss'
import PageWarningMessage from 'src/functions/PageWarningMessage'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'
import DQLoadPanel from 'src/components/dq-load-form/DQLoadPanel'
// import ApacheEChartValueChartComponent from '../arima/ApacheEChartValueChartComponent'
import { useLanguage } from 'src/contexts/languages'
import { AutoSizer, Grid, CellMeasurerCache } from 'react-virtualized'
import Modal from 'react-modal'
import * as VCArima from 'src/functions/ValueChartArima'
import ApiStatus from 'src/api/defines/ApiStatus'
import { getArima } from 'src/api/arima'
import produce from 'immer'
import { getErrCommMsg } from 'src/functions/CommonMassageUtils'

const mapStateToProps = (state) => ({
  common: state.common,
  chartRawData: state.chartRawData,
})

export default connect(mapStateToProps)(ValueChartForm)

// Lazy load the component
const ApacheEChartValueChartComponent = lazy(() => import('../arima/ApacheEChartValueChartComponent'))

const styles = {
  chartContainer: {
    padding: '5px',
  },
  chartInnerContainer: {
    width: 'calc(100%)', // Adjust width to account for the margin
    height: 'calc(100%)', // Adjust height to account for the margin
    padding: '10px',
    borderRadius: '10px',
    boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)',
    overflow: 'visible',
  },
  modalContent: {
    width: '600px',
    height: '400px',
    margin: 'auto',
    padding: '20px',
    backgroundColor: '#fff',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    position: 'relative',
    zIndex: 1000,
  },
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    zIndex: 999,
  },
  gridCell: {},
}

export const arimaNameTranslate = (itemName) => {
  if (itemName === 'low_pred') {
    return dqTranslateMsg('Page_ValueChart_' + 'LowPred')
  } else if (itemName === 'upper_pred') {
    return dqTranslateMsg('Page_ValueChart_' + 'UpperPred')
  } else {
    return dqTranslateMsg('Page_ValueChart_' + 'Pred')
  }
}

function ValueChartForm({ pageKey, common, chartRawData }) {
  const { getTheme } = useTheme()

  const { language } = useLanguage()
  const [isLoadingPanel, setIsLoadingPanel] = useState(false)

  // const valueChartRefs = useMemo(() => new Array(numCharts).fill(null).map(() => ({ current: { element: null, chartElement: null, id: null } })), [numCharts])

  const valueChartRefs = useRef(null)
  const [arimaChartData, setArimaChartData] = useState([])
  const [chartOptions, setChartOptions] = useState([])
  const gridRef = useRef(null) // Create a ref for the Grid component
  const curPage = common.pages.valueChart
  const configPage = common.pages.configuration
  const currentSel = common.current_sel
  const selectedChar = common.loaded_all[pageKey]

  const [arimaShowFlag, setArimaShowFlag] = useState(common.pages.valueChart.page.arima.show)

  // const chartOptions = VCUtils.makeChartOptionArray(pageKey, chars, valuesArranged, curPage, configPage, getTheme())
  const columnCount = curPage.page.layout.column
  const rowCount = Math.ceil(chartOptions.length / columnCount)

  const makeArimaOption = async (settings, configSettings, forecastSize, sampleSrc) => {
    try {
      const { auto, pValue, dValue, qValue } = settings.page.arima
      const srcData = sampleSrc
      const result = await getArima(auto, pValue, dValue, qValue, forecastSize, srcData)
      if (!ApiStatus.isSuccess(result.status)) {
        // alert(getErrCommMsg(result.status))
        return null
      }

      const data = result.data
      return data
    } catch (error) {
      console.error('Error in makeHistogramOption:', error)
    }

    return []
  }

  useEffect(() => {
    const { parts, chars, values } = CPUtils.loadRefChartData(chartRawData)
    const { charArray } = CPUtils.loadRefOtherData(common, chars, pageKey)
    const selectedValueWithDummy = CPUtils.checkMatchCharsAndValues(charArray, values)
    const valuesArranged = CPUtils.removeInvalidInValues(selectedValueWithDummy, curPage.page.showDeletedValue)
    const numCharts = chars.length
    valueChartRefs.current = new Array(numCharts).fill(null).map(() => ({ element: null, chartElement: null, id: null }))

    const processChartOptions = async () => {
      setIsLoadingPanel(true)
      try {
        if (valuesArranged.length > 0) {
          // let tempChartOptions = []
          let tempArimaChartData = []
          await Promise.all(
            valuesArranged.map(async (value) => {
              const targetChar = value.charRaw
              const targetValue = value.valueRaw

              if (targetValue.length < 1) {
                tempArimaChartData.push({ targetChar, targetValue, arimaData: null })
                return
              }

              let responseData = null
              if (arimaShowFlag === false && curPage.page.arima.show === true) {
                const { sampleSize, forecastSize } = VCUtils.adjustedForecastSampleSize(curPage.page.arima.forecastSize, curPage.page.arima.sampleSize, targetValue.length)
                const entireSrc = targetValue.map((value) => value.v0001)
                const sampleSrc = entireSrc.slice(-sampleSize)
                responseData = await makeArimaOption(curPage, configPage, forecastSize, sampleSrc)
                tempArimaChartData.push({ targetChar, targetValue, arimaData: responseData })
              } else {
                if (curPage.page.arima.show === true) {
                  const savedTarget = arimaChartData.find((data) => data.targetChar.part_id === value.part_id && data.targetChar.char_id === value.char_id)
                  if (!savedTarget) {
                    const { sampleSize, forecastSize } = VCUtils.adjustedForecastSampleSize(curPage.page.arima.forecastSize, curPage.page.arima.sampleSize, targetValue.length)
                    const entireSrc = targetValue.map((value) => value.v0001)
                    const sampleSrc = entireSrc.slice(-sampleSize)
                    responseData = await makeArimaOption(curPage, configPage, forecastSize, sampleSrc)
                    tempArimaChartData.push({ targetChar, targetValue, arimaData: responseData })
                  } else {
                    tempArimaChartData.push(savedTarget)
                  }
                } else {
                  tempArimaChartData.push({ targetChar, targetValue, arimaData: null })
                }
              }
            })
          )

          tempArimaChartData.sort((a, b) => {
            if (a.targetChar.part_id !== b.targetChar.part_id) {
              return a.targetChar.part_id - b.targetChar.part_id
            } else {
              return a.targetChar.char_id - b.targetChar.char_id
            }
          })

          const tempChartOptions = tempArimaChartData.map((data) => {
            const targetChar = data.targetChar
            const targetValue = data.targetValue
            const arimaData = data.arimaData

            const pcSet = VCUtils.makePCData(configPage, targetChar, targetValue)
            if (pcSet === null) {
              return { targetChar, targetValue, option: null, error: { name: 'PCSettingsError', extra: 'warn offset' } }
            }
            const chartSet = VCUtils.makeChartData(curPage, configPage, pcSet, targetChar, targetValue)
            if (chartSet === null) {
              return { targetChar, targetValue, option: null, error: { name: 'ChartSettingsError', extra: 'chart data' } }
            }

            let valueChartOption = VCUtils.makeChartOption(pageKey, curPage, configPage, pcSet, chartSet, targetChar, targetValue, getTheme())
            if (valueChartOption === null) {
              return { targetChar, targetValue, option: null, error: { name: 'MakeChartOptionError', extra: null } }
            }

            if (curPage.page.arima.show === true) {
              if (arimaData === null) {
                return { targetChar, targetValue, option: valueChartOption, error: { name: 'ArimaSettingError', extra: null } }
              }
              const expectFieldType = ['low_pred', 'pred', 'upper_pred']
              const forecastFormat = { expectation: [] }
              const minMaxValues = []

              expectFieldType.forEach((field) => {
                forecastFormat.expectation.push({
                  name: field,
                  data: arimaData[field],
                })
                minMaxValues.push(...arimaData[field])
              })

              const min = Math.min(...minMaxValues)
              const max = Math.max(...minMaxValues)
              const addDataLength = forecastFormat.expectation[0].data.length

              const chartXAxis = valueChartOption.xAxis
              let chartXAxisLength = chartXAxis.data.length + addDataLength
              const chartOneSeries = valueChartOption.series.find((series) => series.name === dqTranslateMsg('Page_ValueChart_' + 'ValueInfo'))
              let charDataLength = chartOneSeries.data.length

              const xAxisData = []
              for (let i = 1; i <= chartXAxisLength; i++) {
                xAxisData.push([i])
              }

              const next = produce(valueChartOption, (draft) => {
                forecastFormat.expectation.forEach((item) => {
                  let index = charDataLength + 1
                  draft.series.push({
                    type: 'line',
                    id: item.name,
                    name: arimaNameTranslate(item.name),
                    data: item.data.map((data) => [index++, data]),
                    itemStyle: {
                      color: item.name === 'low_pred' || item.name === 'upper_pred' ? curPage.lineChart.arimaChartLines.outer.color : curPage.lineChart.arimaChartLines.inner.color,
                    },
                    lineStyle: {
                      color: item.name === 'low_pred' || item.name === 'upper_pred' ? curPage.lineChart.arimaChartLines.outer.color : curPage.lineChart.arimaChartLines.inner.color,
                    },
                  })
                  draft.xAxis.data = xAxisData
                  draft.xAxis.min = 0
                  draft.xAxis.max = xAxisData.length

                  draft.yAxis.min = valueChartOption.yAxis.min < min ? draft.yAxis.min : min
                  draft.yAxis.max = valueChartOption.yAxis.max > max ? draft.yAxis.max : max
                })
              })

              return { targetChar, targetValue, option: next, error: { name: null, extra: null } }
            } else {
              return { targetChar, targetValue, option: valueChartOption, error: { name: null, extra: null } }
            }
          })

          batch(() => {
            setArimaShowFlag(curPage.page.arima.show)
            setArimaChartData(tempArimaChartData)
            setChartOptions(tempChartOptions)
          })
        } else {
          batch(() => {
            setArimaShowFlag(curPage.page.arima.show)
            setArimaChartData([])
            setChartOptions([])
          })
        }
      } catch (error) {
        console.error('Error fetching chart options:', error)
      } finally {
        setIsLoadingPanel(false)
      }
    }

    processChartOptions()
  }, [selectedChar, curPage, configPage])

  useEffect(() => {
    const targetRefId = `${currentSel.part_id}_${currentSel.char_id}`
    const targetIndex = chartOptions.findIndex((chart) => `${chart.targetChar.part_id}_${chart.targetChar.char_id}` === targetRefId)

    if (targetIndex !== -1 && gridRef.current) {
      const rowIndex = Math.floor(targetIndex / columnCount)
      const columnIndex = targetIndex % columnCount

      gridRef.current.scrollToCell({ columnIndex, rowIndex })

      setTimeout(() => {
        const targetElement = valueChartRefs?.current[targetIndex]?.element
        if (targetElement) {
          targetElement.classList.add('target-element')
          targetElement.scrollIntoView({ behavior: 'smooth', block: 'center' })

          // 애니메이션이 끝난 후 클래스를 제거합니다.
          targetElement.addEventListener(
            'animationend',
            () => {
              targetElement.classList.remove('target-element')
            },
            { once: true }
          )
        }
      }, 0)
    }
  }, [currentSel, valueChartRefs, chartOptions, columnCount])

  const renderCell = useCallback(({ columnIndex, rowIndex, style, key, parent }) => {
    const index = rowIndex * columnCount + columnIndex
    if (index >= chartOptions.length) return null

    const chart = chartOptions[index]

    return (
      <div
        key={`${chart.targetChar.part_id}_${chart.targetChar.char_id}`}
        ref={(e) => {
          valueChartRefs.current[index].element = e
          valueChartRefs.current[index].id = `${chart.targetChar.part_id}_${chart.targetChar.char_id}`
        }}
        style={{ ...style, padding: '3px' }} // Apply padding for the gap
        // onClick={() => handleCellClick(index)}
      >
        <div style={styles.chartInnerContainer}>
          <Suspense fallback={<div>{dqTranslateMsg('Common_' + 'LoadPanelLoading')}</div>}>
            {chart.option === null ? (
              <PageWarningMessage message={dqTranslateMsg('Page_ValueChart_' + chart.error.name) + ` (${chart.error.extra})`} />
            ) : (
              <ApacheEChartValueChartComponent
                key={`${chart.targetChar.part_id}_${chart.targetChar.char_id}_chart`}
                info={chart.targetChar}
                option={chart.option}
                theme={getTheme()}
                pageKey={pageKey}
              />
            )}
          </Suspense>
        </div>
      </div>
    )
  })

  return (
    <div className={'dx-theme-background-color'}>
      <DQLoadPanel open={isLoadingPanel} message={dqTranslateMsg('Common_' + 'LoadPanelLoading')} />
      <div className="chart-form-content" style={{ width: '100%', height: '100%' }}>
        {chartOptions.length > 0 ? (
          <AutoSizer>
            {({ height, width }) => (
              <Grid
                ref={gridRef} // Assign the ref to the Grid component
                className="custom-scrollbar"
                columnCount={columnCount}
                columnWidth={width / curPage.page.layout.column} // Adjust column width to account for the margin
                height={height}
                rowCount={rowCount}
                rowHeight={height / curPage.page.layout.row} // Adjust row height to account for the margin
                width={width}
                cellRenderer={renderCell}
                style={{ overflowX: 'hidden', overflowY: 'auto' }}
              />
            )}
          </AutoSizer>
        ) : null}
      </div>
    </div>
  )
}
