import { backendPath } from './defines/DBInfo'
// import userDBField from './defines/UserDBField'
import JwtUtils from 'src/functions/JwtUtils'

const path = backendPath

// ** USER APIS
export const createUserInfo = async (id, password, role, name, job) => {
  const requestBody = {
    requestMsg: {
      user_id: id,
      user_password: password,
      user_role: role,
      user_info: JSON.stringify({
        name: name,
        job: job,
      }),
    },
  }

  try {
    const accessToken = JwtUtils.loadToken()
    const response = await fetch(path + 'user/createUserInfo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${accessToken}`,
      },
      body: JSON.stringify(requestBody),
    })

    const result = await response.json()
    return result
  } catch (error) {
    throw error
  }
}

export const deleteUserInfo = async (deleteId) => {
  try {
    const accessToken = JwtUtils.loadToken()
    const response = await fetch(path + 'user/deleteUserInfo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${accessToken}`,
      },
      body: JSON.stringify({
        user_id: deleteId,
      }),
    })

    const result = await response.json()
    return result
  } catch (error) {
    throw error
  }
}

export const getUserInfo = async (id) => {
  try {
    const accessToken = JwtUtils.loadToken()
    console.error(`loadedToken : ${accessToken}`)
    const response = await fetch(path + 'user/getUserInfo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${accessToken}`,
      },
      body: JSON.stringify({
        user_id: id,
      }),
    })

    const result = await response.json()
    return result
  } catch (error) {
    throw error
  }
}

export const getUserInfoList = async () => {
  try {
    const accessToken = JwtUtils.loadToken()
    const response = await fetch(path + 'user/getUserListInfo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${accessToken}`,
      },
    })

    const data = await response.json()

    return data
  } catch (error) {
    throw error
  }
}

export const updateUserInfoForAccount = async (id, password, role, name, job) => {
  const requestBody = {
    requestMsg: {
      user_id: id,
      user_password: password,
      user_role: role,
      user_info: JSON.stringify({
        name: name,
        job: job,
      }),
    },
  }

  try {
    const accessToken = JwtUtils.loadToken()
    const response = await fetch(path + 'user/updateUserInfo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${accessToken}`,
      },
      body: JSON.stringify(requestBody),
    })

    const result = await response.json()
    return result
  } catch (error) {
    throw error
  }
}

export const updateUserInfoForSettings = async (id, field, data) => {
  const requestBody = {
    requestMsg: {
      user_id: id,
      [field]: data,
    },
  }

  try {
    const accessToken = JwtUtils.loadToken()
    const response = await fetch(path + 'user/updateUserInfo', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${accessToken}`,
      },
      body: JSON.stringify(requestBody),
    })

    const result = await response.json()
    return result
  } catch (error) {
    throw error
  }
}

export const createUserTable = async () => {
  try {
    const accessToken = JwtUtils.loadToken()
    const response = await fetch(path + 'user/createUserTable', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${accessToken}`,
      },
    })

    const result = await response.json()

    return result
  } catch (error) {
    throw error
  }
}

export const defaultUserPassword = async (id) => {
  const requestBody = {
    user_id: id,
  }

  try {
    const accessToken = JwtUtils.loadToken()
    const response = await fetch(path + 'user/defaultUserPassword', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${accessToken}`,
      },
      body: JSON.stringify(requestBody),
    })

    const result = await response.json()
    return result
  } catch (error) {
    throw error
  }
}
