export const defCharField = [
  { configuration: false, numValue: null, number: null, value: 'part_id', text: 'Part_id', visible: false, dataType: 'number' },
  { configuration: false, numValue: null, number: null, value: 'char_id', text: 'Char_id', visible: false, dataType: 'number' },
  { configuration: false, numValue: null, number: null, value: 'group_index', text: 'Group_index', visible: false, dataType: 'number' },
  { configuration: true, numValue: '2001', value: 'c2001', text: 'C2001', visible: true, dataType: 'string' },
  { configuration: true, numValue: '2002', value: 'c2002', text: 'C2002', visible: true, dataType: 'string' },
  { configuration: true, numValue: '2003', value: 'c2003', text: 'C2003', visible: true, dataType: 'string' },
  { configuration: true, numValue: '2004', value: 'c2004', text: 'C2004', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2005', value: 'c2005', text: 'C2005', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2006', value: 'c2006', text: 'C2006', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2007', value: 'c2007', text: 'C2007', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2008', value: 'c2008', text: 'C2008', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2009', value: 'c2009', text: 'C2009', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2011', value: 'c2011', text: 'C2011', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2015', value: 'c2015', text: 'C2015', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2016', value: 'c2016', text: 'C2016', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2021', value: 'c2021', text: 'C2021', visible: true, dataType: 'string' },
  { configuration: true, numValue: '2022', value: 'c2022', text: 'C2022', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2035', value: 'c2035', text: 'C2035', visible: false, dataType: 'string' },
  { configuration: true, numValue: '2041', value: 'c2041', text: 'C2041', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2042', value: 'c2042', text: 'C2042', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2043', value: 'c2043', text: 'C2043', visible: true, dataType: 'string' },
  { configuration: true, numValue: '2051', value: 'c2051', text: 'C2051', visible: true, dataType: 'string' },
  { configuration: true, numValue: '2091', value: 'c2091', text: 'C2091', visible: true, dataType: 'string' },
  { configuration: true, numValue: '2092', value: 'c2092', text: 'C2092', visible: true, dataType: 'string' },
  { configuration: true, numValue: '2093', value: 'c2093', text: 'C2093', visible: true, dataType: 'string' },
  { configuration: true, numValue: '2100', value: 'c2100', text: 'C2100', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2101', value: 'c2101', text: 'C2101', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2110', value: 'c2110', text: 'C2110', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2111', value: 'c2111', text: 'C2111', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2112', value: 'c2112', text: 'C2112', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2113', value: 'c2113', text: 'C2113', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2114', value: 'c2114', text: 'C2114', visible: false, dataType: 'number' },
  { configuration: true, numValue: '2115', value: 'c2115', text: 'C2115', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2116', value: 'c2116', text: 'C2116', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2117', value: 'c2117', text: 'C2117', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2120', value: 'c2120', text: 'C2120', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2121', value: 'c2121', text: 'C2121', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2130', value: 'c2130', text: 'C2130', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2131', value: 'c2131', text: 'C2131', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2142', value: 'c2142', text: 'C2142', visible: true, dataType: 'string' },
  { configuration: true, numValue: '2161', value: 'c2161', text: 'C2161', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2162', value: 'c2162', text: 'C2162', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2163', value: 'c2163', text: 'C2163', visible: false, dataType: 'number' },
  { configuration: true, numValue: '2202', value: 'c2202', text: 'C2202', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2205', value: 'c2205', text: 'C2205', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2213', value: 'c2213', text: 'C2213', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2220', value: 'c2220', text: 'C2220', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2221', value: 'c2221', text: 'C2221', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2222', value: 'c2222', text: 'C2222', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2225', value: 'c2225', text: 'C2225', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2226', value: 'c2226', text: 'C2226', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2227', value: 'c2227', text: 'C2227', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2243', value: 'c2243', text: 'C2243', visible: true, dataType: 'string' },
  { configuration: true, numValue: '2244', value: 'c2244', text: 'C2244', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2245', value: 'c2245', text: 'C2245', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2246', value: 'c2246', text: 'C2246', visible: true, dataType: 'number' },
  { configuration: true, numValue: '2302', value: 'c2302', text: 'C2302', visible: true, dataType: 'string' },
  { configuration: true, numValue: '2402', value: 'c2402', text: 'C2402', visible: true, dataType: 'string' },
  { configuration: true, numValue: '2410', value: 'c2410', text: 'C2410', visible: true, dataType: 'string' },
  { configuration: true, numValue: '2413', value: 'c2413', text: 'C2413', visible: true, dataType: 'string' },
  { configuration: true, numValue: '2415', value: 'c2415', text: 'C2415', visible: true, dataType: 'string' },
  { configuration: true, numValue: '2802', value: 'c2802', text: 'C2802', visible: true, dataType: 'string' },
  { configuration: true, numValue: '2812', value: 'c2812', text: 'C2812', visible: true, dataType: 'string' },
  { configuration: true, numValue: '2822', value: 'c2822', text: 'C2822', visible: true, dataType: 'string' },
  { configuration: true, numValue: '2832', value: 'c2832', text: 'C2832', visible: true, dataType: 'string' },
  { configuration: true, numValue: '2842', value: 'c2842', text: 'C2842', visible: true, dataType: 'string' },
  { configuration: true, numValue: '2852', value: 'c2852', text: 'C2852', visible: true, dataType: 'string' },
  { configuration: true, numValue: '2862', value: 'c2862', text: 'C2862', visible: true, dataType: 'string' },
  { configuration: true, numValue: '2872', value: 'c2872', text: 'C2872', visible: true, dataType: 'string' },
  { configuration: true, numValue: '2882', value: 'c2882', text: 'C2882', visible: true, dataType: 'string' },
  { configuration: true, numValue: '2892', value: 'c2892', text: 'C2892', visible: true, dataType: 'string' },
  { configuration: true, numValue: '2900', value: 'c2900', text: 'C2900', visible: true, dataType: 'string' },
  { configuration: true, numValue: '2999', value: 'c2999', text: 'C2999', visible: true, dataType: 'number' },
  { configuration: true, numValue: '8006', value: 'c8006', text: 'C8006', visible: true, dataType: 'number' },
  { configuration: true, numValue: '8007', value: 'c8007', text: 'C8007', visible: true, dataType: 'number' },
  { configuration: true, numValue: '8011', value: 'c8011', text: 'C8011', visible: true, dataType: 'number' },
  { configuration: true, numValue: '8012', value: 'c8012', text: 'C8012', visible: true, dataType: 'number' },
  { configuration: true, numValue: '8013', value: 'c8013', text: 'C8013', visible: true, dataType: 'number' },
  { configuration: true, numValue: '8014', value: 'c8014', text: 'C8014', visible: true, dataType: 'number' },
  { configuration: true, numValue: '8015', value: 'c8015', text: 'C8015', visible: true, dataType: 'number' },
  { configuration: true, numValue: '8106', value: 'c8106', text: 'C8106', visible: true, dataType: 'string' },
  { configuration: true, numValue: '8107', value: 'c8107', text: 'C8107', visible: true, dataType: 'number' },
  { configuration: true, numValue: '8111', value: 'c8111', text: 'C8111', visible: true, dataType: 'number' },
  { configuration: true, numValue: '8112', value: 'c8112', text: 'C8112', visible: true, dataType: 'number' },
  { configuration: true, numValue: '8113', value: 'c8113', text: 'C8113', visible: true, dataType: 'number' },
  { configuration: true, numValue: '8114', value: 'c8114', text: 'C8114', visible: true, dataType: 'number' },
  { configuration: true, numValue: '8115', value: 'c8115', text: 'C8115', visible: true, dataType: 'number' },
  { configuration: true, numValue: '8500', value: 'c8500', text: 'C8500', visible: true, dataType: 'number' },
  { configuration: true, numValue: '8501', value: 'c8501', text: 'C8501', visible: true, dataType: 'number' },
  { configuration: true, numValue: '8531', value: 'c8531', text: 'C8531', visible: true, dataType: 'number' },
  { configuration: true, numValue: '8532', value: 'c8532', text: 'C8532', visible: true, dataType: 'number' },
  { configuration: true, numValue: '8540', value: 'c8540', text: 'C8540', visible: true, dataType: 'number' },
  { configuration: false, numValue: null, number: null, value: 'value_quantity', text: 'Value_quantity', visible: true, dataType: 'number' },
]
