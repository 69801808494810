import React, { useEffect, useRef, useState } from 'react'
import { GroupItem, SimpleItem, ButtonItem, EmptyItem } from 'devextreme-react/form'
import { SelectBox, List, Sortable, Button } from 'devextreme-react'
import { useDispatch, useSelector } from 'react-redux'
import produce from 'immer'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage.js'
import SelectBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItem'
import ListSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ListSimpleItem'
import ButtonSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItem'
import SelectBoxNoValueSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxNoValueSimpleItem'
import { defScrollBoardField, defScrollBoardNoneField } from 'src/default/field/scrollBoard.js'
import RadioGroupSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/RadioGroupSimpleItem'
import * as sd from 'src/pages/components/side-panel-form/component/define/SettingsDefine'
import CPUtils from 'src/functions/CommonPageUtils'

export default function ExtraFields(pageKey, pageState, configState) {
  const dispatch = useDispatch()
  // const pageKey = 'valueList'

  // const pageState = useSelector((state) => state.common.pages[pageKey])
  // const configState = useSelector((state) => state.common.pages.configuration)

  const isCommonEmpty = Object.keys(configState.useFieldList).length === 0
  const isLsUseFieldListEmpty = Object.keys(configState.useFieldList).length === 0

  let useFieldList = {}
  if (isCommonEmpty === false) {
    useFieldList = configState.useFieldList
  } else if (isLsUseFieldListEmpty === false) {
    useFieldList = configState.useFieldList
  } else {
    useFieldList = {
      part: [],
      char: [],
      value: [],
    }
  }

  const useValueFilterField = CPUtils.getConfigItemListFromUseField('Value', false, null, useFieldList.value, configState)
  const extraFieldList = CPUtils.makeItemListFromSetting('Value', pageState.page.extraFieldList, useFieldList.value, configState)

  const itemRender = (e, index, c) => {
    const item = e
    return (
      <div key={index} style={{ width: '100%', height: '100%', display: 'flex' }}>
        <div style={{ width: '10%', height: '100%', textAlign: 'center' }}>▶️</div>
        <div style={{ width: '10%', height: '100%', textAlign: 'center' }}>{index + 1}</div>
        <div style={{ width: '10%', height: '100%', textAlign: 'center' }}>:</div>
        <div style={{ width: '70%', height: '100%' }}>{item.text}</div>
      </div>
    )
  }

  const handleExtraLabelDelete = (e) => {
    const value = e.itemData.value
    const next = produce(pageState, (draft) => {
      draft.page.extraFieldList = draft.page.extraFieldList.filter((element) => element !== value)
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onSelectBoxExtraFieldValueChanged = (e, dataField) => {
    const value = e.itemData.value
    const isExist = pageState.page[dataField].some((item) => item === value)
    if (!isExist) {
      const next = produce(pageState, (draft) => {
        draft.page[dataField].push(value)
      })
      dispatch(ps[pageKey].setPageSettingsToRedux(next))
    }
  }

  const onRadioGroupDateTimeFormatValueChanged = (e, dataField) => {
    if (e.event === undefined) return

    const value = e.value
    const next = produce(pageState, (draft) => {
      draft.page[dataField] = value
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onListReorder = (e) => {
    const { fromIndex, toIndex } = e

    const extraFieldList = pageState.page.extraFieldList

    const updatedExtraFieldList = [...extraFieldList]
    const movedItem = updatedExtraFieldList.splice(fromIndex, 1)[0]
    updatedExtraFieldList.splice(toIndex, 0, movedItem)

    const next = produce(pageState, (draft) => {
      draft.page.extraFieldList = updatedExtraFieldList
    })
    console.log(next)

    dispatch(ps.valueList.setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`extra-field-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'ExtraXAxisLabels')}>
      {SelectBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('SidePanel_' + 'Item'),
        labelLocation: 'top',
        name: 'extraFieldList',
        items: useValueFilterField,
        valueExpr: 'value',
        displayExpr: 'text',
        value: useValueFilterField.length > 0 ? useValueFilterField[0].value : null,
        disabled: false,
        onItemClick: (e) => onSelectBoxExtraFieldValueChanged(e, 'extraFieldList'),
      })}
      <EmptyItem />
      {ListSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'RegisteredItem'),
        labelLocation: 'top',
        dataSource: extraFieldList,
        itemRender: itemRender,
        allowItemDeleting: true,
        itemDeleteMode: 'static',
        noDataText: dqTranslateMsg('SidePanel_' + 'NoData'),
        onItemDeleted: handleExtraLabelDelete,
        onReorder: onListReorder,
        backgroundCondition: true,
      })}
      {RadioGroupSimpleItem({
        ref: null,
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'DateTimeDisplayType'),
        labelLocation: 'top',
        name: 'dateTimeExtraFieldDisplayFormat',
        items: sd.dateTimeDisplayDefine(),
        layout: 'vertical',
        valueExpr: 'value',
        displayExpr: 'text',
        value: pageState.page.dateTimeExtraFieldDisplayFormat,
        disabled: false,
        onValueChanged: (e) => onRadioGroupDateTimeFormatValueChanged(e, 'dateTimeExtraFieldDisplayFormat'),
      })}
    </GroupItem>
  )
}
