import Form from 'devextreme-react/form'
import produce from 'immer'
import { useRef, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useAuth } from 'src/contexts/auth.js'
import DQSaveReset from 'src/functions/DQSaveReset.js'
import DQToast from 'src/functions/DQToast.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage.js'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'
import { scrollBoardSettingsPrm } from '../settings/ScrollBoardSettings.js'
import './SidePanelScrollBoardSettings.scss'
import PartFilter from './components/PartFilter.js'
import DQLoadPanel from 'src/components/dq-load-form/DQLoadPanel.js'
import { FilterBuilder } from 'devextreme-react'
import { Field } from 'devextreme-react/filter-builder.js'

// Main Component
export default function SidePanelScrollBoardSettings(props) {
  const { pageKey } = props
  const { user } = useAuth()
  const dispatch = useDispatch()

  const [scrollPosition, setScrollPosition] = useState(0)
  const parentRef = useRef(null)
  const toastRef = useRef(null)

  const common = useSelector((state) => state.common)
  const curPage = common.pages.scrollBoard
  const configPage = common.pages.configuration

  const open = curPage.common.mode === 'presentation' ? true : false

  const [filterValue, setFilterValue] = useState([
    ['Category', '=', 'Video Players'],
    'or',
    [['Category', '=', 'Monitors'], 'and', ['Price', 'between', [165, 700]]],
    'and',
    [['Category', '=', 'Televisions'], 'and', ['Price', 'between', [2000, 4000]]],
  ])

  const handleAllSettingsSave = (e) => {
    dqsImpl.setCurPageSettings(dispatch, user, pageKey, curPage)
    toastRef.current.handleToastDisplay('success', dqTranslateMsg('SidePanel_' + 'SavedSettings'))
  }

  const handleAllSettingsReset = () => {
    const next = produce(curPage, (draft) => {
      Object.entries(scrollBoardSettingsPrm.filter).forEach(([key, value]) => {
        draft.filter[key] = value
      })
    })

    dqsImpl.setCurPageSettings(dispatch, user, pageKey, next)
    toastRef.current.handleToastDisplay('warning', dqTranslateMsg('SidePanel_' + 'ResetSettings'))
  }

  return (
    <div ref={parentRef} style={{ padding: '5px' }}>
      <DQLoadPanel open={open} refContainer={parentRef} message={'In Auto Slide'} />
      <DQToast ref={toastRef} />
      <DQSaveReset
        saveBtnText={dqTranslateMsg('SidePanel_' + 'Save')}
        resetBtnText={dqTranslateMsg('SidePanel_' + 'Reset')}
        saveCallback={handleAllSettingsSave}
        resetCallback={handleAllSettingsReset}
      />
      <Form
        // ref={formRef}
        id="side-panel-scroll-board-settings-form"
        style={{ paddingTop: '5px' }}
        labelMode={'outside'}
        formData={curPage.filter}
        showColonAfterLabel={false}
        labelLocation={'top'}
      >
        {PartFilter(pageKey, curPage, configPage)}
      </Form>
      {/* <FilterBuilder value={filterValue} onValueChange={setFilterValue}>
        <Field dataField="Product_ID" dataType="number" caption="ID" />
        <Field dataField="Product_Name" />
        <Field dataField="Product_Cost" dataType="number" caption="Cost" format="currency" />
      </FilterBuilder> */}
    </div>
  )
}
