class DTOUser {
  constructor({
    user_id = null,
    user_password = null,
    user_company = null,
    user_plant = null,
    user_group = null,
    user_info = null,
    user_role = null,
    language = 'en',
    license = null,
    web_config = null,
    db_config = null,
    qsense_config = null,
    chart_config_1 = null,
    chart_config_2 = null,
    chart_config_3 = null,
    chart_config_4 = null,
    chart_config_5 = null,
    chart_config_6 = null,
    chart_config_7 = null,
    chart_config_8 = null,
    chart_config_9 = null,
    chart_config_10 = null,
    chart_config_11 = null,
    chart_config_12 = null,
    chart_config_13 = null,
    chart_config_14 = null,
    chart_config_15 = null,
    chart_config_16 = null,
    chart_config_17 = null,
    chart_config_18 = null,
    chart_config_19 = null,
    chart_config_20 = null,
  } = {}) {
    Object.assign(this, {
      user_id,
      user_password,
      user_info,
      user_company,
      user_plant,
      user_group,
      user_role,
      language,
      license,
      web_config,
      db_config,
      qsense_config,
      chart_config_1,
      chart_config_2,
      chart_config_3,
      chart_config_4,
      chart_config_5,
      chart_config_6,
      chart_config_7,
      chart_config_8,
      chart_config_9,
      chart_config_10,
      chart_config_11,
      chart_config_12,
      chart_config_13,
      chart_config_14,
      chart_config_15,
      chart_config_16,
      chart_config_17,
      chart_config_18,
      chart_config_19,
      chart_config_20,
    })
  }
}

export default DTOUser
