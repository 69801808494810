import { Column, DataGrid, GroupPanel, Grouping } from 'devextreme-react/data-grid'
import { batch, useSelector } from 'react-redux'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import './AdminPushSettings.scss'
import { useEffect, useRef, useState } from 'react'
import { getAdminDefault } from 'src/api/admin/AdminDefaultApi'
import { getAdminCompany, getAdminCompanyList, getAdminCompanyPlantList } from 'src/api/admin/AdminCompanyApi'
import { getAdminGroup, getAdminGroupList } from 'src/api/admin/AdminGroupApi'
import { makeGetAdminCompanyListPrms, makeGetAdminCompanyPlantListPrms, makeGetAdminCompanyPrms } from 'src/api/admin/AdminCompanyApiHelper'
import { makeGetAdminGroupListPrms, makeGetAdminGroupPrms } from 'src/api/admin/AdminGroupApiHelper'
import { makeGetAdminDefaultPrms } from 'src/api/admin/AdminDefaultApiHelper'
import { useApi } from 'src/contexts/api'
import ApiStatus from 'src/api/defines/ApiStatus'
import { TreeList } from 'devextreme-react'
import { ColumnChooser, Item, Selection, Toolbar } from 'devextreme-react/tree-list'
import Button from 'devextreme-react/button'

// const [adminDefault, setAdminDefault] = useState(null)
// const [adminCompany, setAdminCompany] = useState(null)
// const [adminCompanyList, setAdminCompanyList] = useState(null)
// const [adminCompanyPlantList, setAdminCompanyPlantList] = useState(null)
// const [adminGroup, setAdminGroup] = useState(null)
// const [adminGroupList, setAdminGroupList] = useState(null)
// const admGroupList = await getAdminGroupList(spring, makeGetAdminGroupListPrms(company, plant))
// const admDefault = await getAdminDefault(spring, makeGetAdminDefaultPrms())
// const admCompany = await getAdminCompany(spring, makeGetAdminCompanyPrms())
// const admGroup = await getAdminGroup(spring, makeGetAdminGroupPrms())
// setAdminCompanyPlantList(admCompanyPlantList)
// setAdminGroupList(admGroupList)
// setAdminDefault(admDefault)
// setAdminCompany(admCompany)
// setAdminGroup(admGroup)

const makeDataSourceForTreeView = (dataSource) => {
  let treeData = []
  let idCounter = 100 // Start with a high number to avoid collisions with existing ids
  let companyMap = {}
  let plantMap = {}

  dataSource.forEach((item) => {
    // Company Level
    if (!companyMap[item.company]) {
      const companyNode = { id: idCounter++, name: item.company, level: 'company', parentId: null }
      treeData.push(companyNode)
      companyMap[item.company] = companyNode.id
    }

    // Plant Level
    const companyId = companyMap[item.company]
    const plantKey = `${item.company}-${item.plant}`
    if (!plantMap[plantKey]) {
      const plantNode = { id: idCounter++, name: item.plant, level: 'plant', parentId: companyId }
      treeData.push(plantNode)
      plantMap[plantKey] = plantNode.id
    }

    // Group Level
    const plantId = plantMap[plantKey]
    const groupNode = { id: item.id, name: item.group, level: 'group', parentId: plantId }
    treeData.push(groupNode)
  })

  return treeData
}

export default function AdminPushSettings(pageKey) {
  const { spring } = useApi() // Use spring API client from the context
  const treeListRef = useRef(null)
  const common = useSelector((state) => state.common)

  const [adminTreeDataSource, setAdminTreeDataSource] = useState(null)
  const [expandedRowKeys, setExpandedRowKeys] = useState([])
  const [selectedRowKeys, setSelectedRowKeys] = useState([])

  useEffect(() => {
    const getDataForPushSettings = async () => {
      try {
        const companyList = await getAdminCompanyList(spring, makeGetAdminCompanyListPrms())
        const uniqueCList = [...new Set(companyList)]

        const admCompanyPlantList = await Promise.all(
          uniqueCList.map(async (company) => {
            const plantList = await getAdminCompanyPlantList(spring, makeGetAdminCompanyPlantListPrms(company))
            return { company, plantList }
          })
        )

        const admDataConverted = []

        await Promise.all(
          admCompanyPlantList.flatMap(async ({ company, plantList }) => {
            return Promise.all(
              plantList.map(async (plant) => {
                const groupList = await getAdminGroupList(spring, makeGetAdminGroupListPrms(company, plant))

                groupList.forEach((group) => {
                  admDataConverted.push({ company, plant, group })
                })
              })
            )
          })
        )

        // Sort by company name
        const sortedData = admDataConverted.sort((a, b) => a.company.localeCompare(b.company))
        sortedData.forEach((item, index) => {
          item.id = index + 1
        })

        const treeDataSource = makeDataSourceForTreeView(sortedData)
        const allRowKeys = treeDataSource.map((item) => item.id)

        batch(() => {
          setAdminTreeDataSource(treeDataSource)
          setExpandedRowKeys(allRowKeys)
          setSelectedRowKeys(allRowKeys)
        })
      } catch (error) {
        console.error('Error fetching push settings data:', error)
      }
    }

    getDataForPushSettings()
  }, [])

  // useEffect(() => {
  //   if (treeListRef.current) {
  //     treeListRef.current.instance.expandAll()
  //   }
  // }, [adminTreeDataSource])

  return (
    <div className="dx-theme-background-color">
      <div className="admin-push-settings-detail-container">
        <DataGrid
          className="view-settings-grid"
          id="view-settings-grid"
          dataSource={adminTreeDataSource}
          keyExpr="id"
          width="100%"
          allowColumnReordering={true}
          columnAutoWidth={true}
          columnResizingMode="widget"
          allowColumnResizing={true}
          scrolling={{
            columnRenderingMode: 'standard',
            mode: 'standard',
            preloadEnabled: true,
            renderAsync: true,
            rowRenderingMode: 'standard',
            scrollByContent: true,
            scrollByThumb: false,
            showScrollbar: 'onHover',
            useNative: false,
          }}
          searchPanel={{
            highlightCaseSensitive: false,
            highlightSearchText: true,
            placeholder: dqTranslateMsg('SearchPlaceHoler'),
            searchVisibleColumnsOnly: false,
            text: '',
            visible: true,
            width: 160,
          }}
          noDataText={dqTranslateMsg('NoData')}
          paging={{ enabled: false }}
          showColumnLines={true}
          showRowLines={true}
          showBorders={true}
          rowAlternationEnabled={false}
          hoverStateEnabled={true}
        >
          <GroupPanel visible={true} />
          <Grouping autoExpandAll={true} />
          <Column dataField="company" dataType="string" groupIndex={0} />
          <Column dataField="plant" dataType="string" />
          <Column dataField="group" dataType="string" />
        </DataGrid>
      </div>
      <div className="admin-push-settings-target-container">
        <TreeList
          ref={treeListRef}
          className="view-user-tree"
          dataSource={adminTreeDataSource}
          expandedRowKeys={expandedRowKeys}
          selectedRowKeys={selectedRowKeys}
          showBorders={true}
          columnAutoWidth={true}
          wordWrapEnabled={true}
          // defaultExpandedRowKeys={expandedKeys}
          noDataText={dqTranslateMsg('Page_AdminSettings_' + 'NoData')}
          keyExpr="id"
          parentIdExpr="parentId"
        >
          <Toolbar>
            <Item key={'database-char-toolbar-button'} location="after">
              <Button icon={'remove'} width={'auto'} text={'Apply Settings'} focusStateEnabled={false} onClick={null} />
              {/* <Button icon={'remove'} width={'auto'} text={dqTranslateMsg('Page_Database_' + 'DeleteSelected')} focusStateEnabled={false} onClick={null} /> */}
            </Item>
          </Toolbar>
          <Selection mode="multiple" />
          <Column dataField="name" />
        </TreeList>
      </div>
    </div>
  )
}
