import { GroupItem } from 'devextreme-react/form'
import { useEffect, useRef, useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import AdvancedFilterItem from 'src/pages/components/dq-wrapper-ui-devexpress/AdvancedFilterItem'
import ButtonSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItem'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import produce from 'immer'
import CPUtils from 'src/functions/CommonPageUtils'
import { pageType } from 'src/functions/table/PageTypeTable'
import { msgType } from 'src/functions/table/MessageTypeTable'
import { getErrProcMsg } from 'src/functions/CommonMassageUtils'
import { procErrTable } from 'src/functions/table/ProcMessageTable'
import CatalogUtils from 'src/functions/CatalogUtils'
import TextBoxNoValueSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/TextBoxNoValueSimpleItem'

const QuickSaveFilter = (currentFilter, setCurrentFilter, setIsVisibleCreateFilterPopup, textBoxRef, pageKey, pageState, configState) => {
  const dispatch = useDispatch()
  // const filterNameTbRef = useRef(null)

  const onFilterNameSaveButtonClick = () => {
    console.log('applied')

    if (textBoxRef.current) {
      const filterName = textBoxRef.current.instance.option('value')

      if (filterName === '') {
        alert('Please Enter Filter Name')
        return
      }

      const filterFormat = {
        filterName,
        ...currentFilter,
      }

      const next = produce(pageState, (draft) => {
        draft.filters.quick.savedFilters.push(filterFormat)
      })
      batch(() => {
        dispatch(ps[pageKey].setPageSettingsToRedux(next))
        setIsVisibleCreateFilterPopup(false)
      })
    }
  }

  // const onFilterNameClearButtonClick = () => {
  //   console.log('clear')
  //   const next = produce(pageState, (draft) => {
  //     draft.filters.advanced.savedFilters = []
  //   })
  //   dispatch(ps[pageKey].setPageSettingsToRedux(next))
  // }

  return (
    <GroupItem key={`db-field-advanced-save-filter-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'SaveFilter')}>
      {TextBoxNoValueSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'FilterName'),
        labelLocation: 'top',
        ref: textBoxRef,
      })}
      {ButtonSimpleItem({
        colSpan: 2,
        type: 'normal',
        text: dqTranslateMsg('SidePanel_' + 'Save'),
        icon: 'check',
        width: '100%',
        height: '100%',
        onClick: onFilterNameSaveButtonClick,
      })}
      {/* {ButtonSimpleItem({
        colSpan: 1,
        type: 'normal',
        text: dqTranslateMsg('SidePanel_' + 'Clear'),
        icon: 'clear',
        width: '100%',
        height: '100%',
        onClick: onFilterNameClearButtonClick,
      })} */}
    </GroupItem>
  )
}

export default QuickSaveFilter
