import Button from 'devextreme-react/button'
import { Column, Scrolling, SearchPanel, Selection } from 'devextreme-react/data-grid'
import { Tooltip } from 'devextreme-react/tooltip'
import TreeList, { Item, Toolbar } from 'devextreme-react/tree-list'
import { useEffect, useMemo, useRef, useState } from 'react'
import { batch, useDispatch, useSelector } from 'react-redux'
import { setCurrentSel, setLoadedAll, setDecisionTreeAddArray } from 'src/store/common'
import './SidePanelDatabaseForm.scss'
import produce from 'immer'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import { connect } from 'react-redux'
import CPUtils from 'src/functions/CommonPageUtils'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'
import CatalogUtils from 'src/functions/CatalogUtils'
import { outlierFilterValues } from 'src/functions/ValueProcessUtils'

// Function to filter tlSelRows by Type 'shiftUp'
function filterRowsByShiftUp(rows) {
  return rows.filter((row) => row.Type === 'shiftUp')
}

// Function to calculate adjusted IDs based on Head_ID
function calculateAdjustedIds(rows) {
  return rows.map((row) => ({
    ...row,
    adjustedPartId: row.Part_ID - row.Head_ID * 1000000,
    adjustedCharId: row.Char_ID - row.Head_ID * 1000000,
  }))
}

// Function to match chars with adjusted IDs
function matchCharsWithAdjustedIds(chars, adjustedRows) {
  const matchChars = adjustedRows
    .map((row) => {
      const tgChar = chars.find((char) => char.part_id === row.adjustedPartId && char.char_id === row.adjustedCharId)

      if (tgChar) {
        return { ...tgChar, ...row }
      }

      return null
    })
    .filter((result) => result !== null)
  // .flat()

  return matchChars
}

// Main logic function that uses the smaller functions
function loadDecisionTreeData(chars, tlSelRows) {
  const shiftUpRows = filterRowsByShiftUp(tlSelRows)
  const adjustedRows = calculateAdjustedIds(shiftUpRows)
  return matchCharsWithAdjustedIds(chars, adjustedRows)
}

function SidePanelDatabaseForm({ pageKey, common, chartRawData }) {
  const dispatch = useDispatch()

  const commonGridRef = useRef(null)

  const { parts, chars, values } = CPUtils.loadRefChartData(chartRawData)
  const curPage = common.pages[pageKey]
  const configPage = common.pages.configuration
  const currentSel = common.current_sel
  const viewData = curPage.page.extraFieldList
  const selectedChar = common.loaded_all[pageKey]
  const [expandAll, setExpandAll] = useState(true)
  const [dataSource, setDataSource] = useState([])
  const [defaultSelectedRowKeys, setDefaultSelectedRowKeys] = useState([])
  const [expandIDList, setExpandIDList] = useState([])

  // let expandIDList = []

  const handleSelectedRowKeysChanged = async (row) => {
    const tlSelRows = row.component.getSelectedRowsData('leavesOnly')
    const updateSelectedRowKeys = tlSelRows.map((row) => row.ID)

    let loadArray = []
    let decisionTreeLoadArray = []
    if (ps[pageKey].key === 'positionalTolerancesChart') {
      tlSelRows.forEach((selRow) => {
        const index = chars.findIndex((char) => char.part_id === selRow.Part_ID && char.char_id === selRow.Char_ID)

        if (index !== -1) {
          loadArray.push(chars[index])
          if (index + 1 < chars.length) {
            loadArray.push(chars[index + 1])
            loadArray.push(chars[index + 2])
          }
        }
      })

      console.log(loadArray)
    } else {
      loadArray = chars.filter((char) => tlSelRows.some((selRow) => selRow.Type === 'normal' && char.part_id === selRow.Part_ID && char.char_id === selRow.Char_ID))

      if (pageKey === 'decisionTreeChart') {
        decisionTreeLoadArray = loadDecisionTreeData(chars, tlSelRows)
      }
    }

    batch(() => {
      setDefaultSelectedRowKeys(updateSelectedRowKeys)
      const pageLoadAll = produce(common.loaded_all, (draft) => {
        draft[pageKey] = loadArray
      })
      dispatch(setLoadedAll(pageLoadAll))

      if (pageKey === 'decisionTreeChart') {
        const next = produce(common.decisionAddedArray, (draft) => {
          draft.data = decisionTreeLoadArray
        })
        dispatch(setDecisionTreeAddArray(next))
      }
    })
  }

  const makeTreeListDataSource = (parts, values, charListInfo, expandIDList, curPage) => {
    const dataSource = []
    let idx = 1
    // part를 map 돌려서 맞는 char
    parts.map((part) => {
      expandIDList.push(idx)
      dataSource.push({
        ID: idx++,
        Type: 'normal',
        Head_ID: 0,
        Part_ID: part.part_id,
        Name: part.p1002,
        dataField: 'v0001',
        IsDataFromDB: true,
      })
    })

    if (pageKey === 'decisionTreeChart' && curPage.page.shiftUpField.length !== 0) {
      const shiftUpField = curPage.page.shiftUpField
      shiftUpField.forEach((field) => {
        const shiftUpFieldName = CatalogUtils.getCatalogFieldName(field, configPage.useFieldList)
        parts.map((part) => {
          expandIDList.push(idx)
          dataSource.push({
            ID: idx,
            Type: 'shiftUp',
            Head_ID: 0,
            Part_ID: part.part_id + idx * 1000000,
            Name: part.p1002 + ' ' + '(' + shiftUpFieldName + ')',
            dataField: field,
            IsDataFromDB: false,
          })
          idx++
        })
      })
    }

    if (dataSource.length > 0) {
      dataSource.map((parent) => {
        if (parent.Type === 'normal') {
          const targetCharList = charListInfo.filter((char) => char.part_id === parent.Part_ID)

          if (targetCharList.length > 0) {
            targetCharList.map((char) => {
              const targetValue = values.find((value) => value.part_id === char.part_id && value.char_id === char.char_id)
              if (targetValue) {
                const { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset } = PCUtils.getAllLimits(char, configPage)
                const limits = { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset }
                const filteredValue = outlierFilterValues(limits, targetValue.charRaw, targetValue.valueRaw, configPage)

                // const valueCnt = targetValue.valueRaw.length
                const valueCnt = filteredValue.length

                dataSource.push({
                  ID: idx++,
                  Type: 'normal',
                  Head_ID: parent.ID,
                  Part_ID: char.part_id,
                  Char_ID: char.char_id,
                  Name: char.c2002 + ' (' + valueCnt + ')',
                  Value_Count: valueCnt,
                  dataField: parent.dataField,
                })
              } else {
                console.log(targetValue)
              }
            })
          }
        } else {
          const addTargetCharList = charListInfo.filter((char) => char.part_id === parent.Part_ID - parent.ID * 1000000)

          if (addTargetCharList.length > 0) {
            if (curPage.page.useAddedInfoTopChar) {
              const tgChar = addTargetCharList.reduce((acc, current) => {
                return acc.char_id < current.char_id ? acc : current
              })
              const targetValue = values.find((value) => value.part_id === tgChar.part_id && value.char_id === tgChar.char_id)
              if (targetValue) {
                const valueCnt = targetValue.valueRaw.length
                dataSource.push({
                  ID: idx++,
                  Type: 'shiftUp',
                  Head_ID: parent.ID,
                  Part_ID: tgChar.part_id + parent.ID * 1000000,
                  Char_ID: tgChar.char_id + parent.ID * 1000000,
                  Name: tgChar.c2002 + ' (' + valueCnt + ')',
                  Value_Count: valueCnt,
                  dataField: parent.dataField,
                })
              } else {
                console.log(targetValue)
              }
            } else {
              addTargetCharList.map((char) => {
                const targetValue = values.find((value) => value.part_id === char.part_id && value.char_id === char.char_id)
                if (targetValue) {
                  const valueCnt = targetValue.valueRaw.length
                  dataSource.push({
                    ID: idx++,
                    Type: 'shiftUp',
                    Head_ID: parent.ID,
                    Part_ID: char.part_id + parent.ID * 1000000,
                    Char_ID: char.char_id + parent.ID * 1000000,
                    Name: char.c2002 + ' (' + valueCnt + ')',
                    Value_Count: valueCnt,
                    dataField: parent.dataField,
                  })
                } else {
                  console.log(targetValue)
                }
              })
            }
          }
        }
      })
    }

    return dataSource
  }

  const makeTreeListPTDataSource = (parts, values, charListInfo, expandIDList) => {
    const dataSource = []
    let idx = 1
    // part를 map 돌려서 맞는 char
    parts.map((part) => {
      expandIDList.push(idx)
      dataSource.push({
        ID: idx++,
        // Type: 'part',
        Head_ID: 0,
        Part_ID: part.part_id,
        Name: part.p1002,
      })
    })

    if (dataSource.length > 0) {
      dataSource.map((parent) => {
        const targetCharList = charListInfo.filter((char) => char.part_id === parent.Part_ID && char.group_index === 1)

        if (targetCharList.length > 0) {
          targetCharList.map((char) => {
            const targetValue = values.find((value) => value.part_id === char.part_id && value.char_id === char.char_id)
            if (targetValue) {
              const valueCnt = targetValue.valueRaw.length
              dataSource.push({
                ID: idx++,
                // Type: 'char',
                Head_ID: parent.ID,
                Part_ID: char.part_id,
                Char_ID: char.char_id,
                Name: char.c2002 + ' (' + valueCnt + ')',
                Value_Count: valueCnt,
              })
            } else {
              console.log(targetValue)
            }
          })
        }
      })
    }

    return dataSource
  }

  const makeTreeListDataDefaultRowKeys = (pageKey, dataSource, charsArranged, decisionAddedArray) => {
    const useCharArray = dataSource.filter((itemA) => {
      return charsArranged.some((itemB) => {
        return itemA.Part_ID === itemB.part_id && itemA.Char_ID === itemB.char_id
      })
    })
    const selRowKeys = useCharArray.map((data) => data.ID)

    let selAddRowKeys = []
    //decisiontree 로 검색
    if (pageKey === 'decisionTreeChart') {
      const useAddCharArray = dataSource.filter((itemA) => {
        return decisionAddedArray.data.some((itemB) => {
          return itemA.Part_ID === itemB.Part_ID && itemA.Char_ID === itemB.Char_ID
        })
      })
      selAddRowKeys = useAddCharArray.map((data) => data.ID)
    }

    return [...selRowKeys, ...selAddRowKeys]
  }

  const makeTreeListPTDataDefaultRowKeys = (dataSource, charsArranged) => {
    const useCharArray = dataSource.filter((itemA) => {
      return charsArranged.some((itemB) => {
        return itemA.Part_ID === itemB.part_id && itemA.Char_ID === itemB.char_id
      })
    })
    const selRowKeys = useCharArray.map((data) => data.ID)
    return selRowKeys
  }

  useEffect(() => {
    const { charArray, currentSel } = CPUtils.loadRefOtherData(common, chars, pageKey)
    const configSettings = configPage
    const selectedValueWithDummy = CPUtils.checkMatchCharsAndValues(chars, values)
    const valuesArranged = CPUtils.removeDummyInValues(selectedValueWithDummy)

    let targetCharData = ps[pageKey].key === 'positionalTolerancesChart' ? chars.filter((char) => char.group_index !== 0) : chars

    if (targetCharData === null || targetCharData === undefined) {
      targetCharData = []
    }

    let ds, defaultSelRowKeys
    if (ps[pageKey].key !== 'positionalTolerancesChart') {
      if (targetCharData.length > 0) {
        ds = makeTreeListDataSource(parts, valuesArranged, targetCharData, expandIDList, curPage)
        defaultSelRowKeys = makeTreeListDataDefaultRowKeys(pageKey, ds, charArray, common.decisionAddedArray)
      }
    } else {
      if (targetCharData.length > 0) {
        ds = makeTreeListPTDataSource(parts, valuesArranged, targetCharData, expandIDList)
        defaultSelRowKeys = makeTreeListPTDataDefaultRowKeys(ds, charArray)
      }
    }

    batch(() => {
      setDataSource(ds)
      setExpandIDList(expandIDList)
      setDefaultSelectedRowKeys(defaultSelRowKeys)
    })
  }, [])

  const handleExpandAllClicked = (value) => {
    setExpandAll(value)
    if (value === true) {
      expandIDList.forEach((id) => commonGridRef.current.instance.expandRow(id))
    } else {
      expandIDList.forEach((id) => commonGridRef.current.instance.collapseRow(id))
    }
  }

  function renderName(cellData, pageKey) {
    const { Head_ID, Part_ID, Char_ID } = cellData.data

    const renderButton = () => (
      <Button
        className={currentSel.part_id === Part_ID && currentSel.char_id === Char_ID ? 'btn-clicked' : 'btn-not-clicked'}
        icon={pageKey === 'decisionTreeChart' ? 'isnotblank' : 'arrowleft'}
        onClick={() => {
          const tgDataSource = dataSource.find((item) => item.Part_ID === Part_ID)

          dispatch(
            setCurrentSel({
              part_id: Part_ID,
              char_id: Char_ID,
              index: tgDataSource.ID,
              dataField: dataSource.find((item) => item.Part_ID === Part_ID).dataField,
            })
          )
        }}
      />
    )

    if (Head_ID !== 0) {
      // if (checkValidationDataCount(cellData, pageKey)) {
      return (
        <div style={{ display: 'flex' }}>
          <div>{CPUtils.checkUseTargetFunction(pageKey) ? renderButton() : null}</div>
          <div style={{ paddingLeft: '5px' }}>{cellData.values}</div>
        </div>
      )
      // }
    } else {
      return cellData.values
    }
  }

  function handleCellPrepared(e) {
    if (CPUtils.checkUseTargetFunction(pageKey))
      if (e.rowType === 'data' && e.column.dataField === 'Name') {
        // 특정 조건을 만족하는 셀의 스타일 변경
        if (e.data.Part_ID === currentSel.part_id && e.data.Char_ID === currentSel.char_id) {
          e.cellElement.style.backgroundColor = 'olive'
          e.cellElement.style.color = 'white'
        }
      }
  }

  const handleSelectAll = () => {
    batch(() => {
      setDefaultSelectedRowKeys([])
      const pageLoadAll = produce(common.loaded_all, (draft) => {
        draft[pageKey] = []
      })
      dispatch(setLoadedAll(pageLoadAll))

      if (pageKey === 'decisionTreeChart') {
        const next = produce(common.decisionAddedArray, (draft) => {
          draft.data = []
        })
        dispatch(setDecisionTreeAddArray(next))
      }
    })
  }

  const handleUnselectAll = () => {
    batch(() => {
      setDefaultSelectedRowKeys([])
      const pageLoadAll = produce(common.loaded_all, (draft) => {
        draft[pageKey] = []
      })
      dispatch(setLoadedAll(pageLoadAll))

      if (pageKey === 'decisionTreeChart') {
        const next = produce(common.decisionAddedArray, (draft) => {
          draft.data = []
        })
        dispatch(setDecisionTreeAddArray(next))
      }
    })
  }

  return (
    <div className="side-panel-database-container">
      {ps[pageKey].key === 'positionalTolerancesChart' ? (
        <TreeList
          ref={commonGridRef}
          id="side-panel-normal-datagrid"
          className="side-panel-database-grid-container"
          dataSource={dataSource}
          hoverStateEnabled={true}
          defaultExpandedRowKeys={expandIDList}
          selectedRowKeys={defaultSelectedRowKeys}
          showRowLines={true}
          showBorders={true}
          columnAutoWidth={false}
          showColumnLines={true}
          autoExpandAll={true}
          keyExpr="ID"
          parentIdExpr="Head_ID"
          // rowAlternationEnabled={true}
          repaintChangesOnly={true}
          onCellPrepared={handleCellPrepared}
          onSelectionChanged={handleSelectedRowKeysChanged}
        >
          <Toolbar>
            <Item location="before" name="searchPanel" />
            {/* <Item location="after">
              <Button icon={'selectall'} style={{ width: '100%', height: '100%' }} onClick={() => handleUnselectAll()} />
            </Item> */}
            <Item location="after">
              <Button icon={'unselectall'} style={{ width: '100%', height: '100%' }} onClick={() => handleUnselectAll()} />
            </Item>
            <Item location="after">
              <Button icon={expandAll === true ? 'chevrondown' : 'chevronright'} onClick={() => handleExpandAllClicked(expandAll === true ? false : true)} />
            </Item>
          </Toolbar>
          <SearchPanel visible={true} placeholder={dqTranslateMsg('SidePanel_' + 'SearchPlaceHoler')} />
          {/* <Selection recursive={true} mode="multiple" /> */}
          <Selection recursive={true} mode="multiple" />
          <Scrolling mode="virtual" useNative={false} preloadEnabled={true} renderAsync={false} />
          <Column dataField="Name" caption={dqTranslateMsg('SidePanel_' + 'Database')} cellRender={(cellData) => renderName(cellData, pageKey)} />
        </TreeList>
      ) : (
        <TreeList
          ref={commonGridRef}
          id="side-panel-normal-datagrid"
          className="side-panel-database-grid-container"
          dataSource={dataSource}
          hoverStateEnabled={true}
          defaultExpandedRowKeys={expandIDList}
          selectedRowKeys={defaultSelectedRowKeys}
          showRowLines={true}
          showBorders={true}
          columnAutoWidth={false}
          showColumnLines={true}
          autoExpandAll={true}
          keyExpr="ID"
          parentIdExpr="Head_ID"
          rowAlternationEnabled={true}
          repaintChangesOnly={true}
          onCellPrepared={handleCellPrepared}
          onSelectionChanged={handleSelectedRowKeysChanged}
          // onContentReady={handleRowPrepared}
        >
          <Toolbar>
            <Item location="before" name="searchPanel" />
            <Item name="columnChooserButton" locateInMenu="never" />
            {/* <Item location="after">
              <Button icon={'selectall'} style={{ width: '100%', height: '100%' }} onClick={() => handleUnselectAll()} />
            </Item> */}
            <Item location="after">
              <Button icon={'unselectall'} style={{ width: '100%', height: '100%' }} onClick={() => handleUnselectAll()} />
            </Item>
            <Item location="after">
              <Button icon={expandAll === true ? 'chevrondown' : 'chevronright'} style={{ width: '100%', height: '100%' }} onClick={() => handleExpandAllClicked(expandAll === true ? false : true)} />
            </Item>
          </Toolbar>
          <SearchPanel visible={true} placeholder={dqTranslateMsg('SidePanel_' + 'SearchPlaceHoler')} />
          <Selection recursive={true} mode="multiple" />
          <Scrolling mode="virtual" useNative={false} preloadEnabled={true} renderAsync={false} />
          <Column dataField="Name" caption={dqTranslateMsg('SidePanel_' + 'Database')} cellRender={(cellData) => renderName(cellData, pageKey)} />
        </TreeList>
      )}
    </div>
  )
}

const mapStateToProps = (state) => ({
  common: state.common,
  chartRawData: state.chartRawData,
})

export default connect(mapStateToProps)(SidePanelDatabaseForm)
