// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dx-theme-background-color {
  width: 100%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}
.dx-theme-background-color .admin-language-control-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  height: 4%;
}
.dx-theme-background-color .admin-language-control-container .button-container {
  width: 8%;
  height: 100%;
}
.dx-theme-background-color .admin-language-container {
  width: 100%;
  height: 96%;
  display: flex;
  flex-direction: row;
}
.dx-theme-background-color .admin-language-container .language-tree-list-container {
  width: 20%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}
.dx-theme-background-color .admin-language-container .language-tree-list-container .tree-list-container {
  width: 100%;
  height: 100%;
  padding: 5px;
}
.dx-theme-background-color .admin-language-container .language-tree-list-container .tree-list-content {
  width: 100%;
  height: 100%;
}
.dx-theme-background-color .admin-language-container .language-items-data-grid-container {
  width: 80%;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
}
.dx-theme-background-color .admin-language-container .language-items-data-grid-container .en-grid-container {
  width: 100%;
  height: 100%;
}
.dx-theme-background-color .admin-language-container .language-items-data-grid-container .en-grid-container .en-grid-content {
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/admin/components/main-view/language/AdminLanguage.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;AACF;AACE;EACE,aAAA;EACA,yBAAA;EACA,WAAA;EACA,UAAA;AACJ;AACI;EACE,SAAA;EACA,YAAA;AACN;AAEE;EACE,WAAA;EACA,WAAA;EACA,aAAA;EACA,mBAAA;AAAJ;AAEI;EACE,UAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;AAAN;AAEM;EACE,WAAA;EACA,YAAA;EACA,YAAA;AAAR;AAEM;EACE,WAAA;EACA,YAAA;AAAR;AAGI;EACE,UAAA;EACA,YAAA;EACA,aAAA;EACA,eAAA;AADN;AAGM;EACE,WAAA;EACA,YAAA;AADR;AAGQ;EACE,WAAA;EACA,YAAA;AADV","sourcesContent":[".dx-theme-background-color {\r\n  width: 100%;\r\n  height: 100%;\r\n  display: flex;\r\n  flex-wrap: wrap;\r\n\r\n  .admin-language-control-container {\r\n    display: flex;\r\n    justify-content: flex-end;\r\n    width: 100%;\r\n    height: 4%;\r\n\r\n    .button-container {\r\n      width: 8%;\r\n      height: 100%;\r\n    }\r\n  }\r\n  .admin-language-container {\r\n    width: 100%;\r\n    height: 96%;\r\n    display: flex;\r\n    flex-direction: row;\r\n\r\n    .language-tree-list-container {\r\n      width: 20%;\r\n      height: 100%;\r\n      display: flex;\r\n      flex-wrap: wrap;\r\n\r\n      .tree-list-container {\r\n        width: 100%;\r\n        height: 100%;\r\n        padding: 5px;\r\n      }\r\n      .tree-list-content {\r\n        width: 100%;\r\n        height: 100%;\r\n      }\r\n    }\r\n    .language-items-data-grid-container {\r\n      width: 80%;\r\n      height: 100%;\r\n      display: flex;\r\n      flex-wrap: wrap;\r\n\r\n      .en-grid-container {\r\n        width: 100%;\r\n        height: 100%;\r\n\r\n        .en-grid-content {\r\n          width: 100%;\r\n          height: 100%;\r\n        }\r\n      }\r\n    }\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
