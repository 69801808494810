import { SelectBox } from 'devextreme-react'
import Button from 'devextreme-react/button'
import { Template } from 'devextreme-react/core/template'
import Toolbar, { Item } from 'devextreme-react/toolbar'
import themes from 'devextreme/ui/themes'
import { useCallback, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import UserPanel from '../user-panel/UserPanel'
import './Header.scss'
// ** Languages
import produce from 'immer'
import { useDispatch, useSelector } from 'react-redux'
import * as dbUser from 'src/api/user'
import { useAuth } from 'src/contexts/auth'
import { useTheme } from 'src/contexts/theme'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'
import { setLanguage, setBaseTheme } from 'src/store/common'
import HeaderIcon from 'src/functions/HeaderIcon'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'
import { convertLanguageStructureForDevLocale, dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import { useLanguage } from 'src/contexts/languages'
import { MONO_BLUE, MONO_RED, COLOR } from 'src/functions/constants/iconPathDefine'

const swatchThemeData = () => [
  {
    value: 'standard',
    text: dqTranslateMsg('Header_' + 'Standard'),
  },
  {
    value: 'linked',
    text: dqTranslateMsg('Header_' + 'LinkedToTheme'),
  },
]

const themeData = () => [
  {
    value: 'generic.carmine.compact',
    text: dqTranslateMsg('Header_generic_carmine_compact'),
  },
  {
    value: 'generic.dark.compact',
    text: dqTranslateMsg('Header_generic_dark_compact'),
  },
  {
    value: 'generic.darkmoon.compact',
    text: dqTranslateMsg('Header_generic_darkmoon_compact'),
  },
  {
    value: 'generic.darkviolet.compact',
    text: dqTranslateMsg('Header_generic_darkviolet_compact'),
  },
  {
    value: 'generic.greenmist.compact',
    text: dqTranslateMsg('Header_generic_greenmist_compact'),
  },
  {
    value: 'generic.light.compact',
    text: dqTranslateMsg('Header_generic_light_compact'),
  },
  {
    value: 'material.blue.dark.compact',
    text: dqTranslateMsg('Header_material_blue_dark_compact'),
  },
  {
    value: 'material.blue.light.compact',
    text: dqTranslateMsg('Header_material_blue_light_compact'),
  },
  {
    value: 'material.lime.dark.compact',
    text: dqTranslateMsg('Header_material_lime_dark_compact'),
  },
  {
    value: 'material.lime.light.compact',
    text: dqTranslateMsg('Header_material_lime_light_compact'),
  },
  {
    value: 'material.orange.dark.compact',
    text: dqTranslateMsg('Header_material_orange_dark_compact'),
  },
  {
    value: 'material.orange.light.compact',
    text: dqTranslateMsg('Header_material_orange_light_compact'),
  },
  {
    value: 'material.purple.dark.compact',
    text: dqTranslateMsg('Header_material_purple_dark_compact'),
  },
  {
    value: 'material.purple.light.compact',
    text: dqTranslateMsg('Header_material_purple_light_compact'),
  },
  {
    value: 'material.teal.dark.compact',
    text: dqTranslateMsg('Header_material_teal_dark_compact'),
  },
]

export default function Header({ menuToggleEnabled, title, toggleMenu }) {
  const { setTheme, getTheme } = useTheme()
  const [value, setValue] = useState(getTheme())
  const { user } = useAuth()
  const { language, switchLanguage } = useLanguage()
  const [loading, setLoading] = useState(false)
  const dispatch = useDispatch()
  // const common = useSelector((state) => state.common)

  // const configAll = useSelector((state) => state.common.pages.configuration)
  const configPage = useSelector((state) => state.common.pages.configuration)

  const navigate = useNavigate()

  const handleLocaleValueChanged = (e) => {
    switchLanguage(e)

    const updated = produce(configPage, (draft) => {
      draft.language = e
    })

    dqsImpl.setCurBaseSettings(dispatch, user, configPage, 'configuration', updated)
  }

  const handleFontFamilyChanged = (e) => {
    const div = document.querySelector('.dx-viewport')
    const classNames = Array.from(div.classList)
    const filteredClassNames = classNames.filter((className) => !className.startsWith('dq-font'))

    div.className = ''
    filteredClassNames.forEach((className) => div.classList.add(className))
    div.classList.add(e)

    const pageSettingsData = produce(configPage, (draft) => {
      draft.fontFamily = e
    })

    dqsImpl.setCurBaseSettings(dispatch, user, configPage, 'configuration', pageSettingsData)
  }
  const handleMenuIconTypeChanged = (e) => {
    const pageSettingsData = produce(configPage, (draft) => {
      draft.menuIconType = e
    })

    dqsImpl.setCurBaseSettings(dispatch, user, configPage, 'configuration', pageSettingsData)
  }

  const handleLogoImageClicked = () => {
    navigate('/database')
  }
  const handleConfigPageButtonClicked = () => {
    navigate('/config')
  }
  const handleAdminPageButtonClicked = () => {
    navigate('/admin')
  }
  const onSwatchThemeValueChanged = async (accent) => {
    const pageSettingsData = produce(configPage, (draft) => {
      draft.swatchTheme = accent
    })

    dqsImpl.setCurBaseSettings(dispatch, user, configPage, 'configuration', pageSettingsData)
  }
  const onValueChanged = async (accent) => {
    setValue(accent)
    setTheme(accent)

    const pageSettingsData = produce(configPage, (draft) => {
      draft.theme = accent
    })

    dqsImpl.setCurBaseSettings(dispatch, user, configPage, 'configuration', pageSettingsData)
  }

  const setFontFamily = (language) => {
    const lang = language
    const fontFamilyList = HeaderIcon.fontFamily(lang)

    console.log(fontFamilyList)
    const commonFont = fontFamilyList.find((item) => item.country === 'common')

    if (fontFamilyList) {
      const saved = fontFamilyList.find((item) => item.Value === configPage.fontFamily)

      if (saved) {
        return saved
      }

      return commonFont
    }

    return commonFont
  }

  const setIconType = () => {
    const rtn = HeaderIcon.menuIconType().find((item) => item.Value === configPage.menuIconType)
    if (rtn === undefined) {
      return HeaderIcon.menuIconType()[0]
    }
    return rtn
  }

  const setLanguageType = (language) => {
    const rtn = HeaderIcon.languageData().find((item) => item.Value === language)

    return rtn
  }

  const headerIconList = HeaderIcon.menuIconType()

  return (
    <header className={'header-component'}>
      <Toolbar className={'header-toolbar'}>
        <Item visible={menuToggleEnabled} location={'before'} widget={'dxButton'} cssClass={'menu-button'}>
          <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
        </Item>

        <Item location={'before'} widget={'dxButton'}>
          <div className="config-page-button-container">
            {value.includes('dark') ? (
              <img src={'/images/indeqs-dark-theme.svg'} height="30" onClick={handleLogoImageClicked} style={{ float: 'left' }} />
            ) : (
              <img src={'/images/indeqs-white-theme.svg'} height="30" onClick={handleLogoImageClicked} style={{ float: 'left' }} />
            )}
          </div>
        </Item>

        <Item location={'after'} cssClass={'header-title'} widget={'dxSelect'}>
          <SelectBox
            label={dqTranslateMsg('Header_' + 'IconType')}
            labelMode="static"
            dataSource={headerIconList}
            value={setIconType().Value}
            width={150}
            valueExpr={'Value'}
            onValueChange={handleMenuIconTypeChanged}
            displayExpr="text"
          />
        </Item>
        <Item location={'after'} cssClass={'header-title'} widget={'dxSelect'}>
          <SelectBox
            label={dqTranslateMsg('Header_' + 'Language')}
            labelMode="static"
            dataSource={HeaderIcon.languageData()}
            value={setLanguageType(language).Value}
            width={150}
            valueExpr={'Value'}
            onValueChange={handleLocaleValueChanged}
            displayExpr="text"
          />
        </Item>
        <Item location={'after'} cssClass={'header-title'} widget={'dxSelect'}>
          <SelectBox
            label={dqTranslateMsg('Header_' + 'Font')}
            labelMode="static"
            dataSource={HeaderIcon.fontFamily(language)}
            value={setFontFamily(language).Value}
            width={150}
            valueExpr={'Value'}
            onValueChange={handleFontFamilyChanged}
            displayExpr="text"
          />
        </Item>
        <Item location={'after'} cssClass={'header-title'} widget={'dxSelect'}>
          <SelectBox
            label={dqTranslateMsg('Header_' + 'NavigationTheme')}
            labelMode="static"
            dataSource={swatchThemeData()}
            value={configPage.swatchTheme}
            // caption={dqTranslateMsg('Header_' + value.replace(/\./g, '_'))}
            width={150}
            onValueChange={onSwatchThemeValueChanged}
            valueExpr="value"
            displayExpr="text"
          />
        </Item>
        <Item location={'after'} cssClass={'header-title'} widget={'dxSelect'}>
          <SelectBox
            label={dqTranslateMsg('Header_' + 'Theme')}
            labelMode="static"
            dataSource={themeData()}
            value={value}
            // caption={dqTranslateMsg('Header_' + value.replace(/\./g, '_'))}
            width={240}
            onValueChange={onValueChanged}
            valueExpr="value"
            displayExpr="text"
          />
        </Item>

        {HeaderIcon.loadConfiguration(user.user_role, configPage.menuIconType, getTheme(), handleConfigPageButtonClicked)}
        {HeaderIcon.loadAdminConfiguration(user.user_role, configPage.menuIconType, getTheme(), handleAdminPageButtonClicked)}

        <Item location={'after'} locateInMenu={'never'} menuItemTemplate={'userPanelTemplate'}>
          <Button className={'user-button authorization'} width={210} height={'100%'} stylingMode={'text'}>
            <UserPanel menuMode={'context'} />
          </Button>
        </Item>
        <Template name={'userPanelTemplate'}>
          <UserPanel menuMode={'list'} />
        </Template>
      </Toolbar>
    </header>
  )
}
