import {
  Column,
  GroupPanel,
  MasterDetail,
  Scrolling,
  StateStoring,
  ColumnChooser,
  ColumnChooserSearch,
  ColumnChooserSelection,
  DataGrid,
  Item,
  Position,
  Toolbar,
  Selection,
} from 'devextreme-react/data-grid'
import ReactECharts from 'echarts-for-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { connect } from 'react-redux'
import { useLanguage } from 'src/contexts/languages'
import { useTheme } from 'src/contexts/theme'
import CPUtils from 'src/functions/CommonPageUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'
import IconLoader from 'src/functions/IconLoader'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import { getDeviationChartOption } from './ValueSummaryListDeviationChartOption'
import './ValueSummaryListForm.scss'
import * as vslImpl from './ValueSummaryListFormImpl'
import ValueSummaryListMasterDetail from './ValueSummaryListMasterDetail'
import useCustomStateStore from 'src/hook/devexpress-custom-state-storing/useDevStateStoring'
import { CheckBox } from 'devextreme-react'
import DQLoadPanel from 'src/components/dq-load-form/DQLoadPanel'

const mapStateToProps = (state) => ({
  common: state.common,
  chartRawData: state.chartRawData,
})

export default connect(mapStateToProps)(ValueSummaryListForm)

export const dataSource = []

function renderDateTime(cellData, settings) {
  const value = cellData.data.char_time

  let displayStr = ''
  const dateTimeFormat = settings.page.dateTimeDisplayFormat ?? 'date/time/sec'

  displayStr = CPUtils.getDisplayDateTimeSec(dateTimeFormat, value)

  return displayStr
}
function ValueSummaryListForm({ pageKey, common, chartRawData }) {
  const { getTheme } = useTheme()
  const { language } = useLanguage()
  // For initialRendering
  const isInitialRender = useRef(true)
  const [focusedRowKey, setFocusedRowKey] = useState(0)

  const datagridRef = useRef(null)

  const { saveState, loadState, clearState } = useCustomStateStore({ stateKey: 'value-summary-list-datagrid', excludedFields: [] }) // Pass your custom state key here

  const [isLoadingPanel, setIsLoadingPanel] = useState(false)

  const { parts, chars, values } = useMemo(() => CPUtils.loadRefChartData(chartRawData), [chartRawData])

  const chartRefs = useRef({})

  const { charArray, curPage, configPage, currentSel } = CPUtils.loadRefOtherData(common, chars, pageKey)

  // ValueSummaryList will show dummy file.
  const valuesArranged = useMemo(() => CPUtils.checkMatchCharsAndValues(charArray, values), [values, charArray])
  console.log(valuesArranged)

  const dataSource = vslImpl.makeValueSummaryTableData(curPage, configPage, parts, chars, valuesArranged)

  function renderValue(cellData, settings, configSettings) {
    if (cellData.data.value > cellData.data.usl || cellData.data.value < cellData.data.lsl) {
      return (
        <div className="value-summary-list-cell-container">
          <div style={{ color: 'red' }}>{DNUtils.valueDisplay(cellData.data.value, cellData.data.char, configSettings)}</div>
          <img src={'/images/color/warning-exclamation.svg'} width="20" height="20" />
        </div>
      )
    }

    return (
      <div className="value-summary-list-cell-container">
        <div>{DNUtils.valueDisplay(cellData.data.value, cellData.data.char, configSettings)}</div>
      </div>
    )
  }

  let index = 0
  function renderDeviationChart(cellData) {
    const uniqueRefKey = cellData.data.no
    // const index = cellData.rowIndex - 1
    let trendChartOption
    const lsl = cellData.data.lsl
    const usl = cellData.data.usl
    const nominal = cellData.data.nominal
    const deviationValue = cellData.data.deviation
    if (deviationValue !== null || (lsl === null && usl === null)) {
      trendChartOption = getDeviationChartOption(lsl, usl, nominal, deviationValue)
      return (
        <ReactECharts
          // className="value-summary-list-deviation-container"
          id="styled-deviation-chart"
          ref={(e) => {
            chartRefs.current[uniqueRefKey] = e
          }}
          option={trendChartOption}
          style={{ height: '32px', width: '100%' }}
          theme={getTheme().includes('dark') || getTheme().includes('contrast') ? 'dark' : 'light'}
          opts={{
            width: 'auto',
            height: 'auto',
            renderer: 'svg',
            locale: ETCUtils.checkLangForECharts(language),
          }}
        />
      )
    } else {
      return (
        <>
          <img src={'/images/color/warning-exclamation.svg'} width="20" height="20" />
          {/* <div style={{ fontSize: '10px' }}>Data cannot be evaluated</div> */}
        </>
      )
    }
  }

  function renderNominal(cellData, configSettings) {
    return cellData.data.nominal
  }
  function renderUsl(cellData, configSettings) {
    if (cellData.data.usl === null) {
      return null
    } else {
      return DNUtils.axisValueDisplay(cellData.data.usl, cellData.data.char, configSettings)
    }
  }

  function renderLsl(cellData, configSettings) {
    if (cellData.data.lsl === null) {
      return null
    } else {
      return DNUtils.axisValueDisplay(cellData.data.lsl, cellData.data.char, configSettings)
    }
  }
  function renderV0014(cellData) {
    if (cellData.data.v0014 === null) {
      return ''
    } else {
      return cellData.data.v0014
    }
  }
  function renderV0053(cellData) {
    if (cellData.data.v0053 === null) {
      return ''
    } else {
      return cellData.data.v0053
    }
  }

  function renderResult(cellData, settings) {
    const iconType = settings.theme.iconType.result

    if (cellData.data.result === 1) {
      return (
        <img
          src={`${IconLoader.getImgPath(
            configPage.menuIconType, // mode
            IconLoader.TYPES_DEF.find((type) => type.path === iconType).id,
            getTheme().includes('dark') ? 1 : 0,
            IconLoader.STATE_DEF.find((type) => type.path === 'good').id
          )}`}
          width="20"
          height="20"
        />
      ) // NG
    } else if (cellData.data.result === 0) {
      return (
        <img
          src={`${IconLoader.getImgPath(
            configPage.menuIconType, // mode
            IconLoader.TYPES_DEF.find((type) => type.path === iconType).id,
            getTheme().includes('dark') ? 1 : 0,
            IconLoader.STATE_DEF.find((type) => type.path === 'bad').id
          )}`}
          width="20"
          height="20"
        />
      ) // NG
    } else {
      return (
        <>
          <img src={'/images/color/warning-exclamation.png'} width="20" height="20" />
          {/* <div style={{ fontSize: '7px' }}>Data cannot be evaluated</div> */}
        </>
      )
    }
  }

  const handleDataGridContentReady = () => {
    Object.values(chartRefs.current).forEach((ref) => {
      const devChartInstance = ref?.getEchartsInstance() // Assuming instance is directly accessible
      if (devChartInstance) {
        devChartInstance.resize() // Or any other method you need to call
      }
    })

    if (datagridRef.current) {
      const dataGridInstance = datagridRef.current.instance
      const rows = dataGridInstance.option('dataSource')
      const targetRow = rows.find((row) => {
        if (currentSel.part_id === row.char.part_id && currentSel.char_id === row.char.char_id) {
          return true
        }
        return false
      })

      if (targetRow) {
        setFocusedRowKey(targetRow.no)
      }
    }

    setIsLoadingPanel(false)
  }

  const handleSaveState = () => {
    if (datagridRef.current) {
      const state = datagridRef.current.instance.state()
      saveState(state)
    }
  }

  const handleLoadState = () => {
    if (datagridRef.current) {
      const state = loadState()
      if (state) {
        datagridRef.current.instance.state(state)
      }
    }
  }

  const handleClearState = () => {
    clearState()
    if (datagridRef.current) {
      const emptyState = {
        columns: [],
        sorting: [],
        grouping: [],
        selectionFilter: [],
        pageIndex: 0,
        pageSize: 10,
      }
      datagridRef.current.instance.state(emptyState)
    }
  }
  const handleKeyDown = (e) => {
    if (summaryListGrid) {
      if (e.event.key === 'Escape') {
        summaryListGrid.current.instance.hideColumnChooser()
      }
    }
  }

  useEffect(() => {
    handleLoadState()
  }, [])
  useEffect(() => {
    setIsLoadingPanel(true)
    if (datagridRef.current) {
      const dataGridInstance = datagridRef.current.instance
      dataGridInstance.collapseAll(-1)
    }
  }, [currentSel])

  if (dataSource.length > 0) {
    return (
      <div className="dx-theme-background-color">
        <DQLoadPanel open={isLoadingPanel} message={dqTranslateMsg('Common_' + 'LoadPanelLoading')} />
        <DataGrid
          ref={datagridRef}
          className="value-summary-list-grid-container"
          id="value-summary-list-datagrid"
          dataSource={[...dataSource]}
          allowColumnReordering={true}
          allowColumnResizing={true}
          columnResizingMode="widget"
          filterRow={{ visible: false }}
          noDataText={dqTranslateMsg('Page_ValueSummaryList_' + 'NoData')}
          paging={{ enabled: false }}
          showColumnLines={true}
          showRowLines={true}
          showBorders={true}
          cacheEnabled={true}
          rowAlternationEnabled={true}
          hoverStateEnabled={true}
          focusedRowEnabled={true}
          focusedRowKey={focusedRowKey}
          autoNavigateToFocusedRow={true}
          onKeyDown={handleKeyDown}
          keyExpr={'no'}
          // onOptionChanged={(e) => {
          //   // Handle column visibility changes from the column chooser
          //   if (e.fullName.includes('columns') || e.name === 'columnOption') {
          //     syncMasterDetailColumns() // Sync columns when the column option changes
          //   }
          // }}
          onContentReady={handleDataGridContentReady}
        >
          <Selection mode="single" />
          <GroupPanel visible={true} emptyPanelText={dqTranslateMsg('Page_ValueSummaryList_' + 'EmptyPanelText')} />
          <Scrolling mode="virtual" useNative={false} preloadEnabled={true} renderAsync={false} />
          <Column dataField="part_name" width={'10%'} caption={dqTranslateMsg('Page_ValueSummaryList_' + 'PartName')} alignment="center" groupIndex={0} />
          <Column dataField="no" width={'10%'} caption={dqTranslateMsg('Page_ValueSummaryList_' + 'ID')} alignment="center" />
          <Column dataField="desc" width={'20%'} caption={dqTranslateMsg('Page_ValueSummaryList_' + 'Description')} alignment="center" />
          <Column dataField="nominal" width={'10%'} caption={dqTranslateMsg('Page_ValueSummaryList_' + 'Nominal')} alignment="center" cellRender={(cellData) => renderNominal(cellData, configPage)} />
          <Column dataField="lsl" caption={dqTranslateMsg('Page_ValueSummaryList_' + 'LSL')} width={'10%'} alignment="center" cellRender={(cellData) => renderLsl(cellData, configPage)} />
          <Column dataField="usl" caption={dqTranslateMsg('Page_ValueSummaryList_' + 'USL')} width={'10%'} alignment="center" cellRender={(cellData) => renderUsl(cellData, configPage)} />
          <Column dataField="v0014" caption={dqTranslateMsg('Db_field_Value_' + 'V0014')} width={'10%'} alignment="center" cellRender={(cellData) => renderV0014(cellData)} />
          <Column dataField="v0053" caption={dqTranslateMsg('Db_field_Value_' + 'V0053')} width={'10%'} alignment="center" cellRender={(cellData) => renderV0053(cellData)} />
          <Column
            dataField="char_time"
            width={'15%'}
            caption={dqTranslateMsg('Page_ValueSummaryList_' + 'MeasurementTime')}
            alignment="center"
            cellRender={(cellData) => renderDateTime(cellData, curPage)}
          />
          <Column dataField="result" alignment="center" caption={dqTranslateMsg('Page_ValueSummaryList_' + 'Result')} width={'10%'} cellRender={(cellData) => renderResult(cellData, curPage)} />
          <Column
            dataField="value"
            width={'10%'}
            caption={dqTranslateMsg('Page_ValueSummaryList_' + 'Value')}
            alignment="center"
            cellRender={(cellData) => renderValue(cellData, curPage, configPage)}
          />
          <Column dataField="deviation" caption={dqTranslateMsg('Page_ValueSummaryList_' + 'Deviation')} alignment="center" width={'15%'} cellRender={renderDeviationChart} />
          <Toolbar>
            <Item key={'database-part-toolbar-group-panel'} name="groupPanel" location="before" />
            <Item
              widget="dxButton"
              options={{
                icon: 'save',
                text: dqTranslateMsg('Page_ValueSummaryList_' + 'SaveLayout'),
                onClick: handleSaveState,
              }}
              location="before"
            />
            <Item
              widget="dxButton"
              options={{
                icon: 'clear',
                text: dqTranslateMsg('Page_ValueSummaryList_' + 'ClearLayout'),
                onClick: handleClearState,
              }}
              location="before"
            />
            <Item name="columnChooserButton" locateInMenu="never" location="after" />
          </Toolbar>
          <ColumnChooser
            enabled={true}
            mode={'select'}
            width={300}
            height={500}
            title={dqTranslateMsg('Page_ValueSummaryList_' + 'ColumnChooserTitle')}
            emptyPanelText={dqTranslateMsg('Page_ValueSummaryList_' + 'ColumnChooserEmptyPanelText')}
          >
            <Position my="center" at="center" of={window} />
            <ColumnChooserSearch
              enabled={true}
              editorOptions={{
                placeholder: dqTranslateMsg('Page_ValueSummaryList_' + 'ColumnChooserPlaceHoler'),
              }}
            />
            <ColumnChooserSelection allowSelectAll={true} selectByClick={true} recursive={true} />
          </ColumnChooser>
          <MasterDetail enabled={true} component={(props) => ValueSummaryListMasterDetail(props, datagridRef, curPage, configPage, common)} />
        </DataGrid>
      </div>
    )
  } else {
    setIsLoadingPanel(false)
    return (
      <>
        <img src={'/images/color/warning-exclamation.svg'} width="30" height="30" />
        <div style={{ fontSize: '20px' }}>{dqTranslateMsg('Page_ValueSummaryList_' + 'DataIsNotSelected')}</div>
      </>
    )
  }
}
