export const defValueField = [
  { configuration: false, numValue: null, number: null, value: 'part_id', text: 'Part_id', visible: false, dataType: 'number' },
  { configuration: false, numValue: null, number: null, value: 'char_id', text: 'Char_id', visible: false, dataType: 'number' },
  { configuration: false, numValue: null, number: null, value: 'value_id', text: 'Value_id', visible: false, dataType: 'number' },
  { configuration: false, numValue: null, number: null, value: 'out_of_tol', text: 'Out_of_tol', visible: false, dataType: 'number' },
  { configuration: false, numValue: null, number: null, value: 'nominal', text: 'Nominal', visible: false, dataType: 'number' },
  { configuration: false, numValue: null, number: null, value: 'v_usl', text: 'V_usl', visible: false, dataType: 'number' },
  { configuration: false, numValue: null, number: null, value: 'v_lsl', text: 'V_lsl', visible: false, dataType: 'number' },
  { configuration: true, numValue: '0001', value: 'v0001', text: 'V0001', visible: true, dataType: 'number' },
  { configuration: true, numValue: '0002', value: 'v0002', text: 'V0002', visible: true, dataType: 'number' },
  { configuration: true, numValue: '0004', value: 'v0004', text: 'V0004', visible: true, dataType: 'string' },
  { configuration: true, numValue: '0005', value: 'v0005', text: 'V0005', visible: true, dataType: 'string' },
  { configuration: true, numValue: '0061', value: 'v0006', text: 'V0006', visible: true, dataType: 'string' },
  { configuration: true, numValue: '0007', value: 'v0007', text: 'V0007', visible: true, dataType: 'number' },
  { configuration: true, numValue: '0008', value: 'v0008', text: 'V0008', visible: true, dataType: 'number' },
  { configuration: true, numValue: '0009', value: 'v0009', text: 'V0009', visible: true, dataType: 'string' },
  { configuration: true, numValue: '0010', value: 'v0010', text: 'V0010', visible: true, dataType: 'number' },
  { configuration: true, numValue: '0011', value: 'v0011', text: 'V0011', visible: true, dataType: 'string' },
  { configuration: true, numValue: '0012', value: 'v0012', text: 'V0012', visible: true, dataType: 'number' },
  { configuration: true, numValue: '0014', value: 'v0014', text: 'V0014', visible: true, dataType: 'string' },
  { configuration: true, numValue: '0016', value: 'v0016', text: 'V0016', visible: true, dataType: 'string' },
  { configuration: true, numValue: '0017', value: 'v0017', text: 'V0017', visible: true, dataType: 'string' },
  { configuration: true, numValue: '0053', value: 'v0053', text: 'V0053', visible: true, dataType: 'string' },
  { configuration: true, numValue: '0054', value: 'v0054', text: 'V0054', visible: true, dataType: 'string' },
  { configuration: true, numValue: '0055', value: 'v0055', text: 'V0055', visible: true, dataType: 'string' },
  { configuration: true, numValue: '0056', value: 'v0056', text: 'V0056', visible: true, dataType: 'string' },
  { configuration: true, numValue: '0057', value: 'v0057', text: 'V0057', visible: true, dataType: 'string' },
  { configuration: true, numValue: '0058', value: 'v0058', text: 'V0058', visible: true, dataType: 'string' },
  { configuration: true, numValue: '0059', value: 'v0059', text: 'V0059', visible: true, dataType: 'string' },
  { configuration: true, numValue: '0060', value: 'v0060', text: 'V0060', visible: true, dataType: 'string' },
  { configuration: true, numValue: '0061', value: 'v0061', text: 'V0061', visible: true, dataType: 'string' },
  { configuration: true, numValue: '0062', value: 'v0062', text: 'V0062', visible: true, dataType: 'string' },
  { configuration: true, numValue: '0063', value: 'v0063', text: 'V0063', visible: true, dataType: 'string' },
]
