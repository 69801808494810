import { SelectBox, TextBox } from 'devextreme-react'
import { GroupItem } from 'devextreme-react/form'
import produce from 'immer'
import { Fragment, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage.js'
import CPUtils from 'src/functions/CommonPageUtils'
import ButtonSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItem'
import ListSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ListSimpleItem'
import SelectBoxNoValueSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxNoValueSimpleItem'
import SelectBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/SelectBoxSimpleItem'
import TextBoxNoValueSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/TextBoxNoValueSimpleItem'
import TextBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/TextBoxSimpleItem'

const makeExpressionPartFilter = (partFilterList) => {
  let formula = ''
  const groupStack = []

  partFilterList.forEach((condition, index) => {
    const { group, field, data, logic, equal } = condition
    const operation = `${field} ${equal} ${data}`

    // Add opening bracket for group 1
    if (group === 1 && !groupStack.includes(1)) {
      formula += ' ('
      groupStack.push(1)
    }

    // Add opening bracket for group 2
    if (group === 2 && !groupStack.includes(2)) {
      formula += ' ('
      groupStack.push(2)
    }

    // Append the current operation
    formula += operation

    // Determine the logic operator
    const nextCondition = partFilterList[index + 1]
    if (nextCondition) {
      formula += ` ${nextCondition.logic} `
    }

    // Close brackets if the next group is different
    if (nextCondition && nextCondition.group < group) {
      if (groupStack.includes(2)) {
        formula += ')'
        groupStack.pop()
      }
      if (groupStack.includes(1) && nextCondition.group < 1) {
        formula += ')'
        groupStack.pop()
      }
    }
  })

  // Close any remaining open brackets
  groupStack.forEach(() => {
    formula += ')'
  })

  return formula
}

const PartFilter = (pageKey, pageState, configState) => {
  const dispatch = useDispatch()
  // const pageKey = 'scrollBoard'
  // const common = useSelector((state) => state.common)
  const sbPageSettings = pageState
  const configPage = configState

  const selectBoxBracketRef = useRef(null)
  const selectBoxFieldRef = useRef(null)
  const selectBoxOperatorRef = useRef(null)
  const selectBoxEqualRef = useRef(null)
  const textBoxDataRef = useRef(null)

  const isCommonEmpty = Object.keys(configPage.useFieldList).length === 0
  const isLsUseFieldListEmpty = Object.keys(configPage.useFieldList).length === 0

  let useFieldList = {}
  if (isCommonEmpty === false) {
    useFieldList = configPage.useFieldList
  } else if (isLsUseFieldListEmpty === false) {
    useFieldList = configPage.useFieldList
  } else {
    useFieldList = {
      part: [],
      char: [],
      value: [],
    }
  }

  const usePartFilterField = CPUtils.getConfigItemListFromUseField('Part', false, null, useFieldList.part, configPage)

  const filterOptions = () => {
    return {
      groups: [
        { value: 0, text: dqTranslateMsg('SidePanel_' + 'None') },
        { value: 1, text: dqTranslateMsg('SidePanel_' + 'LeftParenthesis') },
        { value: 2, text: dqTranslateMsg('SidePanel_' + 'RightParenthesis') },
      ],
      fields: usePartFilterField,
      logics: [
        { value: 'and', text: dqTranslateMsg('SidePanel_' + 'And') },
        { value: 'or', text: dqTranslateMsg('SidePanel_' + 'Or') },
      ],
      equals: [
        { value: 'contain', text: dqTranslateMsg('SidePanel_' + 'Contains') }, //'⊂'
        { value: 'equal', text: dqTranslateMsg('SidePanel_' + 'Equal') }, //'='
      ],
    }
  }

  const updateListFilterConfig = () => {
    const fieldList = sbPageSettings.filter.partFilter

    let filterConfigList
    if (fieldList && fieldList.length > 0) {
      filterConfigList = fieldList.map((field, index) => ({
        id: index,
        group: field.group,
        field: field.field,
        data: field.data,
        logic: field.logic,
        equal: field.equal,
      }))
    } else {
      filterConfigList = []
    }

    return filterConfigList
  }

  const onAddButtonClick = () => {
    const curBracket = selectBoxBracketRef.current.instance.option('value')
    const curField = selectBoxFieldRef.current.instance.option('value')
    const curData = textBoxDataRef.current.instance.option('value')

    if (!curData) {
      alert('Data field is empty')
      return
    }

    const curOperator = selectBoxOperatorRef.current.instance.option('value')
    const curEqual = selectBoxEqualRef.current.instance.option('value')

    const next = produce(sbPageSettings, (draft) => {
      draft.filter.partFilter.push({
        group: curBracket,
        field: curField,
        data: curData,
        logic: curOperator,
        equal: curEqual,
      })
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const handleFilterConfigDelete = (e) => {
    const filterConfigList = updateListFilterConfig()
    filterConfigList.splice(e.itemIndex, 1)

    const next = produce(sbPageSettings, (draft) => {
      draft.filter.partFilter = filterConfigList
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const renderFilterConfigList = (itemData) => {
    return (
      <div
        style={{
          display: 'flex',
          width: '100%',
        }}
      >
        <SelectBox
          style={{ width: '20%', textAlign: 'center' }}
          items={filterOptions().groups}
          value={itemData.group}
          valueExpr="value"
          displayExpr="text"
          showDropDownButton={false}
          dropDownOptions={{
            width: '100px',
          }}
          onValueChanged={(e) => {
            const { value } = e
            const next = produce(sbPageSettings, (draft) => {
              draft.filter.partFilter[itemData.id].group = value
            })
            dispatch(ps[pageKey].setPageSettingsToRedux(next))
          }}
        />
        <SelectBox
          style={{ width: '20%', textAlign: 'center' }}
          items={filterOptions().fields}
          value={itemData.field}
          valueExpr="number"
          displayExpr="text"
          showDropDownButton={false}
          dropDownOptions={{
            width: '200px',
          }}
          onValueChanged={(e) => {
            const { value } = e
            const next = produce(sbPageSettings, (draft) => {
              draft.filter.partFilter[itemData.id].field = value
            })
            dispatch(ps[pageKey].setPageSettingsToRedux(next))
          }}
        />
        <TextBox
          style={{ width: '20%', textAlign: 'center' }}
          value={itemData.data}
          onValueChanged={(e) => {
            const { value } = e
            const next = produce(sbPageSettings, (draft) => {
              draft.filter.partFilter[itemData.id].data = value
            })
            dispatch(ps[pageKey].setPageSettingsToRedux(next))
          }}
        />
        <SelectBox
          style={{ width: '20%', textAlign: 'center' }}
          items={filterOptions().logics}
          value={itemData.logic}
          valueExpr="value"
          displayExpr="text"
          showDropDownButton={false}
          dropDownOptions={{
            width: '100px',
          }}
          onValueChanged={(e) => {
            const { value } = e
            const next = produce(sbPageSettings, (draft) => {
              draft.filter.partFilter[itemData.id].logic = value
            })
            dispatch(ps[pageKey].setPageSettingsToRedux(next))
          }}
        />
        <SelectBox
          style={{ width: '20%', textAlign: 'center' }}
          items={filterOptions().equals}
          value={itemData.equal}
          valueExpr="value"
          displayExpr="text"
          showDropDownButton={false}
          dropDownOptions={{
            width: '100px',
          }}
          onValueChanged={(e) => {
            const { value } = e
            const next = produce(sbPageSettings, (draft) => {
              draft.filter.partFilter[itemData.id].equal = value
            })
            dispatch(ps[pageKey].setPageSettingsToRedux(next))
          }}
        />
      </div>
    )
  }

  return (
    <GroupItem key={'part-filter-group-item'} colCount={5} caption={dqTranslateMsg('SidePanel_' + 'FilterConfig')}>
      {SelectBoxNoValueSimpleItem({
        label: dqTranslateMsg('SidePanel_' + 'Group'),
        ref: selectBoxBracketRef,
        items: filterOptions().groups,
        valueExpr: 'value',
        displayExpr: 'text',
        showDropDownButton: false,
        defaultValue: filterOptions().groups[0].value,
      })}
      {SelectBoxNoValueSimpleItem({
        label: dqTranslateMsg('SidePanel_' + 'Field'),
        ref: selectBoxFieldRef,
        showDropDownButton: false,
        items: filterOptions().fields,
        valueExpr: 'number',
        displayExpr: 'text',
        defaultValue: filterOptions().fields[0].number,
        listWidth: '200px',
      })}
      {TextBoxNoValueSimpleItem({
        label: dqTranslateMsg('SidePanel_' + 'Data'),
        ref: textBoxDataRef,
      })}
      {SelectBoxNoValueSimpleItem({
        label: dqTranslateMsg('SidePanel_' + 'Logic'),
        ref: selectBoxOperatorRef,
        items: filterOptions().logics,
        valueExpr: 'value',
        displayExpr: 'text',
        showDropDownButton: false,
        defaultValue: filterOptions().logics[0].value,
      })}
      {SelectBoxNoValueSimpleItem({
        label: dqTranslateMsg('SidePanel_' + 'Match'),
        ref: selectBoxEqualRef,
        items: filterOptions().equals,
        valueExpr: 'value',
        displayExpr: 'text',
        showDropDownButton: false,
        defaultValue: filterOptions().equals[0].value,
      })}
      {ButtonSimpleItem({
        colSpan: 5,
        type: 'normal',
        text: dqTranslateMsg('SidePanel_' + 'Add'),
        icon: 'plus',
        width: '100%',
        height: '100%',
        onClick: onAddButtonClick,
      })}
      {ListSimpleItem({
        colSpan: 5,
        label: dqTranslateMsg('SidePanel_' + 'RegisteredItem'),
        labelLocation: 'top',
        dataSource: updateListFilterConfig(),
        itemRender: renderFilterConfigList,
        allowItemDeleting: true,
        itemDeleteMode: 'static',
        noDataText: dqTranslateMsg('SidePanel_' + 'NoData'),
        onItemDeleted: handleFilterConfigDelete,
      })}
      {/* PartFielter descroption */}
      {/* {TextBoxSimpleItem({
        colSpan: 5,
        label: 'Part Filter Expression',
        value: makeExpressionPartFilter(sbPageSettings.filter.partFilter),
        disabled: true,
      })} */}
    </GroupItem>
  )
}

export default PartFilter
