import { GroupItem } from 'devextreme-react/form'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import AdvancedFilterItem from 'src/pages/components/dq-wrapper-ui-devexpress/AdvancedFilterItem'
import ButtonSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItem'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import produce from 'immer'
import CPUtils from 'src/functions/CommonPageUtils'
import { pageType } from 'src/functions/table/PageTypeTable'
import { msgType } from 'src/functions/table/MessageTypeTable'
import { getErrProcMsg } from 'src/functions/CommonMassageUtils'
import { procErrTable } from 'src/functions/table/ProcMessageTable'
import CatalogUtils from 'src/functions/CatalogUtils'
import TextBoxNoValueSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/TextBoxNoValueSimpleItem'
import PopupSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/PopupSimpleItem'
import { ScrollView } from 'devextreme-react'
import AdvancedCommonOption from './advanced/AdvancedCommonOption'
import AdvancedDateTimeOption from './advanced/AdvancedDateTimeOption'
import AdvancedLastNFilterOption from './advanced/AdvancedLastNFilterOption'
import AdvancedETCOption from './advanced/AdvancedETCOption'
import { Form } from 'devextreme-react'
import AdvancedRealTimeFilter from './advanced/AdvancedRealTimeFilter'
import AdvancedSaveFilter from './advanced/AdvancedSaveFilter'

const DBFieldAdvancedCreatePopupFilter = (pageKey, pageState, configState, isVisibleCreateFilterPopup, setIsVisibleCreateFilterPopup, popupCreateFilter, setPopupCreateFilter) => {
  const dispatch = useDispatch()
  const textBoxRef = useRef(null)

  const closePopup = () => {
    setIsVisibleCreateFilterPopup(false)
    setPopupCreateFilter(null)
  }

  const renderFilterContent = () => {
    if (popupCreateFilter === null) {
      return null
    }

    return (
      <Form id={`side-panel-form`} style={{ width: '100%', height: '100%', paddingTop: '10px' }} labelMode={'outside'} showColonAfterLabel={false}>
        {[
          AdvancedCommonOption(popupCreateFilter, setPopupCreateFilter),
          AdvancedDateTimeOption(popupCreateFilter, setPopupCreateFilter),
          AdvancedLastNFilterOption(popupCreateFilter, setPopupCreateFilter),
          AdvancedETCOption(popupCreateFilter, setPopupCreateFilter),
          AdvancedRealTimeFilter(popupCreateFilter, setPopupCreateFilter, pageKey, pageState, configState),
          AdvancedSaveFilter(popupCreateFilter, setPopupCreateFilter, setIsVisibleCreateFilterPopup, textBoxRef, pageKey, pageState, configState),
        ]}
      </Form>
    )
  }

  const title = 'Create Filter'
  const width = '30%'
  const height = 'auto'

  return (
    <GroupItem key={`db-field-advanced-create-popup-filter-group-item`} colCount={2} caption={''}>
      {PopupSimpleItem({ visible: isVisibleCreateFilterPopup, onHiding: closePopup, title, width, height, contentRender: renderFilterContent })}
    </GroupItem>
  )
}

export default DBFieldAdvancedCreatePopupFilter
