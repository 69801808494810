import { GroupItem } from 'devextreme-react/form'
import produce from 'immer'
import { useDispatch, useSelector } from 'react-redux'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import NumberBoxSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/NumberBoxSimpleItem'

export default function SettingsProcessCapability() {
  const dispatch = useDispatch()
  const pageKey = 'configuration'
  const pageState = useSelector((state) => state.common.pages[pageKey])

  const onNumberBoxValueChange = (e, dataField) => {
    if (e.event === undefined) return

    let value = 0
    const maxOption = 20
    if (e.value === null) {
      value = maxOption
    } else {
      value = 100 - e.value
    }
    // const value = 100 - e.value

    const next = produce(pageState, (draft) => {
      draft.commonSettings[dataField] = value === null ? maxOption : value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onNumberBoxSigmaValueChange = (e, dataField) => {
    if (e.event === undefined) return

    const value = e.value
    const minOption = e.component.option('min')
    const next = produce(pageState, (draft) => {
      draft.commonSettings[dataField] = value === null ? minOption : value
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <GroupItem key={`common-display-value-option-group-item`} colCount={2} caption={dqTranslateMsg('Page_Configuration_' + 'ProcessCapabilityOption')}>
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('Page_Configuration_' + 'TwoSideWarningLimit'),
        labelLocation: 'top',
        name: 'warningLimitPercentage',
        min: 1,
        max: 100,
        step: 1,
        value: 100 - pageState.commonSettings.warningLimitPercentage,
        unit: 'PercentageShort',
        disabled: false,
        onValueChanged: (e) => onNumberBoxValueChange(e, 'warningLimitPercentage'),
      })}
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('Page_Configuration_' + 'OneSideWarningLimitNat'),
        labelLocation: 'top',
        name: 'warningLimitNatPercentage',
        min: 1,
        max: 100,
        step: 1,
        value: 100 - pageState.commonSettings.warningLimitNatPercentage,
        unit: 'PercentageShort',
        disabled: false,
        onValueChanged: (e) => onNumberBoxValueChange(e, 'warningLimitNatPercentage'),
      })}
      {NumberBoxSimpleItem({
        colSpan: 1,
        label: dqTranslateMsg('Page_Configuration_' + 'DefaultSigmaDegree'),
        labelLocation: 'top',
        name: 'sigmaDegree',
        min: 1,
        max: 3,
        step: 1,
        value: pageState.commonSettings.sigmaDegree,
        unit: 'SigmaShort',
        disabled: false,
        onValueChanged: (e) => onNumberBoxSigmaValueChange(e, 'sigmaDegree'),
      })}
    </GroupItem>
  )
}
