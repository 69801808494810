import ETCUtils from './utils/EtCeteraUtils'
import CCUtils from 'src/functions/CommonCalcUtils'

class ProcessCapabilityUtils {
  /**
   * cp cpk x50 x99.865 x0.135 will calculate in backend server
   */

  /**
   * check data
   * 0 : Cannot Evaluated
   * 1 : Good
   * 2 : Bad
   */

  static INVALID = 0
  static GOOD = 1
  static BAD = 2

  static checkResultCp(cp, criteria) {
    if (cp === null || criteria === null) {
      return this.INVALID
    }

    if (cp < criteria) {
      return this.BAD
    }

    return this.GOOD
  }
  static checkResultCpk(cpk, criteria) {
    if (cpk === null || criteria === null) {
      return this.INVALID
    }

    if (cpk < criteria) {
      return this.BAD
    }

    return this.GOOD
  }

  static checkResultCpCpk(cp, cpk, criteria) {
    if (cp === null || cpk === null || criteria === null) {
      return this.INVALID
    }

    if (cp < criteria || cpk < criteria) {
      return this.BAD
    }

    return this.GOOD
  }

  /**
   * get data / calc data
   */

  static getUsl(charObj) {
    if (charObj && ETCUtils.isValidData(charObj.c2111)) {
      const value = charObj.c2111
      return value
    }

    return null
  }

  static getLsl(charObj) {
    if (charObj && ETCUtils.isValidData(charObj.c2110)) {
      const value = charObj.c2110
      return value
    }

    return null
  }

  /**
   * Plausibility limit value
   */
  static getUpl(charObj) {
    if (charObj && ETCUtils.isValidData(charObj.c2131)) {
      const value = charObj.c2131
      return value
    }

    return null
  }

  static getLpl(charObj) {
    if (charObj && ETCUtils.isValidData(charObj.c2130)) {
      const value = charObj.c2130
      return value
    }

    return null
  }

  /**
   * Scrap limit value
   */
  static getUrl(charObj) {
    if (charObj && ETCUtils.isValidData(charObj.c2115)) {
      const value = charObj.c2115
      return value
    }

    return null
  }

  static getLrl(charObj) {
    if (charObj && ETCUtils.isValidData(charObj.c2114)) {
      const value = charObj.c2114
      return value
    }

    return null
  }

  /**
   * Scrap limit value
   */
  static getUAwl(charObj) {
    if (charObj && ETCUtils.isValidData(charObj.c2113)) {
      const value = charObj.c2113
      return value
    }

    return null
  }

  static getLAwl(charObj) {
    if (charObj && ETCUtils.isValidData(charObj.c2112)) {
      const value = charObj.c2112
      return value
    }

    return null
  }
  /**
   * Acceptance limit value
   */
  static getUAcl(charObj) {
    if (charObj && ETCUtils.isValidData(charObj.c2117)) {
      const value = charObj.c2117
      return value
    }

    return null
  }

  static getLAcl(charObj) {
    if (charObj && ETCUtils.isValidData(charObj.c2116)) {
      const value = charObj.c2116
      return value
    }

    return null
  }

  /**
   * Nominal
   */

  static getNominal(charObj) {
    if (charObj && ETCUtils.isValidData(charObj.c2101)) {
      const value = charObj.c2101
      return value
    }

    return null
  }

  /**
   * Allowances
   */
  static getUpperAllowance(charObj) {
    const fieldName = 'c2113'
    if (charObj && ETCUtils.isValidData(charObj[fieldName])) {
      const value = charObj[fieldName]
      return value
    }

    return null
  }
  static getLowerAllowance(charObj) {
    const fieldName = 'c2112'
    if (charObj && ETCUtils.isValidData(charObj[fieldName])) {
      const value = charObj[fieldName]
      return value
    }

    return null
  }

  /**
   * Natural Boundary
   */

  static getTypeLowerWarningLimit(charObj) {
    if (charObj && ETCUtils.isValidData(charObj.c2120)) {
      const value = charObj.c2120
      return value
    }

    return 1
  }

  static getTypeUpperWarningLimit(charObj) {
    if (charObj && ETCUtils.isValidData(charObj.c2121)) {
      const value = charObj.c2121
      return value
    }

    return 1
  }

  static getCenterUslLsl(usl, lsl) {
    if (usl === null || lsl === null) {
      return null
    }

    const value = (usl + lsl) / 2
    return value
  }

  static getUslLslGap(usl, lsl) {
    if (usl === null || lsl === null) {
      return null
    }

    const value = usl - lsl
    return value
  }

  static getWarningOffset(percentage) {
    if (percentage === null || percentage === undefined) {
      return null
    }

    const value = percentage / 2
    return value
  }

  static getWarningOffsetNat(percentage) {
    if (percentage === null || percentage === undefined) {
      return null
    }

    const value = percentage
    return value
  }

  static getUwl(usl, gapUslLsl, warnOffset) {
    if (usl === null || gapUslLsl === null || warnOffset === null) {
      return null
    }

    const value = usl - (gapUslLsl * warnOffset) / 100
    return value
  }
  static getNaturalBoundaryUwl(usl, gapUslLsl, warnOffset) {
    if (usl === null || gapUslLsl === null || warnOffset === null) {
      return null
    }

    const plusOffset = warnOffset
    const value = usl - (gapUslLsl * plusOffset) / 100
    return value
  }

  static getLwl(lsl, gapUslLsl, warnOffset) {
    if (lsl === null || gapUslLsl === null || warnOffset === null) {
      return null
    }

    const value = lsl + (gapUslLsl * warnOffset) / 100
    return value
  }
  static getNaturalBoundaryLwl(lsl, gapUslLsl, warnOffset) {
    if (lsl === null || gapUslLsl === null || warnOffset === null) {
      return null
    }
    const plusOffset = warnOffset
    const value = lsl + (gapUslLsl * plusOffset) / 100
    return value
  }

  static applyDecimalPlaces(value, decimalPlaces) {
    if (value !== null && value !== undefined) {
      if (typeof value !== 'number' || typeof decimalPlaces !== 'number') {
        throw new Error('Both value and decimalPlaces must be numbers')
      }

      return ETCUtils.toFixedNumber(value, decimalPlaces)
    }
  }

  static getSum(src) {
    if (src.length === 0) {
      return null
    }

    const length = src.length
    const sum = src.reduce((accumulator, currentValue) => {
      // currentValue가 숫자인 경우에만 합산
      if (typeof currentValue === 'number') {
        return accumulator + currentValue
      } else {
        return accumulator
      }
    }, 0)

    return { sum, length }
  }

  static getLength(src) {
    const length = src.length

    return length
  }

  static getAverage(sum, totalCnt) {
    if (totalCnt < 0) {
      return null
    }

    const average = sum / totalCnt
    return average
  }

  static getStandardDeviation(data, average) {
    if (average === null || data === null || data.length === 0) {
      return null
    }

    const squaredDiff = data.map((value) => Math.pow(value - average, 2))
    const sumSquaredDiff = squaredDiff.reduce((acc, value) => acc + value, 0)
    const variance = sumSquaredDiff / data.length
    const stDev = Math.sqrt(variance)

    return stDev
  }

  static getUcl(average, stDev) {
    if (average == null || stDev === null) {
      return null
    }

    const value = average + 3 * stDev
    return value
  }

  static getLcl(average, stDev) {
    if (average == null || stDev === null) {
      return null
    }

    const value = average - 3 * stDev
    return value
  }

  static getMedian(values) {
    if (!Array.isArray(values) || values.length === 0) {
      return null
    }

    const sortedValues = [...values].sort((a, b) => a - b)
    const midIndex = Math.floor(sortedValues.length / 2)

    if (sortedValues.length % 2 === 0) {
      return (sortedValues[midIndex - 1] + sortedValues[midIndex]) / 2
    } else {
      return sortedValues[midIndex]
    }
  }

  // getProcessCapabilityPrms
  static getAllLimits(char, configSettings) {
    const { warningLimitPercentage, warningLimitNatPercentage, useNaturalBoundary } = configSettings.commonSettings

    const warnOffset = this.getWarningOffset(warningLimitPercentage)
    const warnOffsetNat = this.getWarningOffsetNat(warningLimitNatPercentage)

    const lsl = this.getLsl(char)
    const usl = this.getUsl(char)
    const nominal = this.getNominal(char)
    const gapUslLsl = this.getUslLslGap(usl, lsl)
    const lAwl = this.getLAwl(char)
    const uAwl = this.getUAwl(char)

    let lwl, uwl
    if (usl !== null && lsl !== null) {
      if (this.getTypeUpperWarningLimit(char) === 2 || this.getTypeLowerWarningLimit(char) === 2) {
        if (lsl !== null && lAwl !== null && this.getTypeLowerWarningLimit(char) === 2 && this.getTypeUpperWarningLimit(char) !== 2) {
          lwl = null
          uwl = this.getNaturalBoundaryUwl(usl, gapUslLsl, warnOffsetNat)
        } else if (usl !== null && uAwl !== null && this.getTypeLowerWarningLimit(char) !== 2 && this.getTypeUpperWarningLimit(char) === 2) {
          lwl = this.getNaturalBoundaryLwl(lsl, gapUslLsl, warnOffsetNat)
          uwl = null
        } else if (lsl !== null && lAwl !== null && this.getTypeLowerWarningLimit(char) === 2 && usl !== null && uAwl !== null && this.getTypeUpperWarningLimit(char) === 2) {
          lwl = this.getNaturalBoundaryLwl(lsl, gapUslLsl, warnOffsetNat)
          uwl = this.getNaturalBoundaryUwl(usl, gapUslLsl, warnOffsetNat)
        } else {
          lwl = this.getLwl(lsl, gapUslLsl, warnOffset)
          uwl = this.getUwl(usl, gapUslLsl, warnOffset)
        }
      } else {
        lwl = this.getLwl(lsl, gapUslLsl, warnOffset)
        uwl = this.getUwl(usl, gapUslLsl, warnOffset)
      }
    } else {
      lwl = this.getLwl(lsl, gapUslLsl, warnOffset)
      uwl = this.getUwl(usl, gapUslLsl, warnOffset)
    }

    const lpl = this.getLpl(char)
    const upl = this.getUpl(char)
    const lrl = this.getLrl(char)
    const url = this.getUrl(char)
    const lacl = this.getLAcl(char)
    const uacl = this.getUAcl(char)

    //return
    const prms = { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap: gapUslLsl, warnOffset }

    return prms
  }

  static getAllLimitsNoNat(char, configSettings) {
    const { warningLimitPercentage, warningLimitNatPercentage, useNaturalBoundary } = configSettings.commonSettings

    const warnOffset = this.getWarningOffset(warningLimitPercentage)
    const warnOffsetNat = this.getWarningOffsetNat(warningLimitNatPercentage)

    const lsl = this.getLsl(char)
    const usl = this.getUsl(char)
    const nominal = this.getNominal(char)
    const gapUslLsl = this.getUslLslGap(usl, lsl)
    const lAwl = this.getLAwl(char)
    const uAwl = this.getUAwl(char)

    let lwl, uwl
    if (usl !== null && lsl !== null) {
      if (this.getTypeUpperWarningLimit(char) === 2 || this.getTypeLowerWarningLimit(char) === 2) {
        if (lsl !== null && lAwl !== null && this.getTypeLowerWarningLimit(char) === 2 && this.getTypeUpperWarningLimit(char) !== 2) {
          lwl = null
          uwl = this.getNaturalBoundaryUwl(usl, gapUslLsl, warnOffsetNat)
        } else if (usl !== null && uAwl !== null && this.getTypeLowerWarningLimit(char) !== 2 && this.getTypeUpperWarningLimit(char) === 2) {
          lwl = this.getNaturalBoundaryLwl(lsl, gapUslLsl, warnOffsetNat)
          uwl = null
        } else if (lsl !== null && lAwl !== null && this.getTypeLowerWarningLimit(char) === 2 && usl !== null && uAwl !== null && this.getTypeUpperWarningLimit(char) === 2) {
          lwl = this.getNaturalBoundaryLwl(lsl, gapUslLsl, warnOffsetNat)
          uwl = this.getNaturalBoundaryUwl(usl, gapUslLsl, warnOffsetNat)
        } else {
          lwl = this.getLwl(lsl, gapUslLsl, warnOffset)
          uwl = this.getUwl(usl, gapUslLsl, warnOffset)
        }
      } else {
        lwl = this.getLwl(lsl, gapUslLsl, warnOffset)
        uwl = this.getUwl(usl, gapUslLsl, warnOffset)
      }
    } else {
      lwl = this.getLwl(lsl, gapUslLsl, warnOffset)
      uwl = this.getUwl(usl, gapUslLsl, warnOffset)
    }

    const lpl = this.getLpl(char)
    const upl = this.getUpl(char)
    const lrl = this.getLrl(char)
    const url = this.getUrl(char)
    const lacl = this.getLAcl(char)
    const uacl = this.getUAcl(char)

    //return
    const prms = { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap: gapUslLsl, warnOffset }

    return prms
  }

  static getCalculatedPrms(filteredValue, configSettings) {
    const v0001s = filteredValue.map((value) => value.v0001)
    const { sum, length } = this.getSum(v0001s)
    const average = this.getAverage(sum, length)
    const stdDev = this.getStandardDeviation(v0001s, average)
    const minValue = CCUtils.getMin(v0001s)
    const maxValue = CCUtils.getMax(v0001s)
    const ucl = this.getUcl(average, stdDev)
    const lcl = this.getLcl(average, stdDev)
    // const x99_865 = this.getUcl(average, stdDev)
    // const x0_135 = this.getLcl(average, stdDev)

    const prms = { lcl, ucl, average, minValue, maxValue, v0001s }

    return prms
  }
}

export default ProcessCapabilityUtils
