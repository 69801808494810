import TreeMap, { Export, Tooltip } from 'devextreme-react/tree-map'
import styled from 'styled-components'
import './ScrollBoardTreeMap.scss'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { useTheme } from 'src/contexts/theme'

const StyledTreeMap = styled(TreeMap)`
  width: 100%;
  height: 100%;
`
const getViewPortTheme = (pageKey, theme) => {
  if (pageKey !== 'reportPrint') {
    return theme.includes('dark') || theme.includes('contrast') ? 'dark' : 'light'
  }
  return 'light'
}

const getTileSize = (item, pageState) => {
  if (item.result_ng_count > 0) {
    return pageState.theme.treeMapChartNGRectSize
  } else if (item.result_warning_count > 0) {
    return pageState.theme.treeMapChartWarnRectSize
  }
  return pageState.theme.treeMapChartGoodRectSize
}

const getColor = (item, pageState) => {
  if (item.result_ng_count > 0) {
    return pageState.theme.graphicBadSetting
  } else if (item.result_warning_count > 0) {
    return pageState.theme.graphicWarningSetting
  }
  return pageState.theme.graphicGoodSetting
}

const getState = (item) => {
  if (item.result_ng_count > 0) {
    return 'ng'
  } else if (item.result_warning_count > 0) {
    return 'warn'
  }
  return 'good'
}

function convertDataSource(targetStep, pageState) {
  let converted = []
  targetStep.data.map((item) => {
    let resultId = ''
    let partId = 0
    let charId = 0
    let displayName = ''

    try {
      if (targetStep.field === '2000') {
        const match = item.result_id.match(/\/(.*?)\(\u000f(\d+),(\d+)\)/)
        const [_, description, pValue, cValue] = match
        resultId = match ? match[1] : item.result_id
        partId = pValue
        charId = cValue
      } else {
        resultId = item.result_id
        partId = null
        charId = null
        displayName = item.result_id
      }

      if (resultId === '\u0010') {
        displayName = 'unknown'
      } else if (resultId === '\u0011') {
        displayName = ''
      }
    } catch (error) {
      console.error('Error processing result_id:', error)
      resultId = item.result_id
    }

    converted.push({
      name: resultId,
      part_id: partId,
      char_id: charId,
      displayName: displayName,
      tileSize: getTileSize(item, pageState),
      tileColor: getColor(item, pageState),
      tileState: getState(item),
      totalCount: item.result_total_count,
      goodVisible: pageState.page.chartGoodVisible,
      goodCount: item.result_good_count,
      warningVisible: pageState.page.chartWarningVisible,
      warningCount: item.result_warning_count,
      ngVisible: pageState.page.chartBadVisible,
      ngCount: item.result_ng_count,
    })
  })

  const filtered = converted.filter((item) => {
    if (item.tileState === 'good' && item.goodVisible) {
      return true
    } else if (item.tileState === 'warn' && item.warningVisible) {
      return true
    } else if (item.tileState === 'ng' && item.ngVisible) {
      return true
    }
    return false
  })

  return filtered
}

export default function ScrollBoardTreeMap({ id, style, mode, stepReference, handleNextItemClicked }) {
  const pageState = useSelector((state) => state.common.pages.scrollBoard)
  const { getTheme } = useTheme()

  const curStep = stepReference.curStep
  const stepTotalLen = stepReference.totalSteps
  const tgStep = stepReference.steps.find((item) => item.step === curStep)

  const [dataSource, setDataSource] = useState([])

  useEffect(() => {
    setDataSource(convertDataSource(tgStep, pageState))
  }, [tgStep, pageState])

  const onPointClick = (e) => {
    const lastPageStepIndex = stepReference.totalSteps - 1
    if (stepReference.curStep < lastPageStepIndex) {
      const nextStep = curStep < stepTotalLen ? curStep + 1 : 1
      const targetId = e.node.data.name
      const partId = e.node.data.part_id
      const charId = e.node.data.char_id
      handleNextItemClicked(nextStep, targetId, partId, charId)
    } else {
      console.warn('treemap last page')
      alert('this page is the last')
    }
  }

  return (
    <div id={id} style={style}>
      <StyledTreeMap
        id="treemap"
        dataSource={dataSource}
        // title="Sales Amount by Product"
        // valueField="totalCount"
        valueField="tileSize"
        layoutAlgorithm="squarified"
        layoutDirection="leftTopRightBottom"
        tile={{
          label: {
            font: {
              size: pageState.theme.treeMapChartFontSize,
              color: pageState.theme.treeMapChartFontColorCustomize ? pageState.theme.treeMapChartFontColor : '#ffffff',
            },
            visible: true,
          },
        }}
        colorizer={{
          type: 'discrete',
          colorizeGroups: false,
          palette: dataSource.map((item) => item.tileColor), // Apply the color logic
        }}
        // colorizer={typeOptions}
        onClick={mode === 'presentation' ? null : onPointClick}
      >
        <Export enabled={true} printingEnabled={true} />
        <Tooltip
          enabled={true}
          zIndex={2000}
          paddingLeftRight={0}
          paddingTopBottom={0}
          shadow={{ blur: 0, offsetY: 0 }}
          customizeTooltip={(arg) => {
            const data = arg.node.data

            const total = data.goodCount + data.ngCount + data.warningCount
            const goodPercentage = ((data.goodCount / total) * 100).toFixed(2)
            const ngPercentage = ((data.ngCount / total) * 100).toFixed(2)
            const warningPercentage = ((data.warningCount / total) * 100).toFixed(2)

            return {
              html: `<div style="background-color: black; color: white; padding: 10px; ">
                         <b>Category: ${data.name}</b><br>
                         <div style="display: flex; align-items: center;">
                           <div style="width: 10px; height: 10px; background-color: #ff0000; margin-right: 5px;"></div>
                           <span>NG: ${data.ngCount} (${ngPercentage}%)</span>
                         </div>
                         <div style="display: flex; align-items: center;">
                           <div style="width: 10px; height: 10px; background-color: #ffff00; margin-right: 5px;"></div>
                           <span>Warning: ${data.warningCount} (${warningPercentage}%)</span>
                         </div>
                         <div style="display: flex; align-items: center;">
                           <div style="width: 10px; height: 10px; background-color: #00ff00; margin-right: 5px;"></div>
                           <span>Good: ${data.goodCount} (${goodPercentage}%)</span>
                         </div>
                       </div>`,
            }
          }}
        />
      </StyledTreeMap>
    </div>
  )
}
