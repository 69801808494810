import styled from 'styled-components'
import { Button } from 'devextreme-react'
import { useState } from 'react'

const StyledButton = styled(Button)`
  width: 100%;
  height: 100%;

  & .dx-button-content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .dx-icon {
    width: 70%; // 자동 너비 조정
    height: 70%; // 자동 높이 조정
  }

  & .dx-button-text {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    text-align: center;
    transition: opacity 0.3s;
    opacity: 1;
  }
`

export default function ControlPanel(props) {
  const { className, id, data, configPage, icon, text, onClick, disabled, setDisableSidePanel } = props
  const [buttonState, setButtonState] = useState(false)

  return (
    <div className={className}>
      <StyledButton
        // theme={{
        //   imgUrl: buttonState === false ? icon.before : icon.after,
        // }}
        icon={buttonState ? icon.after : icon.before}
        text={text}
        disabled={disabled}
        focusStateEnabled={false}
        onClick={(e) => {
          if (buttonState) {
            // state start
            setButtonState(false)
            setDisableSidePanel(false)
            onClick(e, false)
          } else {
            // state stop
            setButtonState(true)
            setDisableSidePanel(true)
            onClick(e, true)
          }
        }}
      />
    </div>
  )
}
