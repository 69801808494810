// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dx-theme-background-color {
  width: 100%;
  height: 100%;
  padding: 5px;
  display: flex;
}
.dx-theme-background-color .database-part-container {
  width: 50%;
  height: 100%;
}
.dx-theme-background-color .database-char-container {
  width: 50%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/pages/database/components/main-view/DatabaseForm.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,YAAA;EACA,aAAA;AACF;AACE;EACE,UAAA;EACA,YAAA;AACJ;AACE;EACE,UAAA;EACA,YAAA;AACJ","sourcesContent":[".dx-theme-background-color {\r\n  width: 100%;\r\n  height: 100%;\r\n  padding: 5px;\r\n  display: flex;\r\n\r\n  .database-part-container {\r\n    width: 50%;\r\n    height: 100%;\r\n  }\r\n  .database-char-container {\r\n    width: 50%;\r\n    height: 100%;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
