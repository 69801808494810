// setToDB
import produce from 'immer'
import * as dbUser from 'src/api/user'
import { loadUserFromLS } from './dq-local-storage/DQLocalStorageImpl'
import { setAllPages, setConfiguration, setQSense } from 'src/store/common'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'

// **
// User APIs
export function getCurUser() {
  const user = loadUserFromLS()
  return user
}

// get APIs
export function getCurLicenseSettings(selector, userData) {
  const data = selector((state) => state.common.license)
  return data
}

export function getCurLanguageSettings(selector, userData) {
  const data = selector((state) => state.common.pages.configuration.language)
  return data
}

export function getCurBaseSettings(selector, userData) {
  const configAll = selector((state) => state.common.base)
  const configPage = configAll['configuration']

  return { configAll, configPage }
}
export function getCurPageSettings(selector, userData, pageKey) {
  const pageAll = selector((state) => state.common.pages)
  const curPage = pageAll[pageKey]
  return { pageAll, curPage }
}

// set APIs
function updateDQSettings(pageKey, curPage, updatePageData) {
  const next = produce(curPage, (draft) => {
    Object.entries(updatePageData).forEach(([key, value]) => {
      draft[key] = value
    })
  })
  return next
}

export function setCurQSenseSettings(dispatch, user, state, pageKey, updateData) {
  const next = produce(state, (draft) => {
    draft.encoding.part_key_field = updateData.encoding.part_key_field
    draft.encoding.char_key_field = updateData.encoding.char_key_field
  })

  const config = next

  ps[pageKey].updateUserSettingToDB({
    userId: user.user_id,
    updateData: JSON.stringify(config),
  })
  dispatch(ps[pageKey].setPageSettingsToRedux(config))
}

export function setCurBaseSettings(dispatch, user, state, pageKey, updateData) {
  const config = updateData // updateDQSettings(pageKey, state, updateData)

  ps[pageKey].updateUserSettingToDB({
    userId: user.user_id,
    updateData: JSON.stringify(config),
  })
  dispatch(ps[pageKey].setPageSettingsToRedux(config))
}

export async function setCurPageSettings(dispatch, user, pageKey, updateData) {
  try {
    const pageSettings = updateData

    await ps[pageKey].updateUserSettingToDB({
      userId: user.user_id,
      updateData: JSON.stringify(pageSettings),
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(pageSettings))
  } catch (error) {
    console.error('Error updating page settings:', error)
  }
}
