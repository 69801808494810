export const defMeasuredQuantity = [
  { value: 0, MeasureQuantity: 'Undefined', En: 'Undefined', Ja: '未定義', Kr: '정의되지않은' },
  { value: 100, MeasureQuantity: 'Straightness', En: 'Straightness', Ja: '直線度', Kr: '진직도' },
  { value: 101, MeasureQuantity: 'Flatness', En: 'Flatness', Ja: '平面度', Kr: '평평도' },
  { value: 102, MeasureQuantity: 'Roundness', En: 'Roundness', Ja: '真円度', Kr: '진원도' },
  { value: 103, MeasureQuantity: 'Cylindricity', En: 'Cylindricity', Ja: '円筒度', Kr: '원통도' },
  { value: 104, MeasureQuantity: 'Profile of a line', En: 'Profile of a line', Ja: '断面（直線）', Kr: '선형상' },
  { value: 105, MeasureQuantity: 'Profile of a surface', En: 'Profile of a surface', Ja: '表面（形状）', Kr: '면적형상' },
  { value: 106, MeasureQuantity: 'Angularity', En: 'Angularity', Ja: '直角度', Kr: '경사도' },
  { value: 107, MeasureQuantity: 'Perpendicularity', En: 'Perpendicularity', Ja: '垂直度', Kr: '직사각형' },
  { value: 108, MeasureQuantity: 'Parallelism', En: 'Parallelism', Ja: '平行度', Kr: '평행도' },
  { value: 109, MeasureQuantity: 'True position', En: 'True position', Ja: '真の位置（値）', Kr: '진위치도(값)' },
  { value: 110, MeasureQuantity: 'Concentricity', En: 'Concentricity', Ja: '同心度', Kr: '동심도' },
  { value: 111, MeasureQuantity: 'Symmetry', En: 'Symmetry', Ja: '対称度', Kr: '대칭도' },
  { value: 112, MeasureQuantity: 'Runout', En: 'Runout', Ja: '芯振れ', Kr: '진도' },
  { value: 113, MeasureQuantity: 'Total runout', En: 'Total runout', Ja: '全体の芯振れ', Kr: '진원도' },
  { value: 114, MeasureQuantity: 'Comp Coaxial', En: 'Comp Coaxial', Ja: '複合同軸性', Kr: '동축도' },
  { value: 115, MeasureQuantity: 'Comp Pattern', En: 'Comp Pattern', Ja: '複合パターン', Kr: '패턴' },
  { value: 117, MeasureQuantity: 'Coordinate', En: 'Coordinate', Ja: '座標', Kr: '좌표' },
  { value: 118, MeasureQuantity: 'Surface runout', En: 'Surface runout', Ja: '端面振れ', Kr: '표면진도' },
  { value: 120, MeasureQuantity: 'X coordinate', En: 'X coordinate', Ja: 'X座標', Kr: 'X좌표' },
  { value: 121, MeasureQuantity: 'Y coordinate', En: 'Y coordinate', Ja: 'Y座標', Kr: 'Y좌표' },
  { value: 122, MeasureQuantity: 'Z coordinate', En: 'Z coordinate', Ja: 'Z座標', Kr: 'Z좌표' },
  { value: 125, MeasureQuantity: 'Offset', En: 'Offset', Ja: 'オフセット', Kr: '오프셋' },
  { value: 132, MeasureQuantity: 'Ovality', En: 'Ovality', Ja: '楕円', Kr: '난형' },
  { value: 140, MeasureQuantity: 'Appraisal number angle area', En: 'Appraisal number angle area', Ja: '角度評価番号', Kr: '작업자수 각도영역' },
  // 수정필요부분
  { value: 145, MeasureQuantity: 'Surface finish', En: 'Surface finish', Ja: '表面仕上げ', Kr: '표면 끝손질' },
  { value: 149, MeasureQuantity: 'Excavation depth', En: 'Excavation depth', Ja: '掘削深さ', Kr: '굴착 깊이' },
  { value: 150, MeasureQuantity: 'Max. profile height Rz', En: 'Max. profile height Rz', Ja: '最大表面粗さRz', Kr: '최대프로파일 높이 ( Rz)' },
  { value: 151, MeasureQuantity: 'Total profile height Rt', En: 'Total profile height Rt', Ja: '全表面粗さRt', Kr: '총 프로파일 높이 ( Rt)' },
  { value: 152, MeasureQuantity: 'deviation assessed prof. Ra', En: 'deviation assessed prof. Ra', Ja: '表面粗さRaを評価した偏差', Kr: '채택된 프로파일의 차이 (Ra)' },
  { value: 153, MeasureQuantity: 'Max. profile height Pt', En: 'Max. profile height Pt', Ja: '最大表面粗さ点Pt', Kr: '최대프로파일 높이 (Pt)' },
  { value: 154, MeasureQuantity: 'Profile height Point', En: 'Profile height Point', Ja: '表面粗さ点', Kr: '프로파일 높이 Pt.' },
  { value: 155, MeasureQuantity: '프로파일 높이 Pt.', En: '프로파일 높이 Pt.', Ja: '中心の高さを減少', Kr: '줄어든 중앙 높이' },
  { value: 156, MeasureQuantity: 'Red. score depth', En: 'Red. score depth', Ja: 'スコアの深さを減少', Kr: '줄어든 낮은 깊이' },
  { value: 157, MeasureQuantity: 'Profile Wave depth Wt', En: 'Profile Wave depth Wt', Ja: '表面形状深さWt', Kr: '표면 wave 깊이  Wt' },
  { value: 158, MeasureQuantity: 'Maximum roughness depth', En: 'Maximum roughness depth', Ja: '最大表面粗さ深さ', Kr: '최대 거칠기 깊이' },
  { value: 159, MeasureQuantity: 'Basic roughness depth', En: 'Basic roughness depth', Ja: '基本表面粗さ深さ', Kr: '기본 거칠기 깊이' },
  { value: 160, MeasureQuantity: 'Material proportion Pmr', En: 'Material proportion Pmr', Ja: '材料比率Pmr', Kr: '소재비율 Pmr' },
  { value: 161, MeasureQuantity: 'Material proportion Mr1', En: 'Material proportion Mr1', Ja: '材料比率Mr1', Kr: '소재비율 Mr1' },
  { value: 162, MeasureQuantity: 'Material proportion Mr2', En: 'Material proportion Mr2', Ja: '材料比率Mr2', Kr: '소재비율 Mr2' },
  { value: 170, MeasureQuantity: 'Swirl depth', En: 'Swirl depth', Ja: '渦(Swirl)深さ', Kr: '스훨 깊이' },
  { value: 171, MeasureQuantity: 'Swirl angle', En: 'Swirl angle', Ja: '渦(Swirl)角度', Kr: '스훨 각도' },
  { value: 172, MeasureQuantity: 'Swirl pitch', En: 'Swirl pitch', Ja: '渦(Swirl)ピッチ', Kr: '스훨 피치' },
  { value: 180, MeasureQuantity: 'average dominant waviness', En: 'average dominant waviness', Ja: '平均のうねり', Kr: '평균 웨이브' },
  { value: 181, MeasureQuantity: 'max. dominant waviness', En: 'max. dominant waviness', Ja: '最大うねり', Kr: '최대 웨이브' },
  { value: 182, MeasureQuantity: 'Length of the dominant waviness', En: 'Length of the dominant waviness', Ja: 'うねりの長さ', Kr: '웨이브 길이' },
  { value: 190, MeasureQuantity: 'Mean depth of roughness motifs', En: 'Mean depth of roughness motifs', Ja: '粗さの平均深さ', Kr: '거칠기 평균 심도' },
  { value: 191, MeasureQuantity: 'Max. depth of profile irregularity', En: 'Max. depth of profile irregularity', Ja: '粗さの平均幅', Kr: '프로필 불규칙 최대 심도' },
  { value: 192, MeasureQuantity: 'Mean width of roughness motifs', En: 'Mean width of roughness motifs', Ja: '凹凸形状の最大深さ', Kr: '거칠기 평균 폭' },
  { value: 193, MeasureQuantity: 'Material proportion Rmr', En: 'Material proportion Rmr', Ja: '材料の割合　Rmr', Kr: '재료 비율 RMR' },
  { value: 194, MeasureQuantity: 'Material proportion tp', En: 'Material proportion tp', Ja: '材料の割合　tp', Kr: '재료 비율 tp' },
  { value: 200, MeasureQuantity: 'Distance', En: 'Distance', Ja: '距離', Kr: '길이 단위' },
  { value: 201, MeasureQuantity: 'Radius', En: 'Radius', Ja: '半径', Kr: '반경' },
  { value: 202, MeasureQuantity: 'Diameter', En: 'Diameter', Ja: '直径', Kr: '직경' },
  { value: 203, MeasureQuantity: 'Angle', En: 'Angle', Ja: '角度', Kr: '각도' },
  { value: 204, MeasureQuantity: 'Ellipsis minor Ø', En: 'Ellipsis minor Ø', Ja: '楕円の短径 Ø', Kr: '타원 단축  Ø' },
  { value: 205, MeasureQuantity: 'Ellipsis major Ø', En: 'Ellipsis major Ø', Ja: '楕円の長径 Ø', Kr: '타원 장축  Ø' },
  { value: 206, MeasureQuantity: 'Cone angle', En: 'Cone angle', Ja: '円錐の角度', Kr: '원뿔각도 (Cone angle)' },
  { value: 207, MeasureQuantity: 'Inside diameter', En: 'Inside diameter', Ja: '内径', Kr: '내경' },
  { value: 208, MeasureQuantity: 'Outside diameter', En: 'Outside diameter', Ja: '外径', Kr: '외경' },
  { value: 210, MeasureQuantity: 'Spherical measuring rod', En: 'Spherical measuring rod', Ja: '球状の測定ロッド', Kr: '구면 측정 봉' },
  { value: 211, MeasureQuantity: 'Depth / height of tooth', En: 'Depth / height of tooth', Ja: '歯車の深さ/高さ', Kr: '치차 깊이 / 높이' },
  { value: 212, MeasureQuantity: 'Tooth thickness on the reference cylinder', En: 'Tooth thickness on the reference cylinder', Ja: '基準シリンダーの歯の厚さ', Kr: '기준 실린더의 치아두께' },
  { value: 214, MeasureQuantity: 'Tooth thickness deviation (on the reference cylinder)', En: 'Tooth thickness deviation (on the reference cylinder)', Ja: '歯の厚さの偏り（基準シリンダーの）', Kr: '치아 두께 편차 (기준 실린더 위의)' },
  { value: 215, MeasureQuantity: 'Tooth thickness fluctuation', En: 'Tooth thickness fluctuation', Ja: '歯の厚さの変動', Kr: '치아 두께 변동' },
  { value: 216, MeasureQuantity: 'Span distance (over k teeth or gaps)', En: 'Span distance (over k teeth or gaps)', Ja: 'スパン距離（k個の歯あるいはギャップに渡って）', Kr: '경간 거리(K톱니 이상 또는 간격)' },
  { value: 218, MeasureQuantity: 'Secondary Gear Characteristic', En: 'Secondary Gear Characteristic', Ja: '部首的な縁の特性', Kr: '특성의 두번째 기어' },
  { value: 220, MeasureQuantity: 'Spring rate', En: 'Spring rate', Ja: 'ばね係数', Kr: '상승 비율' },
  { value: 230, MeasureQuantity: 'Width', En: 'Width', Ja: '広さ', Kr: '너비' },
  { value: 231, MeasureQuantity: 'Squareness', En: 'Squareness', Ja: '2本の基準軸がある直角', Kr: '직각' },
  { value: 232, MeasureQuantity: 'Diameter maximum', En: 'Diameter maximum', Ja: '最大直径', Kr: '직경 최대' },
  { value: 233, MeasureQuantity: 'Diameter minimum', En: 'Diameter minimum', Ja: '最小直径', Kr: '직경 최소' },
  { value: 234, MeasureQuantity: 'Diameter average', En: 'Diameter average', Ja: '平均直径', Kr: '직경 평균' },
  { value: 250, MeasureQuantity: 'Temperature [°C]', En: 'Temperature [°C]', Ja: '温度 [°C]', Kr: '온도 [°C]' },
  { value: 251, MeasureQuantity: 'Temperature [°F]', En: 'Temperature [°F]', Ja: '温度 [°F]', Kr: '온도 [°F]' },
  { value: 255, MeasureQuantity: 'Pressure [bar]', En: 'Pressure [bar]', Ja: '圧力[バール]', Kr: '압력 [bar]' },
  { value: 260, MeasureQuantity: 'Coating thickness', En: 'Coating thickness', Ja: 'コーティング厚さ', Kr: '코팅 두께' },
  { value: 270, MeasureQuantity: 'Volume', En: 'Volume', Ja: '容量', Kr: '부피' },
  { value: 275, MeasureQuantity: 'Area', En: 'Area', Ja: '表面', Kr: '구역' },
  { value: 280, MeasureQuantity: 'Mass', En: 'Mass', Ja: '質量', Kr: '질량' },
  { value: 282, MeasureQuantity: 'Force', En: 'Force', Ja: '力', Kr: '강제' },
  { value: 285, MeasureQuantity: 'Hardness', En: 'Hardness', Ja: '硬度', Kr: '경도' },
  { value: 290, MeasureQuantity: 'Viscosity', En: 'Viscosity', Ja: '粘度', Kr: '점성' },
  { value: 292, MeasureQuantity: 'Density', En: 'Density', Ja: '密度', Kr: '밀도' },
  { value: 300, MeasureQuantity: 'Unbalance', En: 'Unbalance', Ja: '不安定', Kr: '불균형' },
  { value: 301, MeasureQuantity: 'Torque', En: 'Torque', Ja: 'トルク', Kr: '토크' },
  { value: 302, MeasureQuantity: 'Tightening torque', En: 'Tightening torque', Ja: '締付けトルク', Kr: '조임 토크' },
  { value: 303, MeasureQuantity: 'Additional torque', En: 'Additional torque', Ja: '追加トルク', Kr: '추가 토크' },
  { value: 310, MeasureQuantity: '2D note', En: '2D note', Ja: '2Dノート', Kr: '2D 노트' },
  { value: 311, MeasureQuantity: '3D note', En: '3D note', Ja: '3Dノート', Kr: '3D 노트' },
  { value: 320, MeasureQuantity: 'Rotation angle', En: 'Rotation angle', Ja: '回転角度', Kr: '회전 각도' },
  { value: 350, MeasureQuantity: 'Revolution speed', En: 'Revolution speed', Ja: '回転速度', Kr: '회전속도' },
  { value: 360, MeasureQuantity: 'Angle error', En: 'Angle error', Ja: '角度誤差', Kr: '각도 오류' },
  { value: 362, MeasureQuantity: 'Profile error', En: 'Profile error', Ja: '輪郭誤差', Kr: '프로필 오류' },
  { value: 364, MeasureQuantity: 'Deviation from rated speed', En: 'Deviation from rated speed', Ja: '値つけられたスピードからの偏差', Kr: '정격 속도의 편차' },
  { value: 370, MeasureQuantity: 'Form deviation', En: 'Form deviation', Ja: '外径の偏差', Kr: '양식 편차' },
  { value: 372, MeasureQuantity: 'Form increase', En: 'Form increase', Ja: '外径の増加', Kr: '양식 증가' },
  { value: 380, MeasureQuantity: 'Cam height', En: 'Cam height', Ja: 'カムの高さ', Kr: '캠 높이' },
  { value: 400, MeasureQuantity: 'Reference level', En: 'Reference level', Ja: '基準面', Kr: '기준 레벨' },
  { value: 401, MeasureQuantity: 'Reference axis', En: 'Reference axis', Ja: '基準軸', Kr: '기준 축' },
  { value: 402, MeasureQuantity: 'Reference points', En: 'Reference points', Ja: '基準点', Kr: '기준 점' },
  { value: 403, MeasureQuantity: 'Ref.coord.system', En: 'Ref.coord.system', Ja: '基準座標系', Kr: '기준 좌표계' },
  { value: 501, MeasureQuantity: 'Resistance', En: 'Resistance', Ja: '抵抗', Kr: '저항 (Resistance)' },
  { value: 502, MeasureQuantity: 'Capacity', En: 'Capacity', Ja: '容量', Kr: '충전능력 (Capacity)' },
  { value: 503, MeasureQuantity: 'Inductivity', En: 'Inductivity', Ja: '誘導値', Kr: '유도능력 (Inductivity)' },
  { value: 504, MeasureQuantity: 'Phase shift', En: 'Phase shift', Ja: '位相シフト', Kr: '상전환(phase shift)' },
  { value: 505, MeasureQuantity: 'Frequency', En: 'Frequency', Ja: '周波数', Kr: '주기' },
  { value: 506, MeasureQuantity: 'Amperage', En: 'Amperage', Ja: '電流値', Kr: '암페아' },
  { value: 507, MeasureQuantity: 'Voltage', En: 'Voltage', Ja: '電圧', Kr: '볼트 (Voltage)' },
  { value: 508, MeasureQuantity: 'Output', En: 'Output', Ja: '電力', Kr: '출력 (Output)' },
  { value: 509, MeasureQuantity: 'Field intensity', En: 'Field intensity', Ja: '電界/磁界強度', Kr: '필드 강도(field intensity)' },
  { value: 540, MeasureQuantity: 'Misc. ND', En: 'Misc. ND', Ja: 'Misc.ND', Kr: 'Misc. ND' },
  { value: 541, MeasureQuantity: 'Misc. B1', En: 'Misc. B1', Ja: 'Misc. B1', Kr: 'Misc. B1' },
  { value: 542, MeasureQuantity: 'Misc. B2', En: 'Misc. B2', Ja: 'Misc. B2', Kr: 'Misc. B2' },
  { value: 601, MeasureQuantity: 'Pitch', En: 'Pitch', Ja: '歯車ピッチ', Kr: '피치' },
  { value: 602, MeasureQuantity: 'Pitch error', En: 'Pitch error', Ja: 'ピッチ誤差', Kr: '피치 에러' },
  { value: 604, MeasureQuantity: 'Cumulative pitch bias', En: 'Cumulative pitch bias', Ja: '累積ピッチバイアス', Kr: '누적 피치에러' },
  { value: 605, MeasureQuantity: 'Cumulative pitch error', En: 'Cumulative pitch error', Ja: '累積ピッチ誤差', Kr: '누적 피치에러' },
  { value: 606, MeasureQuantity: 'Pitch fluctuation', En: 'Pitch fluctuation', Ja: 'ピッチの変動', Kr: '피치 변동' },
  { value: 607, MeasureQuantity: 'Total pitch error', En: 'Total pitch error', Ja: '全ピッチ誤差', Kr: '총 피치 에러' },
  { value: 608, MeasureQuantity: 'Base pitch variation', En: 'Base pitch variation', Ja: 'ベースピッチの変動', Kr: '기본 피치 변동' },
  { value: 609, MeasureQuantity: 'Axial pitch variation', En: 'Axial pitch variation', Ja: '軸ピッチの変動', Kr: '축 피치 편동' },
  { value: 610, MeasureQuantity: 'Tip diameter', En: 'Tip diameter', Ja: '先端の外径', Kr: '팁 직경' },
  { value: 612, MeasureQuantity: 'Root diamater', En: 'Root diamater', Ja: '底部の外径', Kr: '루트 직경' },
  { value: 617, MeasureQuantity: 'Space width on the reference cylinder', En: 'Space width on the reference cylinder', Ja: '基準シリンダーの間隔幅', Kr: '기준 실린더 위의 공간 폭' },
  { value: 620, MeasureQuantity: 'Line', En: 'Line', Ja: '歯スジ(Line)', Kr: '라인' },
  { value: 621, MeasureQuantity: 'Formal line error', En: 'Formal line error', Ja: '歯スジ誤差', Kr: '공식적인 라인 에러' },
  { value: 630, MeasureQuantity: 'Tooth profile', En: 'Tooth profile', Ja: '歯形', Kr: '프로파일 ' },
  { value: 631, MeasureQuantity: 'Formal profile error', En: 'Formal profile error', Ja: '歯形誤差', Kr: '공식적인 프로파일 에러' },
  { value: 632, MeasureQuantity: 'Profile angle bias', En: 'Profile angle bias', Ja: '歯形角度誤差', Kr: '프로파일 각도 에러' },
  { value: 633, MeasureQuantity: 'Profile wobble', En: 'Profile wobble', Ja: '歯形の揺れ(wobble)', Kr: '편평한 정도에 대한  프로파일' },
  { value: 640, MeasureQuantity: 'Tip relief', En: 'Tip relief', Ja: '歯先修正', Kr: '팁 릴리프' },
  { value: 641, MeasureQuantity: 'Profile crowning', En: 'Profile crowning', Ja: '歯形クラウニング(冠）', Kr: '크라운닝에 대한 프로파일' },
  { value: 642, MeasureQuantity: 'Crowning', En: 'Crowning', Ja: 'クラウニング', Kr: '크라운닝' },
  { value: 643, MeasureQuantity: 'Crowning height', En: 'Crowning height', Ja: 'クラウニング高さ', Kr: '크라운닝 높이' },
  { value: 651, MeasureQuantity: 'Flank line angle bias', En: 'Flank line angle bias', Ja: '歯形調整誤差', Kr: '치형 얼라인 에러' },
  { value: 652, MeasureQuantity: 'Flank line wobble', En: 'Flank line wobble', Ja: '歯形調整', Kr: '치형 얼라인' },
  { value: 660, MeasureQuantity: 'Radial run-out', En: 'Radial run-out', Ja: '半径方向のはみ出し(run-out)', Kr: '반경 진도' },
  { value: 661, MeasureQuantity: 'Eccentricity', En: 'Eccentricity', Ja: '偏心', Kr: '이심률' },
  { value: 662, MeasureQuantity: 'Wobble', En: 'Wobble', Ja: '揺れ(Wobble)', Kr: '편평도' },
  { value: 663, MeasureQuantity: 'Coaxiality', En: 'Coaxiality', Ja: '同軸度', Kr: '동축도' },
  { value: 670, MeasureQuantity: 'Two-flank working variation', En: 'Two-flank working variation', Ja: '2つの側面の働きの変動', Kr: 'Two-flank 작업 변동' },
  { value: 671, MeasureQuantity: 'Two-flank tooth-to-tooth radial composite deviation', En: 'Two-flank tooth-to-tooth radial composite deviation', Ja: '歯から歯までの2つの側面の軸方向の偏差', Kr: 'Two-flank 합성 반지름 합성 편차' },
  { value: 672, MeasureQuantity: 'Contact runout deviation', En: 'Contact runout deviation', Ja: '接触終了の偏差', Kr: '런아웃 편차 접촉' },
  { value: 673, MeasureQuantity: 'Diametrical two-ball dimension', En: 'Diametrical two-ball dimension', Ja: '2つのボールの直径の寸法', Kr: '직경 two-ball 특성' },
  { value: 674, MeasureQuantity: 'Diametrical two-roll dimension', En: 'Diametrical two-roll dimension', Ja: '2つの円筒の直径の寸法', Kr: '직경 two-roll 특성' },
  { value: 675, MeasureQuantity: 'Radial one-ball dimension', En: 'Radial one-ball dimension', Ja: '1つのボールの半径の寸法', Kr: '반지름 one-ball 특성' },
  { value: 676, MeasureQuantity: 'Radial one-roll dimension', En: 'Radial one-roll dimension', Ja: '1つの円筒の半径の寸法', Kr: '반지름 one-roll 특성' },
  { value: 701, MeasureQuantity: 'Special characteristic', En: 'Special characteristic', Ja: '特殊な測定特性', Kr: '특수 측정항목' },
  { value: 702, MeasureQuantity: 'DwsA characteristic', En: 'DwsA characteristic', Ja: 'DwsA測定特性', Kr: 'DwsA 측정항목' },
  { value: 703, MeasureQuantity: 'S characteristic', En: 'S characteristic', Ja: 'S測定特性', Kr: 'S 측정항목' },
  { value: 704, MeasureQuantity: 'R characteristic', En: 'R characteristic', Ja: 'R測定特性', Kr: 'R 측정항목' },
  { value: 705, MeasureQuantity: 'R&S characteristic', En: 'R&S characteristic', Ja: 'R&S測定特性', Kr: 'R&S 측정항목' },
  { value: 711, MeasureQuantity: 'BP 1', En: 'BP 1', Ja: 'BP 1', Kr: 'BP 1' },
  { value: 712, MeasureQuantity: 'BP 2', En: 'BP 2', Ja: 'BP 2', Kr: 'BP 2' },
  { value: 713, MeasureQuantity: 'BP 1 SPC', En: 'BP 1 SPC', Ja: 'BP 1 SPC', Kr: 'BP 1 SPC' },
  { value: 714, MeasureQuantity: 'BP 2 SPC', En: 'BP 2 SPC', Ja: 'BP 2 SPC', Kr: 'BP 2 SPC' },
  { value: 800, MeasureQuantity: 'Time', En: 'Time', Ja: '時刻', Kr: '시간' },
  { value: 805, MeasureQuantity: 'Number', En: 'Number', Ja: '番号', Kr: '번호' },
  { value: 810, MeasureQuantity: 'Percentage', En: 'Percentage', Ja: '百分率', Kr: '퍼센트' },
  { value: 820, MeasureQuantity: 'Noise', En: 'Noise', Ja: '雑音（ノイズ）', Kr: '소음' },
  { value: 910, MeasureQuantity: 'Leak rate', En: 'Leak rate', Ja: '漏れ速度', Kr: '누설량' },
  { value: 950, MeasureQuantity: 'Cleanliness of component', En: 'Cleanliness of component', Ja: '部品のクリーン度', Kr: '부품 청결도' },
  { value: 955, MeasureQuantity: 'Remaining particles', En: 'Remaining particles', Ja: '残留粒子', Kr: '남은 입자' },
  { value: 1001, MeasureQuantity: 'Volume porosity global', En: 'Volume porosity global', Ja: '体積孔隙率グローバル', Kr: '체적 공극률 전체' },
  { value: 1002, MeasureQuantity: 'Volume porosity ROI', En: 'Volume porosity ROI', Ja: '体積孔隙率ROI', Kr: '체적 공극률 ROI' },
  { value: 1003, MeasureQuantity: 'Volume porosity geo', En: 'Volume porosity geo', Ja: '体積孔隙率geo', Kr: '체적 공극률 geo' },
  { id: 142, value: 1004, MeasureQuantity: 'Single pore volume', En: 'Single pore volume', Ja: '単一の孔容積', Kr: '단일 기공 부피' },
  { value: 1005, MeasureQuantity: 'Pore diameter', En: 'Pore diameter', Ja: '孔径', Kr: '기공 지름' },
  { value: 1006, MeasureQuantity: 'Equivalent pore diameter', En: 'Equivalent pore diameter', Ja: '等価な孔径', Kr: '등가 기공 지름' },
  { value: 1007, MeasureQuantity: 'Sphericity', En: 'Sphericity', Ja: '球形', Kr: '구형성' },
  { value: 1008, MeasureQuantity: 'Compactness', En: 'Compactness', Ja: '緊密さ', Kr: '다짐도' },
  { value: 1009, MeasureQuantity: 'Distance of adjacent pores', En: 'Distance of adjacent pores', Ja: '隣接した孔の距離', Kr: '근접 기공 거리' },
  { value: 1010, MeasureQuantity: 'Distance to surface', En: 'Distance to surface', Ja: '表面への距離', Kr: '표면까지의 거리' },
  { value: 1011, MeasureQuantity: 'Number of permitted pores', En: 'Number of permitted pores', Ja: '許可される孔の数', Kr: '허용되는 기공 수' },
]
