import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { batch, useDispatch } from 'react-redux'
import { Navigate, Route, Routes } from 'react-router-dom'
import { authLogin } from 'src/api/jwt'
import { getUserInfo } from 'src/api/user'

import { LoadPanel } from 'devextreme-react'
import { useNavigate } from 'react-router-dom'
import ApiStatus from 'src/api/defines/ApiStatus'
import * as dtoUtils from 'src/dto/DTOImpl'
import JwtUtils from 'src/functions/JwtUtils'
import { convertUserRoleToString } from 'src/functions/constants/authLevelConstants'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as rd from 'src/store/common'
import { getCurUser } from 'src/pages/components/dq-settings/DQSettingsImpl'
import { getErrCommMsg, getErrProcMsg } from 'src/functions/CommonMassageUtils'
// import { useLanguage } from 'src/contexts/languages'

function AuthProvider({ children }) {
  const [user, setUser] = useState()
  const [loading, setLoading] = useState(true)
  // const { language, switchLanguage } = useLanguage()

  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    setLoading(true)

    const at = JwtUtils.loadToken()
    if (!at) {
      setLoading(false)
      return
    }

    const decoded = JwtUtils.parseJwtToken(at)

    if (JwtUtils.isTokenExpired(decoded)) {
      // if (JwtUtils.isTokenExpiredTest(decoded)) {
      setUser(undefined)
      setLoading(false)
      JwtUtils.removeAccessToken()
      alert(getErrProcMsg(500))
      return (
        <Routes>
          <Route path="*" element={<Navigate to={'/login'} />}></Route>
        </Routes>
      )
    }

    getUserInfo(decoded.userId).then((result) => {
      if (!ApiStatus.isSuccess(result.status)) {
        alert(getErrCommMsg(result.status))
        setUser(undefined)
        setLoading(false)
        JwtUtils.removeAccessToken()
        return (
          <Routes>
            <Route path="*" element={<Navigate to={'/login'} />}></Route>
          </Routes>
        )
      }

      const userInfo = result.data.user_info
      const uiDTO = dtoUtils.makeUserInfoToDTOFormat(userInfo)
      const updatedUserInfo = {
        ...uiDTO,
        user_role: convertUserRoleToString(uiDTO.user_role),
      }
      setUser(updatedUserInfo)

      // JSON 파싱을 위한 헬퍼 함수
      const parseJSONOrDefault = (data, defaultVal) => (data ? JSON.parse(data) : defaultVal)

      const overviewSet = parseJSONOrDefault(uiDTO.chart_config_1, ps.overview.settings)
      const monitoringSet = parseJSONOrDefault(uiDTO.chart_config_2, ps.monitoring.settings)
      const databaseSet = parseJSONOrDefault(uiDTO.chart_config_3, ps.database.settings)
      const valueChartSet = parseJSONOrDefault(uiDTO.chart_config_4, ps.valueChart.settings)
      const histogramChartSet = parseJSONOrDefault(uiDTO.chart_config_5, ps.histogramChart.settings)
      const positionalTolerancesChartSet = parseJSONOrDefault(uiDTO.chart_config_6, ps.positionalTolerancesChart.settings)
      const boxPlotChartSet = parseJSONOrDefault(uiDTO.chart_config_7, ps.boxPlotChart.settings)
      const linearRegressionChartSet = parseJSONOrDefault(uiDTO.chart_config_8, ps.linearRegressionChart.settings)
      const correlationChartSet = parseJSONOrDefault(uiDTO.chart_config_9, ps.correlationChart.settings)
      const qualityControlChartSet = parseJSONOrDefault(uiDTO.chart_config_10, ps.qualityControlChart.settings)
      const capabilitySummaryChartSet = parseJSONOrDefault(uiDTO.chart_config_11, ps.capabilitySummaryChart.settings)
      const paretoChartSet = parseJSONOrDefault(uiDTO.chart_config_12, ps.paretoChart.settings)
      const probabilityPlotChartSet = parseJSONOrDefault(uiDTO.chart_config_13, ps.probabilityPlotChart.settings)
      const decisionTreeChartSet = parseJSONOrDefault(uiDTO.chart_config_14, ps.decisionTreeChart.settings)
      const valueListSet = parseJSONOrDefault(uiDTO.chart_config_15, ps.valueList.settings)
      const summaryListSet = parseJSONOrDefault(uiDTO.chart_config_16, ps.summaryList.settings)
      const valueSummaryListSet = parseJSONOrDefault(uiDTO.chart_config_17, ps.valueSummaryList.settings)
      const scrollBoardSet = parseJSONOrDefault(uiDTO.chart_config_18, ps.scrollBoard.settings)
      const configurationSet = parseJSONOrDefault(uiDTO.chart_config_19, ps.configuration.settings)
      const adminSettingsSet = parseJSONOrDefault(uiDTO.chart_config_20, ps.adminSettings.settings)
      const reportPrintMultiFunctionSet = parseJSONOrDefault(uiDTO.chart_config_21, ps.reportPrintMultiFunction.settings)

      const langType = uiDTO.language || 'en'
      batch(() => {
        dispatch(rd.setMode('sign-in'))
        dispatch(ps.overview.setPageSettingsToRedux(overviewSet))
        dispatch(ps.monitoring.setPageSettingsToRedux(monitoringSet))
        dispatch(ps.database.setPageSettingsToRedux(databaseSet))
        dispatch(ps.valueChart.setPageSettingsToRedux(valueChartSet))
        dispatch(ps.histogramChart.setPageSettingsToRedux(histogramChartSet))
        dispatch(ps.positionalTolerancesChart.setPageSettingsToRedux(positionalTolerancesChartSet))
        dispatch(ps.boxPlotChart.setPageSettingsToRedux(boxPlotChartSet))
        dispatch(ps.linearRegressionChart.setPageSettingsToRedux(linearRegressionChartSet))
        dispatch(ps.correlationChart.setPageSettingsToRedux(correlationChartSet))
        dispatch(ps.qualityControlChart.setPageSettingsToRedux(qualityControlChartSet))
        dispatch(ps.capabilitySummaryChart.setPageSettingsToRedux(capabilitySummaryChartSet))
        dispatch(ps.paretoChart.setPageSettingsToRedux(paretoChartSet))
        dispatch(ps.probabilityPlotChart.setPageSettingsToRedux(probabilityPlotChartSet))
        dispatch(ps.decisionTreeChart.setPageSettingsToRedux(decisionTreeChartSet))
        dispatch(ps.valueList.setPageSettingsToRedux(valueListSet))
        dispatch(ps.summaryList.setPageSettingsToRedux(summaryListSet))
        dispatch(ps.valueSummaryList.setPageSettingsToRedux(valueSummaryListSet))
        dispatch(ps.scrollBoard.setPageSettingsToRedux(scrollBoardSet))
        dispatch(ps.configuration.setPageSettingsToRedux(configurationSet))
        dispatch(ps.adminSettings.setPageSettingsToRedux(adminSettingsSet))
        dispatch(ps.reportPrintMultiFunction.setPageSettingsToRedux(reportPrintMultiFunctionSet))

        dispatch(rd.setLanguage(langType))
        // switchLanguage(configurationSet.language)
        setLoading(false)
      })
    })
  }, [])

  const signIn = useCallback(async (email, password) => {
    try {
      let result = await authLogin(email, password)
      if (!ApiStatus.isSuccess(result.status)) {
        alert(getErrCommMsg(result.status))
      }

      JwtUtils.saveAccessToken(result.data.token)
      console.error(`savedToken : ${result.data.token}`)
      result = await getUserInfo(email, password)
      if (!ApiStatus.isSuccess(result.status)) {
        alert(getErrCommMsg(result.status))
      }

      const userInfo = result.data.user_info
      const uiDTO = dtoUtils.makeUserInfoToDTOFormat(userInfo)
      const updatedUserInfo = {
        ...uiDTO,
        user_role: convertUserRoleToString(uiDTO.user_role),
      }
      setUser(updatedUserInfo)

      return userInfo
    } catch (error) {
      signOut()
      alert(error)
    }
  }, [])

  const signOut = useCallback(() => {
    setUser(undefined)
    setLoading(false)
    JwtUtils.removeAccessToken()
  }, [])

  if (loading) {
    return <LoadPanel />
  }

  return <AuthContext.Provider value={{ user, signIn, signOut, loading }}>{children}</AuthContext.Provider>
}
const AuthContext = createContext({ loading: false })
const useAuth = () => useContext(AuthContext)

export { AuthProvider, useAuth }
