import { Column, Selection, TreeList } from 'devextreme-react/tree-list'
// import { Column, DataGrid, Editing, ColumnChooser, ColumnChooserSearch, ColumnChooserSelection, SearchPanel, Position, Selection, StateStoring } from 'devextreme-react/data-grid'
import { Button } from 'devextreme-react/button'
import { Toast } from 'devextreme-react/toast'
import produce from 'immer'
import { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import DQLoadPanel from 'src/components/dq-load-form/DQLoadPanel'
import { useAuth } from 'src/contexts/auth'
import { defCharField } from 'src/default/field/char'
import { defPartField } from 'src/default/field/part'
import { defValueField } from 'src/default/field/value'
import ETCUtils from 'src/functions/utils/EtCeteraUtils'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import * as dqsImpl from 'src/pages/components/dq-settings/DQSettingsImpl'
import './Configuration.scss'
import {
  defaultCharField,
  defaultPartField,
  defaultValueField,
} from './defines/default'
import { configurationSettingsPrm } from '../../settings/ConfigSettings'
import { connect } from 'react-redux'
import TextTitle from 'src/functions/TextTitle'
import { useLanguage } from 'src/contexts/languages'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus'

const mapStateToProps = (state) => ({
  common: state.common,
})

export default connect(mapStateToProps)(Configuration)

const makeUseFieldForDataGrid = (
  targetFieldArray,
  useFieldArray,
  rmFieldArray
) => {
  const fields = targetFieldArray

  const removedFields = fields.filter(
    (field) => !rmFieldArray.includes(field.dataField)
  )

  let useFields = []
  if (useFieldArray.length > 0) {
    useFields = removedFields.filter((field) =>
      useFieldArray.includes(field.dataField)
    )
  } else {
    useFields = removedFields
  }

  const dataSource = useFields.map((field, index) => ({
    ID: index + 1,
    ...field,
  }))

  return dataSource
}

const filterPartSelectedKeys = (saved, initList, listAll) => {
  let selectedKeys = []

  if (saved.length > 0) {
    const savedValues = saved.map((item) => item.value)
    const filteredList = listAll.filter((item) =>
      savedValues.includes(item.value)
    )
    selectedKeys = filteredList
      .map((item) => item.ID)
      .filter((item) => item !== undefined)
  }
  return selectedKeys
}

function Configuration({ pageKey, common }) {
  const dispatch = useDispatch()
  const { user } = useAuth()

  // const curPage = common.pages.configuration
  const curPage = common.pages[pageKey]
  const configAll = common.base

  const useFieldList = curPage.useFieldList

  const partDataSource = makeUseFieldForDataGrid(defPartField, [], [])
  const charDataSource = makeUseFieldForDataGrid(defCharField, [], [])
  const valueDataSource = makeUseFieldForDataGrid(defValueField, [], [])

  const partSelectedKeys = filterPartSelectedKeys(
    useFieldList.part,
    defaultPartField,
    partDataSource
  )
  const charSelectedKeys = filterPartSelectedKeys(
    useFieldList.char,
    defaultCharField,
    charDataSource
  )
  const valueSelectedKeys = filterPartSelectedKeys(
    useFieldList.value,
    defaultValueField,
    valueDataSource
  )

  const configPartList = useRef(null)
  const configCharList = useRef(null)
  const configValueList = useRef(null)

  const [toastConfig, setToastConfig] = useState({
    isVisible: false,
    type: 'info',
    message: '',
  })

  const handleToastDisplay = (type, message) => {
    setToastConfig({
      ...toastConfig,
      isVisible: true,
      type,
      message,
    })
  }

  function onHiding() {
    setToastConfig({
      ...toastConfig,
      isVisible: false,
    })
  }

  const handleSaveButton = async () => {
    const selectedPartFieldRow =
      configPartList.current.instance.getSelectedRowsData()
    const selectedCharFieldRow =
      configCharList.current.instance.getSelectedRowsData()
    const selectedValueFieldRow =
      configValueList.current.instance.getSelectedRowsData()

    const pageSettingsData = produce(curPage, (draft) => {
      draft.useFieldList.part = selectedPartFieldRow
      draft.useFieldList.char = selectedCharFieldRow
      draft.useFieldList.value = selectedValueFieldRow
    })

    dqsImpl.setCurBaseSettings(
      dispatch,
      user,
      configAll,
      'configuration',
      pageSettingsData
    )
    handleToastDisplay(
      'success',
      dqTranslateMsg('Page_Configuration_' + 'SavedSettings')
    )
  }
  const handleResetButton = async () => {
    const pageSettingsData = produce(curPage, (draft) => {
      draft.useFieldList = configurationSettingsPrm.useFieldList
    })
    dqsImpl.setCurBaseSettings(
      dispatch,
      user,
      configAll,
      'configuration',
      pageSettingsData
    )
    handleToastDisplay(
      'warning',
      dqTranslateMsg('Page_Configuration_' + 'ResetSettings')
    )
  }

  const translateFieldText = (type, cell) => {
    const translated = dqTranslateMsg('Db_field_' + type + '_' + cell.data.text)
    return <div>{translated}</div>
  }

  const handlePartSelectionChanged = (e) => {
    const selectedRowData = e.selectedRowsData
    const next = produce(curPage, (draft) => {
      draft.useFieldList.part = selectedRowData
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }
  const handleCharSelectionChanged = (e) => {
    const selectedRowData = e.selectedRowsData
    const next = produce(curPage, (draft) => {
      draft.useFieldList.char = selectedRowData
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }
  const handleValueSelectionChanged = (e) => {
    const selectedRowData = e.selectedRowsData
    const next = produce(curPage, (draft) => {
      draft.useFieldList.value = selectedRowData
    })

    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  return (
    <div className="configuration-container">
      <Toast
        width={400}
        visible={toastConfig.isVisible}
        message={toastConfig.message}
        type={toastConfig.type}
        onHiding={onHiding}
        displayTime={600}
      />
      <div className="configuration-control-container">
        <div className="button-container">
          <Button
            id="partLoadButton"
            icon="save"
            text={dqTranslateMsg('Page_Configuration_' + 'Save')}
            width={'100%'}
            height={'100%'}
            onClick={handleSaveButton}
          />
        </div>
        <div className="button-container">
          <Button
            id="partLoadButton"
            icon="undo"
            text={dqTranslateMsg('Page_Configuration_' + 'Reset')}
            width={'100%'}
            height={'100%'}
            onClick={handleResetButton}
          />
        </div>
      </div>
      <div className="configuration-content-container">
        <div className="configuration-datagrid-container">
          <TextTitle
            text={dqTranslateMsg('Page_Configuration_' + 'PartField')}
            width="50%"
            fontSize={'16px'}
            textAlign={'left'}
          />
          <TreeList
            ref={configPartList}
            className="configuration-datagrid-content"
            id="configuration-char-treelist"
            dataSource={partDataSource}
            showRowLines={true}
            showBorders={true}
            columnAutoWidth={true}
            selectedRowKeys={partSelectedKeys}
            keyExpr="ID"
            parentIdExpr="HeadID"
            scrolling={{
              columnRenderingMode: 'standard',
              rowRenderingMode: 'standard',
              mode: 'standard',
              preloadEnabled: false,
              renderAsync: false,
              showScrollbar: 'onHover',
              useNative: false,
            }}
            onSelectionChanged={handlePartSelectionChanged}
          >
            <Selection recursive={true} mode="multiple" />
            <Column
              dataField="text"
              caption={dqTranslateMsg('Page_Configuration_' + 'PartField')}
            />
            <Column
              dataField="text"
              caption={dqTranslateMsg('Page_Configuration_' + 'Description')}
              cellRender={(cell) => translateFieldText('Part', cell)}
            />
          </TreeList>
        </div>
        <div className="configuration-datagrid-container">
          <TextTitle
            text={dqTranslateMsg(
              'Page_Configuration_' + 'CharacteristicsField'
            )}
            width="50%"
            fontSize={'16px'}
            textAlign={'left'}
          />
          <TreeList
            ref={configCharList}
            className="configuration-datagrid-content"
            id="configuration-char-treelist"
            dataSource={charDataSource}
            showRowLines={true}
            showBorders={true}
            columnAutoWidth={true}
            selectedRowKeys={charSelectedKeys}
            keyExpr="ID"
            parentIdExpr="HeadID"
            scrolling={{
              columnRenderingMode: 'standard',
              rowRenderingMode: 'standard',
              mode: 'standard',
              preloadEnabled: false,
              renderAsync: false,
              showScrollbar: 'onHover',
              useNative: false,
            }}
            onSelectionChanged={handleCharSelectionChanged}
          >
            <Selection recursive={true} mode="multiple" />
            <Column
              dataField="text"
              caption={dqTranslateMsg(
                'Page_Configuration_' + 'CharacteristicsField'
              )}
            />
            <Column
              dataField="text"
              caption={dqTranslateMsg('Page_Configuration_' + 'Description')}
              cellRender={(cell) => translateFieldText('Characteristics', cell)}
            />
          </TreeList>
        </div>
        <div className="configuration-datagrid-container">
          <TextTitle
            text={dqTranslateMsg('Page_Configuration_' + 'ValueField')}
            width="50%"
            fontSize={'16px'}
            textAlign={'left'}
          />
          <TreeList
            ref={configValueList}
            className="configuration-datagrid-content"
            id="configuration-value-treelist"
            dataSource={valueDataSource}
            showRowLines={true}
            showBorders={true}
            columnAutoWidth={true}
            selectedRowKeys={valueSelectedKeys}
            keyExpr="ID"
            parentIdExpr="HeadID"
            scrolling={{
              columnRenderingMode: 'standard',
              rowRenderingMode: 'standard',
              mode: 'standard',
              preloadEnabled: false,
              renderAsync: false,
              showScrollbar: 'onHover',
              useNative: false,
            }}
            onSelectionChanged={handleValueSelectionChanged}
          >
            <Selection recursive={true} mode="multiple" />
            <Column
              dataField="text"
              caption={dqTranslateMsg('Page_Configuration_' + 'ValueField')}
            />
            <Column
              dataField="text"
              caption={dqTranslateMsg('Page_Configuration_' + 'Description')}
              cellRender={(cell) => translateFieldText('Value', cell)}
            />
          </TreeList>
        </div>
      </div>
    </div>
  )
}
