import { GroupItem } from 'devextreme-react/form'
import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import AdvancedFilterItem from 'src/pages/components/dq-wrapper-ui-devexpress/AdvancedFilterItem'
import ButtonSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/ButtonSimpleItem'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import produce from 'immer'
import CPUtils from 'src/functions/CommonPageUtils'
import { pageType } from 'src/functions/table/PageTypeTable'
import { msgType } from 'src/functions/table/MessageTypeTable'
import { getErrProcMsg } from 'src/functions/CommonMassageUtils'
import { procErrTable } from 'src/functions/table/ProcMessageTable'
import CatalogUtils from 'src/functions/CatalogUtils'
import TextBoxNoValueSimpleItem from 'src/pages/components/dq-wrapper-ui-devexpress/TextBoxNoValueSimpleItem'

const TAB_SIZE = 4

function prepareItem(item, spaces) {
  return Array.isArray(item[0]) ? formatValue(item, spaces + TAB_SIZE) : JSON.stringify(item)
}
function formatValue(value, spaces = TAB_SIZE) {
  if (value && Array.isArray(value[0])) {
    const formattedValue = value.map((item) => prepareItem(item, spaces)).join(`,${getLineBreak(spaces)}`)
    return `[${getLineBreak(spaces)}${formattedValue}${getLineBreak(spaces - TAB_SIZE)}]`
  }
  return JSON.stringify(value)
}
function getLineBreak(spaces) {
  return `\r\n${new Array(spaces + 1).join(' ')}`
}

function makeFieldsByFieldList(useFieldListAll) {
  const fields = useFieldListAll.map((fieldInfo) => {
    const field = {
      dataField: fieldInfo.numValue,
      caption: fieldInfo.text + ` (${fieldInfo.numValue})`,
      dataType: 'string',
      filterOperations: ['contains', '='],
    }

    if (CatalogUtils.isCatalogField(fieldInfo.value)) {
      field.lookup = {
        dataSource: ['1', '2', '3'],
      }
    }

    if (field.dataField === '0004') {
      field.dataType = 'datetime'
      field.filterOperations = ['<=', '>=']
    }

    return field
  })

  return fields
}

const DBFieldAdvancedRealTimeFilter = (pageKey, pageState, configState) => {
  const dispatch = useDispatch()
  const configSettings = configState
  const filterNameTbRef = useRef(null)

  const [filters, setFilters] = useState(pageState.filters.advanced)
  // const filters = useRef(null)

  const isCommonEmpty = Object.keys(configSettings.useFieldList).length === 0
  const isLsUseFieldListEmpty = Object.keys(configSettings.useFieldList).length === 0

  let useFieldList = {}
  if (isCommonEmpty === false) {
    useFieldList = configSettings.useFieldList
  } else if (isLsUseFieldListEmpty === false) {
    useFieldList = configSettings.useFieldList
  } else {
    useFieldList = {
      part: [],
      char: [],
      value: [],
    }
  }
  const usePartFilterField = CPUtils.getConfigItemListFromUseField('Part', false, null, useFieldList.part, configState)
  const useCharFilterField = CPUtils.getConfigItemListFromUseField('Characteristics', false, null, useFieldList.char, configState)
  const useValueFilterField = CPUtils.getConfigItemListFromUseField('Value', false, null, useFieldList.value, configState)

  // filters.current = pageState.filters.advanced
  useFieldList = [...usePartFilterField, ...useCharFilterField, ...useValueFilterField]
  const fields = makeFieldsByFieldList(useFieldList)

  const onValueChanged = (e) => {
    console.log(e)
    setFilters(e.value)
  }
  const isCompositeCondition = (condition) => {
    return Array.isArray(condition) && Array.isArray(condition[0])
  }

  const isValidFieldRange = (beforeField, integerField) => {
    if (beforeField >= 0 && beforeField < 1000) {
      return integerField < 1000
    } else if (beforeField >= 1000 && beforeField < 2000) {
      return integerField >= 1000 && integerField < 2000
    } else if ((beforeField >= 2000 && beforeField < 3000) || (beforeField >= 8000 && beforeField < 9000)) {
      return (integerField >= 2000 && integerField < 3000) || (integerField >= 8000 && integerField < 9000)
    }
    return false
  }

  const validateFilters = (filters) => {
    if (filters === null) {
      return true
    }

    for (const element of filters) {
      if (isCompositeCondition(element)) {
        let beforeField = -1

        for (const el of element) {
          if (Array.isArray(el)) {
            const [field] = el
            const integerField = parseInt(field)

            if (beforeField === -1) {
              beforeField = integerField
            } else {
              if (!isValidFieldRange(beforeField, integerField)) {
                return false
              }
            }
          }
        }
      }
    }
    return true
  }

  const onApplyButtonClick = () => {
    // if (!validateFilters(filters.current)) {
    if (!validateFilters(filters)) {
      alert(getErrProcMsg(procErrTable.E00001))
      return
    }
    console.log('applied')
    const next = produce(pageState, (draft) => {
      // draft.filters.advanced.data = filters.current
      draft.filters.advanced.data = filters
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
  }

  const onClearButtonClick = () => {
    console.log('clear')
    const next = produce(pageState, (draft) => {
      draft.filters.advanced.data = null
    })
    dispatch(ps[pageKey].setPageSettingsToRedux(next))
    setFilters(null)
  }

  const onFilterNameSaveButtonClick = () => {
    console.log('applied')

    if (filterNameTbRef.current) {
      const filterName = filterNameTbRef.current.instance.option('value')

      if (filterName === '') {
        alert('Please Enter Filter Name')
        return
      }

      const filterFormat = {
        filterName,
        common: pageState.filters.advanced.common,
        others: pageState.filters.advanced.others,
        data: pageState.filters.advanced.data,
      }

      const sameNameFilter = pageState.filters.advanced.savedFilters.find((filter) => filter.filterName === filterFormat.filterName)
      if (sameNameFilter) {
        alert('same name filter is exist')
        return
      }

      const next = produce(pageState, (draft) => {
        draft.filters.advanced.savedFilters.push(filterFormat)
      })
      dispatch(ps[pageKey].setPageSettingsToRedux(next))
    }
  }

  const onFilterNameClearButtonClick = () => {
    console.log('Filter Name Clear')
    if (filterNameTbRef.current) {
      filterNameTbRef.current.instance.option('value', '')
    }
  }

  return (
    <GroupItem key={`db-field-advanced-real-time-group-item`} colCount={2} caption={dqTranslateMsg('SidePanel_' + 'DBFieldAdvancedFilter')}>
      {AdvancedFilterItem({
        colSpan: 2,
        fields: fields,
        // value: filters.current,
        value: filters,
        maxGroupLevel: 1,
        onValueChanged: onValueChanged,
        // onContentReady: onContentReady,
      })}
      {ButtonSimpleItem({
        colSpan: 1,
        type: 'normal',
        text: dqTranslateMsg('SidePanel_' + 'Apply'),
        icon: 'check',
        width: '100%',
        height: '100%',
        onClick: onApplyButtonClick,
      })}
      {ButtonSimpleItem({
        colSpan: 1,
        type: 'normal',
        text: dqTranslateMsg('SidePanel_' + 'ClearAll'),
        icon: 'clear',
        width: '100%',
        height: '100%',
        onClick: onClearButtonClick,
      })}
      {/* {TextBoxNoValueSimpleItem({
        colSpan: 2,
        label: dqTranslateMsg('SidePanel_' + 'FilterName'),
        labelLocation: 'top',
        ref: filterNameTbRef,
      })}
      {ButtonSimpleItem({
        colSpan: 1,
        type: 'normal',
        text: dqTranslateMsg('SidePanel_' + 'Save'),
        icon: 'check',
        width: '100%',
        height: '100%',
        onClick: onFilterNameSaveButtonClick,
      })}
      {ButtonSimpleItem({
        colSpan: 1,
        type: 'normal',
        text: dqTranslateMsg('SidePanel_' + 'Clear'),
        icon: 'clear',
        width: '100%',
        height: '100%',
        onClick: onFilterNameClearButtonClick,
      })} */}
    </GroupItem>
  )
}

export default DBFieldAdvancedRealTimeFilter
