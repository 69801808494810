import styled, { css, keyframes } from 'styled-components'
import produce from 'immer'
import { Button } from 'devextreme-react'
import { useState } from 'react'
import ApiStatus from 'src/api/defines/ApiStatus'
import DQLoadPanel from 'src/components/dq-load-form/DQLoadPanel'
import { useApi } from 'src/contexts/api'
import { batch, useDispatch, useSelector } from 'react-redux'
import { pageStatusList as ps } from 'src/functions/constants/pageStatus.js'
import { useAuth } from 'src/contexts/auth'
import JavaApiClient from 'src/api/JavaApiClient'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import { getErrCommMsg } from 'src/functions/CommonMassageUtils'

const rotateAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const StyledButton = styled(Button)`
  width: 100%;
  height: 100%;

  & .dx-button-content {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & .dx-icon {
    font-size: 20px; // 아이콘의 크기를 증가
    ${(props) =>
      props.isAnimating &&
      css`
        animation: ${rotateAnimation} 1s linear infinite;
      `}
  }
`

export default function ControlPanelOneClick(props) {
  const { className, id, data, curPage, configPage, icon, text, stepReferenceInit, pageLength, pageTypes, disabled, tempStepReference, setStepReference, handleHomePage } = props
  const pageKey = 'scrollBoard'
  const { user } = useAuth()
  const { spring } = useApi()

  const [loading, setLoading] = useState(false)
  const [isAnimating, setIsAnimating] = useState(false)
  const dispatch = useDispatch()
  // const isOrderChartListValid = useSelector((state) => state.common.pages.scrollBoard.page.orderChartListOrderValid)

  const fields = pageTypes.map((item) => item.field)
  const types = pageTypes.map((item) => item.type)

  const handleClick = (e) => {
    // if (!isOrderChartListValid) {
    //   alert('Order Chart List is invalid')
    // }

    setIsAnimating(true)
    setLoading(true)

    const convertedStartDateTime = JavaApiClient.calcStartDateTime(curPage.page.dateRangeIsDayTime, curPage.page.dateRangeDayTime, curPage.page.dateRangeSetting, curPage.page.dateRangeDayOffset)
    const convertedEndDateTime = JavaApiClient.calcEndDateTime(curPage.page.dateRangeIsDayTime, curPage.page.dateRangeDayTime)

    const filter = produce(curPage.filter, (draft) => {
      if (user.user_role === 'demo') {
        draft.partFilter.push({
          group: 0,
          field: 1104,
          data: 'demo',
          logic: 'and',
          equal: 'equal',
        })
      }
    })

    spring
      .updateIBoardManual(id, curPage.page, filter, convertedStartDateTime, convertedEndDateTime)
      .then((result) => {
        if (!ApiStatus.isSuccess(result.status)) {
          alert(getErrCommMsg(result.status))
        }

        const totalCount = result.data

        spring
          .getIBoardResultData({
            user_id: user.user_id,
            field_data: 0,
          })
          .then((result) => {
            if (!ApiStatus.isSuccess(result.status)) {
              alert(getErrCommMsg(result.status))
            }

            const data = result.data
            batch(() => {
              const newStepReference = {
                step: 0,
                key: null,
                data: data,
                dataIndex: 0,
                dataTotalLen: data.length,
                field: fields[0],
                type: types[0],
              }

              const nextStep = produce(stepReferenceInit, (draft) => {
                draft.curStep = 0
                draft.totalSteps = fields.length
                draft.fields = fields
                draft.types = types
                draft.steps.push(newStepReference)
              })

              localStorage.setItem('scroll-board-step-reference', JSON.stringify(nextStep))

              tempStepReference.current = nextStep
              setStepReference(nextStep)

              const next = produce(curPage, (draft) => {
                draft.common.mode = 'manual'
                draft.page.savedDataRangeStartDateTime = convertedStartDateTime
                draft.page.savedDataRangeEndDateTime = convertedEndDateTime
                draft.page.updatedCount = totalCount
                draft.page.updatedTime = new Date()
                draft.page.dataUpdated = true
              })

              dispatch(ps[pageKey].setPageSettingsToRedux(next))
              handleHomePage()
            })
          })
      })
      .finally(() => {
        setIsAnimating(false)
        setLoading(false)
      })
  }

  return (
    <div className={className}>
      <DQLoadPanel open={loading} message={dqTranslateMsg('Common_' + 'LoadPanelLoading')} />
      <StyledButton icon={icon} text={text} disabled={disabled} focusStateEnabled={false} isAnimating={isAnimating} onClick={handleClick} />
    </div>
  )
}
