import { Column, ColumnChooser, ColumnChooserSearch, ColumnChooserSelection, DataGrid, GroupPanel, Grouping, Item, Position, Scrolling, StateStoring, Toolbar } from 'devextreme-react/data-grid'
import { Sparkline } from 'devextreme-react/sparkline'
import { useEffect, useRef, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import ApiStatus from 'src/api/defines/ApiStatus'
import { getSummary } from 'src/api/summary-list'
import { useTheme } from 'src/contexts/theme'
import { dqTranslateMsg } from 'src/pages/components/dq-convert/DQLanguage'
import './SummaryListForm.scss'
import * as slImpl from './SummaryListFormImpl'
import CPUtils from 'src/functions/CommonPageUtils'
import { connect } from 'react-redux'
import styled from 'styled-components'
import { renderResult, renderCp, renderCpk, renderOutOfTol, renderCalcDisplay, renderDBValueDisplay } from './SummaryListCellRenderers'
import useDevChartsResize from 'src/hook/devexpress-charts/useDevChartResize'
import PCUtils from 'src/functions/ProcessCapabilityUtils'
import DNUtils from 'src/functions/DisplayNumberUtils'
import * as DBUtils from 'src/functions/DatabaseUtils'
import { outlierFilterValues } from 'src/functions/ValueProcessUtils'
import useCustomStateStore from 'src/hook/devexpress-custom-state-storing/useDevStateStoring'

const mapStateToProps = (state) => ({
  common: state.common,
  chartRawData: state.chartRawData,
})

export default connect(mapStateToProps)(SummaryListForm)

function SummaryListForm({ pageKey, common, chartRawData }) {
  const { saveState, loadState, clearState } = useCustomStateStore({ stateKey: 'summary-list-datagrid', excludedFields: [] })

  const { setTheme, getTheme } = useTheme()
  const [dataSourceState, setDataSourceState] = useState([])
  const [focusedRowKey, setFocusedRowKey] = useState(0)
  const [chartSize, setChartSize] = useState({ width: 250, height: 30 })
  // let dataSourceState
  const summaryListGrid = useRef(null)

  const sparkChartParentRefs = useRef(null)
  const sparkChartRefs = useRef(null)
  const curPage = common.pages.summaryList
  const configPage = common.pages.configuration
  const currentSel = common.current_sel
  const viewData = curPage.page.extraFieldList
  const selectedChar = common.loaded_all[pageKey]

  function renderTrendChart(cellData) {
    console.log('renderTrendChart')
    const uniqueRefKey = cellData.data.no - 1
    return (
      <div
        ref={(e) => {
          sparkChartParentRefs.current[uniqueRefKey] = e
        }}
        style={{ width: '100%', height: '100%' }}
      >
        <Sparkline
          ref={(e) => {
            sparkChartRefs.current[uniqueRefKey] = e
          }}
          dataSource={cellData.value}
          showMinMax={false}
          showFirstLast={false}
          tooltip={{ enabled: true }}
          argumentField="index"
          valueField={'v0001'}
          pathModified={false}
          type="line"
          // size={{ width: cellData.cellElement.clientWidth - 30, height: cellData.cellElement.clientHeight }}
          size={chartSize}
        />
      </div>
    )
  }

  const handleSaveState = () => {
    if (summaryListGrid.current) {
      const state = summaryListGrid.current.instance.state()
      saveState(state)
    }
  }

  const handleLoadState = () => {
    if (summaryListGrid.current) {
      const state = loadState()
      if (state) {
        summaryListGrid.current.instance.state(state)
      }
    }
  }

  const handleClearState = () => {
    clearState()
    if (summaryListGrid.current) {
      const emptyState = {
        columns: [],
        sorting: [],
        grouping: [],
        selectionFilter: [],
        pageIndex: 0,
        pageSize: 10,
      }
      summaryListGrid.current.instance.state(emptyState)
    }
  }

  const handleKeyDown = (e) => {
    if (summaryListGrid) {
      if (e.event.key === 'Escape') {
        summaryListGrid.current.instance.hideColumnChooser()
      }
    }
  }

  const handleReady = () => {
    console.log('handle ready')
    if (sparkChartParentRefs.current) {
      sparkChartParentRefs.current.forEach((element, index) => {
        if (element) {
          console.log('111')
          const newWidth = element.clientWidth
          const newHeight = element.clientHeight
          if (sparkChartRefs.current[index]) {
            const size = sparkChartRefs.current[index].instance.option('size')
            if (size.width === chartSize.width && size.height === chartSize.height) {
              return
            }

            sparkChartRefs.current[index].instance.option('size', {
              width: newWidth,
              height: newHeight,
            })
            setChartSize({ width: newWidth, height: newHeight }) // 상태로 차트 크기 업데이트
            console.log('set', newWidth, newHeight)
          }
        }
      })
    }
  }

  useEffect(() => {
    const { parts, chars, values } = CPUtils.loadRefChartData(chartRawData)
    const { charArray } = CPUtils.loadRefOtherData(common, chars, pageKey)

    const selectedValueWithDummy = CPUtils.checkMatchCharsAndValues(charArray, values)
    const valuesArranged = CPUtils.removeDummyInValues(selectedValueWithDummy)
    const numCharts = chars.length
    sparkChartParentRefs.current = new Array(numCharts).fill(null).map(() => null)
    sparkChartRefs.current = new Array(numCharts).fill(null).map(() => null)

    const tableRow = (part, char, values, index, info, settings) => {
      const lsl = PCUtils.getLsl(char)
      const usl = PCUtils.getUsl(char)
      const valueArray = values.valueRaw.filter((data) => data.v0002 === 0).map((item) => item.v0001)
      const min = info ? info.minimum : null
      const max = info ? info.maximum : null
      const partID = char.part_id
      const charID = char.char_id
      const partName = part.p1002
      const charDesc = slImpl.makeCharDescription(char)
      const nominal = PCUtils.getNominal(char)
      const upAlw = PCUtils.getUpperAllowance(char)
      const loAlw = PCUtils.getLowerAllowance(char)
      const { sum, length } = PCUtils.getSum(valueArray)
      const average = PCUtils.getAverage(sum, length)
      const median = PCUtils.getMedian(valueArray)
      const stdDev = info ? info.stdev : null
      const cp = info ? info.cp : null
      const cpk = info ? info.cpk : null
      const result = slImpl.makeResult(cp, cpk, settings)
      const outOfTol = slImpl.makeOutOfTolerance(
        values.valueRaw.filter((data) => data.v0002 === 0),
        lsl,
        usl
      )

      const convertedValue = values.valueRaw
        .filter((data) => data.v0002 === 0)
        .map((value, index) => ({
          index: index + 1,
          v0001: value.v0001,
        }))
      const trendData = slImpl.makeTrend(convertedValue)

      return {
        no: index + 1,
        part_name: partName,
        desc: charDesc,
        nominal,
        usl,
        lsl,

        upAlw,
        loAlw,
        average,
        median,
        stdDev,
        min,
        max,
        cp,
        cpk,
        result,
        out_of_tol: outOfTol,
        trend: trendData,
        // extra data
        partID,
        charID,
      }
    }
    const processSummaryData = async (valueArray, targetChars, settings, configSettings) => {
      let tableDataArray = []

      try {
        await Promise.all(
          valueArray.map(async (valueObj, index) => {
            const targetPart = valueObj.partRaw
            const targetChar = valueObj.charRaw
            const targetValue = valueObj.valueRaw

            const { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset } = PCUtils.getAllLimits(targetChar, configSettings)
            const limits = { lsl, usl, nominal, lwl, uwl, lpl, upl, lrl, url, lacl, uacl, gap, warnOffset }
            const filteredValue = outlierFilterValues(limits, targetChar, targetValue, configSettings)

            if (targetValue.length < 2) {
              tableDataArray.push(tableRow(targetPart, targetChar, valueObj, index, null, settings))
              return
            }

            const result = await getSummary(targetChar, filteredValue, settings, configSettings)
            if (!ApiStatus.isSuccess(result.status)) {
              alert(getErrCommMsg(result.status))
            }

            tableDataArray.push(tableRow(targetPart, targetChar, valueObj, index, result.data, settings))
          })
        )
      } catch (error) {
        console.error('Error processing summary data:', error)
      }

      return tableDataArray
    }

    processSummaryData(valuesArranged, chars, curPage, configPage)
      .then((result) => {
        console.log('Table data array:', result)
        const sortedData = result.sort((a, b) => {
          if (a.partID !== b.partID) {
            return a.partID - b.partID
          } else {
            return a.charID - b.charID
          }
        })
        setDataSourceState(sortedData)
      })
      .catch((error) => {
        console.error('Error:', error)
      })
  }, [selectedChar, curPage, configPage])

  useEffect(() => {
    if (summaryListGrid.current) {
      const dataGridInstance = summaryListGrid.current.instance
      const rows = dataGridInstance.option('dataSource')
      const targetRow = rows.find((row) => {
        if (currentSel.part_id === row.partID && currentSel.char_id === row.charID) {
          return true
        }
        return false
      })

      if (targetRow) {
        setFocusedRowKey(targetRow.no)
      }
    }
  }, [currentSel])

  useEffect(() => {
    handleLoadState()
  }, [])

  return (
    <div className="dx-theme-background-color">
      <DataGrid
        className="summary-list-grid-container"
        ref={summaryListGrid}
        id="summary-list-datagrid"
        dataSource={[...dataSourceState]}
        allowColumnReordering={true}
        allowColumnResizing={true}
        columnResizingMode={'nextColumn'}
        // columnMinWidth={100}
        columnAutoWidth={false}
        filterRow={{ visible: false }}
        noDataText={dqTranslateMsg('Page_SummaryList_' + 'NoData')}
        paging={{ enabled: false }}
        showColumnLines={true}
        showRowLines={true}
        showBorders={true}
        cacheEnabled={true}
        rowAlternationEnabled={true}
        hoverStateEnabled={true}
        focusedRowEnabled={true}
        focusedRowKey={focusedRowKey}
        autoNavigateToFocusedRow={true}
        onKeyDown={handleKeyDown}
        onContentReady={handleReady}
        keyExpr={'no'}
        width="100%"
      >
        <GroupPanel visible={true} emptyPanelText={dqTranslateMsg('Page_SummaryList_' + 'EmptyPanelText')} />
        <Grouping autoExpandAll={true} />
        <StateStoring enabled={true} type="localStorage" storageKey="storage-grid-summary-list" />
        <Scrolling mode="standard" useNative={false} preloadEnabled={true} renderAsync={false} scrollByContent={true} scrollByThumb={true} />
        <Column dataField="part_name" caption={dqTranslateMsg('Db_field_Part_' + 'P1002')} alignment="center" groupIndex={0} />
        <Column dataField="desc" caption={dqTranslateMsg('Page_SummaryList_' + 'Description')} alignment="center" minWidth={100} width="auto" />
        <Column
          dataField="average"
          caption={dqTranslateMsg('Page_SummaryList_' + 'Average')}
          alignment="center"
          minWidth={100}
          width={'auto'}
          visible={true}
          cellRender={(cellData) => renderCalcDisplay(cellData.data.average, configPage)}
        />
        <Column
          dataField="median"
          caption={dqTranslateMsg('Page_SummaryList_' + 'Median')}
          alignment="center"
          minWidth={100}
          width={'auto'}
          visible={true}
          cellRender={(cellData) => renderCalcDisplay(cellData.data.median, configPage)}
        />
        <Column
          dataField="stdDev"
          caption={dqTranslateMsg('Page_SummaryList_' + 'StandardDeviation')}
          alignment="center"
          minWidth={100}
          width={'auto'}
          visible={true}
          cellRender={(cellData) => renderCalcDisplay(cellData.data.stdDev, configPage)}
        />
        <Column
          dataField="min"
          caption={dqTranslateMsg('Page_SummaryList_' + 'Minimum')}
          minWidth={100}
          width={'auto'}
          alignment="center"
          visible={true}
          cellRender={(cellData) => renderCalcDisplay(cellData.data.min, configPage)}
        />
        <Column
          dataField="max"
          caption={dqTranslateMsg('Page_SummaryList_' + 'Maximum')}
          minWidth={100}
          width={'auto'}
          alignment="center"
          visible={true}
          cellRender={(cellData) => renderCalcDisplay(cellData.data.max, configPage)}
        />
        <Column
          dataField="nominal"
          caption={dqTranslateMsg('Page_SummaryList_' + 'Nominal')}
          minWidth={100}
          width={'auto'}
          alignment="center"
          visible={true}
          cellRender={(cellData) => renderDBValueDisplay(cellData.data.nominal, configPage)}
        />
        <Column
          dataField="usl"
          caption={dqTranslateMsg('Page_SummaryList_' + 'USL')}
          minWidth={100}
          width={'auto'}
          alignment="center"
          visible={true}
          cellRender={(cellData) => renderDBValueDisplay(cellData.data.usl, configPage)}
        />
        <Column
          dataField="lsl"
          caption={dqTranslateMsg('Page_SummaryList_' + 'LSL')}
          minWidth={100}
          width={'auto'}
          alignment="center"
          visible={true}
          cellRender={(cellData) => renderDBValueDisplay(cellData.data.lsl, configPage)}
        />
        <Column
          dataField="upAlw"
          caption={dqTranslateMsg('Page_SummaryList_' + 'UpperAllowance')}
          minWidth={100}
          width={'auto'}
          alignment="center"
          visible={true}
          cellRender={(cellData) => renderDBValueDisplay(cellData.data.upAlw, configPage)}
        />
        <Column
          dataField="loAlw"
          caption={dqTranslateMsg('Page_SummaryList_' + 'LowerAllowance')}
          minWidth={100}
          width={'auto'}
          alignment="center"
          visible={true}
          cellRender={(cellData) => renderDBValueDisplay(cellData.data.loAlw, configPage)}
        />
        <Column
          dataField="cp"
          caption={dqTranslateMsg('Page_SummaryList_' + 'Cp')}
          minWidth={100}
          width={'auto'}
          alignment="center"
          visible={true}
          cellRender={(cellData) => renderCp(cellData, curPage, configPage)}
        />
        <Column
          dataField="cpk"
          caption={dqTranslateMsg('Page_SummaryList_' + 'Cpk')}
          minWidth={100}
          width={'auto'}
          alignment="center"
          cellRender={(cellData) => renderCpk(cellData, curPage, configPage)}
        />
        <Column
          dataField="result"
          caption={dqTranslateMsg('Page_SummaryList_' + 'Result')}
          minWidth={100}
          width={'auto'}
          alignment="center"
          visible={true}
          cellRender={(cellData) => renderResult(cellData, curPage, configPage, getTheme())}
        />
        <Column
          dataField="out_of_tol"
          caption={dqTranslateMsg('Page_SummaryList_' + 'OutOfTol')}
          minWidth={100}
          width={'auto'}
          alignment="center"
          visible={true}
          cellRender={(cellData) => renderOutOfTol(cellData, curPage, configPage, getTheme())}
        />
        <Column
          dataField="trend"
          caption={dqTranslateMsg('Page_SummaryList_' + 'Trend')}
          alignment="center"
          minWidth={200}
          width="auto"
          allowResize={false}
          visible={true}
          cellRender={(cellData) => renderTrendChart(cellData)}
        />
        <Toolbar>
          <Item
            widget="dxButton"
            options={{
              icon: 'save',
              text: dqTranslateMsg('Page_SummaryList_' + 'SaveLayout'),
              onClick: () => handleSaveState(summaryListGrid, saveState),
            }}
            location="before"
          />
          <Item
            widget="dxButton"
            options={{
              icon: 'clear',
              text: dqTranslateMsg('Page_SummaryList_' + 'ClearLayout'),
              onClick: () => handleClearState(summaryListGrid, clearState),
            }}
            location="before"
          />
          <Item name="columnChooserButton" locateInMenu="never" location="after" />
        </Toolbar>
        <ColumnChooser
          enabled={true}
          mode={'select'}
          width={300}
          height={500}
          title={dqTranslateMsg('Page_SummaryList_' + 'ColumnChooserTitle')}
          emptyPanelText={dqTranslateMsg('Page_SummaryList_' + 'ColumnChooserEmptyPanelText')}
        >
          <Position my="center" at="center" of={window} />
          <ColumnChooserSearch
            enabled={true}
            editorOptions={{
              placeholder: dqTranslateMsg('Page_SummaryList_' + 'ColumnChooserPlaceHoler'),
            }}
          />
          <ColumnChooserSelection allowSelectAll={true} selectByClick={true} recursive={true} />
        </ColumnChooser>
      </DataGrid>
    </div>
  )
}
